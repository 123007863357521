import {
  ChatAction,
  ChatActions,
  SET_WORKGROUP_CHATS,
  SET_WORKGROUP_CHATS_ACTIVITY_LEVEL,
  SET_WORKGROUP_CHATS_NOT_READ,
} from "./ChatAction";
import { ChatState } from "./ChatState";

const DEFAULT_STATE: ChatState = {
  workgroupChatsNotRead: [],
  WorkgroupChats: [],
  activityLevel: 0,
};

export const chatReducer = (
  state: ChatState = DEFAULT_STATE,
  action: ChatAction
): ChatState => {
  let res = chatReducerInner(state, action);
  (window as any)["chatReducerState"] = res;
  return res;
};

export const chatReducerInner = (
  state: ChatState = DEFAULT_STATE,
  action: ChatAction
): ChatState => {
  switch (action.type) {
    case SET_WORKGROUP_CHATS_NOT_READ:
      return {
        ...state,
        workgroupChatsNotRead: action.payload,
      };
    case SET_WORKGROUP_CHATS:
      return {
        ...state,
        WorkgroupChats: action.payload,
      };
    case SET_WORKGROUP_CHATS_ACTIVITY_LEVEL:
      return {
        ...state,
        activityLevel: action.payload,
      };
    default:
      return state;
  }
};
