import { useState } from "react";
import "./Accordion.scss";
import { Icon, Label } from "@fluentui/react";

export interface IAccordionProps {
  title: string | JSX.Element;
  children: JSX.Element;
}

const Accordion = (props: IAccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="accordion">
      <div className="accordion-header" onClick={() => setIsOpen(!isOpen)}>
        <div className="accordion-header-left">
          <Icon iconName={isOpen ? "ChevronDown" : "ChevronRight"} />
          <Label>{props.title}</Label>
        </div>
      </div>
      {isOpen && <div className="accordion-content">{props.children}</div>}
    </div>
  );
};

export default Accordion;
