export const Dictionary = {
  __presentation_1:
    "The site for writers of fantasy books, novels and short stories with more complex plots.",
  __presentation_2:
    "Plotter Studio is a platform created in collaboration with writers for writers.",
  __presentation_3: "Event-based plot writing",
  __presentation_4: "Time order",
  __presentation_5:
    "Keep all the key events of your plot in order by placing them on a timeline. Events can be classified by type, characters involved, keywords and more!",
  __presentation_6: "Control",
  __presentation_7:
    "Check the consistency of the inserted events, so as to avoid plot holes or inconsistencies. Plotter Studio will perform the analysis of all events for you and find potential errors.",
  __presentation_8: "Teamwork",
  __presentation_9:
    "Plotter Studio allows users to invite others to collaborate on the same book, thus allowing professional effectiveness even in a work environment.",
  __presentation_10: "Intuitive interface",
  __presentation_11:
    "Using Plotter Studio has been optimized to be easy to use, so you can focus on your book without having to think about anything else.",
  __presentation_12: "Do you have any questions?",
  __presentation_12_2:
    "The Plotter Studio team is at your disposal to answer all your questions",
  __presentation_13: "Contact us",
  __presentation_14: "Or, you can create an account for free ",
  __presentation_15: "Professional quality",
  __presentation_16:
    "Plotter Studio is developed and maintained by a group with decades of experience in the IT world, and we guarantee continuous evolutions over time.",
  __footer_about_us: "About us",
  __footer_our_mission: "Our mission",
  __footer_were_are_we_headed: "Were are we headed",
  __footer_services: "Our services",
  __footer_serv_support: "Writers support",
  __footer_serv_teaching: "Teaching",
  __footer_serv_private_solutions: "Private solutions",
  __footer_contact_us: "Contact us",
  __footer_social_media: "Social media",
  __footer_social_facebook: "Facebook",
  __footer_social_twitter: "Twitter",
  __app_for_writers_logo: "The App for Writers",
};
