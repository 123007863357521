import {
  DropdownMenuItemType,
  IDropdownOption,
  ISelectableOption,
} from "@fluentui/react";
import Tr from "./Translations/Translations";
import Tools from "./Tools";
import SettingsUtil from "./Settings";

export const getCharacterMetadataTypeOptions = () => {
  return [
    {
      key: "metadata_type_class_family",
      text: Tr.Translate("metadata", "metadata_type_class_family"),
      itemType: DropdownMenuItemType.Header,
    },
    {
      key: "metadata_type_family_father_positive",
      text: Tr.Translate("metadata", "metadata_type_family_father_positive"),
    },
    {
      key: "metadata_type_family_mother_positive",
      text: Tr.Translate("metadata", "metadata_type_family_mother_positive"),
    },
    {
      key: "metadata_type_family_father_negative",
      text: Tr.Translate("metadata", "metadata_type_family_father_negative"),
    },
    {
      key: "metadata_type_family_mother_negative",
      text: Tr.Translate("metadata", "metadata_type_family_mother_negative"),
    },
    {
      key: "metadata_type_family_step_father_positive",
      text: Tr.Translate(
        "metadata",
        "metadata_type_family_step_father_positive"
      ),
    },
    {
      key: "metadata_type_family_step_mother_positive",
      text: Tr.Translate(
        "metadata",
        "metadata_type_family_step_mother_positive"
      ),
    },
    {
      key: "metadata_type_family_step_father_negative",
      text: Tr.Translate(
        "metadata",
        "metadata_type_family_step_father_negative"
      ),
    },
    {
      key: "metadata_type_family_step_mother_negative",
      text: Tr.Translate(
        "metadata",
        "metadata_type_family_step_mother_negative"
      ),
    },
    {
      key: "metadata_type_family_child_positive",
      text: Tr.Translate("metadata", "metadata_type_family_child_positive"),
    },
    {
      key: "metadata_type_family_child_negative",
      text: Tr.Translate("metadata", "metadata_type_family_child_negative"),
    },
    {
      key: "metadata_type_family_sibling_positive",
      text: Tr.Translate("metadata", "metadata_type_family_sibling_positive"),
    },
    {
      key: "metadata_type_family_sibling_negative",
      text: Tr.Translate("metadata", "metadata_type_family_sibling_negative"),
    },
    {
      key: "div_1",
      text: "-",
      itemType: DropdownMenuItemType.Divider,
    },
    {
      key: "metadata_type_class_beliefs",
      text: Tr.Translate("metadata", "metadata_type_class_beliefs"),
      itemType: DropdownMenuItemType.Header,
    },
    {
      key: "metadata_type_religion_pregiudice_positive",
      text: Tr.Translate(
        "metadata",
        "metadata_type_religion_pregiudice_positive"
      ),
    },
    {
      key: "metadata_type_religion_pregiudice_negative",
      text: Tr.Translate(
        "metadata",
        "metadata_type_religion_pregiudice_negative"
      ),
    },
    {
      key: "metadata_type_religion_pregiudice_negative_aggressive",
      text: Tr.Translate(
        "metadata",
        "metadata_type_religion_pregiudice_negative_aggressive"
      ),
    },
    {
      key: "metadata_type_race_pregiudice_positive",
      text: Tr.Translate("metadata", "metadata_type_race_pregiudice_positive"),
    },
    {
      key: "metadata_type_race_pregiudice_negative",
      text: Tr.Translate("metadata", "metadata_type_race_pregiudice_negative"),
    },
    {
      key: "metadata_type_race_pregiudice_negative_aggressive",
      text: Tr.Translate(
        "metadata",
        "metadata_type_race_pregiudice_negative_aggressive"
      ),
    },
    {
      key: "metadata_type_ethinicity_pregiudice_positive",
      text: Tr.Translate(
        "metadata",
        "metadata_type_ethinicity_pregiudice_positive"
      ),
    },
    {
      key: "metadata_type_ethinicity_pregiudice_negative",
      text: Tr.Translate(
        "metadata",
        "metadata_type_ethinicity_pregiudice_negative"
      ),
    },
    {
      key: "metadata_type_ethinicity_pregiudice_negative_aggressive",
      text: Tr.Translate(
        "metadata",
        "metadata_type_ethinicity_pregiudice_negative_aggressive"
      ),
    },
    {
      key: "metadata_type_sex_pregiudice_positive",
      text: Tr.Translate("metadata", "metadata_type_sex_pregiudice_positive"),
    },
    {
      key: "metadata_type_sex_pregiudice_negative",
      text: Tr.Translate("metadata", "metadata_type_sex_pregiudice_negative"),
    },
    {
      key: "metadata_type_sex_pregiudice_negative_aggressive",
      text: Tr.Translate(
        "metadata",
        "metadata_type_sex_pregiudice_negative_aggressive"
      ),
    },
    {
      key: "div_2",
      text: "-",
      itemType: DropdownMenuItemType.Divider,
    },
    {
      key: "metadata_type_social_group_class_personal",
      text: Tr.Translate(
        "metadata",
        "metadata_type_social_group_class_personal"
      ),
      itemType: DropdownMenuItemType.Header,
    },
    {
      key: "metadata_type_social_group_religion_affiliation",
      text: Tr.Translate(
        "metadata",
        "metadata_type_social_group_religion_affiliation"
      ),
    },
    {
      key: "metadata_type_social_group_personal_ethnicity",
      text: Tr.Translate(
        "metadata",
        "metadata_type_social_group_personal_ethnicity"
      ),
    },
    {
      key: "metadata_type_social_group_personal_sex",
      text: Tr.Translate("metadata", "metadata_type_social_group_personal_sex"),
    },
    {
      key: "metadata_type_social_group_personal_biological_race",
      text: Tr.Translate(
        "metadata",
        "metadata_type_social_group_personal_biological_race"
      ),
    },
  ] as IDropdownOption[];
};

export const getCharacterMetadataTypeOptionsForSliders = () => {
  return [
    {
      key: "metadata_extradata_scientific_inclination",
      text: Tr.Translate(
        "metadata",
        "metadata_extradata_scientific_inclination"
      ),
    },
    {
      key: "metadata_extradata_military_inclination",
      text: Tr.Translate("metadata", "metadata_extradata_military_inclination"),
    },
    {
      key: "metadata_extradata_religious_inclination",
      text: Tr.Translate(
        "metadata",
        "metadata_extradata_religious_inclination"
      ),
    },
    {
      key: "metadata_extradata_political_inclination",
      text: Tr.Translate(
        "metadata",
        "metadata_extradata_political_inclination"
      ),
    },
    {
      key: "metadata_extradata_economic_inclination",
      text: Tr.Translate("metadata", "metadata_extradata_economic_inclination"),
    },
    {
      key: "metadata_extradata_social_inclination",
      text: Tr.Translate("metadata", "metadata_extradata_social_inclination"),
    },
    {
      key: "metadata_extradata_cultural_inclination",
      text: Tr.Translate("metadata", "metadata_extradata_cultural_inclination"),
    },
    {
      key: "metadata_extradata_environmental_inclination",
      text: Tr.Translate(
        "metadata",
        "metadata_extradata_environmental_inclination"
      ),
    },
    {
      key: "metadata_extradata_spiritual_inclination",
      text: Tr.Translate(
        "metadata",
        "metadata_extradata_spiritual_inclination"
      ),
    },
    {
      key: "metadata_extradata_educational_inclination",
      text: Tr.Translate(
        "metadata",
        "metadata_extradata_educational_inclination"
      ),
    },
    {
      key: "metadata_extradata_athletic_inclination",
      text: Tr.Translate("metadata", "metadata_extradata_athletic_inclination"),
    },
    {
      key: "metadata_extradata_artistic_inclination",
      text: Tr.Translate("metadata", "metadata_extradata_artistic_inclination"),
    },
    {
      key: "metadata_extradata_musical_inclination",
      text: Tr.Translate("metadata", "metadata_extradata_musical_inclination"),
    },
    {
      key: "metadata_extradata_romantic_inclination",
      text: Tr.Translate("metadata", "metadata_extradata_romantic_inclination"),
    },
    {
      key: "metadata_extradata_familial_inclination",
      text: Tr.Translate("metadata", "metadata_extradata_familial_inclination"),
    },
    {
      key: "metadata_extradata_friendship_inclination",
      text: Tr.Translate(
        "metadata",
        "metadata_extradata_friendship_inclination"
      ),
    },
    {
      key: "metadata_extradata_professional_inclination",
      text: Tr.Translate(
        "metadata",
        "metadata_extradata_professional_inclination"
      ),
    },
    {
      key: "metadata_extradata_violence_inclination",
      text: Tr.Translate("metadata", "metadata_extradata_violence_inclination"),
    },
  ];
};

export const getCharacterMetadataObjectives = () => {
  return [
    {
      key: "metadata_objective_of_character_encounter",
      text: Tr.Translate(
        "metadata",
        "metadata_objective_of_character_encounter"
      ),
    },
    {
      key: "metadata_objective_of_character_marry",
      text: Tr.Translate("metadata", "metadata_objective_of_character_marry"),
    },
    {
      key: "metadata_objective_of_character_reach_position",
      text: Tr.Translate(
        "metadata",
        "metadata_objective_of_character_reach_position"
      ),
    },
    {
      key: "metadata_objective_of_character_obtain_object",
      text: Tr.Translate(
        "metadata",
        "metadata_objective_of_character_obtain_object"
      ),
    },
    {
      key: "metadata_objective_of_character_kill",
      text: Tr.Translate("metadata", "metadata_objective_of_character_kill"),
    },
    {
      key: "metadata_objective_of_character_learn",
      text: Tr.Translate("metadata", "metadata_objective_of_character_learn"),
    },
  ];
};

export const getMetadataClassByType = (type: string) => {
  let options: IDropdownOption[] = getCharacterMetadataTypeOptions();

  for (let i = options.length - 1; i >= 0; i--) {
    let currentKey = options[i].key.toString();
    if (currentKey === type) {
      for (let j = i; j >= 0; j--) {
        if (options[j].itemType === DropdownMenuItemType.Header) {
          return options[j].key.toString();
        }
      }
    }
  }
};
