import { combineReducers } from "redux";
import { genericReducer } from "./Generic/GenericReducer";
import { GenericState } from "./Generic/GenericState";
import { EventProcessingReducer } from "./EventProcessing/EventProcessingReducer";
import { EventProcessingState } from "./EventProcessing/EventProcessingState";
import { ChatState } from "./Chat/ChatState";
import { chatReducer } from "./Chat/ChatReducer";

const rootReducer = combineReducers({
  generic: genericReducer,
  eventProcessing: EventProcessingReducer,
  chat: chatReducer,
});

export interface GlobalState {
  generic: GenericState;
  chat: ChatState;
  eventProcessing: EventProcessingState;
}

export default rootReducer;
