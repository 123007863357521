import { ProgressIndicator } from "@fluentui/react";
import "./OperationProgressBar.scss";
import { useEffect, useState } from "react";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";

export interface IOperationProgressBarProps {
  operationId: string;
  enable: boolean;
}

const OperationProgressBar = (props: IOperationProgressBarProps) => {
  const [progress, setProgress] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);

  const stopPolling = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  };

  const pollProgress = () => {
    ApiService.SysController.GetOperationProgress(
      props.operationId,
      (response: IAPIResponse) => {
        if (response.error === null && response.parsed) {
          setProgress(response.parsed.Progress); // progress is between 0 and 1
        }
      }
    );
  };

  useEffect(() => {
    if (props.operationId && props.enable) {
      setProgress(0);
      stopPolling();
      setIntervalId(
        setInterval(() => {
          pollProgress();
        }, 2000)
      );
    }

    if (!props.enable) {
      stopPolling();
    }
  }, [props.operationId, props.enable]);

  useEffect(() => {
    return () => {
      stopPolling();
    };
  }, []);

  return (
    <div>
      {props.enable && (
        <ProgressIndicator barHeight={5} percentComplete={progress} />
      )}
    </div>
  );
};

export default OperationProgressBar;
