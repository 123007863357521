import { Icon, Label, PrimaryButton } from "@fluentui/react";
import { IEvent } from "../../Models/IEvent";
import Tr from "../../Utils/Translations/Translations";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import "./EventTickContextMenu.scss";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../Redux/RootReducer";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import { useEffect, useState } from "react";
import { matchDateOnTarget } from "../TimelineEventMap/TimelineEventMap";
import { IBookTimeline } from "../../Models/IBookTimeline";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import Loadable from "../Loadable/Loadable";
import { ToastMessage } from "../../Utils/UIMessages";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import MultiForm from "../MultiForm/MultiForm";
import { mobileCheck } from "../../App";
import TextToEvents from "../TextToEvents/TextToEvents";
import Accordion from "../Accordion/Accordion";
import EventMover from "../EventMover/EventMover";

export interface IEventTickContextMenuProps {
  targetEvents: IEvent[];
  onDismiss: () => void;
}

const EventTickContextMenu = (props: IEventTickContextMenuProps) => {
  return (
    <div>
      <CustomTooltip
        isButton
        iconName="More"
        onClick={() => {
          SummonModal("moreActionsModal");
        }}
        content={<div>{Tr.Translate("language", "more_actions_in_date")}</div>}
      />
    </div>
  );
};

export const EventTickContextMenuModal = () => {
  const dispatch = useDispatch();
  const eventsDate: string = useSelector(
    (state: GlobalState) => state.generic.exploreEventsDate
  );
  const navigator: string = useSelector(
    (state: GlobalState) => state.generic.timelineNavigator
  );
  var displayDateSpl: string[] = (eventsDate ?? "").split("/");
  var displayDateJoin: string[] = [displayDateSpl[0]];
  if (navigator === "month" || navigator === "day") {
    displayDateJoin.push(displayDateSpl[1]);
  }
  if (navigator === "day") {
    displayDateJoin.push(displayDateSpl[2]);
  }

  const dismiss = () => {
    DismissModal("moreActionsModal");
  };

  return (
    <SmartModal
      onDismiss={dismiss}
      title={
        Tr.Translate("language", "more_actions_in_date") +
        ": " +
        displayDateJoin.join("/")
      }
      modalUniqueId={"moreActionsModal"}
      content={
        <div className="more-actions-modal-content">
          <Accordion
            title={Tr.Translate(
              "language",
              "move_all_events_on_a_different_date"
            )}
            children={
              <EventMover
                onDismiss={() => {
                  DismissModal("moreActionsModal");
                }}
              />
            }
          />
          <Accordion
            title={
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                {Tr.Translate("language", "text_to_events")}
                <Icon iconName="Relationship" />
              </div>
            }
            children={
              <TextToEvents
                onDismiss={() => {
                  DismissModal("moreActionsModal");
                }}
              />
            }
          />
        </div>
      }
      buttons={[
        {
          text: Tr.Translate("language", "cancel"),
          onClick: dismiss,
        },
      ]}
    />
  );
};

export default EventTickContextMenu;
