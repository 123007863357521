import { Icon, Label, Link, MessageBar, MessageBarType } from "@fluentui/react";
import "./FeedbackHandler.scss";
import Tr from "../../Utils/Translations/Translations";
import { useId, useState } from "react";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import ApiService from "../../Services/ApiService";
import { IFeedback, IFeedbackInsert } from "../../Models/IFeedback";
import { IAPIResponse } from "../../Services/AjaxService";
import { ToastMessage } from "../../Utils/UIMessages";
import { IUser } from "../../Models/IUser";
import { useSelector } from "react-redux";
import { stat } from "fs";
import { GlobalState } from "../../Redux/RootReducer";
import CustomTooltip from "../CustomTooltip/CustomTooltip";

export interface IFeedbackProps {
  section: string;
}

const FeedbackHandler = (props: IFeedbackProps) => {
  const uniqueId = useId();
  const [loading, setLoading] = useState(false);

  const sendFeedback = (data: any) => {
    setLoading(true);
    let feedback: IFeedbackInsert = {
      Title: data.title,
      Description: data.description,
      Vote: data.vote,
      Section: props.section,
      Language: Tr.GetLanguage(),
    };
    ApiService.FeedbackController.NewFeedback(
      feedback,
      (response: IAPIResponse) => {
        if (response.error == null) {
          ToastMessage(
            "success",
            Tr.Translate("language", "action_completed_successfully")
          );
          DismissModal("feedbackModal" + uniqueId);
        } else {
          ToastMessage("error", Tr.Translate("system", "generic_api_failure"));
        }
        setLoading(false);
      }
    );
  };

  return (
    <div>
      <div
        className="feedback-link"
        onClick={() => {
          SummonModal("feedbackModal" + uniqueId);
        }}
      >
        <CustomTooltip
          isButton
          iconName="Feedback"
          content={Tr.Translate("language", "leave_a_feedback")}
        />
      </div>
      <SmartModal
        loading={loading}
        title={Tr.Translate("language", "leave_a_feedback")}
        modalUniqueId={"feedbackModal" + uniqueId}
        content={
          <div>
            <MessageBar messageBarType={MessageBarType.success}>
              {Tr.Translate("language", "feedback_hint")}
            </MessageBar>

            <MultiForm
              formUniqueId={"feedbackForm" + uniqueId}
              onSubmit={sendFeedback}
              inputs={[
                {
                  type: "text",
                  name: "title",
                  label: Tr.Translate("language", "feedback_title"),
                  required: true,
                },
                {
                  type: "multiline",
                  name: "description",
                  label: Tr.Translate("language", "feedback_description"),
                  required: true,
                  extraParams: { maxLength: 1000 },
                },
                {
                  type: "starpicker",
                  name: "vote",
                  label: Tr.Translate("language", "feedback_vote"),
                  required: true,
                },
              ]}
            />
          </div>
        }
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: loading,
            onClick: () => {
              DismissModal("feedbackModal" + uniqueId);
            },
          },
          {
            text: Tr.Translate("language", "accept"),
            disabled: loading,
            onClick: () => {
              triggerFormValidation("feedbackForm" + uniqueId);
            },
          },
        ]}
      />
    </div>
  );
};

export default FeedbackHandler;
