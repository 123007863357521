export const Dictionary = {
  __new_book: "Fügen Sie ein Buch zu Ihrem Bücherregal hinzu",
  __new_book_desc:
    "Als erstes möchten Sie hier ein Buch erstellen. Von hier aus können Sie ein neues Buch hinzufügen.",
  __edit_workgroup: "Fügen Sie Ihrem Team Personen hinzu",
  __edit_workgroup_desc:
    "Hier können Sie andere Benutzer zum Zugriff auf diese Arbeitsgruppe einladen. Eingeladene Benutzer können an allen Assets aus der Arbeitsmappe arbeiten.",
  __ai_box: "Virtuelle Assistenten-Tools",
  __ai_box_desc:
    "Wenn Sie dieses Symbol auf einer Schaltfläche sehen, bedeutet das, dass Sie die Vorteile unserer fortschrittlichen KI-Assistenten-Engine nutzen können, um die Qualität Ihrer Arbeit auf die nächste Stufe zu heben. Für einige Funktionen müssen bestimmte Abonnements aktiviert werden.",
  __new_event_button: "Fügen Sie hier Ereignisse hinzu",
  __new_event_button_desc:
    "Sie können einen Tag/einen Monat/ein Jahr aus der Zeitleiste auswählen, indem Sie auf eines der Häkchen klicken. Anschließend können Sie mit dieser Schaltfläche ein neues Ereignis erstellen. Das Standarddatum für das Ereignis ist das Datum, das Sie auf der Zeitleiste ausgewählt haben. Wenn Sie möchten, können Sie es später jederzeit ändern.",
  __draft_ideas: "Machen Sie Notizen zu Ihren Ideen",
  __draft_ideas_desc:
    "Sie können sie als Notizen zu Konzepten betrachten, die Sie in Zukunft ausarbeiten möchten. Ideenentwürfe können nach Status verwaltet werden und sind für alle Ihre Teammitglieder sichtbar.",
  __characters_meta_data: "Charaktere",
  __characters_meta_data_desc:
    "Charaktere werden automatisch zu Ihrem Buch hinzugefügt, sobald Sie sie in einem Ereignis auf der Zeitleiste verwenden. Auf diese Weise können Sie so viele Charaktere hinzufügen, wie Sie möchten. Sobald ein Charakter hinzugefügt wurde, können Sie beliebig viele Metadaten zu jedem Charakter hinzufügen. Sie können auch ein Bild zu jedem Charakter hinzufügen. Diese Informationen helfen dem Ereigniscompiler, Konsistenzfehler im Verhalten Ihrer Charaktere zu erkennen.",
  __new_timeline_button: "Erstellen Sie eine neue Zeitleiste",
  __new_timeline_button_desc:
    "Sie können von hier aus eine neue Zeitleiste für Ihr Buch erstellen. Eine Zeitleiste ist eine Möglichkeit, die Ereignisse Ihres Buches visuell zu organisieren. Sie können Ereignisse zu einer Zeitleiste hinzufügen und sie in chronologischer Reihenfolge anzeigen. Sie können die Ereignisse auch direkt von der Zeitleiste aus ändern.",
  __new_timeline_button_more:
    "Jede neue Zeitleiste, die Sie erstellen, wird als nachfolgend zur vorherigen betrachtet. Alle Zeitleisten, in Reihenfolge, repräsentieren den gesamten Zeitraum Ihres narrativen Universums. \n\nAngenommen, Ihr Buch ist ein historischer Detektivroman, der 1951 spielt und dessen Handlung 1963 endet. Die Zeitleiste sollte in diesem Fall ab dem Jahr 1 beginnen und mindestens 1963 enden.\nEs kann sinnvoll sein, einen Spielraum zu lassen, im gegebenen Beispiel wäre die Zeitleiste nützlich vom Jahr 1 bis zum Jahr 2000.",
};
