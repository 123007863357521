import { APIUrls } from "../../ApiUrls";
import { IBookTimeline } from "../../Models/IBookTimeline";
import { AjaxService, IAPIRequest, IAPIResponse } from "../AjaxService";
import { BuildParameters } from "../ApiService";

const BookTimelineController = {
  GetBookTimelines: async (
    bookTimelineId?: number,
    BookId?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(APIUrls.BOOK_TIMELINES.GET_BOOK_TIMELINES, [
      ["bookTimelineId", bookTimelineId],
      ["BookId", BookId],
    ]);
    request.allowCache = true;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  NewBookTimeline: async (
    BookTimeline: IBookTimeline,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.BOOK_TIMELINES.INSERT_BOOK_TIMELINE;
    request.method = "POST";
    request.payload = BookTimeline;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  UpdateBookTimeline: async (
    BookTimeline: IBookTimeline,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.BOOK_TIMELINES.UPDATE_BOOK_TIMELINE;
    request.method = "PUT";
    request.payload = BookTimeline;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  DeleteBookTimeline: async (
    bookTimelineId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url =
      APIUrls.BOOK_TIMELINES.DELETE_BOOK_TIMELINE +
      "?bookTimelineId=" +
      bookTimelineId;
    request.method = "DELETE";
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
};

export default BookTimelineController;
