import { APIUrls } from "../../ApiUrls";
import { IDraftIdea } from "../../Models/IDraftIdea";
import { AjaxService, IAPIRequest, IAPIResponse } from "../AjaxService";
import { BuildParameters } from "../ApiService";

const DraftIdeaController = {
  GetDraftIdeas: async (
    DraftIdeaId?: number,
    BookId?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(APIUrls.DRAFT_IDEA.GET_DRAFTIDEAS, [
      ["DraftIdeaId", DraftIdeaId],
      ["BookId", BookId],
    ]);

    request.allowCache = true;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  NewDraftIdea: async (
    DraftIdea: IDraftIdea,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.DRAFT_IDEA.INSERT_DRAFTIDEA;
    request.method = "POST";
    request.payload = DraftIdea;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  UpdateDraftIdea: async (
    DraftIdea: IDraftIdea,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.DRAFT_IDEA.UPDATE_DRAFTIDEA;
    request.method = "PUT";
    request.payload = DraftIdea;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  DeleteDraftIdea: async (
    DraftIdeaId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url =
      APIUrls.DRAFT_IDEA.DELETE_DRAFTIDEA + "?DraftIdeaId=" + DraftIdeaId;
    request.method = "DELETE";
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
};

export default DraftIdeaController;
