import {
  Dropdown,
  IconButton,
  PrimaryButton,
  SearchBox,
} from "@fluentui/react";
import "./PrettyTable.scss";
import { useEffect, useState } from "react";
import Tr from "../../Utils/Translations/Translations";
import Tools from "../../Utils/Tools";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import { mobileCheck } from "../../App";
import Pager from "../Pager/Pager";

export interface IPrettyTableProps {
  headers: string[];
  rows: JSX.Element[][];
  plainRows: any[];
  width?: string;
  tdAlign?: string;
  rowKeyBinder?: (row: any) => string;
  extraToolbarItems?: JSX.Element[];
  disableToolbar?: boolean;
}

const PrettyTable = (props: IPrettyTableProps) => {
  const [page, setPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");

  let rowData = props.rows;

  if (search.length > 0 || false) {
    rowData = rowData.filter(
      (x: any, i: number) =>
        JSON.stringify(props.plainRows[i])
          .toLocaleLowerCase()
          .includes(search) ||
        (props.rowKeyBinder
          ? props
              .rowKeyBinder(props.plainRows[i])
              .toLocaleLowerCase()
              .includes(search)
          : false)
    );
  }

  return (
    <div style={props.width ? { width: props.width } : {}}>
      {!props.disableToolbar && (
        <div
          className="pretty-table-pager-row"
          style={mobileCheck() ? { flexDirection: "column" } : {}}
        >
          <div
            style={mobileCheck() ? { width: "100%" } : {}}
            className="pretty-table-toolbar"
          >
            {props.extraToolbarItems?.map((x: JSX.Element, i: number) => {
              return <div key={i}>{x}</div>;
            })}
            <SearchBox
              placeholder={Tr.Translate("language", "search")}
              onChange={(e, t) => {
                if (t !== undefined) {
                  setSearch(t.toLocaleLowerCase());
                }
              }}
              onClear={() => {
                setSearch("");
              }}
            />
          </div>
          <Pager
            onPaginate={(page: number, perPage: number) => {
              setPage(page);
              setPerPage(perPage);
            }}
            maxItems={rowData.length}
          />
        </div>
      )}
      <div className="table-pretty-main-wrap-outer">
        <table className="table-pretty-main-wrap">
          <thead>
            <tr>
              {props.headers.map((x: string, i: number) => {
                return (
                  <th key={i} style={{ whiteSpace: "pre" }}>
                    {x}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {rowData
              .filter(
                (x: any, i: number) =>
                  page * perPage <= i && i < (page + 1) * perPage
              )
              .map((x: JSX.Element[], i: number) => {
                return (
                  <tr key={i}>
                    {x.map((y: JSX.Element, j: number) => {
                      return (
                        <td
                          className={i % 2 == 0 ? "" : "odd-table-row"}
                          style={{ verticalAlign: props.tdAlign }}
                          key={j}
                        >
                          {y}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PrettyTable;
