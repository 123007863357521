export const Dictionary = {
  __event_class_add_type: "Personaje añadido a la historia.",
  __event_class_remove_type: "Personaje eliminado de la historia.",
  __event_class_affect_others_type:
    "Las acciones de un personaje afectan a otro.",
  __event_class_change_status_type: "Evolución del personaje",
  __event_class_plot_milestone_type: "Hito de la trama",
  __event_class_strong_effects: "Desestabilización extrema",
  __event_type_appear: "Aparece",
  __event_type_born: "Ha nacido",
  __event_type_revealed: "Es revelado",
  __event_type_abbandons: "Abandonos",
  __event_type_dies: "Muere",
  __event_type_kills: "mata",
  __event_type_marries: "se casa",
  __event_type_allies: "Alianza",
  __event_type_engage: "involucrarse en el romance",
  __event_type_learns_skill: "aprende habilidad",
  __event_type_learns_secret: "aprende secreto",
  __event_type_book_begins: "La historia comienza",
  __event_type_holiday: "Día festivo",
  __event_type_book_closure: "fin del libro",
  __event_type_geopolitic: "Político",
  __event_type_natural_disaster: "Desastre natural",
  __event_type_joins_faction: "Se une a la facción",
  __event_type_leaves_faction: "Deja la facción",
  __event_type_obtains_item: "Obtiene el artículo",
  __event_type_shares_a_secret: "Comparte un secreto",
  __event_type_changes_id: "Cambia de identidad",
  __event_type_friendship: "Amistad",
  __event_type_friendship_end: "Fin de la amistad",
  __event_type_engage_end: "Fin de la relación romántica",
  __event_type_betrays: "Traiciona",
  __event_type_assumes_status: "Adquiere estatus",
  __event_type_assumes_condition: "Adquiere una condición",
  __event_type_reaches_location: "Llega a la ubicación",
  __event_type_teams_up_with: "equipos",
  __event_type_disappears: "se perdió",
  __event_type_leaves_location: "Ubicación de las hojas",
  __event_type_meets: "Satisface",
  __event_type_challenge: "Desafío",
  __event_type_fight: "Combate",
  __event_type_starts_mission: "Comienza misión/trabajo",
  __event_type_starts_mission_end: "Finalizar misión/trabajo",
  __event_type_falls_in_love: "Enamorarse",
  __event_type_situation: "Situación",
  __event_type_mass_kill: "Destrucción masiva",
  __event_type_war: "Guerra",
  __event_type_rule_changer: "Cambio de regla universal",
  __event_type_uses_object: "Utiliza herramienta/objeto",
  __event_type_creates_or_builds: "Crea/Construye",
};
