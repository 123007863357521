export const APIUrls = {
  SESSION: {
    LOGIN: "/session/login",
    LOGOUT: "/session/logout",
    GET_SELF: "/session/self",
    GET_SYSADMIN_MESSAGES: "/session/sysadminmessages",
    INSERT_REGISTRATION: "/session/insertregistration",
    UPDATE_REGISTRATION: "/session/updateregistration",
    UPDATE_USER: "/session/updateuser",
    UPDATE_PASSWORD: "/session/updatepassword",
    RESEND_INVITE: "/session/resendinvite",
    PASSWORD_RESET_EMAIL: "/session/resetpasswordsendlink",
  },
  FEEDBACK: {
    GET_FEEDBACKS: "/feedback/get",
    INSERT_FEEDBACK: "/feedback/new",
    UPDATE_FEEDBACK: "/feedback/edit",
    DELETE_FEEDBACK: "/feedback/delete",
  },
  WORKGROUP_CHAT: {
    GET_WORKGROUP_CHAT: "/workgroupchat/get",
    GET_WORKGROUP_CHAT_NOT_READ: "/workgroupchat/get/notread",
    GET_WORKGROUP_CHAT_FILE: "/workgroupchat/get/file",
    INSERT_WORKGROUP_CHAT: "/workgroupchat/new",
    UPDATE_WORKGROUP_CHAT: "/workgroupchat/edit",
    UPDATE_WORKGROUP_CHAT_READ_BY: "/workgroupchat/edit/readby",
    DELETE_WORKGROUP_CHAT: "/workgroupchat/delete",
  },
  METADATA: {
    GET_METADATA: "/metadata/get",
    INSERT_METADATA: "/metadata/new",
    UPDATE_METADATA: "/metadata/edit",
    DELETE_METADATA: "/metadata/delete",
    GET_METADATA_IMAGE: "/metadata/get/image",
    INSERT_METADATA_IMAGE: "/metadata/new/image",
    UPDATE_METADATA_IMAGE: "/metadata/edit/image",
    DELETE_METADATA_IMAGE: "/metadata/delete/image",
  },
  BOOK: {
    GET_BOOKS: "/book/get",
    GET_RECENT_BOOKS: "/book/get/recent",
    INSERT_BOOK: "/book/new",
    UPDATE_BOOK: "/book/edit",
    DELETE_BOOK: "/book/delete",
  },
  EVENT: {
    GET_EVENTS: "/event/get",
    INSERT_EVENT: "/event/new",
    UPDATE_EVENT: "/event/edit",
    DELETE_EVENT: "/event/delete",
    BOT_REQUEST: "/event/botrequest",
    ERROR_COMPILER_REQUEST: "/event/errorcompiler",
    TEXT_TO_EVENTS: "/event/events-from-text",
  },
  BOOK_TIMELINES: {
    GET_BOOK_TIMELINES: "/booktimeline/get",
    INSERT_BOOK_TIMELINE: "/booktimeline/new",
    UPDATE_BOOK_TIMELINE: "/booktimeline/edit",
    DELETE_BOOK_TIMELINE: "/booktimeline/delete",
  },
  DRAFT_IDEA: {
    GET_DRAFTIDEAS: "/draftidea/get",
    INSERT_DRAFTIDEA: "/draftidea/new",
    UPDATE_DRAFTIDEA: "/draftidea/edit",
    DELETE_DRAFTIDEA: "/draftidea/delete",
  },
  WORKGROUP: {
    LOGIN_WORKGROUP: "/workgroup/signin",
    GET_WORKGROUPS: "/workgroup/get",
    INSERT_WORKGROUP: "/workgroup/new",
    UPDATE_WORKGROUP: "/workgroup/edit",
    DELETE_WORKGROUP: "/workgroup/delete",
    INVITE_MEMBER: "/workgroup/members/invite",
    ACCEPT_INVITATION: "/workgroup/members/acceptinvite",
    GET_WORKGROUP_MEMBERS: "/workgroup/members/get",
    INSERT_WORKGROUP_MEMBER: "/workgroup/members/new",
    UPDATE_WORKGROUP_MEMBER: "/workgroup/members/edit",
    DELETE_WORKGROUP_MEMBER: "/workgroup/members/delete",
  },
  STORE: {
    GET_SUBSCRIPTIONS: "/store/subscriptions",
    GET_SUBSCRIPTION_FEATURES: "/store/subscriptionfeatures",
    GET_SUBSCRIPTION_FEATURES_USAGES: "/store/subscriptionfeaturesusages",
    GET_PURCHASES: "/store/purchases",
    INSERT_PURCHASE: "/store/newpurchase",
    GET_VOUCHER_EFFECT: "/store/testVoucher",
    UPDATE_PAYPAL_TRANSACTION_STATUS: "/store/updatePurchaseStatus",
  },
  SYSTEM: {
    INSERT_CONTACT_US: "/Sys/AddContactUs",
    GET_OPERATION_PROGRESS: "/Sys/GetOperationProgress",
  },
};
