import { useEffect, useState } from "react";
import "./SmartModal.scss";
import { DefaultButton, IconButton, PrimaryButton } from "@fluentui/react";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import Tr from "../../Utils/Translations/Translations";
import SuperSpinner from "../SuperSpinner/SuperSpinner";

var pauseDismiss = false;

export interface ISmartModalButtons {
  onClick: () => void;
  text: string;
  iconOnlyLabel?: string;
  iconName?: string;
  disabled?: boolean;
}

export interface ISmartModalProps {
  modalUniqueId: string;
  title?: string;
  onSubmit?: () => void;
  onDismiss?: () => void;
  content: JSX.Element;
  buttons: ISmartModalButtons[];
  leftButtons?: ISmartModalButtons[];
  isWideModal?: boolean;
  loading?: boolean;
  titleBarExtra?: JSX.Element[];
}

export const SummonModal = (modalUniqueId: string) => {
  document.dispatchEvent(
    new CustomEvent("summon-modal", { detail: { id: modalUniqueId } })
  );
};

export const DismissModal = (modalUniqueId: string) => {
  if (!pauseDismiss) {
    if (modalUniqueId === "*") {
      let allOpenModals = Array.from(
        document.getElementsByClassName("smart-modal-outer-inkdrop")
      );
      if (allOpenModals.length > 0) {
        let last = allOpenModals[allOpenModals.length - 1];
        DismissModal(last.id.replace("modal-unique-", ""));
      }
    } else {
      pauseDismiss = true;
      document.dispatchEvent(
        new CustomEvent("dismiss-modal", { detail: { id: modalUniqueId } })
      );
      setTimeout(() => {
        pauseDismiss = false;
      }, 500);
    }
  }
};

const SmartModal = (props: ISmartModalProps) => {
  const [visible, setVisible] = useState<boolean>(false);

  const dismiss = () => {
    if (props.onDismiss) {
      props.onDismiss();
    }
    setVisible(false);
  };

  const summonModal = (e: any) => {
    if (e.detail.id === props.modalUniqueId) {
      setVisible(true);
    }
  };
  const dismissModal = (e: any) => {
    if (e.detail.id === props.modalUniqueId || e.detail.id === "*") {
      dismiss();
    }
  };

  useEffect(() => {
    document.addEventListener("summon-modal", summonModal);
    document.addEventListener("dismiss-modal", dismissModal);

    return () => {
      document.removeEventListener("summon-modal", summonModal);
      document.removeEventListener("dismiss-modal", dismissModal);
    };
  }, []);

  const renderModalButton = (
    x: ISmartModalButtons,
    i: number,
    isLast: boolean
  ) => {
    if (x.iconOnlyLabel) {
      return (
        <CustomTooltip
          key={i}
          isButton
          disabled={x.disabled}
          onClick={x.onClick}
          iconName={x.iconName ?? ""}
          content={<div>{x.iconOnlyLabel}</div>}
        />
      );
    } else {
      return isLast ? (
        <PrimaryButton
          key={i}
          text={x.text}
          iconProps={{ iconName: x.iconName }}
          onClick={x.onClick}
          disabled={x.disabled}
        />
      ) : (
        <DefaultButton
          key={i}
          text={x.text}
          iconProps={{ iconName: x.iconName }}
          onClick={x.onClick}
          disabled={x.disabled}
        />
      );
    }
  };

  return (
    <div style={{ fontSize: "1rem" }}>
      {visible && (
        <div
          className="smart-modal-outer-inkdrop"
          id={"modal-unique-" + props.modalUniqueId}
        >
          <div
            className="smart-modal-wrap"
            style={props.isWideModal ? { maxWidth: "100vw" } : {}}
          >
            <div className="smart-modal-title-line">
              <div className="smart-modal-title">{props.title}</div>

              <div
                style={{ display: "flex", alignItems: "center", gap: "0.5em" }}
              >
                {props.titleBarExtra?.map((x, i) => {
                  return <div key={i}>{x}</div>;
                })}
                <CustomTooltip
                  iconName="Cancel"
                  isButton
                  content={Tr.Translate("language", "cancel")}
                  onClick={dismiss}
                />
              </div>
            </div>

            {visible && (
              <div className="smart-modal-content">{props.content}</div>
            )}
            {!props.loading && (
              <div className="smart-modal-row-buttons-wrap">
                <div className="smart-modal-row-buttons">
                  {(props.buttons ?? []).map(
                    (x: ISmartModalButtons, i: number) => {
                      return renderModalButton(
                        x,
                        i,
                        i === props.buttons.length - 1
                      );
                    }
                  )}
                </div>
                <div className="smart-modal-row-buttons">
                  {(props.leftButtons ?? []).map(
                    (x: ISmartModalButtons, i: number) => {
                      return renderModalButton(
                        x,
                        i,
                        i === props.buttons.length - 1
                      );
                    }
                  )}
                </div>
              </div>
            )}
            {props.loading && (
              <div className="smart-modal-row-buttons-wrap">
                <SuperSpinner />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SmartModal;
