import { useEffect, useState } from "react";
import App from "./App";
import { Spinner, SpinnerSize } from "@fluentui/react";
import Tr from "./Utils/Translations/Translations";
import Tools from "./Utils/Tools";
import SuperSpinner from "./Components/SuperSpinner/SuperSpinner";
import AnimatedTextLogo from "./Components/AnimatedTextLogo/AnimatedTextLogo";
import ImageAssetLoader from "./Components/ImageAssetLoader/ImageAssetLoader";
import { getBaseUrl } from "./Services/Env";
import { useNavigate } from "react-router-dom";

var mustUpdate = false;
var updateCounterClone = 0;
const AppPreload = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [mustUpdateCounter, setMustUpdateCounter_] = useState<number>(0);

  const setMustUpdateCounter = (ctr: number) => {
    updateCounterClone = ctr;
    setMustUpdateCounter_(ctr);
  };

  useEffect(() => {
    if (localStorage.getItem("skip-intro-animation") !== "true") {
      fetch(getBaseUrl() + "/sys/getserverdate")
        .then((res) => res.text())
        .then((text) => {
          let storedDate = localStorage.getItem("serverDate");
          let serverDate = text.substring(0, 20);

          if (serverDate != storedDate && storedDate !== null) {
            mustUpdate = true;
          }
          localStorage.setItem("serverDate", serverDate);
        })
        .catch((err) => localStorage.removeItem("serverDate"));
      setLoading(true);
    }

    setTimeout(() => {
      localStorage.removeItem("skip-intro-animation");
    }, 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (updateCounterClone === 1) {
        (window as any)["dropApiCache"]();
        localStorage.setItem("skip-intro-animation", "true");
        (window.location as any).reload(true);
      }

      if (updateCounterClone > 0) {
        setMustUpdateCounter(updateCounterClone - 1);
      }
    }, 1000);
  }, [mustUpdateCounter]);

  return (
    <div>
      {loading && (
        <div className="loading-main-image will-fadein">
          <div className="intro-box-centerer">
            <div className="intro-image-wrapper-spark">
              <ImageAssetLoader src={"/Assets/Public/spark.gif"} />
            </div>
            <AnimatedTextLogo
              underText={Tr.Translate("presentation", "app_for_writers_logo")}
              onAnimationDone={() => {
                setLoading(false);

                if (mustUpdate) {
                  setMustUpdateCounter(5);
                }
              }}
            />
          </div>
        </div>
      )}
      {mustUpdateCounter > 0 && (
        <div className="update-container">
          <div>
            <div className="update-container-label">
              {Tr.Translate("language", "getting_updates")
                .split(".")
                .filter((x: string) => x)
                .map((x: string, i: number) => {
                  return <div key={i}>{x + "."}</div>;
                })}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <SuperSpinner
                mode="raimbow"
                label={mustUpdateCounter.toString() + "s"}
              />
            </div>
          </div>
        </div>
      )}
      {!loading && mustUpdateCounter === 0 && (
        <div className="will-fadein">
          <App />
        </div>
      )}
    </div>
  );
};

export default AppPreload;
