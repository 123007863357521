import { useDispatch, useSelector } from "react-redux";
import { mobileCheck } from "../../App";
import { GlobalState } from "../../Redux/RootReducer";
import Tr from "../../Utils/Translations/Translations";
import "./TimelineEventExplorer.scss";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import { useEffect, useState } from "react";
import { PrimaryButton } from "@fluentui/react";
import { IBookTimeline } from "../../Models/IBookTimeline";
import TimelineEventMap from "../TimelineEventMap/TimelineEventMap";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import TimelineEventTable from "../TimelineEventTable/TimelineEventTable";

const DEFAULT_BASE_YEAR_DELTASPAN = 200;

const TimelineEventExplorer = () => {
  const dispatch = useDispatch();
  const navigator: string = useSelector(
    (state: GlobalState) => state.generic.timelineNavigator
  );
  const timeline: IBookTimeline | undefined = useSelector(
    (state: GlobalState) => state.generic.activeTimeline
  );

  const [currentDeltaYear, setCurrentDeltaYear] = useState<number>(
    DEFAULT_BASE_YEAR_DELTASPAN
  );
  const [currentCenterYear, setCurrentCenterYear] = useState<number>(0);
  const [debuff, setDebuff] = useState<boolean>(false);
  const [state, setState] = useState<any>({});
  useEffect(() => {
    if (timeline) {
      setCurrentCenterYear(timeline?.CenterYear ?? 0);
    }
  }, [timeline]);

  const doDebuff = () => {
    setDebuff(true);
    setTimeout(() => {
      setDebuff(false);
    }, 500);
  };

  return (
    <div>
      <div className="event-year-map-centerer">
        <div className="event-year-search-form">
          <div style={{ width: "100%" }}>
            {!debuff && currentCenterYear > 0 && (
              <div
                style={
                  mobileCheck()
                    ? { marginBottom: "0.5em" }
                    : { display: "flex", marginTop: "0.5em" }
                }
              >
                <div style={{ width: "100%" }}>
                  <MultiForm
                    onChange={setState}
                    inlineForm={!mobileCheck()}
                    formUniqueId="timelineNavigatorForm"
                    inputs={[
                      {
                        type: "number",
                        width: mobileCheck() ? 100 : 35,
                        name: "navigatorCenter",
                        label: Tr.Translate(
                          "language",
                          "book_timeline_center_year"
                        ),
                        required: true,
                        currentValue: currentCenterYear,
                        disabled: state.navigatorMode === "table_view",

                        extraParams: {
                          min: 1,
                          max: timeline?.TimelineYearSpan ?? 1,
                        },
                      },
                      {
                        type: "number",
                        width: mobileCheck() ? 100 : 35,
                        name: "navigatorDelta",
                        disabled: state.navigatorMode === "table_view",
                        label: Tr.Translate(
                          "language",
                          "book_timeline_delta_year"
                        ),
                        required: true,
                        currentValue: currentDeltaYear,
                        extraParams: {
                          min: 1,
                          max: DEFAULT_BASE_YEAR_DELTASPAN * 1.5,
                          underElement: (
                            <div className="under-input-hint">
                              {Tr.Translate(
                                "language",
                                "book_timeline_delta_year_hint"
                              )}
                            </div>
                          ),
                        },
                      },
                      {
                        type: "select",
                        width: mobileCheck() ? 100 : 20,
                        name: "navigatorMode",
                        extraParams: {
                          extraStyles: {
                            position: "relative",
                            top: mobileCheck() ? "-0.7em" : "-1.5em",
                          },
                          options: [
                            {
                              key: "table_view",
                              text: Tr.Translate("language", "table_view"),
                            },
                            {
                              key: "year",
                              text: Tr.Translate("language", "year_view"),
                            },
                            {
                              key: "month",
                              text: Tr.Translate("language", "month_view"),
                            },
                            {
                              key: "day",
                              text: Tr.Translate("language", "day_view"),
                            },
                          ],
                        },
                        currentValue: navigator,
                      },
                    ]}
                    onSubmit={(data: any) => {
                      setCurrentCenterYear(data.navigatorCenter);
                      setCurrentDeltaYear(data.navigatorDelta);
                      dispatch(
                        GenericActions.setTimelineNavigator(data.navigatorMode)
                      );

                      doDebuff();
                    }}
                  />
                </div>
                <div style={mobileCheck() ? { marginTop: "-1em" } : {}}>
                  <PrimaryButton
                    onClick={() => {
                      triggerFormValidation("timelineNavigatorForm");
                    }}
                    iconProps={{ iconName: "Search" }}
                    text={Tr.Translate("language", "search")}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {!debuff && (
        <div>
          {navigator !== "table_view" && (
            <TimelineEventMap
              currentCenterYear={currentCenterYear}
              currentDeltaYear={currentDeltaYear}
              onRequestedReload={(suggestedCenterYear: number) => {
                setCurrentCenterYear(suggestedCenterYear);
                doDebuff();
              }}
            />
          )}
          {navigator === "table_view" && (
            <TimelineEventTable
              currentCenterYear={currentCenterYear}
              currentDeltaYear={currentDeltaYear}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TimelineEventExplorer;
