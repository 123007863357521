import { IDropdownOption, PrimaryButton } from "@fluentui/react";
import { IBookCharacterMetaData } from "../../../Models/IBookCharacterMetaData";
import Tr from "../../../Utils/Translations/Translations";
import MultiForm, {
  IMultiFormInput,
  triggerFormValidation,
} from "../../MultiForm/MultiForm";
import { mobileCheck } from "../../../App";
import { betterCommaListDisplay } from "../../ExploreEventsTickGrid/ExploreEventsTickGrid";
import {
  getCharacterMetadataTypeOptions,
  getMetadataClassByType,
} from "../../../Utils/CharacterMetaDataMap";
import ApiService from "../../../Services/ApiService";
import { IAPIResponse } from "../../../Services/AjaxService";
import { GlobalState } from "../../../Redux/RootReducer";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { IDataImage } from "../../../Models/IDataImage";
import Loadable from "../../Loadable/Loadable";
import SuperSpinner from "../../SuperSpinner/SuperSpinner";
import CustomTooltip from "../../CustomTooltip/CustomTooltip";
import { Id } from "react-toastify";
import { ToastMessage } from "../../../Utils/UIMessages";
import { IWorkgroup } from "../../../Models/IWorkgroup";
import RoleDependantDisplayer, {
  canDisplayElement,
} from "../../RoleDependantDisplayer/RoleDependantDisplayer";

var loadingRowClone: any = {};

interface ICharacterMetaDataEditorInnerProps {
  bookId: number;
  characterName: string;
}

const CharacterMetaDataEditorInnerMeta = (
  props: ICharacterMetaDataEditorInnerProps
) => {
  const [characterMetaData, setCharacterMetaData] = useState<
    IBookCharacterMetaData[]
  >([]);
  const [bookSocialGroupMetadata, setBookSocialGroupMetadata] = useState<
    IBookCharacterMetaData[]
  >([]);
  const knownCharacters: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownCharacters
  );
  const activeWorkgroup: IWorkgroup | undefined = useSelector(
    (state: GlobalState) => state.generic.activeWorkgroup
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingRow, setLoadingRow_] = useState<any>({});
  const [inited, setInited] = useState<boolean>(false);
  const [formHideDeletedRows, setFormHideDeletedRows] = useState<number[]>([]);

  const setLoadingRow = (loading: boolean, index: number) => {
    loadingRowClone[index] = loading;
    setLoadingRow_({ ...loadingRowClone });
  };

  const addHiddenRow = (hideRow: number) => {
    let rows: number[] = [...formHideDeletedRows];
    rows.push(hideRow);
    setFormHideDeletedRows(rows);
  };

  const loadBookSocialGroupMetaData = () => {
    ApiService.MetaDataController.GetCharacterMetaData(
      props.bookId,
      undefined,
      undefined,
      undefined,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setBookSocialGroupMetadata(
            response.parsed.filter((x: IBookCharacterMetaData) =>
              (x.MetaDataType ?? "").includes("social_group")
            )
          );
        }
      }
    );
  };

  const loadCharacterMetaData = () => {
    loadingRowClone = {};
    setLoading(true);
    ApiService.MetaDataController.GetCharacterMetaData(
      props.bookId,
      props.characterName,
      undefined,
      undefined,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setCharacterMetaData(
            response.parsed.filter((x: IBookCharacterMetaData) =>
              (x.MetaDataType ?? "").includes("metadata_type")
            )
          );
          setTimeout(() => {
            setInited(true);
          }, 300);
        }
        setLoading(false);
      }
    );
  };

  const addCharacterMetaData = (
    metaData: IBookCharacterMetaData,
    index: number
  ) => {
    setLoadingRow(true, index);
    ApiService.MetaDataController.InsertCharacterMetaData(
      metaData,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let meta: IBookCharacterMetaData[] = [...characterMetaData];
          metaData.Id = response.parsed;
          meta[index] = metaData;
          setCharacterMetaData(meta);
          ToastMessage(
            "success",
            Tr.Translate("language", "action_completed_successfully")
          );
        }
        setLoadingRow(false, index);
      }
    );
  };

  const editCharacterMetaData = (
    metaData: IBookCharacterMetaData,
    index: number
  ) => {
    setLoadingRow(true, index);
    ApiService.MetaDataController.UpdateCharacterMetaData(
      metaData,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let meta: IBookCharacterMetaData[] = [...characterMetaData];
          meta[index] = metaData;
          setCharacterMetaData(meta);
          ToastMessage(
            "success",
            Tr.Translate("language", "action_completed_successfully")
          );
        }
        setLoadingRow(false, index);
      }
    );
  };

  const deleteCharacterMetaData = (
    metaData: IBookCharacterMetaData,
    index: number
  ) => {
    setLoadingRow(true, index);
    ApiService.MetaDataController.DeleteCharacterMetaData(
      metaData.Id ?? 0,
      (response: IAPIResponse) => {
        if (response.error === null) {
          addHiddenRow(index);
          ToastMessage(
            "success",
            Tr.Translate("language", "action_completed_successfully")
          );
        }
        setLoadingRow(false, index);
      }
    );
  };

  useEffect(() => {
    loadBookSocialGroupMetaData();
    loadCharacterMetaData();
  }, [props.characterName]);

  const builSocialGroupTargetOptions = (currentDiscriminantType: string) => {
    let options: IDropdownOption[] = [];
    bookSocialGroupMetadata.forEach((x: IBookCharacterMetaData) => {
      if (
        options.find((y: IDropdownOption) => y.text === x.MetaDataValue) ===
        undefined
      ) {
        options.push({
          key: x.MetaDataType,
          text: x.MetaDataValue,
        });
      }
    });
    characterMetaData.forEach((x: IBookCharacterMetaData) => {
      if (
        (x.MetaDataType ?? "").includes("social_group") &&
        options.find((y: IDropdownOption) => y.text === x.MetaDataValue) ===
          undefined
      ) {
        options.push({
          key: x.MetaDataType,
          text: x.MetaDataValue,
        });
      }
    });

    // its a religion pregiudice, keep only religions in list
    if ((currentDiscriminantType ?? "").includes("religion_pregiudice")) {
      options = options.filter((x: IDropdownOption) =>
        x.key.toString().includes("social_group_religion_affiliation")
      );
    }
    // its a race pregiudice, keep only race in list
    if ((currentDiscriminantType ?? "").includes("race_pregiudice")) {
      options = options.filter((x: IDropdownOption) =>
        x.key
          .toString()
          .includes("metadata_type_social_group_personal_biological_race")
      );
    }

    // its an etnicity pregiudice, keep only race in list
    if ((currentDiscriminantType ?? "").includes("ethinicity")) {
      options = options.filter((x: IDropdownOption) =>
        x.key
          .toString()
          .includes("metadata_type_social_group_personal_ethnicity")
      );
    }

    // its a sexual pregiudice, keep only race in list
    if ((currentDiscriminantType ?? "").includes("sex")) {
      options = options.filter((x: IDropdownOption) =>
        x.key.toString().includes("metadata_type_social_group_personal_sex")
      );
    }

    return options;
  };

  const getInputsForForm = (metaData: IBookCharacterMetaData) => {
    let alreadySelected: string[] = characterMetaData
      .map((x: IBookCharacterMetaData) => x.MetaDataType ?? "")
      .filter(
        (x: string, i: number) =>
          x !== "" && formHideDeletedRows.indexOf(i) === -1
      );

    let metaOptions: IDropdownOption[] =
      getCharacterMetadataTypeOptions().filter(
        (x: any) => alreadySelected.indexOf(x.key) === -1
      );

    if (metaData.MetaDataType) {
      metaOptions = [
        {
          key: metaData.MetaDataType,
          text: Tr.Translate("metadata", metaData.MetaDataType),
        },
        ...metaOptions,
      ];
    }

    let inputs: IMultiFormInput[] = [
      {
        type: "hidden",
        currentValue: metaData.Id,
        name: "Id",
      },
      {
        type: "hidden",
        currentValue: metaData.BookId,
        name: "BookId",
      },
      {
        type: "hidden",
        currentValue: metaData.CharacterName,
        name: "CharacterName",
      },
      {
        type: "select",
        width: mobileCheck() ? 100 : 50,
        currentValue: metaData.MetaDataType,
        label: Tr.Translate("language", "metadata_type"),
        required: true,
        onChangeResetsOtherInputs: ["MetaDataValue"],
        name: "MetaDataType",
        extraParams: {
          options: metaOptions,
        },
      },
    ];

    let metadataClass = getMetadataClassByType(metaData.MetaDataType);

    if (metadataClass === "metadata_type_class_family") {
      inputs.push({
        type: "combobox",
        width: mobileCheck() ? 100 : 50,
        currentValue: metaData.MetaDataValue,
        label: Tr.Translate("language", "metadata_value"),
        required: true,
        name: "MetaDataValue",
        extraParams: {
          options: knownCharacters.map((x: string) => {
            return {
              key: x,
              text: betterCommaListDisplay(x),
            };
          }),
        },
      });
    }

    if (metadataClass === "metadata_type_class_beliefs") {
      inputs.push({
        type: "combobox",
        width: mobileCheck() ? 100 : 50,
        currentValue: metaData.MetaDataValue,
        label: Tr.Translate("language", "metadata_value"),
        required: true,
        name: "MetaDataValue",
        extraParams: {
          options: builSocialGroupTargetOptions(metaData.MetaDataType),
        },
      });
    }

    if (metadataClass === "metadata_type_social_group_class_personal") {
      inputs.push({
        type: "text",
        width: mobileCheck() ? 100 : 50,
        currentValue: metaData.MetaDataValue,
        label: Tr.Translate("language", "metadata_value"),
        required: true,
        name: "MetaDataValue",
        extraParams: { maxLength: 200 },
      });
    }

    return inputs;
  };

  return (
    <div>
      <Loadable
        isLoading={loading}
        content={
          <div style={mobileCheck() ? { width: "75vw" } : {}}>
            {characterMetaData.map(
              (metaData: IBookCharacterMetaData, i: number) => {
                return (
                  <div key={i}>
                    {formHideDeletedRows.indexOf(i) === -1 && (
                      <div
                        key={i}
                        className={
                          mobileCheck()
                            ? "char-meta-form-row-mobile"
                            : "char-meta-form-row"
                        }
                      >
                        <div style={{ width: "100%" }}>
                          <MultiForm
                            readOnlyMode={
                              !canDisplayElement(activeWorkgroup, [
                                "admin",
                                "contributor",
                              ])
                            }
                            strictBindingValue
                            disabled={loadingRow[i]}
                            formUniqueId={"edit_character_meta_" + i}
                            inputs={getInputsForForm(metaData)}
                            onChange={(data: any) => {
                              if (!inited) {
                                return;
                              }
                              let meta: IBookCharacterMetaData[] = [
                                ...characterMetaData,
                              ];
                              meta[i] = data;
                              setCharacterMetaData(meta);
                            }}
                            onSubmit={(data: any) => {
                              if (data.Id) {
                                editCharacterMetaData(data, i);
                              } else {
                                addCharacterMetaData(data, i);
                              }
                            }}
                          />
                        </div>
                        {loadingRow[i] && (
                          <div
                            style={{
                              display: "flex",
                              paddingBottom: "1.3em",
                              width: "6em",
                            }}
                          >
                            <SuperSpinner />
                          </div>
                        )}
                        {!loadingRow[i] && (
                          <RoleDependantDisplayer
                            whiteList={["admin", "contributor"]}
                            element={
                              <div
                                style={{
                                  display: "flex",
                                  paddingBottom: "1em",
                                  width: "6em",
                                }}
                              >
                                <CustomTooltip
                                  isButton
                                  iconName="Save"
                                  disabled={!metaData.MetaDataValue}
                                  onClick={() => {
                                    triggerFormValidation(
                                      "edit_character_meta_" + i
                                    );
                                  }}
                                  content={
                                    <div>
                                      {Tr.Translate("language", "save")}
                                    </div>
                                  }
                                />
                                <CustomTooltip
                                  isButton
                                  iconName="Trash"
                                  onClick={() => {
                                    if (metaData.Id) {
                                      deleteCharacterMetaData(metaData, i);
                                    } else {
                                      addHiddenRow(i);
                                    }
                                  }}
                                  content={
                                    <div>
                                      {Tr.Translate("language", "delete")}
                                    </div>
                                  }
                                />
                              </div>
                            }
                          />
                        )}
                      </div>
                    )}
                  </div>
                );
              }
            )}
            <div className="char-meta-button-on-right">
              <PrimaryButton
                iconProps={{ iconName: "Add" }}
                text={Tr.Translate("language", "add")}
                onClick={() => {
                  let meta: IBookCharacterMetaData[] = [...characterMetaData];
                  meta.push({
                    BookId: props.bookId,
                    CharacterName: props.characterName,
                    MetaDataType: "",
                    MetaDataValue: "",
                    Deleted: false,
                  });
                  setCharacterMetaData(meta);
                }}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default CharacterMetaDataEditorInnerMeta;
