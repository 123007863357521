import { useEffect } from "react";
import "./AnimatedTextLogo.scss";
import ImageAssetLoader from "../ImageAssetLoader/ImageAssetLoader";

export interface IAnimatedTextLogoProps {
  onAnimationDone: () => void;
  underText: string;
}

const AnimatedTextLogo = (props: IAnimatedTextLogoProps) => {
  useEffect(() => {
    setTimeout(() => {
      props.onAnimationDone();
    }, 4000);
  }, []);

  return (
    <div className="animation-text-container">
      <div className="patterns">
        <svg width="100%" height="100%">
          <text x="50%" y="60%" textAnchor="middle">
            Plotter Studio
          </text>
        </svg>
      </div>
      <div className="animation-text-undertex-wrap">
        <div className="animation-text-undertex">
          <ImageAssetLoader src="/Assets/Public/favicon-transparent.png" />
          <div className="animation-text-undertex-label">{props.underText}</div>
        </div>
      </div>
    </div>
  );
};

export default AnimatedTextLogo;
