import { useEffect, useRef, useState } from "react";
import "./WorkgroupTeamEditor.scss";
import { IWorkgroup, IWorkgroupMember } from "../../Models/IWorkgroup";
import ApiService from "../../Services/ApiService";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Redux/RootReducer";
import { IAPIResponse } from "../../Services/AjaxService";
import { ToastMessage, ToastMessageUnique } from "../../Utils/UIMessages";
import Tr from "../../Utils/Translations/Translations";
import Loadable from "../Loadable/Loadable";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import {
  Dropdown,
  DropdownMenuItemType,
  IconButton,
  IDropdownOption,
  ISelectableOption,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
} from "@fluentui/react";
import BetterTextField from "../BetterTextField/BetterTextField";
import { IBook } from "../../Models/IBook";
import BetterDropDown from "../BetterDropDown/BetterDropDown";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";

export interface IWorkgroupTeamEditorProps {}

const WorkgroupTeamEditor = (props: IWorkgroupTeamEditorProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [team, setTeam] = useState<IWorkgroupMember[]>([]);
  const [email, setEmail] = useState<string>("");
  const [membershipType, setMembershipType] = useState<string>("");
  const [bookPermissions, setBookPermissions] = useState<string[]>([]);
  const [toEditUser, setToEditUser] = useState<IWorkgroupMember | undefined>();
  const [toRemoveUser, setToRemoveUser] = useState<
    IWorkgroupMember | undefined
  >();
  const books: IBook[] = useSelector(
    (state: GlobalState) => state.generic.books
  );

  const activeWorkgroup: IWorkgroup | undefined = useSelector(
    (state: GlobalState) => state.generic.activeWorkgroup
  );

  const loadTeam = () => {
    if (activeWorkgroup) {
      setLoading(true);
      ApiService.WorkGroupController.GetWorkgroupMembers(
        activeWorkgroup.Id,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setTeam(response.parsed);
          }
          setLoading(false);
        }
      );
    }
  };

  const updateUser = () => {
    setProcessing(true);
    ApiService.WorkGroupController.UpdateWorkgroupMember(
      {
        Id: toEditUser?.Id ?? 0,
        WorkgroupID: activeWorkgroup?.Id ?? 0,
        MembershipType: membershipType.split("__")[0], // remove __all flag
        BookTargets: membershipType.includes("__all")
          ? "*"
          : bookPermissions.join(","),
      },
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(
            "success",
            Tr.Translate("language", "action_completed_successfully")
          );
          setToEditUser(undefined);
          loadTeam();
        }
        setProcessing(false);
      }
    );
  };

  const inviteUser = () => {
    setProcessing(true);
    ApiService.WorkGroupController.InviteWorkgroupMember(
      {
        Email: email,
        EmailContent: Tr.Translate("emails", "invite_user_email")
          .replaceAll("$CURRENT_DOMAIN", window.location.origin)
          .replaceAll(
            "$EMAIL_IMAGE",
            "https://emanueleuniroma2.github.io/FFASP/common/assets/smiling_woman_and_man.png"
          ),
        WorkgroupId: activeWorkgroup?.Id ?? 0,
        MembershipType: membershipType.split("__")[0], // remove __all flag
        BookTargets: membershipType.includes("__all")
          ? "*"
          : bookPermissions.join(","),
      },
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage("success", Tr.Translate("language", "invitation_sent"));
          setEmail("");
        } else {
          if (response.raw.status === 404) {
            ToastMessageUnique(
              "error",
              Tr.Translate("language", "invite_user_not_found")
            );
          }
        }
        setProcessing(false);
      }
    );
  };

  const removeMemberFromGroup = (x: IWorkgroupMember) => {
    setLoading(true);
    if (activeWorkgroup) {
      ApiService.WorkGroupController.DeleteWorkgroupMember(
        activeWorkgroup?.Id ?? 0,
        x.Id ?? 0,
        (response: IAPIResponse) => {
          if (response.error === null) {
            loadTeam();
            DismissModal("removeUserModal");
          }
          setLoading(false);
        }
      );
    }
  };

  const getBookOptions = (books: IBook[]) => {
    let options: IDropdownOption[] = [
      {
        key: -2,
        text: Tr.Translate("language", "reader"),
        itemType: DropdownMenuItemType.Header,
      },
    ];

    for (let i = 0; i < books.length; i++) {
      options.push({
        key: "R" + books[i].Id?.toString(),
        text: "(R)" + books[i].Title,
      });
    }

    if (membershipType.includes("contributor")) {
      options.push({
        key: -1,
        text: Tr.Translate("language", "contributor"),
        itemType: DropdownMenuItemType.Header,
      });

      for (let i = 0; i < books.length; i++) {
        options.push({
          key: "W" + books[i].Id?.toString(),
          text: "(W)" + books[i].Title,
        });
      }
    }

    return options;
  };

  useEffect(() => {
    loadTeam();
  }, [activeWorkgroup]);

  const displayBookOptions =
    membershipType !== "" && !membershipType.includes("__all");

  return (
    <div>
      <SmartModal
        title={
          Tr.Translate("language", "delete") + " " + toRemoveUser?.Email + "?"
        }
        modalUniqueId="removeUserModal"
        content={
          <div>
            <MessageBar messageBarType={MessageBarType.info}>
              {Tr.Translate("language", "proceed_removing_user")}
            </MessageBar>
          </div>
        }
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: loading,
            onClick: () => {
              DismissModal("removeUserModal");
            },
          },
          {
            text: Tr.Translate("language", "accept"),
            disabled: loading,
            onClick: () => {
              if (toRemoveUser) {
                removeMemberFromGroup(toRemoveUser);
              }
            },
          },
        ]}
      />
      <Loadable
        isLoading={loading}
        content={
          <div>
            {toEditUser && (
              <div className="edit-team-member-wrap">
                <div className="edit-team-member-top-line">
                  <PrimaryButton
                    iconProps={{ iconName: "Back" }}
                    text={Tr.Translate("language", "back")}
                    onClick={() => setToEditUser(undefined)}
                  />
                  <Label>{toEditUser.Email}</Label>
                </div>
                <div>
                  <form
                    onSubmit={(e: any) => {
                      e.preventDefault();
                      updateUser();
                      return false;
                    }}
                  >
                    <div className="wgmembers-button-right-zone">
                      <div
                        style={
                          displayBookOptions
                            ? { width: "50%" }
                            : { width: "100%" }
                        }
                      >
                        <Dropdown
                          required
                          selectedKey={toEditUser.MembershipType}
                          label={Tr.Translate(
                            "language",
                            "invite_user_membership_type"
                          )}
                          placeholder={Tr.Translate(
                            "language",
                            "invite_user_membership_type"
                          )}
                          onChange={(e, o) => {
                            if (o != undefined) {
                              setBookPermissions([]);
                              setMembershipType(o.key.toString());
                            }
                          }}
                          options={[
                            {
                              key: "reader",
                              text: Tr.Translate("language", "reader"),
                            },
                            {
                              key: "reader__all",
                              text:
                                Tr.Translate("language", "reader") +
                                " (" +
                                Tr.Translate("language", "all") +
                                ")",
                            },
                            {
                              key: "contributor",
                              text: Tr.Translate("language", "contributor"),
                            },
                            {
                              key: "contributor__all",
                              text:
                                Tr.Translate("language", "contributor") +
                                " (" +
                                Tr.Translate("language", "all") +
                                ")",
                            },
                          ]}
                        />
                      </div>
                      {displayBookOptions && (
                        <div style={{ width: "50%" }}>
                          <BetterDropDown
                            required
                            currentValue={bookPermissions}
                            label={Tr.Translate(
                              "language",
                              "invite_user_book_targets"
                            )}
                            placeholder={Tr.Translate(
                              "language",
                              "invite_user_book_targets"
                            )}
                            multiSelect
                            onChange={(e, o) => {
                              if (o != undefined) {
                                setBookPermissions(
                                  o.key
                                    .toString()
                                    .replaceAll(" ", "")
                                    .split(",")
                                );
                              }
                            }}
                            options={getBookOptions(books)}
                          />
                        </div>
                      )}
                      <Loadable
                        noPadding
                        content={
                          <PrimaryButton
                            type="submit"
                            iconProps={{ iconName: "Save" }}
                            text={Tr.Translate("language", "save")}
                          />
                        }
                        isLoading={processing}
                      />
                    </div>
                  </form>
                  {displayBookOptions &&
                    bookPermissions.filter((x) => x).length > 0 && (
                      <div>
                        <Label>
                          Recap{" "}
                          {Tr.Translate(
                            "language",
                            "invite_user_book_targets"
                          ).toLocaleLowerCase()}
                        </Label>
                        <div>
                          {bookPermissions.map((x: string, i: number) => {
                            let targetBook: IBook | undefined = books.find(
                              (y: IBook) => {
                                return x.substring(1) === y.Id?.toString();
                              }
                            );
                            return (
                              <div key={i} className="recap-permission-box">
                                {"(" +
                                  x.substring(0, 1) +
                                  ") " +
                                  targetBook?.Title}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}
            {!toEditUser && (
              <div>
                <div className="wgmembers-userlist-mainwrap">
                  {team.map((x: IWorkgroupMember, i: number) => {
                    return (
                      <div key={i} className="wgmembers-row">
                        <div className="wgmembers-row-uname">{x.Username}</div>
                        <div className="wgmembers-row-email">{x.Email}</div>
                        <div className="wgmembers-row-type">
                          {x.MembershipType}
                        </div>
                        <CustomTooltip
                          disabled={x.MembershipType === "admin"}
                          onClick={() => {
                            setMembershipType(x.MembershipType);
                            setBookPermissions(x.BookTargets?.split(",") ?? []);
                            setToEditUser(x);
                          }}
                          iconName="Settings"
                          isButton
                          content={Tr.Translate("language", "settings")}
                        />
                        <CustomTooltip
                          disabled={x.MembershipType === "admin"}
                          onClick={() => {
                            setToRemoveUser(x);
                            SummonModal("removeUserModal");
                          }}
                          iconName="Trash"
                          isButton
                          content={Tr.Translate("language", "delete")}
                        />
                      </div>
                    );
                  })}
                </div>

                <div className="wgmembers-button-right-message">
                  <MessageBar messageBarType={MessageBarType.info}>
                    {Tr.Translate("language", "invite_user_to_group_info")}
                  </MessageBar>
                </div>
                <div>
                  <form
                    onSubmit={(e: any) => {
                      e.preventDefault();
                      inviteUser();
                      return false;
                    }}
                  >
                    <div className="wgmembers-button-right-zone">
                      <div style={{ width: "50%" }}>
                        <BetterTextField
                          required
                          type="email"
                          value={email}
                          onChange={(e, o) => {
                            if (o != undefined) {
                              setEmail(o);
                            }
                          }}
                          label={Tr.Translate(
                            "language",
                            "invite_user_to_group"
                          )}
                          placeholder={Tr.Translate(
                            "language",
                            "invite_user_to_group"
                          )}
                        />
                      </div>
                      <div
                        style={
                          displayBookOptions
                            ? { width: "30%" }
                            : { width: "50%" }
                        }
                      >
                        <Dropdown
                          required
                          label={Tr.Translate(
                            "language",
                            "invite_user_membership_type"
                          )}
                          placeholder={Tr.Translate(
                            "language",
                            "invite_user_membership_type"
                          )}
                          onChange={(e, o) => {
                            if (
                              o != undefined &&
                              o.key?.toString() !== membershipType
                            ) {
                              setBookPermissions([]);
                              setMembershipType(o.key.toString());
                            }
                          }}
                          options={[
                            {
                              key: "reader",
                              text: Tr.Translate("language", "reader"),
                            },
                            {
                              key: "reader__all",
                              text:
                                Tr.Translate("language", "reader") +
                                " (" +
                                Tr.Translate("language", "all") +
                                ")",
                            },
                            {
                              key: "contributor",
                              text: Tr.Translate("language", "contributor"),
                            },
                            {
                              key: "contributor__all",
                              text:
                                Tr.Translate("language", "contributor") +
                                " (" +
                                Tr.Translate("language", "all") +
                                ")",
                            },
                          ]}
                        />
                      </div>
                      {displayBookOptions && (
                        <div style={{ width: "40%" }}>
                          <BetterDropDown
                            required
                            label={Tr.Translate(
                              "language",
                              "invite_user_book_targets"
                            )}
                            placeholder={Tr.Translate(
                              "language",
                              "invite_user_book_targets"
                            )}
                            multiSelect
                            onChange={(e, o) => {
                              if (o != undefined) {
                                setBookPermissions(
                                  o.key
                                    .toString()
                                    .replaceAll(" ", "")
                                    .split(",")
                                );
                              }
                            }}
                            options={getBookOptions(books)}
                          />
                        </div>
                      )}
                      <Loadable
                        noPadding
                        content={
                          <PrimaryButton
                            type="submit"
                            iconProps={{ iconName: "Send" }}
                          />
                        }
                        isLoading={processing}
                      />
                    </div>
                  </form>
                  {displayBookOptions &&
                    bookPermissions.filter((x) => x).length > 0 && (
                      <div>
                        <Label>
                          Recap{" "}
                          {Tr.Translate(
                            "language",
                            "invite_user_book_targets"
                          ).toLocaleLowerCase()}
                        </Label>
                        <div>
                          {bookPermissions.map((x: string, i: number) => {
                            let targetBook: IBook | undefined = books.find(
                              (y: IBook) => {
                                return x.substring(1) === y.Id?.toString();
                              }
                            );
                            return (
                              <div key={i} className="recap-permission-box">
                                {"(" +
                                  x.substring(0, 1) +
                                  ") " +
                                  targetBook?.Title}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

export default WorkgroupTeamEditor;
