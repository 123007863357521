export const Dictionary = {
  __dev_sub_name: "Developer",
  __dev_sub_description:
    "A test subscription to verify the proper functioning of the Paypal store. This subscription is not visible to the public and cannot be purchased.",

  __single_user_mini_name: "Single user (Mini)",
  __single_user_mini_description:
    "A subscription for people who feel they need more time to evaluate the product.This subscription is near cost-free and offers a sneak pick of what this App can do for your projects.",
  __single_user_name: "Single user",
  __single_user_description:
    "A subscription for personal use. Perfect for private projects or hobbists.",
  __small_group_name: "Small group",
  __small_group_description:
    "This intermediate solution is fit for small working groups, students or family members.",
  __office_group_name: "Office",
  __office_group_description:
    "Solution for an office tier level of involvement. This subscription offers a reduced price per user to help small business to star over.",
  __enterprise_group_name: "Enterprise",
  __enterprise_group_description:
    "Bring your team to maximum productivity and proficiency in writing content. This subscriptions comes with unique advanced plot analysis tools .",
  __month: "Monthly price",
  __subscription_includes: "This subscription includes",
  __advanced_event_processing: "Plot's event auto correction.",
  __advanced_event_query: "Event query bot.",
  __advanced_event_advanced_processing: "Advanced plot's event processing.",
  __book_limit_per_workgroup: "Up to 100 books for each workgroup",
  __price_yearly: "Yearly price discount",

  __workgroupsNumberFeature: "Workgroup amount",
  __workgroupsSizeFeature: "Workgroup size",
  __eventErrorDetection: "Error detection",
  __queryBotFeature: "Query Bot",
  __bookAmountFeature: "Books per workgroup",

  __workgroupsNumberFeatureDesc: "Different workgroup to organize your works.",
  __workgroupsSizeFeatureDesc:
    "Each workgroup can have a different set of contributors, so you can manage different teams on different projects.",
  __eventErrorDetectionDesc:
    "The event compiler will detect inconsistency for you. This will improve the quality of the content delivered to your end clients.",
  __queryBotFeatureDesc:
    "Your AI secretary. Ask questions to a smart bot about any event to keep track of any valuable information. No more paper notes laying around your office.",
  __bookAmountFeatureDesc:
    "Each workgroup you create will be able to store this amount of books.",

  __per_month_calls: "calls/month",
  __amount: "Quantità",
};
