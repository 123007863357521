import Tr from "../../Utils/Translations/Translations";
import "./Footer.scss";

export interface IIFooterData {
  title: string;
  voices: JSX.Element[];
}

export interface IFooterProps {}

const FooterData: IIFooterData[] = [
  {
    title: Tr.Translate("presentation", "footer_about_us"),
    voices: [
      <div>{Tr.Translate("presentation", "footer_our_mission")}</div>,
      <div>{Tr.Translate("presentation", "footer_were_are_we_headed")}</div>,
      <div>{Tr.Translate("presentation", "footer_services")}</div>,
    ],
  },
  {
    title: Tr.Translate("presentation", "footer_services"),
    voices: [
      <div>{Tr.Translate("presentation", "footer_serv_support")}</div>,
      <div>{Tr.Translate("presentation", "footer_serv_teaching")}</div>,
      <div>
        {Tr.Translate("presentation", "footer_serv_private_solutions")}
      </div>,
    ],
  },
  {
    title: Tr.Translate("presentation", "footer_contact_us"),
    voices: [<div>semanticmap.customers@gmail.com</div>],
  },
  {
    title: Tr.Translate("presentation", "footer_social_media"),
    voices: [
      <div>{Tr.Translate("presentation", "footer_social_facebook")}</div>,
      <div>{Tr.Translate("presentation", "footer_social_twitter")}</div>,
    ],
  },
];

const Footer = (props: IFooterProps) => {
  return (
    <div className="main-footer-wrap">
      {FooterData.map((x: IIFooterData, i: number) => {
        return (
          <div key={i} className="footer-column">
            <div className="footer-title">{x.title}</div>
            {x.voices.map((y: JSX.Element, j: number) => {
              return (
                <div className="footer-label" key={j}>
                  {y}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Footer;
