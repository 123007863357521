import { APIUrls } from "../../ApiUrls";
import { IBotRequest } from "../../Models/IBotRequest";
import { IErrorCompilerRequest } from "../../Models/IErrorCompilerRequest";
import { IEvent } from "../../Models/IEvent";
import { ITextToEventRequest } from "../../Models/ITextToEventRequest";
import { AjaxService, IAPIRequest, IAPIResponse } from "../AjaxService";
import { BuildParameters } from "../ApiService";

const EventController = {
  GetEvents: async (
    EventId?: number,
    BookId?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(APIUrls.EVENT.GET_EVENTS, [
      ["EventId", EventId],
      ["BookId", BookId],
    ]);
    request.allowCache = true;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  NewEvent: async (
    Event: IEvent,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.EVENT.INSERT_EVENT;
    request.method = "POST";

    // correct data
    if (!Event.EventKeyword) {
      Event.EventKeyword = "";
    }
    if (!Event.CharacterList) {
      Event.CharacterList = "";
    }

    request.payload = Event;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  UpdateEvent: async (
    Event: IEvent,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.EVENT.UPDATE_EVENT;

    // correct data
    if (!Event.EventKeyword) {
      Event.EventKeyword = "";
    }
    if (!Event.CharacterList) {
      Event.CharacterList = "";
    }

    request.method = "PUT";
    request.payload = Event;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  DeleteEvent: async (
    EventId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.EVENT.DELETE_EVENT + "?EventId=" + EventId;
    request.method = "DELETE";
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  SendBotRequest: async (
    botRequest: IBotRequest,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.EVENT.BOT_REQUEST;
    request.method = "POST";
    request.payload = botRequest;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  SendErrorCompilerRequest: async (
    errorCompilerReqiest: IErrorCompilerRequest,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.EVENT.ERROR_COMPILER_REQUEST;
    request.method = "POST";
    request.payload = errorCompilerReqiest;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  TextToEvents: async (
    textToEventRequest: ITextToEventRequest,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.EVENT.TEXT_TO_EVENTS;
    request.method = "POST";
    request.payload = textToEventRequest;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
};

export default EventController;
