import {
  DefaultButton,
  IconButton,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
} from "@fluentui/react";
import "./EventPage.scss";
import Tr from "../../Utils/Translations/Translations";
import { useEffect, useState } from "react";
import { IBook } from "../../Models/IBook";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import { ToastMessage } from "../../Utils/UIMessages";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../Redux/RootReducer";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import Loadable from "../../Components/Loadable/Loadable";
import { IEvent } from "../../Models/IEvent";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../Components/SmartModal/SmartModal";
import MultiForm, {
  triggerFormValidation,
} from "../../Components/MultiForm/MultiForm";
import MonthMapEditor, {
  IMonthMap,
} from "../../Components/MonthMapEditor/MonthMapEditor";
import BookExplorer from "../../Components/BookExplorer/BookExplorer";
import { IWorkgroup } from "../../Models/IWorkgroup";
import CustomTooltip from "../../Components/CustomTooltip/CustomTooltip";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../AppRoutes";
import CenterPad from "../../Components/CenterPad/CenterPad";
import WorkgroupTeamEditor from "../../Components/WorkgroupTeamEditor/WorkgroupTeamEditor";
import { IUser } from "../../Models/IUser";
import { mobileCheck } from "../../App";
import BooksMainGallery from "../../Components/BooksMainGallery/BooksMainGallery";
import BetterCoachMarks from "../../Components/BetterCoachMark/BetterCoachMark";
import AssetThemedLoader from "../../Components/AssetThemedLoader/AssetThemedLoader";
import ImageAssetLoader, {
  GenerateAssetUrl,
} from "../../Components/ImageAssetLoader/ImageAssetLoader";
import BookListPicker from "../../Components/BookListPicker/BookListPicker";
import ImagePicker from "../../Components/ImagePicker/ImagePicker";
import WorkgroupInfoCard from "../../Components/WorkgroupInfoCard/WorkgroupInfoCard";
import RoleDependantDisplayer from "../../Components/RoleDependantDisplayer/RoleDependantDisplayer";
import FeedbackHandler from "../../Components/FeedbackHandler/FeedbackHandler";
import ChatPage from "../ChatPage/ChatPage";
import ChatPollingHandler from "../../Components/ChatPollingHandler/ChatPollingHandler";
import { IWorkgroupChatNotRead } from "../../Models/IWorkgroupChat";

const EventPageInner = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const books = useSelector((state: GlobalState) => state.generic.books);
  const recentBooks = useSelector(
    (state: GlobalState) => state.generic.recentBooks
  );
  const [imgUrl, setImgUrl] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingRecent, setLoadingRecent] = useState<boolean>(true);
  const [innerPage, setInnerPage] = useState<string>("book-events");
  const [monthMap, setMonthMap] = useState<IMonthMap>();
  const [addingBook, setAddingBook] = useState<IBook>();
  const [mobileMenu, setMobileMenu] = useState<boolean>();
  const workgroupChatsNotRead = useSelector(
    (state: GlobalState) => state.chat.workgroupChatsNotRead
  );
  const activeBook: IBook | undefined = useSelector(
    (state: GlobalState) => state.generic.activeBook
  );
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.generic.user
  );
  const activeWorkgroup: IWorkgroup | undefined = useSelector(
    (state: GlobalState) => state.generic.activeWorkgroup
  );

  const loadBooks = () => {
    if (activeWorkgroup) {
      setLoading(true);
      ApiService.BookController.GetBooks(
        activeWorkgroup?.Id ?? 0,
        undefined,
        (response: IAPIResponse) => {
          if (response.error === null) {
            loadRecentBooks();
            dispatch(GenericActions.setBooks(response.parsed));
          }
          setLoading(false);
        }
      );
    }
  };

  const loadRecentBooks = () => {
    if (activeWorkgroup) {
      setLoadingRecent(true);
      ApiService.BookController.GetRecentBooks(
        activeWorkgroup?.Id ?? 0,
        undefined,
        (response: IAPIResponse) => {
          if (response.error === null) {
            dispatch(GenericActions.setRecentBooks(response.parsed));
          }
          setLoadingRecent(false);
        }
      );
    }
  };

  const openMobileMenu = () => {
    setMobileMenu(true);
  };

  useEffect(() => {
    document.addEventListener("toggle-mobile-menu", openMobileMenu);

    return () => {
      document.removeEventListener("toggle-mobile-menu", openMobileMenu);
    };
  }, []);

  const toWorkgroupPage = () => {
    dispatch(GenericActions.setExploreEventsDate(""));
    dispatch(GenericActions.setActiveBook(undefined));
    dispatch(GenericActions.SetActiveWorkgroup(undefined));
    navigate(AppRoutes.WORKGROUP_PICK_PAGE);
  };

  useEffect(() => {
    loadBooks();

    if (!activeWorkgroup) {
      setTimeout(() => {
        toWorkgroupPage();
      }, 500);
    }
  }, [activeWorkgroup]);

  const addBook = (data: any) => {
    if (monthMap?.Months.length === 0) {
      ToastMessage(
        "warning",
        Tr.Translate("language", "must_add_at_least_one_month")
      );
      return;
    }

    setAddingBook({ ...data });

    setLoading(true);
    let book: IBook = { ...data };
    book.MonthMap = JSON.stringify(monthMap);
    book.WorkgroupId = activeWorkgroup?.Id;
    ApiService.BookController.NewBook(
      book,
      activeWorkgroup?.Id ?? 0,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage("success", Tr.Translate("language", "book_added"));
          let books_: IBook[] = [...books];
          book.Id = response.parsed;
          book.UpdateDT = new Date().toISOString();
          books_.push(book);
          dispatch(GenericActions.setBooks(books_));
          DismissModal("addBookModal");
        } else {
          if (response.raw.status == 422) {
            Tr.ToastMessageUnique("language", "book_limit_reached");
          }
        }
        setLoading(false);
      }
    );
  };

  const unreadMessages = workgroupChatsNotRead.reduce(
    (a, b: IWorkgroupChatNotRead) => a + b.UnreadCount,
    0
  );

  return (
    <div>
      <SmartModal
        titleBarExtra={[<FeedbackHandler section="manage-book" />]}
        modalUniqueId="addBookModal"
        title={Tr.Translate("language", "add_new_book_form")}
        content={
          <div>
            <Loadable
              isLoading={loading}
              content={
                <div>
                  <MultiForm
                    disableOverflowHandler
                    formUniqueId="addBookForm"
                    onSubmit={(data: any) => {
                      data.BookImage = imgUrl;
                      addBook(data);
                    }}
                    inputs={[
                      {
                        type: "text",
                        name: "Title",
                        currentValue: addingBook?.Title,
                        label: Tr.Translate("language", "book_title"),
                        required: true,
                        extraParams: { maxLength: 50 },
                      },
                      {
                        type: "text",
                        name: "Description",
                        currentValue: addingBook?.Description,
                        label: Tr.Translate("language", "book_description"),
                        required: true,
                        extraParams: { maxLength: 3000 },
                      },
                      {
                        type: "text",
                        name: "Collection",
                        currentValue: addingBook?.Collection,
                        label: Tr.Translate("language", "book_collection"),
                        required: true,
                        extraParams: { maxLength: 50 },
                      },
                      {
                        type: "custom",
                        name: "_image",
                        element: (
                          <ImagePicker
                            text={Tr.Translate("language", "book_logo")}
                            onLoad={setImgUrl}
                            initialImage={imgUrl}
                          />
                        ),
                      },
                      {
                        type: "custom",
                        name: "_monthmap",
                        element: (
                          <MonthMapEditor
                            defaultValue={monthMap}
                            onChange={setMonthMap}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              }
            />
          </div>
        }
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: loading,
            onClick: () => {
              DismissModal("addBookModal");
            },
          },
          {
            text: Tr.Translate("language", "accept"),
            disabled: loading,
            onClick: () => {
              triggerFormValidation("addBookForm");
            },
          },
        ]}
      />
      {activeWorkgroup && (
        <div className="event-page-main-wrap">
          <div
            className={
              "left-menu " +
              (mobileCheck() ? "mobile-left-menu" : "") +
              " " +
              (mobileMenu ? "mobile-left-menu-open" : "")
            }
          >
            <WorkgroupInfoCard />

            <div className="menu-button-wrap">
              <RoleDependantDisplayer
                whiteList={["admin", "contributor"]}
                element={
                  <div style={{ marginTop: "0.5em" }}>
                    <BetterCoachMarks
                      coachMarkId="new_book_button"
                      coachMarckTitle={Tr.Translate("coaching", "new_book")}
                      coachMarckContent={Tr.Translate(
                        "coaching",
                        "new_book_desc"
                      )}
                      element={
                        <DefaultButton
                          onClick={() => {
                            setAddingBook(undefined);
                            setMonthMap(undefined);
                            setImgUrl("");
                            setMobileMenu(false);
                            SummonModal("addBookModal");
                          }}
                          style={{ width: "100%" }}
                          iconProps={{ iconName: "Add" }}
                          text={Tr.Translate("language", "new_book")}
                        />
                      }
                    />
                  </div>
                }
              />
              <div style={{ marginTop: "0.5em" }}>
                <DefaultButton
                  onClick={() => {
                    setMobileMenu(false);
                    SummonModal("BookPickerModal");
                  }}
                  style={{ width: "100%" }}
                  iconProps={{ iconName: "Edit" }}
                  text={Tr.Translate("language", "work_on_book")}
                />
              </div>
              {activeWorkgroup.OwnerID === loggedUser?.Id && (
                <div style={{ marginTop: "0.5em" }}>
                  <BetterCoachMarks
                    suggestedPosition="right"
                    coachMarkId="edit_work_group_people"
                    coachMarckTitle={Tr.Translate("coaching", "edit_workgroup")}
                    coachMarckContent={Tr.Translate(
                      "coaching",
                      "edit_workgroup_desc"
                    )}
                    element={
                      <DefaultButton
                        iconProps={{ iconName: "AddFriend" }}
                        onClick={() => {
                          setMobileMenu(false);
                          SummonModal("ManageTeamModal");
                        }}
                        text={Tr.Translate("language", "edit_workgroup_people")}
                      />
                    }
                  />
                </div>
              )}
              <div style={{ marginTop: "0.5em", position: "relative" }}>
                {unreadMessages > 0 && (
                  <div className="chat-red-dot-signal">{unreadMessages}</div>
                )}
                <DefaultButton
                  onClick={() => {
                    setMobileMenu(false);
                    setInnerPage("chat");
                  }}
                  style={{ width: "100%" }}
                  iconProps={{ iconName: "Chat" }}
                  text={Tr.Translate("language", "chat")}
                />
              </div>
              <div style={{ marginTop: "0.5em" }}>
                <DefaultButton
                  onClick={() => {
                    setMobileMenu(false);
                    toWorkgroupPage();
                  }}
                  style={{ width: "100%" }}
                  iconProps={{ iconName: "RevToggleKey" }}
                  text={Tr.Translate("language", "change_workgroup")}
                />
              </div>
            </div>
          </div>
          <div
            className={
              "right-page " + (mobileCheck() ? "right-page-mobile" : "")
            }
            id="timeline-main-div"
          >
            <ChatPollingHandler />

            <div className={innerPage !== "chat" ? "hidden-item" : ""}>
              <ChatPage
                onBack={() => {
                  setInnerPage("book-events");
                }}
              />
            </div>
            <div className={innerPage !== "book-events" ? "hidden-item" : ""}>
              {activeBook && (
                <div>
                  <BookExplorer />
                </div>
              )}
              {!activeBook && (
                <div className="start-work-label">
                  <AssetThemedLoader
                    assetName="books_pile"
                    style={{ height: "10em" }}
                  />

                  <br />
                  <DefaultButton
                    onClick={() => {
                      SummonModal("BookPickerModal");
                    }}
                    iconProps={{ iconName: "Edit" }}
                    text={Tr.Translate("language", "pick_book_to_start")}
                  />
                  <div style={{ marginTop: "1em" }}>
                    <Loadable
                      isLoading={loadingRecent}
                      content={
                        <div className="book-library-main-column">
                          <Label>
                            {Tr.Translate("language", "your_recent_books")}
                          </Label>
                          <BooksMainGallery
                            obBookSelected={(book: IBook | undefined) => {
                              if (book !== undefined) {
                                if (book?.Locked) {
                                  SummonModal("BadBookStatusPlsFix");
                                } else {
                                  setMobileMenu(false);
                                  dispatch(
                                    GenericActions.setActiveBookTimeline(
                                      undefined
                                    )
                                  );
                                  dispatch(
                                    GenericActions.setExploreEventsDate("")
                                  );
                                  dispatch(GenericActions.setActiveBook(book));
                                  setInnerPage("book-events");
                                }
                              }
                            }}
                            books={recentBooks ?? []}
                          />
                        </div>
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <SmartModal
        modalUniqueId="BookPickerModal"
        title={Tr.Translate("language", "pick_book_to_start")}
        content={
          <div>
            <Loadable
              isLoading={loading}
              content={
                <BookListPicker
                  books={books}
                  onBookSelected={(book: IBook) => {
                    if (book?.Locked) {
                      SummonModal("BadBookStatusPlsFix");
                    } else {
                      setMobileMenu(false);
                      dispatch(GenericActions.setActiveBookTimeline(undefined));
                      dispatch(GenericActions.setExploreEventsDate(""));
                      dispatch(GenericActions.setActiveBook(book));
                      DismissModal("BookPickerModal");
                      setInnerPage("book-events");
                    }
                  }}
                />
              }
            />
          </div>
        }
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: loading,
            onClick: () => {
              DismissModal("BookPickerModal");
            },
          },
        ]}
      />
      <SmartModal
        modalUniqueId="BadBookStatusPlsFix"
        title={Tr.Translate("language", "workgroup_book_limit_title")}
        content={
          <div style={{ maxWidth: "30em" }}>
            <MessageBar messageBarType={MessageBarType.warning}>
              {Tr.Translate("language", "workgroup_book_limit_description")}
            </MessageBar>
          </div>
        }
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: loading,
            onClick: () => {
              DismissModal("BadBookStatusPlsFix");
            },
          },
        ]}
      />

      <SmartModal
        title={Tr.Translate("language", "edit_workgroup_people")}
        modalUniqueId="ManageTeamModal"
        content={
          <div>
            <WorkgroupTeamEditor />
          </div>
        }
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: loading,
            onClick: () => {
              DismissModal("ManageTeamModal");
            },
          },
        ]}
      />
      {mobileCheck() && (
        <div
          onClick={() => {
            setMobileMenu(false);
          }}
          className={
            "left-menu-inkdrop " + (mobileMenu ? "left-menu-inkdrop-open" : "")
          }
        ></div>
      )}
    </div>
  );
};

const EventPage = () => {
  const [loading, setLoading] = useState<number>(0);

  const setLoader = () => {
    downCount(5);
  };

  const downCount = (counting: number) => {
    if (counting > 0) {
      setLoading(counting - 1);
      setTimeout(() => {
        downCount(counting - 1);
      }, 1000);
    }
  };

  useEffect(() => {
    document.addEventListener("sync-data", setLoader);

    return () => {
      document.removeEventListener("sync-data", setLoader);
    };
  }, []);

  return (
    <div>
      {loading === 0 && <EventPageInner />}
      {loading > 0 && (
        <div className="update-container">
          <div>
            <div className="update-container-label">
              {Tr.Translate("language", "getting_updates")
                .split(".")
                .filter((x: string) => x)
                .map((x: string, i: number) => {
                  return <div key={i}>{x + "."}</div>;
                })}
            </div>
            <Spinner labelPosition="right" label={loading.toString() + "s"} />
          </div>
        </div>
      )}
    </div>
  );
};

export default EventPage;
