import { Link, useSearchParams } from "react-router-dom";
import "./EmailConfirmationPage.scss";
import { useEffect, useState } from "react";
import CenterPad from "../../Components/CenterPad/CenterPad";
import Loadable from "../../Components/Loadable/Loadable";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import { ToastMessage } from "../../Utils/UIMessages";
import { Icon, PrimaryButton } from "@fluentui/react";
import Tr from "../../Utils/Translations/Translations";
import { AppRoutes } from "../../AppRoutes";
import { mobileCheck } from "../../App";
import Generic from "../../Utils/Generic";

const EmailConfirmationPage = () => {
  const [params] = useSearchParams();
  const token = params.get("token");
  const [loading, setLoading] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const validateToken = () => {
    if (token) {
      setError(false);
      setLoading(true);
      ApiService.SessionController.UpdateRegistration(
        token,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setDone(true);
            ToastMessage(
              "success",
              Tr.Translate("language", "email_registered")
            );
          } else {
            setError(true);
          }
          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    validateToken();
  }, []);

  return (
    <div
      className="confirm-email-page-wrap"
      style={Generic.GetRandomBackgroundUrlCss()}
    >
      <CenterPad
        content={
          <Loadable
            isLoading={loading}
            content={
              <div className="confirm-email-inner-frame">
                {done && (
                  <div>
                    <div className="confirmation-done-icon">
                      <div>{Tr.Translate("language", "done")}</div>
                      <Icon iconName="SkypeCircleCheck" />
                    </div>
                    <div className="confirmation-done-subtitle">
                      {Tr.Translate("language", "email_registered")}
                    </div>
                  </div>
                )}
                {error && (
                  <div
                    className="confirmation-not-done-icon"
                    style={mobileCheck() ? { fontSize: "1.2em" } : {}}
                  >
                    <div>{Tr.Translate("system", "invalid_token")}</div>
                    <Icon iconName="StatusErrorFull" />
                  </div>
                )}
                <hr />
                <div className="flex-line">
                  <Link to={AppRoutes.LOGIN_PAGE}>
                    {Tr.Translate("language", "back")}
                  </Link>
                </div>
              </div>
            }
          />
        }
      />
    </div>
  );
};

export default EmailConfirmationPage;
