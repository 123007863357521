export const Dictionary = {
  __metadata_type_class_family: "Familia",
  __metadata_type_class_beliefs: "Creencias",
  __metadata_type_social_group_class_personal: "Personal",
  __metadata_type_family_father_positive: "Familia - Padre (Amoroso)",
  __metadata_type_family_mother_positive: "Familia - Madre (Amorosa)",
  __metadata_type_family_father_negative: "Familia - Padre (No amar)",
  __metadata_type_family_mother_negative: "Familia - Madre (Sin amar)",
  __metadata_type_family_step_father_positive: "Familia - Padrastro (Amoroso)",
  __metadata_type_family_step_mother_positive: "Familia - Madrastra (Amorosa)",
  __metadata_type_family_step_father_negative: "Familia - Padrastro (Sin amar)",
  __metadata_type_family_step_mother_negative: "Familia - Madrastra (Sin amar)",

  __metadata_type_family_child_negative: "Hijo(a) (No deseado(a))",
  __metadata_type_family_child_positive: "Hijo(a) (Deseado(a))",
  __metadata_type_family_sibling_negative: "Hermano(a) (No amistoso(a))",
  __metadata_type_family_sibling_positive: "Hermano(a) (Amistoso(a))",

  __metadata_type_religion_pregiudice_negative: "Prejuicio religioso negativo",
  __metadata_type_religion_pregiudice_positive: "Prejuicio religioso positivo",
  __metadata_type_religion_pregiudice_negative_aggressive:
    "Prejuicio religioso negativo - Agresivo",
  __metadata_type_race_pregiudice_negative: "Prejuicio racial negativo",
  __metadata_type_race_pregiudice_positive: "Prejuicio racial positivo",
  __metadata_type_race_pregiudice_negative_aggressive:
    "Prejuicio racial negativo - Agresivo",

  __metadata_type_ethinicity_pregiudice_positive: "Prejuicio étnico positivo",
  __metadata_type_ethinicity_pregiudice_negative: "Prejuicio étnico negativo",
  __metadata_type_ethinicity_pregiudice_negative_aggressive:
    "Prejuicio étnico negativo - Agresivo",
  __metadata_type_sex_pregiudice_positive: "Prejuicio sexual positivo",
  __metadata_type_sex_pregiudice_negative: "Prejuicio sexual negativo",
  __metadata_type_sex_pregiudice_negative_aggressive:
    "Prejuicio sexual negativo - Agresivo",

  __metadata_type_social_group_religion_affiliation: "Afiliación religiosa",
  __metadata_type_social_group_personal_ethnicity: "Etnicidad",
  __metadata_type_social_group_personal_sex: "Sexo",
  __metadata_type_social_group_personal_biological_race: "carrera biológica",
  __metadata_extradata_violence_inclination: "Inclinación a la violencia",
  __metadata_extradata_scientific_inclination: "Inclinación científica",
  __metadata_extradata_military_inclination: "Inclinación militar",
  __metadata_extradata_religious_inclination: "Inclinación religiosa",
  __metadata_extradata_political_inclination: "Inclinación política",
  __metadata_extradata_economic_inclination: "Inclinación económica",
  __metadata_extradata_social_inclination: "Inclinación social",
  __metadata_extradata_cultural_inclination: "Inclinación cultural",
  __metadata_extradata_environmental_inclination: "Inclinación ambiental",
  __metadata_extradata_spiritual_inclination: "inclinación espiritual",
  __metadata_extradata_educational_inclination: "Inclinación educativa",
  __metadata_extradata_athletic_inclination: "Inclinación atlética",
  __metadata_extradata_artistic_inclination: "Inclinación artística",
  __metadata_extradata_musical_inclination: "Inclinación musical",
  __metadata_extradata_romantic_inclination: "Inclinación romántica",
  __metadata_extradata_familial_inclination: "Inclinación familiar",
  __metadata_extradata_friendship_inclination: "inclinación a la amistad",
  __metadata_extradata_professional_inclination: "Inclinación profesional",

  __metadata_objective_of_character_encounter: "Conocer a alguien",
  __metadata_objective_of_character_marry: "casarse con alguien",
  __metadata_objective_of_character_reach_position: "llegar al lugar",
  __metadata_objective_of_character_obtain_object: "Obtener el artículo",
  __metadata_objective_of_character_kill: "matar a alguien",
  __metadata_objective_of_character_learn: "Aprender una habilidad o concepto",
  __metadata_objective_of_character_live_event:
    "Encontrarse en un evento o situación",
};
