import { useEffect, useState } from "react";
import "./DraftIdeasHandler.scss";
import { IDraftIdea } from "../../Models/IDraftIdea";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../Redux/RootReducer";
import {
  IconButton,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  SearchBox,
} from "@fluentui/react";
import Tr from "../../Utils/Translations/Translations";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import Loadable from "../Loadable/Loadable";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import { ToastMessage } from "../../Utils/UIMessages";
import { IUser } from "../../Models/IUser";
import { IBook } from "../../Models/IBook";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import BetterPivot from "../BetterPivot/BetterPivot";
import PrettyTable from "../PrettyTable/PrettyTable";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import RoleDependantDisplayer from "../RoleDependantDisplayer/RoleDependantDisplayer";

export interface IDraftIdeasHandlerProps {}

export interface IDraftIdeaRowProps {
  draftIdea: IDraftIdea;
  onEditRequest: () => void;
  onDeleteRequest: () => void;
}

const supportedDraftIdeaStatuses = [
  "active",
  "to_review",
  "completed",
  "archived",
];

const DraftIdeasHandler = (props: IDraftIdeasHandlerProps) => {
  const dispatch = useDispatch();
  const [editIdea, setEditIdea] = useState<IDraftIdea>();
  const [editing, setEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string>(
    supportedDraftIdeaStatuses[0]
  );

  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.generic.user
  );

  const activeBook: IBook | undefined = useSelector(
    (state: GlobalState) => state.generic.activeBook
  );

  const draftIdeas: IDraftIdea[] = useSelector(
    (state: GlobalState) => state.generic.bookDraftIdeas
  );

  const loadDraftIdeas = () => {
    if (activeBook) {
      setLoading(true);
      ApiService.BookDraftIdeaController.GetDraftIdeas(
        undefined,
        activeBook?.Id,
        (response: IAPIResponse) => {
          if (response.error === null) {
            dispatch(GenericActions.SetBookDraftIdeas(response.parsed));
          }
          setLoading(false);
        }
      );
    }
  };

  const deleteDraftIdea = () => {
    setLoading(true);
    ApiService.BookDraftIdeaController.DeleteDraftIdea(
      editIdea?.Id ?? 0,
      (response: IAPIResponse) => {
        if (response.error === null) {
          loadDraftIdeas();
          ToastMessage(
            "success",
            Tr.Translate("language", "draf_idea_deleted")
          );
          DismissModal("deleteDraftIdeaModal");
        }
        setLoading(false);
      }
    );
  };

  const editDraftIdea = (data: IDraftIdea) => {
    setLoading(true);
    data = { ...editIdea, ...data };
    ApiService.BookDraftIdeaController.UpdateDraftIdea(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          loadDraftIdeas();
          ToastMessage("success", Tr.Translate("language", "draf_idea_edited"));
          DismissModal("draftIdeaEditor");
        }
        setLoading(false);
      }
    );
  };

  const addDraftIdea = (data: IDraftIdea) => {
    setLoading(true);
    data = {
      ...data,
      OwnerId: loggedUser?.Id ?? 0,
      BookId: activeBook?.Id ?? 0,
    };
    ApiService.BookDraftIdeaController.NewDraftIdea(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          loadDraftIdeas();
          ToastMessage("success", Tr.Translate("language", "draf_idea_added"));
          DismissModal("draftIdeaEditor");
        }

        setLoading(false);
      }
    );
  };

  const filterDraftIdeasOnType = (type: string) => {
    return draftIdeas.filter((x: IDraftIdea) => x.DraftIdeaStatus === type);
  };

  useEffect(() => {
    loadDraftIdeas();
  }, []);

  const draftIdeaRows = filterDraftIdeasOnType(selectedType);

  return (
    <div>
      {loggedUser && activeBook && (
        <div>
          {draftIdeas.length === 0 && (
            <div>
              <MessageBar messageBarType={MessageBarType.info}>
                {Tr.Translate("language", "no_draft_ideas_yet")}
              </MessageBar>
            </div>
          )}
          <Loadable
            isLoading={loading}
            content={
              <div>
                <BetterPivot
                  onSelectedKey={setSelectedType}
                  voices={supportedDraftIdeaStatuses.map((x: string) => {
                    return {
                      key: x,
                      text:
                        Tr.Translate("language", "draft_idea_status_" + x) +
                        ` (${filterDraftIdeasOnType(x).length})`,
                    };
                  })}
                />
                <PrettyTable
                  tdAlign="baseline"
                  plainRows={draftIdeaRows}
                  headers={[
                    "",
                    Tr.Translate("language", "draft_idea_title"),
                    Tr.Translate("language", "draft_idea_description"),
                    Tr.Translate("language", "draft_idea_status"),
                  ]}
                  extraToolbarItems={[
                    <RoleDependantDisplayer
                      whiteList={["admin", "contributor"]}
                      element={
                        <PrimaryButton
                          onClick={() => {
                            setEditing(false);
                            setEditIdea(undefined);
                            SummonModal("draftIdeaEditor");
                          }}
                          iconProps={{ iconName: "Add" }}
                          text={Tr.Translate("language", "add_draft_idea")}
                        />
                      }
                    />,
                  ]}
                  rows={draftIdeaRows
                    .filter(
                      (x: IDraftIdea) =>
                        x.DraftIdeaTitle.toLocaleLowerCase().includes(
                          searchText.toLocaleLowerCase()
                        ) ||
                        x.DraftIdeaDescription.toLocaleLowerCase().includes(
                          searchText.toLocaleLowerCase()
                        )
                    )
                    .map((x: IDraftIdea, i: number) => {
                      return [
                        <RoleDependantDisplayer
                          whiteList={["admin", "contributor"]}
                          element={
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5em",
                              }}
                            >
                              <CustomTooltip
                                iconName="Edit"
                                isButton
                                content={Tr.Translate("language", "edit")}
                                onClick={() => {
                                  setEditIdea(x);
                                  setEditing(true);
                                  SummonModal("draftIdeaEditor");
                                }}
                              />
                              <CustomTooltip
                                iconName="Trash"
                                isButton
                                content={Tr.Translate("language", "delete")}
                                onClick={() => {
                                  setEditIdea(x);
                                  setEditing(false);
                                  SummonModal("deleteDraftIdeaModal");
                                }}
                              />
                            </div>
                          }
                        />,
                        <div>{x.DraftIdeaTitle}</div>,
                        <div style={{ whiteSpace: "break-spaces" }}>
                          {x.DraftIdeaDescription}
                        </div>,
                        <div>
                          {Tr.Translate(
                            "language",
                            "draft_idea_status_" + x.DraftIdeaStatus
                          )}
                        </div>,
                      ];
                    })}
                />
              </div>
            }
          />

          <SmartModal
            modalUniqueId="draftIdeaEditor"
            title={
              editing
                ? Tr.Translate("language", "edit_draft_idea")
                : Tr.Translate("language", "add_draft_idea")
            }
            content={
              <Loadable
                isLoading={loading}
                content={
                  <div>
                    <MultiForm
                      formUniqueId="draftIdeaForm"
                      inputs={[
                        {
                          type: "text",
                          name: "DraftIdeaTitle",
                          extraParams: { maxLength: 200 },
                          required: true,
                          currentValue: editIdea?.DraftIdeaTitle,
                          label: Tr.Translate("language", "draft_idea_title"),
                        },
                        {
                          type: "multiline",
                          name: "DraftIdeaDescription",
                          extraParams: { maxLength: 5000 },
                          required: true,
                          currentValue: editIdea?.DraftIdeaDescription,
                          label: Tr.Translate(
                            "language",
                            "draft_idea_description"
                          ),
                        },
                        {
                          type: "select",
                          name: "DraftIdeaStatus",
                          required: true,
                          label: Tr.Translate("language", "draft_idea_status"),
                          currentValue: editIdea
                            ? editIdea.DraftIdeaStatus
                            : supportedDraftIdeaStatuses[0],
                          extraParams: {
                            options: supportedDraftIdeaStatuses.map(
                              (x: string) => {
                                return {
                                  key: x,
                                  text: Tr.Translate(
                                    "language",
                                    "draft_idea_status_" + x
                                  ),
                                };
                              }
                            ),
                          },
                        },
                      ]}
                      onSubmit={(data: any) => {
                        if (editing) {
                          editDraftIdea(data);
                        } else {
                          addDraftIdea(data);
                        }
                      }}
                    />
                  </div>
                }
              />
            }
            buttons={[
              {
                text: Tr.Translate("language", "cancel"),
                disabled: loading,
                onClick: () => {
                  DismissModal("draftIdeaEditor");
                },
              },
              {
                text: Tr.Translate("language", "accept"),
                disabled: loading,
                onClick: () => {
                  triggerFormValidation("draftIdeaForm");
                },
              },
            ]}
          />
          <SmartModal
            title={Tr.Translate("language", "delete_draft_idea")}
            modalUniqueId="deleteDraftIdeaModal"
            content={
              <Loadable
                isLoading={loading}
                content={
                  <div style={{ marginBottom: "1em" }}>
                    {Tr.Translate("language", "action_cannot_be_undone")}
                  </div>
                }
              />
            }
            buttons={[
              {
                text: Tr.Translate("language", "cancel"),
                disabled: loading,
                onClick: () => {
                  DismissModal("deleteDraftIdeaModal");
                },
              },
              {
                text: Tr.Translate("language", "accept"),
                disabled: loading,
                onClick: () => {
                  deleteDraftIdea();
                },
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default DraftIdeasHandler;
