export const Dictionary = {
  __new_book: "Ajoutez un livre à votre bibliothèque",
  __new_book_desc:
    "La première chose que vous voulez faire ici est de créer un livre. Vous pouvez ajouter un nouveau livre à partir d'ici.",
  __edit_workgroup: "Ajoutez des personnes à votre équipe",
  __edit_workgroup_desc:
    "Ici, vous pouvez inviter d'autres utilisateurs à accéder à ce groupe de travail. Les utilisateurs invités pourront travailler sur toutes les ressources du classeur.",
  __ai_box: "Outils d'assistant virtuel",
  __ai_box_desc:
    "Lorsque vous voyez cette icône sur un bouton, cela signifie que vous pouvez profiter de notre moteur d'assistant IA avancé pour amener la qualité de votre travail au niveau supérieur. Certaines fonctionnalités nécessiteront des abonnements spécifiques pour être activées.",
  __new_event_button: "Ajouter des événements à partir d'ici",
  __new_event_button_desc:
    "Vous pouvez utiliser ce bouton pour créer un nouvel événement. La date de l'événement par défaut sera celle que vous avez sélectionnée sur la chronologie. Si vous préférez, vous pourrez toujours le modifier ultérieurement.",
  __draft_ideas: "Prenez des mémos de vos idées",
  __draft_ideas_desc:
    "Les brouillons d'idées n'influencent pas votre travail sur le livre. Vous pouvez les considérer comme des notes papier sur les concepts que vous aimeriez élaborer à l'avenir. Les brouillons d'idées peuvent être gérés par statut et peuvent être vus par tous les membres de votre équipe.",
  __characters_meta_data: "Personnages",
  __characters_meta_data_desc:
    "Les personnages sont automatiquement ajoutés à votre livre dès que vous les utilisez dans un événement sur la chronologie. De cette manière, vous pouvez ajouter autant de personnages que vous le souhaitez. Une fois qu'un personnage est ajouté, vous pouvez ajouter autant de métadonnées que vous le souhaitez à chaque personnage. Vous pouvez également ajouter une image à chaque personnage. Ces informations aideront le compilateur d'événements à détecter les erreurs de cohérence concernant le comportement de vos personnages.",

  __new_timeline_button: "Créez une nouvelle chronologie",
  __new_timeline_button_desc:
    "Vous pouvez créer une nouvelle chronologie pour votre livre à partir d'ici. Une chronologie est un moyen d'organiser les événements de votre livre de manière visuelle. Vous pouvez ajouter des événements à une chronologie et les visualiser dans un ordre chronologique. Vous pouvez également modifier les événements directement à partir de la chronologie.",
  __new_timeline_button_more:
    "Chaque chronologie suivante que vous créerez sera considérée comme suivant la précédente. Toutes les chronologies, dans l'ordre, représentent toute la période temporelle de votre univers narratif. \n\nSupposons que votre livre soit un roman policier historique se déroulant en 1951, avec une intrigue se terminant en 1963. La chronologie dans ce cas devrait commencer à l'année 1 et se terminer au moins en 1963.\nIl peut être judicieux de laisser une marge, dans l'exemple donné la chronologie serait utile de l'année 1 à l'année 2000.",
};
