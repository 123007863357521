export const Dictionary = {
  __event_class_add_type: "Charakter zur Geschichte hinzugefügt",
  __event_class_remove_type: "Charakter aus der Geschichte entfernt",
  __event_class_affect_others_type:
    "Handlungen eines Charakters wirken sich auf einen anderen aus",
  __event_class_change_status_type: "Charakterentwicklung",
  __event_class_plot_milestone_type: "Meilenstein der Handlung",
  __event_class_strong_effects: "Extreme Destabilisierung",
  __event_type_appear: "Erscheint",
  __event_type_born: "Wurde geboren",
  __event_type_revealed: "Wurde offenbart",
  __event_type_abbandons: "Abdankungen",
  __event_type_dies: "Stirbt",
  __event_type_kills: "Tötet",
  __event_type_marries: "Heiratet",
  __event_type_allies: "Allianz",
  __event_type_engage: "Engagieren Sie sich für Romantik",
  __event_type_learns_skill: "Erlernt Geschicklichkeit",
  __event_type_learns_secret: "Lernt Geheimnis",
  __event_type_book_begins: "Die Geschichte beginnt",
  __event_type_holiday: "Urlaub",
  __event_type_book_closure: "Ende des Buches",
  __event_type_geopolitic: "Politisch",
  __event_type_natural_disaster: "Naturkatastrophe",
  __event_type_joins_faction: "Tritt der Fraktion bei",
  __event_type_leaves_faction: "Verlässt die Fraktion",
  __event_type_obtains_item: "Erhält einen Artikel",
  __event_type_shares_a_secret: "Gibt ein Geheimnis preis",
  __event_type_changes_id: "Ändert die Identität",
  __event_type_friendship: "Freundschaft",
  __event_type_friendship_end: "Ende der Freundschaft",
  __event_type_engage_end: "Ende der Liebesbeziehung",
  __event_type_betrays: "Verrät",
  __event_type_assumes_status: "Erhält Status",
  __event_type_assumes_condition: "Erwirbt eine Bedingung",
  __event_type_reaches_location: "Erreicht den Standort",
  __event_type_teams_up_with: "Zusammentun",
  __event_type_disappears: "verloren gehen",
  __event_type_leaves_location: "Verlässt den Standort",
  __event_type_meets: "Trifft",
  __event_type_challenge: "Herausforderung",
  __event_type_fight: "Kampf",
  __event_type_starts_mission: "Startet Mission/Job",
  __event_type_starts_mission_end: "Mission/Job beenden",
  __event_type_falls_in_love: "Sich verlieben",
  __event_type_situation: "Situation",
  __event_type_mass_kill: "Massenvernichtungs",
  __event_type_war: "Krieg",
  __event_type_rule_changer: "Universelle Regeländerung",
  __event_type_uses_object: "Verwendet Werkzeug/Objekt",
  __event_type_creates_or_builds: "Erstellt/baut",
};
