import { APIUrls } from "../../ApiUrls";
import { IUser, IUserRegister } from "../../Models/IUser";
import { AjaxService, IAPIRequest, IAPIResponse } from "../AjaxService";

const SessionController = {
  Login: async (
    email: string,
    password: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url =
      APIUrls.SESSION.LOGIN + "?email=" + email + "&password=" + password;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped && !response.skipped) {
      callback(response);
    }
    return response;
  },
  Logout: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.SESSION.LOGOUT;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetSysAdminMessages: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.SESSION.GET_SYSADMIN_MESSAGES;
    request.allowCache = true;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetSelfUser: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.SESSION.GET_SELF;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  InsertRegistration: async (
    user: IUserRegister,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.SESSION.INSERT_REGISTRATION;
    request.method = "POST";
    request.payload = user;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  UpdateRegistration: async (
    token: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.SESSION.UPDATE_REGISTRATION + "?token=" + token;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  UpdateUser: async (
    user: IUser,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.SESSION.UPDATE_USER;
    request.method = "POST";
    request.payload = user;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  UpdatePassword: async (
    password: string,
    token: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url =
      APIUrls.SESSION.UPDATE_PASSWORD +
      "?password=" +
      password +
      "&token=" +
      token;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  ResendInviteEmail: async (
    email: string,
    emailContent: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.SESSION.RESEND_INVITE;
    request.method = "POST";
    request.payload = {
      email,
      emailContent,
    };
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  PasswordResetEmail: async (
    email: string,
    emailContent: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.SESSION.PASSWORD_RESET_EMAIL;
    request.method = "POST";
    request.payload = {
      email,
      emailContent,
    };
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
};

export default SessionController;
