export const Dictionary = {
  // classi
  __event_class_add_type: "Personaggio aggiunto alla storia",
  __event_class_remove_type: "Personaggio rimosso dalla storia",
  __event_class_affect_others_type:
    "Le azioni di un personaggio influenzano un altro",
  __event_class_change_status_type: "Evoluzione del personaggio",
  __event_class_plot_milestone_type: "Punto cruciale di trama",
  __event_class_strong_effects: "Destabilizzazione estrema",
  // tipi
  __event_type_appear: "Appare",
  __event_type_born: "È nato",
  __event_type_revealed: "Viene rivelato",
  __event_type_abbandons: "Abbandona",
  __event_type_disappears: "Persona smarrita",
  __event_type_dies: "Muore",
  __event_type_kills: "Uccide",
  __event_type_marries: "Si sposa",
  __event_type_allies: "Alleanza",
  __event_type_engage: "Inizia storia d'amore",
  __event_type_learns_skill: "Impara abilità",
  __event_type_joins_faction: "Si unisce alla fazione",
  __event_type_leaves_faction: "Lascia la fazione",
  __event_type_learns_secret: "Apprende segreto",
  __event_type_book_begins: "Inizio della narrazione",
  __event_type_holiday: "Festività",
  __event_type_book_closure: "Fine libro",
  __event_type_geopolitic: "Politico",
  __event_type_natural_disaster: "Disastro naturale",
  __event_type_obtains_item: "Ottiene l'oggetto",
  __event_type_shares_a_secret: "Condivide un segreto",
  __event_type_changes_id: "Cambia identità",
  __event_type_friendship: "Amicizia",
  __event_type_friendship_end: "Fine dell'amicizia",
  __event_type_engage_end: "Fine della relazione romantica",
  __event_type_betrays: "Tradisce",
  __event_type_assumes_status: "Acquisisce lo status",
  __event_type_assumes_condition: "Acquisisce una condizione",
  __event_type_reaches_location: "Raggiunge la posizione",
  __event_type_teams_up_with: "Fare squadra",
  __event_type_leaves_location: "Lascia la posizione",
  __event_type_meets: "Incontra",
  __event_type_challenge: "Sfida",
  __event_type_fight: "Combatte",
  __event_type_starts_mission: "Inizia la missione/lavoro",
  __event_type_starts_mission_end: "Termina missione/lavoro",
  __event_type_falls_in_love: "Innamorarsi",
  __event_type_situation: "Situazione",
  __event_type_mass_kill: "Distruzione di massa",
  __event_type_war: "Guerra",
  __event_type_rule_changer: "Cambiamento delle regole universali",
  __event_type_uses_object: "Utilizza uno strumento/oggetto",
  __event_type_creates_or_builds: "Crea/costruisce",
};
