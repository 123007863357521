import { useEffect, useState } from "react";
import "./BetterColorPicker.scss";
import { Label } from "@fluentui/react";

export interface IBetterColorPickerProps {
  onChange?: (color: string) => void;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  onRenderLabel: (el: any) => JSX.Element;
}

const BetterColorPicker = (props: IBetterColorPickerProps) => {
  const [value, setValue] = useState<string>(props.defaultValue ?? "");

  useEffect(() => {
    if (props.onChange) {
      props.onChange(value);
    }
  }, [value]);

  return (
    <div className="color-picker-input-wrap">
      {props.label && (
        <div>
          {props.onRenderLabel(
            <Label required={props.required}>{props.label}</Label>
          )}
        </div>
      )}
      <div className="color-picker-internal">
        <input
          disabled={props.disabled}
          className="color-picker-input"
          type="color"
          onChange={(e: any) => {
            setValue(e.target.value);
          }}
          value={value}
        ></input>
        <Label>{value}</Label>
      </div>
    </div>
  );
};

export default BetterColorPicker;
