import { useEffect, useRef, useState } from "react";
import { IEvent } from "../../Models/IEvent";
import "./TimelineEventMap.scss";
import { ITimeData, ITimeDataMonth } from "../../Models/ITimeData";
import { IMonthMap, IMonthMapElement } from "../MonthMapEditor/MonthMapEditor";
import { IBook } from "../../Models/IBook";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import { ToastMessage } from "../../Utils/UIMessages";
import Tr from "../../Utils/Translations/Translations";
import Tools from "../../Utils/Tools";
import Loadable from "../Loadable/Loadable";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import {
  DropdownMenuItemType,
  IDropdownOption,
  Icon,
  IconButton,
  PrimaryButton,
  SearchBox,
  Spinner,
} from "@fluentui/react";
import {
  dropDownEventOption,
  getEventClassByType,
  getEventTypesOptions,
} from "../../Utils/EventTypeMap";
import { useDispatch, useSelector } from "react-redux";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import { GlobalState } from "../../Redux/RootReducer";
import { IUser } from "../../Models/IUser";
import { IHtmlRect } from "../../Models/ISupport";
import { mobileCheck } from "../../App";
import {
  MonthIndex,
  MonthIndexJSON,
} from "../CustomDatePicker/CustomDatePicker";
import {
  GetCustomDateAbsoluteNumericDate,
  eventProcessingListToOptions,
  loadEventReducer,
} from "../../Utils/EventProcessing";
import EventsGlobalView from "../EventsGlobalView/EventsGlobalView";
import { IBookTimeline } from "../../Models/IBookTimeline";
import ExploreEventsTickGrid from "../ExploreEventsTickGrid/ExploreEventsTickGrid";
import SettingsUtil from "../../Utils/Settings";

import SuperSpinner from "../SuperSpinner/SuperSpinner";
import { GenerateAssetUrl } from "../ImageAssetLoader/ImageAssetLoader";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import EventTickContextMenu, {
  EventTickContextMenuModal,
} from "../EventTickContextMenu/EventTickContextMenu";

export interface ITimelineEventMapProps {
  currentDeltaYear: number;
  currentCenterYear: number;
  onRequestedReload: (suggestedCenterYear: number) => void;
}
export interface ITimelineEventMapTickProps {
  year: number;
  month: string;
  day: number;
  isFirstMonthOfYear: boolean;
  isFirstDayOfMonth: boolean;
  centralYear: boolean;
  bookId: number;
  validLeapDay?: boolean;
}

export interface ITimelineEventMapTickContainerProps {
  ticks: ITimelineEventMapTickProps[];
  centerYear: number;
  monthList: string[];
}

export interface IEventDisplayDotsProps {
  events: IEvent[];
}

var pauseHandleScroll = false;

export const safeToExcecute = (jsFormula: string) => {
  if (jsFormula) {
    jsFormula = jsFormula.replaceAll(" ", "");
    jsFormula = jsFormula.replaceAll("1", "");
    jsFormula = jsFormula.replaceAll("2", "");
    jsFormula = jsFormula.replaceAll("3", "");
    jsFormula = jsFormula.replaceAll("4", "");
    jsFormula = jsFormula.replaceAll("5", "");
    jsFormula = jsFormula.replaceAll("6", "");
    jsFormula = jsFormula.replaceAll("7", "");
    jsFormula = jsFormula.replaceAll("8", "");
    jsFormula = jsFormula.replaceAll("9", "");
    jsFormula = jsFormula.replaceAll("0", "");
    jsFormula = jsFormula.replaceAll("+", "");
    jsFormula = jsFormula.replaceAll("-", "");
    jsFormula = jsFormula.replaceAll("/", "");
    jsFormula = jsFormula.replaceAll("x", "");

    return jsFormula.length === 0;
  }

  return false;
};

export const matchDateOnTarget = (
  eventCustomDate: string,
  timelineDate: string,
  navigator: string
) => {
  if (eventCustomDate && timelineDate && navigator) {
    let eventSplitDate = eventCustomDate.split("/");
    let timelineSplitDate = timelineDate.split("/");

    if (navigator === "day") {
      return eventCustomDate === timelineDate;
    }
    if (navigator === "month") {
      return (
        eventSplitDate[0] === timelineSplitDate[0] &&
        eventSplitDate[1] === timelineSplitDate[1]
      );
    }
    if (navigator === "year") {
      return eventSplitDate[0] === timelineSplitDate[0];
    }
  }

  return false;
};

const tickCanDisplay = (
  tick: ITimelineEventMapTickProps,
  navigatorMode: string
) => {
  let validNavigatorForDay = ["day"];
  let validNavigatorForMonth = ["month", "day"];
  let validNavigatorForYear = ["year", "month", "day"];

  let validForYear = tick.isFirstDayOfMonth && tick.isFirstMonthOfYear;
  let validForMonth = tick.isFirstDayOfMonth;
  let validForDay = true;

  if (validForDay && validNavigatorForDay.indexOf(navigatorMode) !== -1) {
    return true;
  }
  if (validForMonth && validNavigatorForMonth.indexOf(navigatorMode) !== -1) {
    return true;
  }
  if (validForYear && validNavigatorForYear.indexOf(navigatorMode) !== -1) {
    return true;
  }

  return false;
};

const EventDots = (props: { eventTypes: string[] }) => {
  let currentPos: number = props.eventTypes.length;
  let currentType: string | null =
    props.eventTypes.length > 0 ? props.eventTypes[0] : null;
  let futureTypes: string[] = props.eventTypes.slice(1);

  // class of event
  let classOfType = currentType ? getEventClassByType(currentType) : "";

  // color for class of event
  let circleColor = classOfType
    ? SettingsUtil.GetSettings(classOfType)
    : "#000000";

  // graphic tweak, add shades
  let opacity = currentPos % 2 === 0 ? "FF" : "44";
  //circleColor += opacity;

  return (
    <div
      className="default-event-dot-outer"
      //style={{ borderColor: circleColor + "44" }}
    >
      <div className="default-event-dot" style={{ borderColor: circleColor }}>
        {futureTypes.length > 0 && <EventDots eventTypes={futureTypes} />}
      </div>
    </div>
  );
};

const EventDisplayDots = (props: IEventDisplayDotsProps) => {
  const limit = SettingsUtil.GetSettings("limit_dots_on_timeline");

  return (
    <div className="event-display-dots-wrap-outer">
      <div className="event-display-dots-wrap">
        {props.events.slice(0, limit).map((x: IEvent, i: number) => {
          let eventTypes: string[] = x.EventType.split(", ");
          return (
            <div className="event-display-dots-event-single" key={i}>
              <EventDots key={i} eventTypes={eventTypes} />
            </div>
          );
        })}
        {props.events.length > limit && (
          <div
            title={"+ " + (props.events.length - limit).toString()}
            className="event-display-dots-wrap-hidden-events-count"
          >
            + {props.events.length - limit}
          </div>
        )}
      </div>
    </div>
  );
};

const TimelineEventMapTick = (props: ITimelineEventMapTickProps) => {
  const dispatch = useDispatch();
  const [meta] = useState<ITimelineEventMapTickProps>(props);
  const [showContextButton, setShowContextButton] = useState<boolean>(false);

  const globalEvents: IEvent[] = useSelector(
    (state: GlobalState) => state.generic.globalevents
  );
  const navigator: string = useSelector(
    (state: GlobalState) => state.generic.timelineNavigator
  );
  const activeDate: string = useSelector(
    (state: GlobalState) => state.generic.exploreEventsDate
  );
  const timeline: IBookTimeline | undefined = useSelector(
    (state: GlobalState) => state.generic.activeTimeline
  );

  const activeEvents = globalEvents.filter(
    (x: IEvent) => x.TimelineId === timeline?.Id
  );

  const processCoreElements = () => {
    let processedElements: any = {};
    if (meta) {
      processedElements.signatureDate =
        meta.year + "/" + meta.month + "/" + meta.day;
      processedElements.tickClassName = "event-day-tick";
      processedElements.tickLabelElementDay = (
        <div className="event-day-tick-label">{meta.day}</div>
      );
      processedElements.tickLabelElementMonth = (
        <div className="event-month-tick-label">{meta.month}</div>
      );

      processedElements.tickLabelElementYear = (
        <div className="event-year-tick-label">{meta.year}</div>
      );

      if (meta.isFirstDayOfMonth) {
        processedElements.tickClassName = "event-month-tick";
      }
      if (meta.isFirstMonthOfYear && meta.isFirstDayOfMonth) {
        processedElements.tickClassName = "event-year-tick";
      }
      if (
        meta.isFirstMonthOfYear &&
        meta.isFirstDayOfMonth &&
        meta.centralYear
      ) {
        processedElements.tickClassName = "event-central-year-tick";
      }

      processedElements.eventsOnThisTick = activeEvents.filter((x: IEvent) =>
        matchDateOnTarget(
          x.CustomDate,
          processedElements.signatureDate,
          navigator
        )
      );
    }

    return processedElements;
  };

  const closeTickMoreOptions = () => {
    setShowContextButton(false);
  };

  let coreElements = processCoreElements();

  return (
    <div
      className={
        "tick-event-main-wrap-selector " +
        (props.validLeapDay === false ? "invalid-leap-day" : "")
      }
      id={coreElements.signatureDate}
    >
      {meta && (
        <div
          className={
            "tick-event-main-wrap " +
            (activeDate === coreElements.signatureDate
              ? "tick-event-main-wrap-active"
              : "")
          }
          onMouseEnter={() => {
            setShowContextButton(true);
          }}
          onMouseLeave={() => {
            setShowContextButton(false);
          }}
          onClick={() => {
            if (props.validLeapDay !== false) {
              dispatch(
                GenericActions.setExploreEventsDate(coreElements.signatureDate)
              );
            }
          }}
        >
          {showContextButton && (
            <div style={{ position: "relative" }}>
              <div className="event-display-dots-context-positioner">
                <EventTickContextMenu
                  onDismiss={closeTickMoreOptions}
                  targetEvents={coreElements.eventsOnThisTick}
                />
              </div>
            </div>
          )}
          <div className={"event-dot-column-corrector-" + navigator}>
            <EventDisplayDots events={coreElements.eventsOnThisTick} />
          </div>
          <div className={coreElements.tickClassName}>
            {navigator === "day" && coreElements.tickLabelElementDay}
            {navigator === "day" && meta.isFirstDayOfMonth && (
              <div>{coreElements.tickLabelElementMonth}</div>
            )}
            {navigator === "day" &&
              meta.isFirstDayOfMonth &&
              meta.isFirstMonthOfYear && (
                <div style={{ padding: "1em 0.2em" }}>
                  {coreElements.tickLabelElementYear}
                </div>
              )}

            {navigator === "month" && meta.isFirstDayOfMonth && (
              <div> {coreElements.tickLabelElementMonth}</div>
            )}
            {navigator === "month" &&
              meta.isFirstDayOfMonth &&
              meta.isFirstMonthOfYear && (
                <div className="year-label-month-view">
                  {coreElements.tickLabelElementYear}
                </div>
              )}

            {navigator === "year" &&
              meta.isFirstDayOfMonth &&
              meta.isFirstMonthOfYear && (
                <div className="year-label-year-view">
                  {coreElements.tickLabelElementYear}
                </div>
              )}

            {navigator === "day" &&
              meta.isFirstDayOfMonth &&
              meta.isFirstMonthOfYear &&
              meta.centralYear && (
                <div className="center-year-label">
                  {Tr.Translate("language", "book_start")}
                </div>
              )}
            {navigator === "month" &&
              meta.isFirstDayOfMonth &&
              meta.isFirstMonthOfYear &&
              meta.centralYear && (
                <div className="year-label-month-view">
                  <div className="center-year-label-month-view">
                    {Tr.Translate("language", "book_start")}
                  </div>
                </div>
              )}
            {navigator === "year" &&
              meta.isFirstDayOfMonth &&
              meta.isFirstMonthOfYear &&
              meta.centralYear && (
                <div className="center-label-year-view">
                  <div className="center-year-label">
                    {Tr.Translate("language", "book_start")}
                  </div>
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
};

const TimelineEventMapTickContainer = (
  props: ITimelineEventMapTickContainerProps
) => {
  const ref = useRef<any>();
  const navigator: string = useSelector(
    (state: GlobalState) => state.generic.timelineNavigator
  );
  const computeVisibilityForCenterYear = () => {
    let year = props.ticks.length > 0 ? props.ticks[0].year : 0;
    return year === props.centerYear;
  };

  const [mainWidth, setMainWidth] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(
    computeVisibilityForCenterYear()
  );

  useEffect(() => {
    if (computeVisibilityForCenterYear()) {
      setTimeout(() => {
        if (ref && ref.current) {
          let rectYearContainer: IHtmlRect =
            ref.current.getBoundingClientRect();
          document.dispatchEvent(
            new CustomEvent("set-global-year-container-width", {
              detail: { width: rectYearContainer.width - 2 },
            })
          );
          setTimeout(() => {
            document.dispatchEvent(
              new CustomEvent("timeline-ready-to-display", {
                detail: {
                  targetYear: props.ticks[0].year,
                },
              })
            );
          }, 500);
        }
      }, 500);
    }
  }, []);

  const handleUpdateMainWidth = (e: any) => {
    let width = e.detail.width;
    setMainWidth(width);
  };

  const handleScroll = () => {
    let timeline: any = document.getElementById("event-map-timeline");
    if (ref && ref.current && timeline) {
      let rectTimelineDiv: IHtmlRect = timeline.getBoundingClientRect();
      let rectYearContainer: IHtmlRect = ref.current.getBoundingClientRect();

      const extraSpan = 200; // px

      let rightCornerIsIn =
        rectTimelineDiv.left - extraSpan < rectYearContainer.right &&
        rectYearContainer.right < rectTimelineDiv.right + extraSpan;
      let leftCornerIsIn =
        rectTimelineDiv.left - extraSpan < rectYearContainer.left &&
        rectYearContainer.left < rectTimelineDiv.right + extraSpan;
      let yearContainerIncludesScreen =
        rectYearContainer.left < rectTimelineDiv.left &&
        rectYearContainer.right > rectTimelineDiv.right;

      let visibility =
        rightCornerIsIn || leftCornerIsIn || yearContainerIncludesScreen;

      setVisible(visibility);
    }
  };

  useEffect(() => {
    document.addEventListener(
      "set-global-year-container-width",
      handleUpdateMainWidth
    );
    document.addEventListener("handle-tick-scroll", handleScroll);
    return () => {
      document.removeEventListener(
        "set-global-year-container-width",
        handleUpdateMainWidth
      );
      document.removeEventListener("handle-tick-scroll", handleScroll);
    };
  }, []);

  let aggregatedTicksBySeason: any = {
    season1: [],
    season2: [],
    season3: [],
    season4: [],
  };

  for (let i = 0; i < props.ticks.length; i++) {
    let season = Math.floor(
      (4 * props.monthList.indexOf(props.ticks[i].month)) /
        props.monthList.length
    );
    aggregatedTicksBySeason["season" + (season + 1).toString()].push(
      props.ticks[i]
    );
  }

  const getSeasonClass = (index: number, navigation: string) => {
    if (navigation === "year") {
      return "";
    }
    return "season-class-" + (index + 1);
  };

  return (
    <div
      className="event-year-map-container-visibility-wrap"
      ref={ref}
      id={"year_" + props.ticks[0].year}
      style={mainWidth > 0 ? { minWidth: mainWidth + "px" } : {}}
    >
      {visible && (
        <div className="event-year-map-container">
          {Object.keys(aggregatedTicksBySeason).map(
            (season: string, j: number) => {
              let ticks: ITimelineEventMapTickProps[] =
                aggregatedTicksBySeason[season];
              return (
                <div
                  key={j}
                  className={
                    "event-year-map-container " + getSeasonClass(j, navigator)
                  }
                >
                  {ticks.map((x: ITimelineEventMapTickProps, i: number) => {
                    if (tickCanDisplay(x, navigator)) {
                      return <TimelineEventMapTick key={i} {...x} />;
                    } else {
                      return <div key={i}></div>;
                    }
                  })}
                </div>
              );
            }
          )}
        </div>
      )}
    </div>
  );
};

var lastEventRequested = false;
const TimelineEventMap = (props: ITimelineEventMapProps) => {
  const dispatch = useDispatch();
  const [ticks, setTicks] = useState<ITimelineEventMapTickContainerProps[]>([]);
  const [monthMap, setMonthMap] = useState<IMonthMap>();
  const [loadingEvents, setLoadingEvents] = useState<boolean>(false);
  const [timelineOpacity, setTimelineOpacity] = useState<string>("0");
  const [skipDaysValue, setSkipDaysValue] = useState<number>(0);
  const [processing, setProcessing] = useState<boolean>(false);

  const globalEvents: IEvent[] = useSelector(
    (state: GlobalState) => state.generic.globalevents
  );
  const navigator: string = useSelector(
    (state: GlobalState) => state.generic.timelineNavigator
  );
  const activeBook: IBook | undefined = useSelector(
    (state: GlobalState) => state.generic.activeBook
  );
  const activeTimeline: IBookTimeline | undefined = useSelector(
    (state: GlobalState) => state.generic.activeTimeline
  );
  const eventsDate: string = useSelector(
    (state: GlobalState) => state.generic.exploreEventsDate
  );

  const centerMapTimeline = (e: any) => {
    setTimeout(() => {
      const activeYear: string = e.detail.targetYear;
      const timelineDiv: any = document.getElementById("event-map-timeline");
      const element: any = document.getElementById("year_" + activeYear);
      if (element && timelineDiv) {
        let rect = element.getBoundingClientRect();
        timelineDiv.scrollLeft = 0;
        let positionOfTargetYear = rect.left;

        setTimeout(() => {
          timelineDiv.scrollLeft = positionOfTargetYear - 250;
          document.dispatchEvent(new Event("handle-tick-scroll"));
          setTimeout(() => {
            setTimelineOpacity("1");
          }, 500);
        }, 500);
      }
    }, 500);
  };

  useEffect(() => {
    if (timelineOpacity === "1" && lastEventRequested) {
      lastEventRequested = false;
      gotoLastEvent();
    }
  }, [timelineOpacity]);

  const gotoLastEvent = () => {
    const activeEvents = globalEvents.filter(
      (x: IEvent) => x.TimelineId === activeTimeline?.Id
    );

    if (activeEvents.length > 0) {
      let capDateEvent: IEvent | undefined =
        activeEvents[activeEvents.length - 1];
      if (capDateEvent && monthMap) {
        if (
          capDateEvent.CustomDate.split("/")[0] === eventsDate.split("/")[0]
        ) {
          let coreDiff = 0;

          if (navigator === "year") {
            let currentDate = +eventsDate.split("/")[0];
            let cEventDate = +capDateEvent.CustomDate.split("/")[0];
            coreDiff = cEventDate - currentDate;
          }

          if (navigator === "month") {
            let currentDateY = +eventsDate.split("/")[0];
            let cEventDateY = +capDateEvent.CustomDate.split("/")[0];

            let currentDateM = MonthIndex(monthMap, eventsDate.split("/")[1]);
            let cEventDateM = MonthIndex(
              monthMap,
              capDateEvent.CustomDate.split("/")[1]
            );

            coreDiff =
              cEventDateM -
              currentDateM +
              (cEventDateY - currentDateY) * monthMap.Months.length;
          }

          if (navigator === "day") {
            let currentDate = GetCustomDateAbsoluteNumericDate(
              eventsDate,
              monthMap,
              activeTimeline?.Id ?? 0
            );
            let cEventDate = GetCustomDateAbsoluteNumericDate(
              capDateEvent.CustomDate,
              monthMap,
              activeTimeline?.Id ?? 0
            );
            coreDiff = cEventDate - currentDate;
          }

          setSkipDaysValue(coreDiff);
        } else {
          lastEventRequested = true;

          let startTargetYear = [
            capDateEvent.CustomDate.split("/")[0],
            monthMap.Months[0].MonthName,
            1,
          ];
          dispatch(
            GenericActions.setExploreEventsDate(startTargetYear.join("/"))
          );

          props.onRequestedReload(+capDateEvent.CustomDate.split("/")[0]);
        }
      }
    }
  };

  const reloadMap = (data: any) => {
    setLoadingEvents(true);
    setTimeout(() => {
      setTicks([]);
      setTimeout(() => {
        initMap(data.navigatorCenter, data.navigatorDelta, data.navigatorMode);
        setLoadingEvents(false);
      }, 500);
    }, 500);
  };

  useEffect(() => {
    document.addEventListener("timeline-ready-to-display", centerMapTimeline);

    return () => {
      document.removeEventListener(
        "timeline-ready-to-display",
        centerMapTimeline
      );
    };
  }, []);

  useEffect(() => {
    setTimelineOpacity("0");
    if (activeBook) {
      setMonthMap(JSON.parse(activeBook?.MonthMap));
    }
  }, [activeBook]);

  useEffect(() => {
    if (monthMap) {
      initMap(props.currentCenterYear, props.currentDeltaYear, navigator);
    }
  }, [monthMap]);

  const initMap = (center: number, delta: number, navigator: string) => {
    if (activeBook && monthMap) {
      setTimelineOpacity("0");
      let centerYear = +center;
      let deltaYears = +delta;
      dispatch(GenericActions.setTimelineNavigator(navigator));

      dispatch(
        GenericActions.setExploreEventsDate(
          centerYear + "/" + monthMap.Months[0].MonthName + "/1"
        )
      );

      let minYear = centerYear - deltaYears;
      let maxYear = centerYear + deltaYears;

      if (minYear < 1) {
        minYear = 1;
      }
      if (maxYear > (activeTimeline?.TimelineYearSpan ?? 1)) {
        maxYear = activeTimeline?.TimelineYearSpan ?? 1;
      }

      const tickYears = [];
      for (let i = minYear; i <= maxYear; i++) {
        tickYears.push(i);
      }

      let processedContainerTicks: ITimelineEventMapTickContainerProps[] = [];
      let monthList: string[] = monthMap.Months.map((x: IMonthMapElement) => {
        return x.MonthName;
      });

      tickYears.forEach((year: number, year_cursor: number) => {
        let processedTicks: ITimelineEventMapTickProps[] = [];
        monthMap.Months.forEach(
          (month: IMonthMapElement, month_cursor: number) => {
            let daysArray = [];

            // compute days of month
            let limit = +month.MonthMaxDays;
            for (let dtime = 1; dtime <= limit; dtime++) {
              daysArray.push(dtime);
            }

            // create tick for each day of month
            daysArray.forEach((day: number, day_cursor: number) => {
              processedTicks.push({
                year: year,
                month: month.MonthName,
                day: day,
                isFirstMonthOfYear: month_cursor === 0,
                isFirstDayOfMonth: day_cursor === 0,
                centralYear: year === (activeTimeline?.CenterYear ?? 0),
                bookId: activeBook.Id ?? 0,
              });
            });

            // this month supports leap year days
            if (month.LeapYearEveryTotYears != 0) {
              // add leap day tick
              processedTicks.push({
                year: year,
                month: month.MonthName,
                day: +month.MonthMaxDays + 1,
                isFirstMonthOfYear: month_cursor === 0,
                isFirstDayOfMonth: false,
                centralYear: year === (activeTimeline?.CenterYear ?? 0),
                validLeapDay: year % month.LeapYearEveryTotYears === 0,
                bookId: activeBook.Id ?? 0,
              });
            }
          }
        );
        processedContainerTicks.push({
          ticks: processedTicks,
          centerYear: centerYear,
          monthList: monthList,
        });
      });

      setTicks(processedContainerTicks);
    }
  };

  useEffect(() => {
    if (!processing && skipDaysValue !== 0) {
      setProcessing(true);
      performSkipDays(skipDaysValue);
    }
  }, [skipDaysValue]);

  const performSkipDays = async (daysToSkip: number) => {
    let lastDateVisited = eventsDate;
    while (daysToSkip !== 0) {
      let partialResult: any = performSkipDaysLoopInner(
        daysToSkip,
        lastDateVisited
      );

      if (partialResult[1] !== null) {
        dispatch(GenericActions.setExploreEventsDate(partialResult[1]));
      }
      lastDateVisited = partialResult[1];
      daysToSkip = partialResult[0];
      setSkipDaysValue(daysToSkip);

      let doPause = false;
      let absRemaining = Math.abs(daysToSkip);
      if (absRemaining > 100 && daysToSkip % 10 === 0) {
        doPause = true;
      }
      if (absRemaining > 100 && daysToSkip % 10 === 0) {
        doPause = true;
      }
      if (absRemaining < 100 && daysToSkip % 5 === 0) {
        doPause = true;
      }
      if (doPause || Math.abs(daysToSkip) < 20) {
        await new Promise((r) => setTimeout(r, 50));
      }
    }
    setProcessing(false);
  };

  const performSkipDaysLoopInner = (
    daysToSkip: number,
    currentDate: string
  ) => {
    let newVal = 0;
    let scrollDiv = document.getElementById("event-map-timeline");
    let countPopup = document.getElementById("count-skip-element");
    let availableTicks = Array.from(
      document.getElementsByClassName("tick-event-main-wrap-selector")
    ).filter((x: any) => !x.className.includes("invalid-leap-day"));

    let sampleTick = (availableTicks[0] as any).firstChild;

    if (availableTicks.length > 0 && sampleTick && countPopup) {
      let sampleTickSize = sampleTick.getBoundingClientRect().width;

      let currentIndex = -1;
      let nextDate = "";
      for (let i = 0; i < availableTicks.length; i++) {
        if (availableTicks[i].id === currentDate) {
          currentIndex = i;
          break;
        }
      }

      if (daysToSkip < 0 && currentIndex - 1 >= 0) {
        newVal = daysToSkip + 1;
        nextDate = availableTicks[currentIndex - 1].id;
      }
      if (daysToSkip > 0 && currentIndex + 1 < availableTicks.length - 1) {
        newVal = daysToSkip - 1;
        nextDate = availableTicks[currentIndex + 1].id;
      }

      if (currentIndex !== -1) {
        let onCurrent = availableTicks[currentIndex].getBoundingClientRect();
        let onPopup = (countPopup as any).getBoundingClientRect();

        // reduce speed
        if (onCurrent.left < onPopup.left && daysToSkip > 0) {
          sampleTickSize *= 0.8;
        }
        // accelerate speed
        if (onCurrent.right > onPopup.right && daysToSkip < 0) {
          sampleTickSize *= 0.8;
        }

        if (daysToSkip > 0) {
          (scrollDiv as any).scrollLeft += sampleTickSize;
        }
        if (daysToSkip < 0) {
          (scrollDiv as any).scrollLeft -= sampleTickSize;
        }

        return [newVal, nextDate];
      } else {
        return [0, null];
      }
    } else {
      return [0, null];
    }
  };

  return (
    <div>
      {skipDaysValue !== 0 && (
        <div className="skip-days-popup-outer">
          <div className="skip-days-popup" id="count-skip-element">
            <div className="skip-days-popup-alt">
              <PrimaryButton
                iconProps={{ iconName: "Cancel" }}
                onClick={() => {
                  setSkipDaysValue(0);
                }}
              />
            </div>
            {skipDaysValue > 0 && <Icon iconName="Add"></Icon>}
            {skipDaysValue < 0 && <Icon iconName="Remove"></Icon>}
            {Math.abs(skipDaysValue)}
          </div>
        </div>
      )}

      <Loadable
        content={
          <div>
            <div>
              {timelineOpacity === "0" && (
                <div className="spinner-centerer">
                  <div className="spinner-centerer-inner">
                    <SuperSpinner />
                  </div>
                </div>
              )}
              <div
                className="event-year-map"
                id="event-map-timeline"
                style={{
                  opacity: timelineOpacity,
                  backgroundImage: GenerateAssetUrl(
                    "/Assets/Public/wide-4.jpg",
                    true
                  ),
                }}
                onScroll={() => {
                  if (pauseHandleScroll) {
                    return;
                  }
                  pauseHandleScroll = true;

                  setTimeout(() => {
                    document.dispatchEvent(new Event("handle-tick-scroll"));
                    pauseHandleScroll = false;
                  }, 500);
                }}
              >
                <div className="event-year-map-inner">
                  {ticks.map(
                    (x: ITimelineEventMapTickContainerProps, i: number) => {
                      return <TimelineEventMapTickContainer key={i} {...x} />;
                    }
                  )}
                </div>
              </div>
            </div>
            {eventsDate && timelineOpacity !== "0" && skipDaysValue === 0 && (
              <div className="fade-in-item">
                <div className="event-year-map-controls-wrap">
                  <div
                    className="event-year-map-controls-image"
                    style={{
                      backgroundImage: GenerateAssetUrl(
                        "/Assets/Public/neon-2.png",
                        true
                      ),
                    }}
                  ></div>
                  <div
                    className="event-year-map-controls"
                    style={{ opacity: timelineOpacity }}
                  >
                    {skipDaysValue === 0 && (
                      <div className="left-lower-events-toolbar">
                        <MultiForm
                          formUniqueId="timelineShorcutCommands"
                          onSubmit={(data: any) => {
                            if (data.skipDays && skipDaysValue === 0) {
                              let skipDays = data.skipDays;

                              try {
                                if (
                                  safeToExcecute(skipDays.replaceAll(" ", ""))
                                ) {
                                  let total = eval(
                                    skipDays.replaceAll("x", "*")
                                  );

                                  if (total.toString() === "Infinity") {
                                    ToastMessage(
                                      "warning",
                                      Tr.Translate(
                                        "language",
                                        "this_is_infinite"
                                      )
                                    );
                                  } else {
                                    if (
                                      navigator === "year" &&
                                      Math.abs(total) > props.currentDeltaYear
                                    ) {
                                      let newTarget =
                                        props.currentCenterYear + total;
                                      if (newTarget < 1) {
                                        newTarget = 1;
                                      }
                                      reloadMap({
                                        navigatorCenter: newTarget,
                                        navigatorDelta: props.currentDeltaYear,
                                        navigatorMode: navigator,
                                      });
                                    } else {
                                      setSkipDaysValue(+total);
                                    }
                                  }
                                } else {
                                  ToastMessage(
                                    "warning",
                                    Tr.Translate("language", "bad_input")
                                  );
                                }
                              } catch (e) {
                                ToastMessage(
                                  "warning",
                                  Tr.Translate("language", "bad_input")
                                );
                              }
                            }
                          }}
                          inputs={[
                            {
                              width: 100,
                              type: "text",
                              name: "skipDays",
                              placeholder: Tr.Translate(
                                "language",
                                "jump_timeline_ticks"
                              ),
                              inputTooltip: Tr.Translate(
                                "language",
                                "jump_timeline_ticks_hint"
                              ),
                            },
                          ]}
                        />
                      </div>
                    )}
                    <div className="controls-buttons-on-right">
                      <PrimaryButton
                        text={Tr.Translate("language", "goto_last_event")}
                        iconProps={{ iconName: "Go" }}
                        onClick={() => {
                          gotoLastEvent();
                        }}
                      />
                      <PrimaryButton
                        text={Tr.Translate("language", "search_events")}
                        iconProps={{ iconName: "Search" }}
                        onClick={() => {
                          SummonModal("searchAdvEvents");
                        }}
                      />
                    </div>
                  </div>
                </div>
                {!processing && timelineOpacity === "1" && (
                  <div>
                    <ExploreEventsTickGrid />
                  </div>
                )}
              </div>
            )}
          </div>
        }
        isLoading={loadingEvents}
      />
      <EventTickContextMenuModal />
      <SmartModal
        modalUniqueId="searchAdvEvents"
        title={Tr.Translate("language", "search_events")}
        content={<EventsGlobalView />}
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            onClick: () => {
              DismissModal("searchAdvEvents");
            },
          },
        ]}
      />
    </div>
  );
};

export default TimelineEventMap;
