import { useEffect, useRef, useState } from "react";
import "./BetterCoachMark.scss";
import { IHtmlRect } from "../../Models/ISupport";
import Tools from "../../Utils/Tools";
import { DefaultButton, IconButton, PrimaryButton } from "@fluentui/react";
import Tr from "../../Utils/Translations/Translations";
import { useDispatch, useSelector } from "react-redux";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import { GlobalState } from "../../Redux/RootReducer";
import { mobileCheck } from "../../App";
import CustomTooltip from "../CustomTooltip/CustomTooltip";

export interface IBetterCoachMarksProps {
  element: JSX.Element;
  coachMarkId: string;
  coachMarckTitle: string;
  coachMarckContent: string | string[];
  suggestedPosition?: "right" | "left" | "bottom" | "top";
}

var emptyRect: IHtmlRect = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

const coachMarkGetIsRemoved = (coachMarkId: string) => {
  return localStorage.getItem("coachmark-removed-" + coachMarkId) !== null;
};

const coachMarkSetAsRemoved = (coachMarkId: string) => {
  localStorage.setItem("coachmark-removed-" + coachMarkId, "true");
};

var cachedPopupExtraStyles: any = null;

export const resetAllCoachMarkers = () => {
  let storagetKeys: string[] = Object.keys(localStorage);
  for (let i = 0; i < storagetKeys.length; i++) {
    if (storagetKeys[i].startsWith("coachmark-removed-")) {
      localStorage.removeItem(storagetKeys[i]);
    }
  }
};

const BetterCoachMarks = (props: IBetterCoachMarksProps) => {
  const dispatch = useDispatch();
  const ref = useRef<any>();
  const popupRef = useRef<any>();

  const positionCorrectorSpan: number = 12;
  const [position, setPosition] = useState<"right" | "left" | "bottom" | "top">(
    "right"
  );
  const [targetRect, setTargetRect] = useState<IHtmlRect>(emptyRect);
  const [visible, setVisible] = useState<boolean>(true);
  const [utilRenderDisable, setUtilRenderDisable] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);

  const [activeContent, setActiveContent] = useState<string>("");
  const [enablePaginator, setEnablePaginator] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    if (
      enablePaginator &&
      Array.isArray(props.coachMarckContent) &&
      currentPage < props.coachMarckContent.length
    ) {
      setActiveContent(props.coachMarckContent[currentPage]);
    }
  }, [currentPage]);

  const [removed, setRemoved] = useState<boolean>(
    coachMarkGetIsRemoved(props.coachMarkId)
  );

  const removeInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    setIntervalId(null);
  };

  const openCoachMark: string = useSelector(
    (state: GlobalState) => state.generic.openCoachMark
  );
  const canDisplay =
    openCoachMark === "" || openCoachMark === props.coachMarkId;

  const temporaryDisable = () => {
    setUtilRenderDisable(true);
    setTimeout(() => {
      setUtilRenderDisable(false);
    }, 1000);
  };

  useEffect(() => {
    setCurrentPage(0);
    if (open) {
      if (intervalId === null) {
        setIntervalId(
          setInterval(() => {
            if (ref && ref.current && !removed) {
              setTargetRect(ref.current.getBoundingClientRect());
            }
          }, 500)
        );
      }
    } else {
      removeInterval();
    }
  }, [open]);

  useEffect(() => {
    if (Array.isArray(props.coachMarckContent)) {
      setActiveContent(props.coachMarckContent[0]);
      setEnablePaginator(true);
    } else {
      setActiveContent(props.coachMarckContent);
    }

    setTimeout(() => {
      setVisible(true);
    }, 1000);

    window.addEventListener("resize", temporaryDisable);
    return () => {
      window.removeEventListener("resize", temporaryDisable);
      removeInterval();
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      setTargetRect(ref.current.getBoundingClientRect());
    }
  }, [ref]);

  useEffect(() => {
    if (targetRect.x !== 0 || targetRect.y !== 0) {
      computePosition();
    }
  }, [targetRect]);

  const computePosition = () => {
    let halfScreenHeight = window.innerHeight / 2;
    let halfScreenWidth = window.innerWidth / 2;

    let objectTop = targetRect.top;
    let objectLeft = targetRect.left;

    let defaultPosition: "right" | "left" | "bottom" | "top" = "right";

    // can stay on horiz line
    if (targetRect.width < window.innerWidth * 0.8 && !mobileCheck()) {
      if (objectLeft > halfScreenWidth) {
        defaultPosition = "left";
      }
    } else {
      // must go on vertical, get spaced direction
      if (objectTop > halfScreenHeight) {
        defaultPosition = "top";
      } else {
        defaultPosition = "bottom";
      }
    }

    setPosition(props.suggestedPosition ?? defaultPosition);
  };

  const getCoachMarkPosition = () => {
    let positionStyle: any = { top: 0, left: 0 };

    if (position === "top") {
      positionStyle = {
        top: -3 * positionCorrectorSpan,
        left: +targetRect.width / 2 - positionCorrectorSpan,
      };
    }

    if (position === "bottom") {
      positionStyle = {
        top: +targetRect.height + positionCorrectorSpan,
        left: +targetRect.width / 2 - positionCorrectorSpan,
      };
    }

    if (position === "left") {
      positionStyle = {
        top: +targetRect.height / 2 - positionCorrectorSpan,
        left: -2 * positionCorrectorSpan,
      };
    }

    if (position === "right") {
      positionStyle = {
        top: +targetRect.height / 2 - positionCorrectorSpan,
        left: +targetRect.width + positionCorrectorSpan,
      };
    }

    if (open) {
      positionStyle.top += targetRect.top + window.scrollY;
      positionStyle.left += targetRect.left + window.scrollX;
      positionStyle.position = "fixed";
    }

    return positionStyle;
  };

  const popupPositionFixer = () => {
    if (cachedPopupExtraStyles) {
      return cachedPopupExtraStyles;
    }

    let targetStyle: any = null;
    if (position === "top" && mobileCheck()) {
      targetStyle = {
        bottom: 0,
      };
    }

    if ((position === "left" || position === "right") && popupRef.current) {
      let popupRect = popupRef.current.getBoundingClientRect();
      let isOffScreen = popupRect.y + popupRect.height > window.innerHeight;
      if (isOffScreen) {
        targetStyle = {
          marginTop: -popupRect.height / 2,
        };
      }
    }

    if (targetStyle) {
      cachedPopupExtraStyles = targetStyle;
    }
    return {};
  };

  const canSeeMore =
    enablePaginator && currentPage < props.coachMarckContent.length - 1;

  return (
    <div style={{ position: "relative" }}>
      <div ref={ref}>{props.element}</div>
      {canDisplay && !removed && !utilRenderDisable && (
        <div className="fader-element" style={{ opacity: visible ? "1" : "0" }}>
          <div
            onMouseEnter={() => {
              if (props.coachMarkId !== openCoachMark) {
                cachedPopupExtraStyles = null;
              }
              dispatch(GenericActions.SetOpenCoachMark(props.coachMarkId));
              setTargetRect(ref.current.getBoundingClientRect());
              setTimeout(() => {
                setOpen(true);
              }, 200);
            }}
            className="coach-mark-main-positioner"
            style={getCoachMarkPosition()}
          >
            <div className="coach-mark-marker">
              {visible && position === "top" && (
                <div className="coach-mark-pointer-bottom"></div>
              )}
              {visible && position === "bottom" && (
                <div className="coach-mark-pointer-top"></div>
              )}
              {visible && position === "right" && (
                <div className="coach-mark-pointer-left"></div>
              )}
              {visible && position === "left" && (
                <div className="coach-mark-pointer-right"></div>
              )}
              <div
                style={popupPositionFixer()}
                ref={popupRef}
                className={
                  "coach-mark-mild-popup coach-mark-mild-popup-positioner-" +
                  position +
                  (open ? " coach-mark-mild-popup-open " : " ") +
                  (mobileCheck() ? "mobile-popup-positioner" : "")
                }
              >
                <div>
                  <div className="coach-mark-mild-popup-title">
                    <div>{props.coachMarckTitle}</div>
                    <CustomTooltip
                      onClick={() => {
                        dispatch(GenericActions.SetOpenCoachMark(""));
                        setOpen(false);
                      }}
                      isButton
                      content={Tr.Translate("language", "cancel")}
                      iconName="Cancel"
                    />
                  </div>
                  <div className="coach-mark-mild-popup-content">
                    {activeContent.split("\n").map((x: string, i: number) => {
                      return (
                        <div key={i}>
                          <br />
                          {x}
                        </div>
                      );
                    })}
                  </div>
                  <div className="coach-mark-mild-popup-buttons">
                    {canSeeMore && (
                      <DefaultButton
                        onClick={() => {
                          setCurrentPage(currentPage + 1);
                        }}
                        text={Tr.Translate("language", "tell_mee_more")}
                      />
                    )}
                    {!canSeeMore && <div></div>}
                    <DefaultButton
                      text={Tr.Translate("language", "got_it")}
                      onClick={() => {
                        setVisible(false);
                        setOpen(false);
                        dispatch(GenericActions.SetOpenCoachMark(""));
                        coachMarkSetAsRemoved(props.coachMarkId);
                        removeInterval();
                        setTimeout(() => {
                          setRemoved(true);
                        }, 500);
                      }}
                    />
                  </div>
                </div>
              </div>
              {!open && visible && (
                <div className="coach-mark-marker-bubble"></div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BetterCoachMarks;
