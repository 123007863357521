import { useEffect, useState } from "react";
import { IBookCharacterMetaData } from "../../../Models/IBookCharacterMetaData";
import SuperSpinner from "../../SuperSpinner/SuperSpinner";
import ApiService from "../../../Services/ApiService";
import { IAPIResponse } from "../../../Services/AjaxService";
import Loadable from "../../Loadable/Loadable";
import { getCharacterMetadataTypeOptionsForSliders } from "../../../Utils/CharacterMetaDataMap";
import BetterSlider from "../../BetterSlider/BetterSlider";
import MultiForm, { triggerFormValidation } from "../../MultiForm/MultiForm";
import Tr from "../../../Utils/Translations/Translations";
import ImagePicker from "../../ImagePicker/ImagePicker";
import { PrimaryButton } from "@fluentui/react";
import { mobileCheck } from "../../../App";
import { IDataImage } from "../../../Models/IDataImage";
import Tools from "../../../Utils/Tools";
import { ToastMessage } from "../../../Utils/UIMessages";
import { GenerateAssetUrl } from "../../ImageAssetLoader/ImageAssetLoader";
import { GlobalState } from "../../../Redux/RootReducer";
import { IWorkgroup } from "../../../Models/IWorkgroup";
import { useSelector } from "react-redux";
import RoleDependantDisplayer, {
  canDisplayElement,
} from "../../RoleDependantDisplayer/RoleDependantDisplayer";
interface ICharacterMetaDataEditorInnerProps {
  bookId: number;
  characterName: string;
}

var dataImageClone: IDataImage | undefined = undefined;
const CharacterMetaDataEditorInnerBaseDesc = (
  props: ICharacterMetaDataEditorInnerProps
) => {
  const [characterMetaData, setCharacterMetaData] = useState<
    IBookCharacterMetaData[]
  >([]);
  const [characterImage, setCharacterImage_] = useState<
    IDataImage | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [imgUrl, setImgUrl] = useState<string>("");
  const [imgUrlBase, setImgUrlBase] = useState<string>("");
  const activeWorkgroup: IWorkgroup | undefined = useSelector(
    (state: GlobalState) => state.generic.activeWorkgroup
  );

  const setCharacterImage = (image: IDataImage) => {
    dataImageClone = { ...image };
    setCharacterImage_(image);
  };

  useEffect(() => {
    setTimeout(() => {
      if (dataImageClone) {
        setImgUrl(dataImageClone.DataImage);

        if (imgUrlBase === "") {
          setImgUrlBase(dataImageClone.DataImage);
        }
      }
    }, 400);
  }, [dataImageClone]);

  const loadCharacterMetaData = () => {
    setLoading(true);
    ApiService.MetaDataController.GetCharacterMetaData(
      props.bookId,
      props.characterName,
      undefined,
      undefined,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setCharacterMetaData(
            response.parsed.filter((x: IBookCharacterMetaData) =>
              (x.MetaDataType ?? "").includes("generic_meta_description")
            )
          );
        }
        setLoading(false);
      }
    );
  };

  const getDataImage = () => {
    setLoadingImage(true);
    ApiService.MetaDataController.GetDataImage(
      props.bookId,
      props.characterName,
      undefined,
      (response: IAPIResponse) => {
        if (response.parsed.length > 0) {
          setCharacterImage(response.parsed[0]);
        }
        setLoadingImage(false);
      }
    );
  };

  const addImageOnCharacter = () => {
    let image: IDataImage = {
      BookId: props.bookId,
      ImageTarget: props.characterName,
      DataImage: imgUrl,
      Deleted: false,
    };
    ApiService.MetaDataController.InsertDataImage(
      image,
      (response: IAPIResponse) => {
        if (response.error === null) {
          image.Id = response.parsed;
          setCharacterImage(image);
        }
      }
    );
  };

  const editImageOnCharacter = () => {
    if (characterImage) {
      let image: IDataImage = { ...characterImage };
      image.DataImage = imgUrl;
      ApiService.MetaDataController.UpdateDataImage(
        image,
        (response: IAPIResponse) => {
          if (response.error === null) {
            setCharacterImage(image);
          }
        }
      );
    }
  };

  const addCharacterMetaData = (
    metaData: IBookCharacterMetaData,
    index: number
  ) => {
    setSaving(true);
    ApiService.MetaDataController.InsertCharacterMetaData(
      metaData,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let meta: IBookCharacterMetaData[] = [...characterMetaData];
          metaData.Id = response.parsed;
          meta[index] = metaData;
          setCharacterMetaData(meta);
          ToastMessage(
            "success",
            Tr.Translate("language", "action_completed_successfully")
          );
        }
        setSaving(false);
      }
    );
  };

  const editCharacterMetaData = (
    metaData: IBookCharacterMetaData,
    index: number
  ) => {
    setSaving(true);
    ApiService.MetaDataController.UpdateCharacterMetaData(
      metaData,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let meta: IBookCharacterMetaData[] = [...characterMetaData];
          meta[index] = metaData;
          setCharacterMetaData(meta);
          ToastMessage(
            "success",
            Tr.Translate("language", "action_completed_successfully")
          );
        }
        setSaving(false);
      }
    );
  };

  const handleChangedData = (data: any) => {
    let keys: string[] = Object.keys(data);

    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let value = data[key];

      let index = characterMetaData.findIndex(
        (item: IBookCharacterMetaData) => (item?.MetaDataType ?? "") === key
      );
      if (index === -1) {
        addCharacterMetaData(
          {
            BookId: props.bookId,
            CharacterName: props.characterName,
            MetaDataType: key,
            MetaDataValue: value.toString(),
            Deleted: false,
          },
          i
        );
      } else {
        let selected: IBookCharacterMetaData = characterMetaData[index];
        selected.MetaDataValue = value.toString();
        editCharacterMetaData(selected, i);
      }
    }
  };

  const getMetaDefaultValue = (key: string) => {
    let index = characterMetaData.findIndex(
      (item: IBookCharacterMetaData) => (item?.MetaDataType ?? "") === key
    );
    return index === -1 ? null : characterMetaData[index].MetaDataValue;
  };

  useEffect(() => {
    loadCharacterMetaData();
    getDataImage();
  }, [props.characterName]);

  return (
    <div>
      <Loadable
        isLoading={loading}
        content={
          <div style={mobileCheck() ? { width: "75vw" } : {}}>
            <div
              className="metadata-generic-info-container"
              style={mobileCheck() ? { flexDirection: "column" } : {}}
            >
              {loadingImage && (
                <div className="metadata-generic-info-container-spinner">
                  <SuperSpinner />
                </div>
              )}

              {!loadingImage && (
                <ImagePicker
                  readonlyMode={
                    !canDisplayElement(activeWorkgroup, [
                      "admin",
                      "contributor",
                    ])
                  }
                  disabled={saving}
                  text={Tr.Translate("language", "character_potrait")}
                  onLoad={(imageBase64: string) => {
                    setImgUrl(imageBase64);
                  }}
                  initialImage={
                    imgUrl
                      ? imgUrl
                      : GenerateAssetUrl("/Assets/Public/default-no-person.png")
                  }
                  requestedPreviewWidth={mobileCheck() ? 200 : 400}
                  requestedResolution={{
                    width: 800,
                    height: 800,
                    native: false,
                  }}
                />
              )}
              <div style={mobileCheck() ? {} : { minWidth: "40vw" }}>
                <MultiForm
                  disabled={saving}
                  readOnlyMode={
                    !canDisplayElement(activeWorkgroup, [
                      "admin",
                      "contributor",
                    ])
                  }
                  disableOverflowHandler
                  formUniqueId="characterMetaGenericDesc"
                  inputs={[
                    {
                      type: "multiline",
                      required: true,
                      name: "metadata_character_generic_meta_description",
                      currentValue: getMetaDefaultValue(
                        "metadata_character_generic_meta_description"
                      ),
                      extraParams: { maxLength: 3000, rows: 20 },
                      label: Tr.Translate(
                        "language",
                        "character_generic_meta_description"
                      ),
                    },
                  ]}
                  onSubmit={(data: any) => {
                    handleChangedData(data);
                    if (imgUrl && imgUrl !== imgUrlBase) {
                      if (characterImage) {
                        editImageOnCharacter();
                      } else {
                        addImageOnCharacter();
                      }
                    }
                  }}
                />
              </div>
            </div>
            <RoleDependantDisplayer
              whiteList={["admin", "contributor"]}
              element={
                <div className="char-meta-button-on-right">
                  <PrimaryButton
                    disabled={saving}
                    onClick={() => {
                      triggerFormValidation("characterMetaGenericDesc");
                    }}
                  >
                    {Tr.Translate("language", "save")}
                  </PrimaryButton>
                </div>
              }
            />
          </div>
        }
      />
    </div>
  );
};

export default CharacterMetaDataEditorInnerBaseDesc;
