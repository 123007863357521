import ImageAssetLoader from "../ImageAssetLoader/ImageAssetLoader";
import "./SuperSpinner.scss";
import { Label } from "@fluentui/react";

export interface ISuperSpinnerProps {
  extraStyles?: any;
  mode?: "raimbow" | "ai" | "default";
  label?: string;
}

const SuperSpinner = (props: ISuperSpinnerProps) => {
  const mode = props.mode ? props.mode : "default";

  return (
    <div style={props.extraStyles ?? {}}>
      <div className="center-object-spinner" style={props.extraStyles ?? {}}>
        {mode === "ai" && (
          <div className="ai-super-spinner will-fadein">
            <ImageAssetLoader src={"/Assets/Public/AI_loader.gif"} />
          </div>
        )}
        {mode === "raimbow" && (
          <div className="raimbow-super-spinner">
            <ImageAssetLoader src={"/Assets/Public/raimbow_spinner.gif"} />
            <Label className="raimbow-super-spinner-center-text">
              {props.label}
            </Label>
          </div>
        )}
        {mode === "default" && (
          <div>
            <div className="example">
              <div className="block">
                <div className="item"></div>
                <div className="item"></div>
                <div className="item"></div>
                <div className="item"></div>
                <div className="item"></div>
                <div className="item"></div>
                <div className="item"></div>
                <div className="item"></div>
              </div>
            </div>
            <Label>{props.label}</Label>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuperSpinner;
