import { useEffect, useState } from "react";
import "./MultiForm.scss";
import {
  ColorPicker,
  ComboBox,
  Dropdown,
  IDropdownOption,
  ISelectableOption,
  ITag,
  Label,
  PrimaryButton,
  Spinner,
  TagPicker,
} from "@fluentui/react";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import BetterDropDown from "../BetterDropDown/BetterDropDown";
import Tools from "../../Utils/Tools";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import { mobileCheck } from "../../App";
import BetterTagPicker from "../BetterTagPicker/BetterTagPicker";
import BetterComboBox from "../BetterComboBox/BetterComboBox";
import BetterTextField from "../BetterTextField/BetterTextField";
import BetterColorPicker from "../BetterColorPicker/BetterColorPicker";
import KarmaPicker from "../KarmaPicker/KarmaPicker";
import SuperSpinner from "../SuperSpinner/SuperSpinner";
import StarPicker from "../StarPicker/StarPicker";

export interface IMultiFormInputExtraParams {
  min?: number;
  max?: number;
  rows?: number;
  options?: IDropdownOption[];
  multiSelect?: boolean;
  maxLength?: number;
  extraStyles?: any;
  underElement?: JSX.Element;
  inputContainerClass?: string;
  onRenderOption?: (item: ISelectableOption | undefined) => JSX.Element;
}

export interface IMultiFormInput {
  name: string;
  type:
    | "hidden"
    | "starpicker"
    | "text"
    | "email"
    | "password"
    | "custom"
    | "picker"
    | "number"
    | "combobox"
    | "multiline"
    | "color"
    | "customDatePicker"
    | "karmaPicker"
    | "select";
  width?: number;
  onChangeResetsOtherInputs?: string[];
  currentValue?: any;
  required?: boolean;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  element?: JSX.Element;
  extraParams?: IMultiFormInputExtraParams;
  inputTooltip?: JSX.Element;
}

export interface IMultiFormProps {
  formUniqueId: string;
  inputs: IMultiFormInput[];
  autoButton?: string;
  autoButtonDisable?: boolean;
  disableOverflowHandler?: boolean;
  autoButtonIcon?: string;
  strictBindingValue?: boolean;
  inlineForm?: boolean;
  resetOnStartChange?: boolean;
  tinySubmitButton?: boolean;
  readOnlyMode?: boolean;
  disabled?: boolean;
  onSubmit: (data: any) => void;
  onChange?: (data: any) => void;
}

export const triggerFormValidation = (formId: string) => {
  let btn = document.getElementById("trigger-form-" + formId);
  if (btn) {
    btn.click();
  }
};

export const renderLabel = (props: any, input: IMultiFormInput) => {
  return (
    <div className="custom-multi-form-label-wrap">
      <Label className={input.required ? "required-label" : ""}>
        {input.label}
      </Label>
      <CustomTooltip content={input.inputTooltip} iconName="Info" />
    </div>
  );
};

const MultiForm = (props: IMultiFormProps) => {
  const [state, setState] = useState<any>({});
  const [inited, setInited] = useState<boolean>(false);
  useEffect(() => {
    if (props.onChange) {
      props.onChange(state);
    }
  }, [state]);

  useEffect(() => {
    let initialState: any = {};
    props.inputs.forEach((x: IMultiFormInput) => {
      initialState[x.name] = x.currentValue ?? undefined;
    });
    setState(initialState);
    setInited(true);
  }, []);

  const updateState = (key: string, value: any) => {
    let newState: any = { ...state };
    let targetInput = props.inputs.find((x: IMultiFormInput) => x.name === key);
    (targetInput?.onChangeResetsOtherInputs ?? []).forEach((x: string) => {
      newState[x] = null;
    });

    newState[key] = value;
    setState(newState);
  };

  const renderSingleInput = (x: IMultiFormInput) => {
    let element: JSX.Element = <div>?{x.name}</div>;

    let valueProps: any = { defaultValue: x.currentValue };
    if (props.strictBindingValue) {
      valueProps = { value: x.currentValue };
    }

    if (
      x.type === "text" ||
      x.type === "password" ||
      x.type === "email" ||
      x.type === "number"
    ) {
      element = (
        <BetterTextField
          type={x.type}
          underlined={props.inlineForm}
          onRenderLabel={(el: any) => renderLabel(el, x)}
          canRevealPassword
          placeholder={x.placeholder ?? x.label}
          label={x.label}
          maxLength={x.extraParams?.maxLength}
          min={x.extraParams?.min}
          max={x.extraParams?.max}
          required={x.required}
          disabled={x.disabled || props.disabled}
          onChange={(e, t) => {
            updateState(x.name, t);
          }}
          {...valueProps}
        />
      );
    }

    if (x.type === "customDatePicker") {
      element = (
        <CustomDatePicker
          onRenderLabel={(el: any) => renderLabel(el, x)}
          placeholder={x.placeholder ?? x.label}
          label={x.label}
          currentValue={x.currentValue}
          required={x.required}
          disabled={x.disabled || props.disabled}
          onChange={(t) => {
            updateState(x.name, t);
          }}
        />
      );
    }

    if (x.type === "multiline") {
      element = (
        <BetterTextField
          type={"text"}
          multiline
          onRenderLabel={(el: any) => renderLabel(el, x)}
          rows={x.extraParams?.rows ?? 4}
          canRevealPassword
          maxLength={x.extraParams?.maxLength}
          placeholder={x.placeholder ?? x.label}
          label={x.label}
          required={x.required}
          disabled={x.disabled || props.disabled}
          onChange={(e, t) => {
            updateState(x.name, t);
          }}
          {...valueProps}
        />
      );
    }

    if (x.type === "karmaPicker") {
      element = (
        <KarmaPicker
          label={x.label}
          disabled={x.disabled || props.disabled}
          placeholder={x.placeholder ?? x.label}
          required={x.required}
          currentValue={x.currentValue}
          suggestions={x.extraParams?.options ?? []}
          onRenderLabel={(el: any) => renderLabel(el, x)}
          onChange={(karmaMap: string) => {
            updateState(x.name, karmaMap);
          }}
        />
      );
    }

    if (x.type === "picker") {
      element = (
        <BetterTagPicker
          label={x.label}
          disabled={x.disabled || props.disabled}
          placeholder={x.placeholder ?? x.label}
          required={x.required}
          currentValue={x.currentValue}
          suggestions={x.extraParams?.options ?? []}
          onRenderLabel={(el: any) => renderLabel(el, x)}
          onChange={(commaList: string | undefined) => {
            updateState(x.name, commaList);
          }}
        />
      );
    }

    if (x.type === "select") {
      element = (
        <BetterDropDown
          onRenderLabel={(el: any) => renderLabel(el, x)}
          placeholder={x.placeholder ?? x.label}
          label={x.label}
          currentValue={x.currentValue}
          required={x.required}
          disabled={x.disabled || props.disabled}
          multiSelect={x.extraParams?.multiSelect}
          options={x.extraParams?.options ?? []}
          onRenderOption={x.extraParams?.onRenderOption}
          onChange={(e, t) => {
            if (t) {
              updateState(x.name, t.key);
            }
          }}
        />
      );
    }

    if (x.type === "starpicker") {
      element = (
        <StarPicker
          onRenderLabel={(el: any) => renderLabel(el, x)}
          label={x.label}
          disabled={x.disabled || props.disabled}
          required={x.required}
          onChange={(value: number) => {
            updateState(x.name, value);
          }}
          currentValue={x.currentValue}
        />
      );
    }

    if (x.type === "color") {
      element = (
        <BetterColorPicker
          onRenderLabel={(el: any) => renderLabel(el, x)}
          label={x.label}
          required={x.required}
          disabled={x.disabled || props.disabled}
          placeholder={x.placeholder ?? x.label}
          defaultValue={x.currentValue}
          onChange={(color: string) => {
            updateState(x.name, color);
          }}
        />
      );
    }

    if (x.type === "combobox") {
      element = (
        <BetterComboBox
          onRenderLabel={(el: any) => renderLabel(el, x)}
          placeholder={x.placeholder ?? x.label}
          label={x.label}
          required={x.required}
          disabled={x.disabled || props.disabled}
          defaultValue={x.currentValue}
          options={x.extraParams?.options ?? []}
          onChange={(e, t) => {
            if (t) {
              updateState(x.name, t);
            }
          }}
        />
      );
    }

    if (x.type === "custom" && x.element) {
      element = x.element;
    }

    if (props.readOnlyMode) {
      let value = state[x.name];

      if (x.extraParams?.options) {
        let foundOption = x.extraParams.options.find(
          (y: any) => y.key === value
        );
        if (foundOption) {
          value = foundOption.text;
        }
      }

      element = (
        <div>
          <Label>{x.label}</Label>
          <div>{value}</div>
        </div>
      );
    }

    return <div className={x?.extraParams?.inputContainerClass}>{element}</div>;
  };

  return (
    <div>
      {!inited && (
        <div className="multi-form-init-spinner">
          <SuperSpinner />
        </div>
      )}
      {inited && (
        <form
          onSubmit={(e: any) => {
            e.preventDefault();
            props.onSubmit(state);
            return false;
          }}
        >
          <div
            className={
              props.disableOverflowHandler || props.inlineForm
                ? ""
                : "multi-form-input-box"
            }
            onScroll={() => {
              window.document.dispatchEvent(new Event("form-is-scrolling"));
            }}
          >
            <div className={props.inlineForm ? "multi-form-inline-form" : ""}>
              {props.inputs
                .filter((x: IMultiFormInput) => x.type !== "hidden")
                .map((x: IMultiFormInput, i: number) => {
                  let inputStyle = x.width
                    ? { width: "calc(" + x.width.toString() + "% - 15px" }
                    : { width: "100%" };

                  return (
                    <div
                      key={i}
                      className="multi-form-input-slot"
                      style={{
                        ...inputStyle,
                        ...(x.extraParams?.extraStyles ?? {}),
                      }}
                    >
                      <div style={x.disabled ? { opacity: 0.5 } : {}}>
                        {renderSingleInput(x)}
                      </div>
                      {x?.extraParams?.underElement && (
                        <div style={{ marginTop: "0.3em" }}>
                          {x?.extraParams?.underElement}
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
          {(props.autoButton || props.autoButtonIcon) && (
            <div
              className={
                props.tinySubmitButton ? "multi-form-tiny-submit-button" : ""
              }
            >
              <PrimaryButton
                style={{
                  minWidth: props.tinySubmitButton
                    ? "0"
                    : props.inlineForm
                    ? "12em"
                    : "100%",
                  marginTop: props.inlineForm ? "0.5em" : "1em",
                }}
                iconProps={
                  props.autoButtonIcon ? { iconName: props.autoButtonIcon } : {}
                }
                disabled={props.autoButtonDisable}
                text={props.autoButton}
                onClick={() => {
                  triggerFormValidation(props.formUniqueId);
                }}
              />
            </div>
          )}
          <div
            style={{
              position: "absolute",
              zIndex: "-1000",
              left: "-1000px",
              top: "-1000px",
            }}
          >
            <button
              tabIndex={-1}
              type="submit"
              id={"trigger-form-" + props.formUniqueId}
            >
              accept
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default MultiForm;
