export const Dictionary = {
  __presentation_1:
    "Die Website für Autoren von Fantasy-Büchern, Romanen und Kurzgeschichten mit komplexeren Handlungssträngen.",
  __presentation_2:
    "Plotter Studio ist eine Plattform, die in Zusammenarbeit mit Autoren für Autoren entwickelt wurde.",
  __presentation_3: "Ereignisbasiertes Plotschreiben",
  __presentation_4: "Zeitliche Reihenfolge",
  __presentation_5:
    "Halten Sie alle wichtigen Ereignisse Ihrer Handlung in Ordnung, indem Sie sie auf einer Zeitleiste platzieren. ",
  __presentation_6: "Kontrolle",
  __presentation_7:
    "Überprüfen Sie die Konsistenz der eingefügten Ereignisse, um Handlungslücken oder Inkonsistenzen zu vermeiden. ",
  __presentation_8: "Zusammenarbeit",
  __presentation_9:
    "Mit Plotter Studio können Benutzer andere zur Zusammenarbeit an demselben Buch einladen und so professionelle Effektivität auch in einer Arbeitsumgebung ermöglichen.",
  __presentation_10: "Intuitive Schnittstelle",
  __presentation_11:
    "Die Verwendung von Plotter Studio wurde so optimiert, dass es benutzerfreundlich ist, sodass Sie sich auf Ihr Buch konzentrieren können, ohne an etwas anderes denken zu müssen.",
  __presentation_12: "Haben Sie irgendwelche Fragen?",
  __presentation_12_2:
    "Das Plotter Studio-Team steht Ihnen zur Verfügung, um alle Ihre Fragen zu beantworten",
  __presentation_13: "Kontaktieren Sie uns",
  __presentation_14: "Oder Sie können kostenlos ein Konto erstellen ",
  __presentation_15: "Professionelle Qualität",
  __presentation_16:
    "Plotter Studio wird von einer Gruppe mit jahrzehntelanger Erfahrung in der IT-Welt entwickelt und gepflegt und wir garantieren eine kontinuierliche Weiterentwicklung im Laufe der Zeit.",
  __footer_about_us: "Über uns",
  __footer_our_mission: "Unsere Aufgabe",
  __footer_were_are_we_headed: "Wohin gehen wir?",
  __footer_services: "Unsere Dienstleistungen",
  __footer_serv_support: "Autorenunterstützung",
  __footer_serv_teaching: "Lehren",
  __footer_serv_private_solutions: "Private Lösungen",
  __footer_contact_us: "Kontaktiere uns",
  __footer_social_media: "Sozialen Medien",
  __footer_social_facebook: "Facebook",
  __footer_social_twitter: "Twitter",
  __app_for_writers_logo: "Die App für Autoren",
};
