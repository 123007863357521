export const Dictionary = {
  __registration_link_email: `
  <html>
    <head>
        <title>Confirm Your Account</title>
    </head>
    <body>
        <h2 style='background-color: #6197d6; padding: 0.5em; color: white; text-align: center;'>Account Confirmation</h2>
        <div style='text-align: center'>
        <img src='$EMAIL_IMAGE' alt='image' title='image' style="display:block" width="450" height="450"/>
        <div style='padding: 2em;'>
          <div>Welcome to <strong>Plotter Studio</strong>!</div>
          <br />
          <div>You are almost ready to start working on your fantastic projects.</div>
          <br />
          <div>We just need to confirm your registration to protect your account.</div>
          <br />
          <div>Please click the following link to activate your account:</div>
          <br/>
          <div style='margin: 2em auto; background-color: #6197d6; padding: 0.5em; width: 15em;'>
              <a style='text-decoration: none;' href='$CURRENT_DOMAIN/confirmaccount?token=$TOKEN'>
                <div style='color: white;'>Confirm Account</div>
              </a>
          </div>
          <br />
          <br />
          <div style='font-size:0.8em'>
              This is an automated request, please do not reply directly to this email. <br /> To learn more about <strong>Plotter Studio</strong>, visit our <a href='$CURRENT_DOMAIN/about'>about</a> page.
          </div>
        </div>
    </body>
  </html>
`,
  __invite_user_email: `
<html>
  <head>
    <title>Invitation to Work Together</title>
  </head>
  <body>
    <h2 style='background-color: #6197d6; padding: 0.5em; color: white; text-align: center;'>Teamwork Request</h2>
    <div style='text-align: center'>
      <img src='$EMAIL_IMAGE' alt='image' title='image' style="display:block" width="450" height="450"/>
      <div style='padding: 2em;'>
        <div>Hello,<br/><strong>$INVITERUSERNAME</strong> has decided to work with you on a writing project.</div>
        <br />
        <div>You have been invited to join a workgroup on Plotter Studio!</div>
        <br />
        <div>If you need to know more, you can contact the user who invited you by email: <strong>$INVITEREMAIL</strong> </div>
        <br/>
        <div style='margin: 2em auto; background-color: #6197d6; padding: 0.5em; width: 15em;'>
          <a style='text-decoration: none;' href='$CURRENT_DOMAIN/acceptinvitation?token=$TOKEN'>
            <div style='color: white;'>Accept Invitation</div>
          </a>
        </div>
        <br />
        <br />
        <div style='font-size:0.8em'>
          This is an automated request, please do not reply directly to this email. <br /> To learn more about <strong>Plotter Studio</strong>, visit our <a href='$CURRENT_DOMAIN/about'>about</a> page.
        </div>
      </div>
    </div>
  </body>
</html>
`,
  __reset_password_link_email: `
<html>
  <head>
    <title>Password Reset</title>
  </head>
  <body>
    <h2 style='background-color: #6197d6; padding: 0.5em; color: white; text-align: center;'>Password Reset</h2>
    <div style='text-align: center'>
      <div style='padding: 2em;'>
        Greetings from <strong>Plotter Studio</strong>.<br /><br />You have received this email because you requested to reset your password. <br /><br />Please click the following link to reset your password:<br/><br/>
        <div style='margin: 2em auto; background-color: #6197d6; padding: 0.5em; width: 15em;'>
          <a style='text-decoration: none;' href='$CURRENT_DOMAIN/resetpasswordrequest?token=$TOKEN'>
            <div style='color: white;'>Reset Password</div>
          </a>
        </div>
        <br />
        <br />
        <div style='font-size:0.8em'>
          This is an automated request, please do not reply directly to this email. <br /> To learn more about <strong>Plotter Studio</strong>, visit our <a href='$CURRENT_DOMAIN/about'>about</a> page.
        </div>
      </div>
    </div>
  </body>
</html>
`,
};
