import { useEffect, useState } from "react";
import { IBookCharacterMetaData } from "../../../Models/IBookCharacterMetaData";
import SuperSpinner from "../../SuperSpinner/SuperSpinner";
import ApiService from "../../../Services/ApiService";
import { IAPIResponse } from "../../../Services/AjaxService";
import Loadable from "../../Loadable/Loadable";
import { getCharacterMetadataTypeOptionsForSliders } from "../../../Utils/CharacterMetaDataMap";
import BetterSlider from "../../BetterSlider/BetterSlider";
import { mobileCheck } from "../../../App";
import Tr from "../../../Utils/Translations/Translations";
import { ToastMessage } from "../../../Utils/UIMessages";
import { IWorkgroup } from "../../../Models/IWorkgroup";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../Redux/RootReducer";
import { canDisplayElement } from "../../RoleDependantDisplayer/RoleDependantDisplayer";

const sliderAriaValueText = (value: number) => `${value} percent`;
const sliderValueFormat = (value: number) => `${value}%`;

var loadingRowClone: any = {};

interface ICharacterMetaDataEditorInnerProps {
  bookId: number;
  characterName: string;
}

var metaDataClone: any = {};

const CharacterMetaDataEditorInnerSliders = (
  props: ICharacterMetaDataEditorInnerProps
) => {
  const [characterMetaData, setCharacterMetaData] = useState<
    IBookCharacterMetaData[]
  >([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingRow, setLoadingRow_] = useState<any>({});
  const [formHideDeletedRows, setFormHideDeletedRows] = useState<number[]>([]);
  const [metadataState, setMetadataState_] = useState<any>({});
  const activeWorkgroup: IWorkgroup | undefined = useSelector(
    (state: GlobalState) => state.generic.activeWorkgroup
  );
  const setMetadataState = (data: any) => {
    metaDataClone = { ...data };
    setMetadataState_(data);
  };

  const setLoadingRow = (loading: boolean, index: number) => {
    loadingRowClone[index] = loading;
    setLoadingRow_({ ...loadingRowClone });
  };

  useEffect(() => {
    metaDataClone = {};
  }, []);

  const loadCharacterMetaData = () => {
    loadingRowClone = {};
    setLoading(true);
    ApiService.MetaDataController.GetCharacterMetaData(
      props.bookId,
      props.characterName,
      undefined,
      undefined,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setCharacterMetaData(
            response.parsed.filter((x: IBookCharacterMetaData) =>
              (x.MetaDataType ?? "").includes("extradata")
            )
          );
        }
        setLoading(false);
      }
    );
  };

  const addCharacterMetaData = (
    metaData: IBookCharacterMetaData,
    index: number
  ) => {
    setLoadingRow(true, index);
    ApiService.MetaDataController.InsertCharacterMetaData(
      metaData,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let meta: IBookCharacterMetaData[] = [...characterMetaData];
          metaData.Id = response.parsed;
          meta[index] = metaData;
          setCharacterMetaData(meta);
          ToastMessage(
            "success",
            Tr.Translate("language", "action_completed_successfully")
          );
        }
        setLoadingRow(false, index);
      }
    );
  };

  const editCharacterMetaData = (
    metaData: IBookCharacterMetaData,
    index: number
  ) => {
    setLoadingRow(true, index);
    ApiService.MetaDataController.UpdateCharacterMetaData(
      metaData,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let meta: IBookCharacterMetaData[] = [...characterMetaData];
          meta[index] = metaData;
          setCharacterMetaData(meta);
          ToastMessage(
            "success",
            Tr.Translate("language", "action_completed_successfully")
          );
        }
        setLoadingRow(false, index);
      }
    );
  };

  const saveSliderValue = () => {
    for (let key in metaDataClone) {
      let index = characterMetaData.findIndex(
        (item: IBookCharacterMetaData) => (item?.MetaDataType ?? "") === key
      );
      if (index === -1) {
        addCharacterMetaData(
          {
            BookId: props.bookId,
            CharacterName: props.characterName,
            MetaDataType: key,
            MetaDataValue: metaDataClone[key].value.toString(),
            Deleted: false,
          },
          metaDataClone[key].rowIndex
        );
      } else {
        let selected: IBookCharacterMetaData = characterMetaData[index];
        selected.MetaDataValue = metaDataClone[key].value.toString();
        editCharacterMetaData(selected, metaDataClone[key].rowIndex);
      }
    }
    setMetadataState({});
  };

  const handleChangedSlider = (
    key: string,
    value: number,
    rowIndex: number
  ) => {
    setMetadataState({ ...metadataState, [key]: { value, rowIndex } });

    if (canDisplayElement(activeWorkgroup, ["admin", "contributor"])) {
      setTimeout(() => {
        saveSliderValue();
      }, 2000);
    }
  };

  const getSliderDefaultValue = (key: string) => {
    let index = characterMetaData.findIndex(
      (item: IBookCharacterMetaData) => (item?.MetaDataType ?? "") === key
    );
    return index === -1 ? 0 : parseInt(characterMetaData[index].MetaDataValue);
  };

  useEffect(() => {
    loadCharacterMetaData();
  }, [props.characterName]);

  return (
    <div>
      <Loadable
        isLoading={loading}
        content={
          <div style={mobileCheck() ? { width: "75vw" } : {}}>
            {getCharacterMetadataTypeOptionsForSliders().map(
              (x: { key: string; text: string }, i: number) => {
                return (
                  <div key={i} className="slider-input-row">
                    <div className="slider-input-slider">
                      <BetterSlider
                        disabled={
                          loadingRow[i] ||
                          !canDisplayElement(activeWorkgroup, [
                            "admin",
                            "contributor",
                          ])
                        }
                        defaultValue={getSliderDefaultValue(x.key)}
                        onChange={(value: number) => {
                          if (!loadingRow[i]) {
                            handleChangedSlider(x.key, value, i);
                          }
                        }}
                        label={x.text}
                        max={100}
                        ariaValueText={sliderAriaValueText}
                        valueFormat={sliderValueFormat}
                        showValue
                      />
                    </div>
                    {loadingRow[i] && (
                      <div
                        style={{
                          position: "relative",
                          top: "1em",
                          left: "-0.5em",
                        }}
                      >
                        <div style={{ position: "absolute" }}>
                          <SuperSpinner />
                        </div>
                      </div>
                    )}
                  </div>
                );
              }
            )}
          </div>
        }
      />
    </div>
  );
};

export default CharacterMetaDataEditorInnerSliders;
