export const SET_KNOWN_CHARACTERS = "SET_KNOWN_CHARACTERS";
export const SET_KNOWN_ITEMS = "SET_KNOWN_ITEMS";
export const SET_KNOWN_SKILLS = "SET_KNOWN_SKILLS";
export const SET_KNOWN_SECRETS = "SET_KNOWN_SECRETS";
export const SET_KNOWN_FACTIONS = "SET_KNOWN_FACTIONS";
export const SET_KNOWN_STATUSES = "SET_KNOWN_STATUSES";
export const SET_KNOWN_LOCATIONS = "SET_KNOWN_LOCATIONS";
export const SET_KNOWN_CONDITIONS = "SET_KNOWN_CONDITIONS";

interface SetKnownCharacters {
  type: typeof SET_KNOWN_CHARACTERS;
  payload: string[];
}
interface SetKnownItems {
  type: typeof SET_KNOWN_ITEMS;
  payload: string[];
}
interface SetKnownSkills {
  type: typeof SET_KNOWN_SKILLS;
  payload: string[];
}
interface SetKnownSecrets {
  type: typeof SET_KNOWN_SECRETS;
  payload: string[];
}
interface SetKnownFactions {
  type: typeof SET_KNOWN_FACTIONS;
  payload: string[];
}
interface SetKnownStatuses {
  type: typeof SET_KNOWN_STATUSES;
  payload: string[];
}
interface SetKnownLocations {
  type: typeof SET_KNOWN_LOCATIONS;
  payload: string[];
}
interface SetKnownConditions {
  type: typeof SET_KNOWN_CONDITIONS;
  payload: string[];
}

export type EventProcessingActions =
  | SetKnownCharacters
  | SetKnownItems
  | SetKnownSkills
  | SetKnownSecrets
  | SetKnownFactions
  | SetKnownStatuses
  | SetKnownLocations
  | SetKnownConditions;

const setKnownCharacters = (data: string[]): SetKnownCharacters => {
  return { type: SET_KNOWN_CHARACTERS, payload: data };
};
const setKnownItems = (data: string[]): SetKnownItems => {
  return { type: SET_KNOWN_ITEMS, payload: data };
};
const setKnownSkills = (data: string[]): SetKnownSkills => {
  return { type: SET_KNOWN_SKILLS, payload: data };
};
const setKnownSecrets = (data: string[]): SetKnownSecrets => {
  return { type: SET_KNOWN_SECRETS, payload: data };
};
const setKnownFactions = (data: string[]): SetKnownFactions => {
  return { type: SET_KNOWN_FACTIONS, payload: data };
};
const setKnownStatuses = (data: string[]): SetKnownStatuses => {
  return { type: SET_KNOWN_STATUSES, payload: data };
};
const setKnownLocations = (data: string[]): SetKnownLocations => {
  return { type: SET_KNOWN_LOCATIONS, payload: data };
};
const SetKnownConditions = (data: string[]): SetKnownConditions => {
  return { type: SET_KNOWN_CONDITIONS, payload: data };
};

export const EventProcessingActions = {
  setKnownCharacters,
  setKnownItems,
  setKnownSkills,
  setKnownSecrets,
  setKnownFactions,
  setKnownStatuses,
  SetKnownConditions,
  setKnownLocations,
};
