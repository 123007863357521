import { Label } from "@fluentui/react";
import "./BetterRadioButtons.scss";
import { useEffect, useState } from "react";

export interface IBetterRadioButtonsProps {
  fieldName: string;
  options: { key: string; text: string }[];
  onChange: (selected: string) => void;
  defaultChecked?: string;
  checked?: string;
  disabled?: boolean;
}

const BetterRadioButtons = (props: IBetterRadioButtonsProps) => {
  return (
    <div>
      {props.options.map(
        (option: { key: string; text: string }, index: number) => {
          let dymanicProps: any = {};

          if (props.checked) {
            dymanicProps.checked = option.key === props.checked;
          }
          if (props.defaultChecked) {
            dymanicProps.defaultChecked = option.key === props.defaultChecked;
          }

          return (
            <Label
              key={index}
              htmlFor={"radio-option-" + option.key}
              className="container"
            >
              <input
                tabIndex={-1}
                onClick={() => {
                  if (props.onChange) {
                    props.onChange(option.key);
                  }
                }}
                disabled={props.disabled}
                type="radio"
                {...dymanicProps}
                id={"radio-option-" + option.key}
                name={props.fieldName}
                value={option.key}
              />
              <div className="label-text">{option.text}</div>
              <span className="checkmark"></span>
            </Label>
          );
        }
      )}
    </div>
  );
};

export default BetterRadioButtons;
