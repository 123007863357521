import "./CenterPad.scss";

export interface ICenterPadProps {
  content: JSX.Element;
  isWidePad?: boolean;
}

const CenterPad = (props: ICenterPadProps) => {
  return (
    <div className="pad-main-wrap-outer">
      <div
        className="pad-main-wrap"
        style={props.isWidePad ? { maxWidth: "unset" } : {}}
      >
        {props.content}
      </div>
    </div>
  );
};

export default CenterPad;
