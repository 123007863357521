import {
  Dropdown,
  IDropdownOption,
  IDropdownProps,
  ISelectableOption,
} from "@fluentui/react";
import "./BetterDropDown.scss";
import { useEffect, useState } from "react";
import Tools from "../../Utils/Tools";

export interface IBetterDropDownProps extends IDropdownProps {
  currentValue?: any;
}

const BetterDropDownMultiple = (props: IBetterDropDownProps) => {
  const [selectedOptions, setSelectedOptions] = useState<IDropdownOption[]>([]);
  const [inited, setInited] = useState<boolean>(false);
  useEffect(() => {
    if (props.currentValue && Array.isArray(props.currentValue) && !inited) {
      let initialSelections: IDropdownOption[] = (
        props.currentValue as string[]
      ).map((x: string) => {
        return { key: x };
      }) as any;
      setSelectedOptions(initialSelections);
      setTimeout(() => {
        exportSelection(initialSelections);
      }, 200);
    }
    setInited(true);
  }, [props.currentValue]);

  const handleSelection = (option: IDropdownOption | undefined) => {
    if (option) {
      let selected = option["selected"];
      let currOptions: IDropdownOption[] = [...selectedOptions];
      if (selected) {
        currOptions.push(option);
      } else {
        currOptions = currOptions.filter(
          (x: IDropdownOption) => x.key !== option.key
        );
      }
      setSelectedOptions(currOptions);
    }
  };

  const exportSelection = (opt: IDropdownOption[] | undefined = undefined) => {
    if (props.onChange) {
      let str = (opt ?? selectedOptions)
        .filter((x: IDropdownOption) => x.key.toString() !== "")
        .map((x: IDropdownOption) => x.key.toString());
      let processedResult: IDropdownOption = {
        key: str.join(", "),
        text: "",
      };
      props.onChange("" as any, processedResult);
    }
  };

  useEffect(() => {
    exportSelection();
  }, [selectedOptions]);

  return (
    <div>
      <Dropdown
        {...props}
        selectedKeys={
          selectedOptions.map((x: IDropdownOption) => {
            return x.key;
          }) as any
        }
        onDismiss={exportSelection}
        onChange={(e, o) => {
          handleSelection(o);
        }}
      />
    </div>
  );
};

const BetterDropDownSingle = (props: IBetterDropDownProps) => {
  const [selectedOptions, setSelectedOptions] = useState<IDropdownOption[]>([]);
  const [inited, setInited] = useState<boolean>(false);

  useEffect(() => {
    if (props.currentValue && selectedOptions.length === 0 && !inited) {
      setSelectedOptions([{ key: props.currentValue }] as any);
      setTimeout(() => {
        exportSelection([{ key: props.currentValue }] as any);
      }, 200);
    }
    setInited(true);
  }, [props.currentValue]);

  const handleSelection = (option: IDropdownOption | undefined) => {
    if (option) {
      setSelectedOptions([option]);

      setTimeout(() => {
        exportSelection([option]);
      }, 200);
    }
  };

  const exportSelection = (opt: IDropdownOption[] | undefined = undefined) => {
    if (props.onChange) {
      let str = (opt ?? selectedOptions)
        .filter((x: IDropdownOption) => x.key.toString() !== "")
        .map((x: IDropdownOption) => x.key.toString());
      let processedResult: IDropdownOption = {
        key: str.join(", "),
        text: "",
      };

      props.onChange("" as any, processedResult);
    }
  };

  useEffect(() => {
    exportSelection();
  }, [selectedOptions]);

  return (
    <div>
      {inited && (
        <Dropdown
          {...props}
          defaultSelectedKey={
            selectedOptions.length > 0 ? selectedOptions[0].key : null
          }
          onDismiss={exportSelection}
          onChange={(e, o) => {
            handleSelection(o);
          }}
        />
      )}
    </div>
  );
};

const BetterDropDown = (props: IBetterDropDownProps) => {
  return (
    <div>
      {props.multiSelect && <BetterDropDownMultiple {...props} />}
      {!props.multiSelect && <BetterDropDownSingle {...props} />}
    </div>
  );
};

export default BetterDropDown;
