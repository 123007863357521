import { CustomDateDaysToTextJSON } from "../Components/CustomDatePicker/CustomDatePicker";
import { GenerateAssetUrl } from "../Components/ImageAssetLoader/ImageAssetLoader";
import { IMonthMap } from "../Components/MonthMapEditor/MonthMapEditor";
import { IBookTimeline } from "../Models/IBookTimeline";
import { IBotAnswer } from "../Models/IBotAnswer";
import { IEvent } from "../Models/IEvent";
import { EventProcessingActions } from "../Redux/EventProcessing/EventProcessingActions";
import SettingsUtil from "./Settings";
import Tr from "./Translations/Translations";

const eventTypeValidForSet = (evType: string, evList: string[]) => {
  for (let i = 0; i < evList.length; i++) {
    if (evType.includes(evList[i])) {
      return true;
    }
  }
  return false;
};

const loadCharacters = (events: IEvent[]) => {
  let characters: string[] = [];
  events.forEach((x: IEvent) => {
    let splChars: string[] = x.CharacterList.split(",");
    splChars.forEach((x: string) => {
      let char: string = x.toLocaleLowerCase().trim();
      if (!characters.includes(char)) {
        characters.push(char);
      }
    });
  });
  return characters.filter((x: string) => x.length > 1).sort();
};

const loadItems = (events: IEvent[]) => {
  let items: string[] = [];
  events
    .filter((x: IEvent) => eventTypeValidForSet(x.EventType, ["obtains_item"]))
    .forEach((x: IEvent) => {
      let splChars: string[] = x.EventKeyword.split(",");
      splChars.forEach((x: string) => {
        let char: string = x.toLocaleLowerCase().trim();
        if (!items.includes(char)) {
          items.push(char);
        }
      });
    });
  return items.filter((x: string) => x.length > 1).sort();
};

const loadSkills = (events: IEvent[]) => {
  let skills: string[] = [];
  events
    .filter((x: IEvent) => eventTypeValidForSet(x.EventType, ["learns_skill"]))
    .forEach((x: IEvent) => {
      let splChars: string[] = x.EventKeyword.split(",");
      splChars.forEach((x: string) => {
        let char: string = x.toLocaleLowerCase().trim();
        if (!skills.includes(char)) {
          skills.push(char);
        }
      });
    });
  return skills.filter((x: string) => x.length > 1).sort();
};

const loadSecrets = (events: IEvent[]) => {
  let secrets: string[] = [];
  events
    .filter((x: IEvent) =>
      eventTypeValidForSet(x.EventType, ["learns_secret", "shares_a_secret"])
    )
    .forEach((x: IEvent) => {
      let splChars: string[] = x.EventKeyword.split(",");
      splChars.forEach((x: string) => {
        let char: string = x.toLocaleLowerCase().trim();
        if (!secrets.includes(char)) {
          secrets.push(char);
        }
      });
    });
  return secrets.filter((x: string) => x.length > 1).sort();
};

const loadFactions = (events: IEvent[]) => {
  let factions: string[] = [];
  events
    .filter((x: IEvent) =>
      eventTypeValidForSet(x.EventType, ["leaves_faction", "joins_faction"])
    )
    .forEach((x: IEvent) => {
      let splChars: string[] = x.EventKeyword.split(",");
      splChars.forEach((x: string) => {
        let char: string = x.toLocaleLowerCase().trim();
        if (!factions.includes(char)) {
          factions.push(char);
        }
      });
    });
  return factions.filter((x: string) => x.length > 1).sort();
};

const loadStatuses = (events: IEvent[]) => {
  let statuses: string[] = [];
  events
    .filter((x: IEvent) =>
      eventTypeValidForSet(x.EventType, ["assumes_status"])
    )
    .forEach((x: IEvent) => {
      let splChars: string[] = x.EventKeyword.split(",");
      splChars.forEach((x: string) => {
        let char: string = x.toLocaleLowerCase().trim();
        if (!statuses.includes(char)) {
          statuses.push(char);
        }
      });
    });
  return statuses.filter((x: string) => x.length > 1).sort();
};

const loadConditions = (events: IEvent[]) => {
  let conditions: string[] = [];
  events
    .filter((x: IEvent) =>
      eventTypeValidForSet(x.EventType, ["assumes_condition"])
    )
    .forEach((x: IEvent) => {
      let splChars: string[] = x.EventKeyword.split(",");
      splChars.forEach((x: string) => {
        let char: string = x.toLocaleLowerCase().trim();
        if (!conditions.includes(char)) {
          conditions.push(char);
        }
      });
    });
  return conditions.filter((x: string) => x.length > 1).sort();
};

const loadLocations = (events: IEvent[]) => {
  let locations: string[] = [];
  events
    .filter((x: IEvent) =>
      eventTypeValidForSet(x.EventType, ["reaches_location"])
    )
    .forEach((x: IEvent) => {
      let splChars: string[] = x.EventKeyword.split(",");
      splChars.forEach((x: string) => {
        let char: string = x.toLocaleLowerCase().trim();
        if (!locations.includes(char)) {
          locations.push(char);
        }
      });
    });
  return locations.filter((x: string) => x.length > 1).sort();
};

export const loadEventReducer = (events: IEvent[], dispatch: any) => {
  dispatch(EventProcessingActions.setKnownCharacters(loadCharacters(events)));
  dispatch(EventProcessingActions.setKnownItems(loadItems(events)));
  dispatch(EventProcessingActions.setKnownFactions(loadFactions(events)));
  dispatch(EventProcessingActions.setKnownLocations(loadLocations(events)));
  dispatch(EventProcessingActions.setKnownSecrets(loadSecrets(events)));
  dispatch(EventProcessingActions.setKnownSkills(loadSkills(events)));
  dispatch(EventProcessingActions.setKnownStatuses(loadStatuses(events)));
  dispatch(EventProcessingActions.SetKnownConditions(loadConditions(events)));
};

export const eventProcessingListToOptions = (eventList: string[]) => {
  return eventList.map((x: string) => {
    return { key: x, text: x };
  });
};

export const ExtraEventDataProcessed = (
  botCapDate: number,
  monthMaph: string,
  questionTypeKey: string,
  botAnswer: IBotAnswer
) => {
  let extraInfos: JSX.Element[] = [];

  // extra infos
  if (questionTypeKey === "age") {
    let lateInferredDate: number =
      botCapDate == -1 ? botAnswer.LatestEventRegistered : botCapDate;

    let lateDate: number =
      botCapDate == -1 ? botAnswer.LatestEventOnCharacter : botCapDate;
    let earlyDate: number = botAnswer.EarliestEventOnCharacter;

    let bornEvent: IEvent | undefined = botAnswer.Events.find((x: IEvent) =>
      x.EventType.includes("born")
    );
    let deathEvent: IEvent | undefined = botAnswer.Events.find((x: IEvent) =>
      x.EventType.includes("dead")
    );

    if (deathEvent) {
      lateDate = GetCustomDateAbsoluteNumericDate(
        deathEvent.CustomDate,
        JSON.parse(monthMaph ?? "{}"),
        deathEvent.TimelineId
      );
    }

    if (bornEvent) {
      earlyDate = GetCustomDateAbsoluteNumericDate(
        bornEvent.CustomDate,
        JSON.parse(monthMaph ?? "{}"),
        bornEvent.TimelineId
      );
    }

    let delta = lateDate - earlyDate;
    let inferredDelta = lateInferredDate - earlyDate;

    if (!bornEvent && !deathEvent) {
      extraInfos.push(
        <div>
          {Tr.Translate("language", "character_life_at_least_this_time")} <br />
          <strong>{CustomDateDaysToTextJSON(monthMaph, delta)}</strong>
          <br />
          {Tr.Translate("language", "character_life_inferred")} <br />
          <strong>{CustomDateDaysToTextJSON(monthMaph, inferredDelta)}</strong>
        </div>
      );
    } else {
      if (delta > 0) {
        extraInfos.push(
          <div>{CustomDateDaysToTextJSON(monthMaph, delta)}</div>
        );
      } else {
        extraInfos.push(
          <div>
            {Tr.Translate("language", "character_will_be_born_in")}{" "}
            {CustomDateDaysToTextJSON(monthMaph, -delta)}
          </div>
        );
      }
    }
  }

  return extraInfos;
};

export const CustomDateISOFormat = (
  customDate: string,
  monthMap: IMonthMap
) => {
  let splDate: string[] = customDate.split("/");
  for (let i = 0; i < monthMap.Months.length; i++) {
    if (monthMap.Months[i].MonthName === splDate[1]) {
      return (
        String(splDate[0]).padStart(10, "0") +
        "-" +
        String(i + 1).padStart(2, "0") +
        "-" +
        String(splDate[2]).padStart(2, "0")
      );
    }
  }

  return "";
};
export const CustomISODateCompare = (
  customISOA: string,
  customISOB: string
) => {
  let splA: number[] = customISOA.split("-").map((x) => +x);
  let splB: number[] = customISOB.split("-").map((x) => +x);

  for (let i = 0; i < 3; i++) {
    if (splA[i] < splB[i]) {
      return -1;
    }
    if (splA[i] > splB[i]) {
      return 1;
    }
  }
  return 0;
};

export const GetCustomDateAbsoluteNumericDate = (
  customDate: string,
  monthMap: IMonthMap,
  targetTimelineId: number
) => {
  let timelines: IBookTimeline[] = (window as any)["getReducerStore"].getState()
    .generic.bookTimelines;

  if (timelines.length > 0) {
    let daysFromStartOfExistence = 0;

    let cdateSpl: string[] = customDate.split("/");
    let targetYear = +cdateSpl[0];
    let targetDays = +cdateSpl[2];

    let cumulativeYears = 0;

    // for each timeline
    for (let index = 0; index < timelines.length; index++) {
      // for each year in the timeline
      for (let year = 1; year <= timelines[index].TimelineYearSpan; year++) {
        // for each month in the year
        for (let k = 0; k < monthMap.Months.length; k++) {
          // on match of timeline, year and month, send back result
          if (
            timelines[index].Id === targetTimelineId &&
            targetYear === year &&
            monthMap.Months[k].MonthName === cdateSpl[1]
          ) {
            return targetDays + daysFromStartOfExistence;
          }

          daysFromStartOfExistence += monthMap.Months[k].MonthMaxDays;

          if (
            monthMap.Months[k].LeapYearEveryTotYears !== 0 &&
            (year + cumulativeYears) %
              monthMap.Months[k].LeapYearEveryTotYears ===
              0
          ) {
            daysFromStartOfExistence += 1;
          }
        }
      }

      // remember passed years on current timeline
      cumulativeYears += timelines[index].TimelineYearSpan;
    }
  }

  // this function must only end inside the for loop
  return -1;
};

export const GetEventTypeVersion = () => {
  let iconSet = SettingsUtil.GetSettings("icon_set");
  if (iconSet === "simple_icon_set") {
    return "1";
  }
  if (iconSet === "anime_icon_set") {
    return "2";
  }
  return "0";
};

export const GenerateAssetEventUrl = (x: string, cssUrl = false) => {
  let iconSet = GetEventTypeVersion();
  let iconSetExt = "";

  switch (iconSet) {
    case "1":
      iconSetExt = "svg";
      break;
    case "2":
      iconSetExt = "png";
      break;
    default:
      iconSetExt = "";
  }

  let path = GenerateAssetUrl(
    "/Assets/EventsV" + iconSet + "/" + x + "." + iconSetExt,
    cssUrl
  );

  return path;
};
