import {
  Checkbox,
  MessageBar,
  MessageBarType,
  PrimaryButton,
} from "@fluentui/react";
import CenterPad from "../../Components/CenterPad/CenterPad";
import Tr from "../../Utils/Translations/Translations";
import "./LoginPage.scss";
import { Link, useNavigate } from "react-router-dom";
import ApiService from "../../Services/ApiService";
import { useEffect, useState } from "react";
import { IAPIResponse } from "../../Services/AjaxService";
import { ToastMessage, ToastMessageUnique } from "../../Utils/UIMessages";
import { useDispatch } from "react-redux";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import { AppRoutes } from "../../AppRoutes";
import Loadable from "../../Components/Loadable/Loadable";
import MultiForm, {
  triggerFormValidation,
} from "../../Components/MultiForm/MultiForm";
import SettingsUtil from "../../Utils/Settings";
import Generic from "../../Utils/Generic";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});

  const login = (data: any) => {
    (window as any)["SessionExpired"] = false;

    setFormData(data);
    setLoading(true);
    ApiService.SessionController.Login(
      data.email,
      data.password,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (SettingsUtil.GetSettings("remember_me")) {
            SettingsUtil.SetSettings("store_log_user_email", data.email);
            SettingsUtil.SetSettings("store_log_user_password", data.password);
          } else {
            SettingsUtil.SetSettings("store_log_user_email", "");
            SettingsUtil.SetSettings("store_log_user_password", "");
          }

          ToastMessage("success", Tr.Translate("language", "login_done"));
          localStorage.setItem("sessionToken", response.parsed.Token);

          // update the purchase status of any pending paypal transaction
          ApiService.StoreController.UpdatePaypalTransactionStatus();

          obtainUser();
        } else {
          if (response.raw.status === 422) {
            ToastMessageUnique(
              "error",
              Tr.Translate("language", "this_account_needs_verification")
            );
          } else if (response.raw.status === 404) {
            ToastMessageUnique(
              "error",
              Tr.Translate("language", "bad_credentials")
            );
          } else if (response.raw.status === 400) {
            ToastMessageUnique(
              "error",
              Tr.Translate("language", "too_many_failed_attempts")
            );
          }
          setLoading(false);
        }
      }
    );
  };

  const obtainUser = () => {
    ApiService.SessionController.GetSelfUser((response: IAPIResponse) => {
      if (response.error === null) {
        dispatch(GenericActions.setUser(response.parsed));
        navigate(AppRoutes.WORKGROUP_PICK_PAGE);
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    dispatch(GenericActions.setPurchases([]));
    dispatch(GenericActions.setSubscriptions([]));
    dispatch(GenericActions.setUser(undefined));

    if (localStorage.getItem("do-login-automatic") === "1") {
      localStorage.setItem("do-login-automatic", "2");
      setTimeout(() => {
        triggerFormValidation("loginForm");
      }, 200);
    }
  }, []);

  return (
    <div
      className="login-page-wrap"
      style={Generic.GetRandomBackgroundUrlCss()}
    >
      <CenterPad
        content={
          <Loadable
            isLoading={loading}
            content={
              <div className="login-inner-frame">
                {(window as any)["SessionExpired"] && (
                  <MessageBar messageBarType={MessageBarType.error}>
                    {Tr.Translate("language", "session_expired")}
                  </MessageBar>
                )}
                <MultiForm
                  autoButton={Tr.Translate("language", "login")}
                  formUniqueId="loginForm"
                  onSubmit={(data: any) => {
                    login(data);
                  }}
                  inputs={[
                    {
                      type: "email",
                      name: "email",
                      currentValue: SettingsUtil.GetSettings(
                        "store_log_user_email"
                      ),
                      label: Tr.Translate("language", "email"),
                      required: true,
                      extraParams: { maxLength: 50 },
                    },
                    {
                      type: "password",
                      name: "password",
                      currentValue: SettingsUtil.GetSettings(
                        "store_log_user_password"
                      ),
                      label: Tr.Translate("language", "password"),
                      required: true,
                      extraParams: { maxLength: 50 },
                    },
                  ]}
                />
                <Checkbox
                  label={Tr.Translate("language", "remember_me")}
                  defaultChecked={SettingsUtil.GetSettings("remember_me")}
                  onChange={(e, check) => {
                    if (check != undefined) {
                      SettingsUtil.SetSettings("remember_me", check);
                    }
                  }}
                />

                <hr />
                <div className="flex-line">
                  <div>{Tr.Translate("language", "no_account")}</div>
                  <Link to={AppRoutes.REGISTRATION_PAGE}>
                    {Tr.Translate("language", "register")}
                  </Link>
                </div>
                <div className="flex-line">
                  <div>{Tr.Translate("language", "forgot_password")}</div>
                  <Link to={AppRoutes.FORGOT_PASSWORD}>
                    {Tr.Translate("language", "reset_password")}
                  </Link>
                </div>
                <div className="flex-line">
                  <div>{Tr.Translate("language", "email_not_received")}</div>
                  <Link to={AppRoutes.RESEND_EMAIL_CONFIRMATION_PAGE}>
                    {Tr.Translate("language", "ask_new_one")}
                  </Link>
                </div>
              </div>
            }
          />
        }
      />
    </div>
  );
};

export default LoginPage;
