export const Dictionary = {
  __welcome: "Welcome",
  __not_found_page: "Not found!",
  __not_found_page_subtitle:
    "The page you are looking for does not exists, or requires you to login.",

  __login: "Login",
  __goto_home: "Back to Home Page",
  __password: "Password",
  __username: "Username",
  __image_load: "Load picture",
  __image_profile_load: "Load profile picture",
  __confirm_password: "Confirm password",
  __email: "Email",
  __email_not_received: "Confirmation Email not received?",
  __send_email: "Send new Email",
  __no_such_email_registered:
    "The provided Email is not registered in our system.",
  __ask_new_one: "Resend",
  __no_account: "You don't have an account?",
  __register: "Register!",
  __forgot_password: "Forgot password?",
  __load_older_chat_messages: "Load older messages",
  __recaptcha_test_failed: "Recaptcha test failed.",
  __reset_password: "Reset it!",
  __send_reset_email_title:
    "Insert the email you used to register. We will send you a link to reset your password.",
  __send_reset_email: "Send email",
  __login_done: "Login completed!",
  __new_book: "New book",
  __password_reset_email_sent:
    "We have sent you an email with the reset password link.",
  __password_reset_complete: "Password reset completed.",
  __registration_done_sent_email:
    "Done! We have sent you an email. Clik on the link in the email to confirm your account.",
  __done: "All done!",
  __user_already_registered:
    "This email is already registered, and cannot be used again to register another account.",
  __back: "Back",
  __email_registered: "Account activation complete. Now you can login.",
  __this_account_needs_verification:
    "This account needs verification before it can access the system. Check your inbox for a confirmation email.",
  __bad_credentials: "Email or password don't match.",
  __cancel: "Cancel",
  __accept: "Accept",
  __password_mismatch: "The password and confirm password are not equal.",
  __add_new_book_form: "Add a new book to start with.",
  __book_title: "Book title",
  __book_description: "Book description",
  __book_collection: "Book collection",
  __create_first_timeline: "Create the first timeline",
  __book_timeline_center_year: "Center on the year",
  __book_timeline_delta_year: "Interval width",
  __allow_custom_months: "Custom months & days structure",
  __add: "Add",
  __month_name: "Month name",
  __month_days: "Days per month",
  __month_leap_every: "Leap month",
  __table_rows_page_prev: "Previous page",
  __table_rows_page_next: "Next page",
  __table_rows_per_page: "Per page",
  __table_rows: "Rows $LLIM to $ULIM of $MLIM",
  __month_leap_every_hint:
    "Define every how many years the month gains a day, starting from year zero. Use zero to disable this feature.",
  __session_expired: "Your session has expired, you must login again.",
  __book_added: "Book added to your library.",
  __last_upated: "Updated on",
  __book_start: "Central year",
  __password_must_be_8_long: "Password must be at least 8 characters long.",
  __add_new_event: "Add new event on day",
  __new_event: "New Event",
  __event_title: "Event title",
  __event_description: "Event description",
  __event_type: "Type of event",
  __event_character_list: "Characters (optional)",
  __event_keyword: "Event subject (optional)",
  __event_added_succesfully: "Event added to time line.",
  __must_add_at_least_one_month: "You must add at least one month.",
  __exploring_on_date: "Exploring events on date",
  __delete_event: "Delete event?",
  __edit_event: "Edit event",
  __delete_book: "Delete book?",
  __edit_book: "Edit book",
  __action_cannot_be_undone:
    "This action will permanently remove this item, are you sure you want to remove it?",
  __event_deleted_succesfully: "Event deleted.",
  __event_edited_succesfully: "Event edited.",
  __book_deleted_succesfully: "Book deleted.",
  __book_edited_succesfully: "Book edited.",
  __book_total_days_per_year: "Total days per year",
  __comma_separated_list:
    "Insert a list of elements separated by comma. (e.g Name1, Name, etc)",
  __no_events_on_this_day: "No events on this day.",
  __expand: "Expand details",
  __collapse: "Hide details",
  __book_timeline_navigator_mode: "Timeline mode",
  __year_view: "Year view",
  __month_view: "Month view",
  __day_view: "Day view",
  __year: "Year",
  __month: "Month",
  __day: "Day",
  __custom_date: "Event date",
  __search: "Search",
  __no_workgroup_ready:
    "You have no workgroups yet. Create one to start working on your writing!",
  __add_workgroup: "Add new workgroup",
  __edit_workgroup: "Edit workgroup",
  __workgroup_name: "Workgroup name",
  __workgroup_description: "Workgroup description",
  __workgroup_logo: "Workgroup logo",
  __workgroup_license: "Assign license to Workgroup",
  __license_selection_is_required:
    "You must select a license to create a new Workgroup.",
  __obtain_a_license: "Plotter Studio license store",
  __workgroup_size: "Workgroup size",
  __workgroup_amount: "Number of workgroups",
  __purchase: "Purchase",
  __purchase_with_trial: "Start free trial",
  __must_accept_terms: "You must accept terms and conditions to continue.",
  __purchase_with_trial_hint_large:
    "A free trial of $FDAYS days is available (no credit card required). Only registration required!",
  __purchase_with_trial_hint:
    "Free trial will last $FDAYS days, then you'll need to purchase a license to keep working.",
  __purchase_with_trial_about_to_start:
    "You are about to request a free trial on a subscription. The trial period stars immediately. Once the trial period is expired, you will need to complete your purchase of the subscription.",
  __trial_started: "Trial period started succesfully.",
  __subscription_must_expire_first:
    "You already purchased this subscription. You cannot purchase it again untill it expires.",
  __workgroup_added: "Workgroup created!",
  __workgroup_edited: "Workgroup modified!",
  __workgroup_open: "Select",
  __workgroup_main_list: "Select a workgroup to load the workspace.",
  __change_workgroup: "Change workgroup",
  __edit: "Edit",
  __max_workgroup_limit_for_license:
    "You cannot add more workgroups on this license. You have reached the limit of supported workgroups for this license.",
  __delete: "Delete",
  __delete_workgroup: "Delete workgroup?",
  __delete_workgroup_accept:
    "I consent the removal of all the books, events, timelines, and any other data related to this workgroud. I understand that this action is destructive and permanent.",
  __delete_workgroup_warning:
    "Please be sure. If you delete a workgroup, all the books in the workgroup and all their related events will be deleted forever. This action cannot be undone.",
  __edit_workgroup_people: "Manage your team",
  __invite_user_to_group: "Email new member",
  __allow_standard_months: "Standard months & days structure",
  __move_all_events_on_a_different_date: "Move all events on a different date",
  __more_actions_in_date: "More actions in date",
  __events_in_this_date: "Events in this date",
  __total_days: "Total days",
  __invite_user_to_group_info:
    "Insert the email of another user to invite him to join your workgroup. The invited user will need to accept the invitation to complete this process.",
  __invite_user_not_found:
    "The invited user is not registered on Plotter Studio. Please, make sure any involved user has completed the free registration step before invitation.",
  __invitation_sent: "Invitation sent.",
  __trial_ends_on: "Trial ends on",
  __purchase_expire_on: "Expires on",
  __invitation_accepted: "Invitation accepted!",
  __proceed_removing_user:
    "Proceed with removing the user? The user will be removed from the workgroup.",
  __pick_book_to_start: "Pick a book to start.",
  __query_modal_title: "AI Engine",
  __query_modal_date_specific: "All results will depend on the selected date",
  __query_target_character: "Ask a question on this character",
  __query_target_type: "What you want to know",
  __age: "Age",
  __knows_about_character: "Knows about character",
  __has_skill_or_ability: "Has skill or ability",
  __posses_item: "Posses of item",
  __faction: "Faction",
  __status: "Status",
  __condition: "Condition",
  __location: "Location",
  __object_of_the_query: "Object of the question",
  __character: "Character",
  __item: "Item",
  __skill: "Skill",
  __secret: "Secret",
  __run_question: "Run query",
  __run_question_fields_missing: "Some fields are missing",
  __elapsed: "elapsed",
  __character_evolution: "Character evolution",
  __anyone: "Anyone",
  __any: "Any",
  __tell_mee_more: "Tell me more",
  __jump_timeline_ticks: "Jump ticks",
  __jump_timeline_ticks_hint:
    "Enter the number of tick to advance from the current date. To go backwards use negative numbers. Simple formulas are accepted (e.g. in daily view, 5 x 7 moves by 5 weeks).",
  __bad_input: "Only numbers or basic formulas are accepted.",
  __this_is_infinite: "Your input value is infinite!",
  __remember_me: "Remember me",
  __years: "Years",
  __months: "Months",
  __days: "Days",
  __goto_last_event: "Last event",
  __expired_purchase:
    "Either your subscription expired or its free trial is concluded. To keep using the workgroup, you must fix the subscription status.",
  __renew_license_purchase: "Renew license",
  __buy_license: "Buy license",
  __already_have_trial_on_this:
    "You already requested a free trial on this type of subcription. You may only request one free trial per subscription type.",
  __payment_type_month: "Duration: 1 month",
  __payment_type_year: "Duration: 1 year",
  __voucher_code: "Cupon",
  __subscrbe_recurrent_payment:
    "I want to subscribe to an automated recurrent payment instead.",
  __cupon_not_allowed: "Cupon code are not available for recurring payments",
  __purchase_is_now_free:
    "Your total is 0! Congrats, you can skip the payment step!",
  __purchase_completed: "Purchase completed!",
  __requires_payment: "Requires payment",
  __bad_cupon: "Your cupon code is invalid or expired.",
  __search_events: "Search events",
  __search_results: "Search results",
  __case_sensitive: "Case sensitive",
  __draft_ideas_title: "Handle here your draft ideas",
  __draft_idea_title: "Draft idea title",
  __draft_idea_description: "Draft idea description",
  __draft_idea_status: "Draft idea status",
  __add_draft_idea: "Add Draft",
  __edit_draft_idea: "Edit a Draft Idea",
  __draft_ideas: "Draft Ideas",
  __delete_draft_idea: "Delete Draft Idea",
  __draf_idea_deleted: "Draft Idea deleted.",
  __draf_idea_edited: "Draft Idea updated correctly.",
  __draf_idea_added: "Dragt Idea created.",
  __draft_idea_status_active: "Active",
  __draft_idea_status_archived: "Archived",
  __draft_idea_status_completed: "Completed",
  __draft_idea_status_to_review: "To review",
  __no_draft_ideas_yet:
    "You have no draft ideas yet. Use this form to add a new idea. Ideas are used to keep track of how your story could evolve.",
  __remaining_chars: "Remaining characters",
  __manage_timelines: "Manage book's timelines",
  __timeline_name: "Timeline name",
  __timeline_duration: "Timeline duration (Years)",
  __timeline_center_year: "Timeline central year",
  __timeline_order: "Order",
  __timeline_add: "Add timeline",
  __timeline_edit: "Edit timeline",
  __timeline_delete: "Delete timeline",
  __timeline_add_done: "Timeline created correctly.",
  __timeline_edit_done: "Timeline updated correctly.",
  __timeline_delete_done: "Timeline deleted.",
  __timeline_chose_workwith: "Pick a timeline",
  __notimeline_yet:
    "It seems you dont have any timeline setup for this book. All books require at least one timeline to start with. You can later add as many timelines as you wish.",
  __acknowledge_editmonth_can_screw_up_things:
    "I accept that the modification to the month structure made in this form will not automatically correct the dates of any events already present on the timeline. I therefore understand that following alterations to the month structure, some of the events may lose their place on the time line.",
  __you_must_acknowledge_book_kill:
    "You must agree to the deletion of the following items before you can proceed.",
  __erease_current_book: "Erease current book",
  __erease_all_events: "Erease $AMOUNT events",
  __erease_all_draft_ideas: "Erease $AMOUNT draft ideas",
  __erease_all_timelines: "Erease $AMOUNT timelines",
  __pay_monthly: "I wish to pay each month",
  __pay_yearly: "I wish to pay each year",
  __read_terms_and_condition: "Read out terms and conditions here",
  __read_about_privacy_statement: "Read our privacy policy here",
  __terms_and_cond:
    "I accept the terms and conditions for the services and the privacy statement.",
  __event_timeline: "Event timeline",
  __character_will_be_born_in: "Character will be born in ",
  __character_life_at_least_this_time:
    "There is no clear data on the Birth of this character. The character is known to have been around at least this time:",
  __character_life_inferred:
    "If the character is still alive, up to this point he would have this age:",
  __logout: "Logout",
  __settings: "Settings",
  __sync: "synchronize",
  __user_settings: "User settings",
  __events_colors: "Events color (for event group)",
  __acknowledge_edittimeline_can_screw_up_things:
    "I accept that the change to the timeline structure made in this form will not automatically correct the dates of any events already present on the timeline. I therefore understand that following alterations to the timeline structure, some of the events may lose their placement.",
  __acknowledge_timeline_removal:
    "I accept to remove the timeline and all the events that currently are placed on it.",
  __timeline_begin_year: "Begins on year",
  __timeline_end_year: "End on year",
  __required_fields_missing: "Some required fields are missing.",
  __timeline_view: "Timeline view",
  __table_view: "Table view",
  __all: "All",
  __all_hint: "Insert '$ALL' to search without restrictions.",
  __save: "Save",
  __color_for_event_type: "Bullet color for event type",
  __generic_ui_settings: "Generic UI settings",
  __limit_dots_on_timeline: "Max bullets for timeline tick",
  __settings_updated: "Settings updated correctly.",
  __your_subscription_doesnt_support_this_feature:
    "The subscription currently in use does not support this feature.",
  __please_pick_a_timeline: "Select a timeline.",
  __query_bot: "Query Bot",
  __error_detection: "Error detection",
  __workgroup_book_limit_title: "Too mani books!",
  __expired: "Expired",
  __book_limit_reached:
    "You cant add more books in the current workgroup. You must upgrade your license.",
  __workgroup_book_limit_description:
    "It seems that you have too many books in this workgroup. Exceeding books will still display in the bookshelf, but you will not be able to operate on them in any way. If you want to unlock them, you may upgrade the subscription binded to this workgroup.",
  __feature_usage_limit_reached:
    "You cannot use this feature until you get more usage tokens. You may navigate to the subscriptions dashboard to keep track of all usages.",
  __getting_updates: "Getting updates, please wait...",
  __got_it: "Got it",
  __show_again_tutorial_coachmarkers:
    "Restore the visibility of all tutorial's Coachmarkers",
  __statistics: "Workgroup usage data",
  __copy: "Copy item",
  __event_generic_info: "General info",
  __no_usages_recorded:
    "No usages of services on call have been recorded on this Workgroup. Remember that data will be displayed based on the subscription binded to the Workgroup.",
  __error_detection_intro:
    "This panel will allow to take advantage of the powerfull event compiler engine to discover and fix any unwanted errors which falls into one of the following categories.We are constantly working to enhance this feature as more and more error types are added to the engine.",
  __error_detection_type_consistency: "Characters consistency",
  __error_detection_type_events_out: "Events out of timeline",
  __error_detection_type_continuity: "Continuity error",
  __error_detection_type: "Error detection type",
  __karma_shift: "Karma shift (optional)",
  __karma_shift_positive: "Positive Karma",
  __karma_shift_neutral: "Neutral Karma",
  __karma_shift_negative: "Negative Karma",
  __karma_shift_weight: "Shift proportion",
  __karma_shift_weight_small: "Small Karma Shift",
  __karma_shift_weight_normal: "Medium Karma Shift",
  __karma_shift_weight_big: "Huge Karma Shift",
  __how_to_fix: "How to fix",
  __severity_error: "error",
  __severity_warning: "warning",
  __severity_info: "info",
  __character_inclinations: "Inclinations",
  __metadata: "Metadata",
  __character_goals: "Goals",
  __metadata_type: "Metadata Type",
  __metadata_value: "Value",
  __character_generic_infos: "Generic info",
  __character_potrait: "Character potrait",
  __character_generic_meta_description: "Generic description",
  __metadata_heading_hint:
    "A character must be added from an event on the timeline before you can add metadata.",
  __metadata_attitude_negative: "Detests",
  __metadata_attitude_neutral: "Neutral",
  __metadata_attitude_positive: "Loves",
  __draft_ideas_short: "Drafts",
  __action_completed_successfully: "Action completed successfully.",
  __too_many_failed_attempts:
    "You have made too many failed attempts. Try again in a few minutes.",
  __simple_icon_set: "Default icon set",
  __anime_icon_set: "Set of anime icons",
  __used_icon_set: "Icon style for events",
  __other_options: "Other options",
  __your_recent_books: "Recent books",
  __work_on_book: "Work on book",
  __amount_paid: "Amount paid",
  __no_results: "There are no items to display.",
  __book_logo: "Book logo",
  __full_price: "Full price",
  __created_on: "Created on",
  __book_has_been_disabled: "The book has been disabled.",
  __all_my_purchases: "All my purchases",
  __license_type: "License type",
  __paid_on: "Paid on",
  __yes: "Yes",
  __no: "No",
  __payment_type: "Payment type",

  __payment_is_valid: "Payment is valid",
  __trial_in_progress: "Trial in progress",
  __statistic_is_subscription_based:
    "This information is based on the subscription currently associated with the workgroup.",
  __workgroup_page: "Workgroup page",
  __trial_days: "Trial days",
  __requested_trial: "Requested trial",
  __timeline_duration_hint:
    "Length of timeline in years. (e.g. for first timeline 2000 means from year 1 to 2000)",
  __timeline_central_year_hint:
    "When you open the timeline editor, this will be the middle date displayed by default.",
  __timeline_order_hint:
    "Indicates the position of this timeline with respect to any others. (e.g. 1 for the first timeline, 2 for the second, etc)",
  __book_timeline_delta_year_hint:
    "Load only a range of years relative to the central year.",
  __workgroup_session_expired:
    "The workgroup session has expired. Please renew the session by selecting the workgroup.",
  __invite_user_membership_type: "Role",
  __reader: "Reader",
  __contributor: "Contributor",
  __invite_user_book_targets: "Book permissions",
  __your_role_in_this_workgroup: "You have been logged in as $ROLE.",
  __notimeline_yet_reader:
    "It appears there are no timelines for this book yet. Since you are a reader, you cannot add new timelines.",
  __jan: "January",
  __feb: "February",
  __mar: "March",
  __apr: "April",
  __may: "May",
  __jun: "June",
  __jul: "July",
  __aug: "August",
  __sep: "September",
  __oct: "October",
  __nov: "November",
  __dec: "December",

  __small: "Small",
  __medium: "Medium",
  __large: "Large",

  __model_size: "Model size",
  __model_size_hint:
    "Larger models may take longer to process but are more accurate.",
  __language: "Language",
  __text_to_process: "Text to process",
  __generated_events: "Generated events",
  __no_events_generated: "No events generated.",

  __text_to_events: "Text to events",
  __generate_events: "Generate events",
  __apply_generated_events: "Apply generated events",

  __leave_a_feedback: "Leave a feedback",
  __feedback_title: "Title",
  __feedback_description: "Description",
  __feedback_vote: "Vote",
  __feedback_hint:
    "Leave a feedback to help us improve Plotter Studio. Thank you!",
  __go_to_payment: "Go to payment",
  __message: "Message",

  __chat: "Chat",
  __workgroup_chat: "Workgroup Chat",
  __workgroup_chat_hint: "Use this chat to communicate with workgroup members.",

  __send: "Send",
  __attach_file: "Attach file",
  __pick_a_chat_room_to_start_talking: "Pick a chat room to start talking.",

  __the_total_file_size_you_may_send_per_message_canno_exceed:
    "The total file size you may send per message cannot exceed $SIZE KB.",
  __with: "with",
};
