export const AppRoutes = {
  HOME_PAGE: "/",
  LOGIN_PAGE: "/login",
  SETTINGS_PAGE: "/settings",
  EVENT_MAP_PAGE: "/event-map",
  MY_PURCHASES_PAGE: "/my-purchases",
  REGISTRATION_PAGE: "/registration",
  RESET_PASSWORD: "/resetpasswordrequest",
  FORGOT_PASSWORD: "/forgotpassword",
  EMAIL_CONFIRMATION_PAGE: "/confirmaccount",
  RESEND_EMAIL_CONFIRMATION_PAGE: "/resendemail",
  WORKGROUP_PICK_PAGE: "/workgroups",
  CONTACT_US_PAGE: "/contactus",
  STORE_PAGE: "/store",
  ACCEPT_INVITATION_PAGE: "/acceptinvitation",
  DEVEMAIL_BUILDER_PAGE: "/dev-email-builder",
  TEST_PAGE: "/dev-test",
  ABOUT_PAGE: "/about",
};
