export const Dictionary = {
  __metadata_type_class_family: "Famille",
  __metadata_type_class_beliefs: "Croyances",
  __metadata_type_social_group_class_personal: "Personnel",
  __metadata_type_family_father_positive: "Famille - Père (Aimant)",
  __metadata_type_family_mother_positive: "Famille - Mère (Aimante)",
  __metadata_type_family_father_negative: "Famille - Père (Pas aimant)",
  __metadata_type_family_mother_negative: "Famille - Mère (Pas aimante)",
  __metadata_type_family_step_father_positive: "Famille - Beau-père (Aimant)",
  __metadata_type_family_step_mother_positive: "Famille - Belle-mère (aimante)",
  __metadata_type_family_step_father_negative:
    "Famille - Beau-père (pas aimant)",
  __metadata_type_family_step_mother_negative:
    "Famille - Belle-mère (pas aimante)",

  __metadata_type_family_child_negative: "Enfant (Non désiré)",
  __metadata_type_family_child_positive: "Enfant (Désiré)",
  __metadata_type_family_sibling_negative: "Frère/Sœur (Non amical)",
  __metadata_type_family_sibling_positive: "Frère/Sœur (Amical)",

  __metadata_type_religion_pregiudice_negative: "Préjugés religieux négatifs",
  __metadata_type_religion_pregiudice_positive: "Préjugés religieux positifs",
  __metadata_type_religion_pregiudice_negative_aggressive:
    "Préjugés religieux négatifs - Agressif",
  __metadata_type_race_pregiudice_negative: "Préjugés raciaux négatifs",
  __metadata_type_race_pregiudice_positive: "Préjugés raciaux positifs",
  __metadata_type_race_pregiudice_negative_aggressive:
    "Préjugés raciaux négatifs - Agressif",

  __metadata_type_ethinicity_pregiudice_positive: "Préjugés ethniques positifs",
  __metadata_type_ethinicity_pregiudice_negative: "Préjugés ethniques négatifs",
  __metadata_type_ethinicity_pregiudice_negative_aggressive:
    "Préjugés ethniques négatifs - Agressif",
  __metadata_type_sex_pregiudice_positive: "Préjugés sexuels positifs",
  __metadata_type_sex_pregiudice_negative: "Préjugés sexuels négatifs",
  __metadata_type_sex_pregiudice_negative_aggressive:
    "Préjugés sexuels négatifs – Agressifs",

  __metadata_type_social_group_religion_affiliation: "Appartenance religieuse",
  __metadata_type_social_group_personal_ethnicity: "Origine ethnique",
  __metadata_type_social_group_personal_sex: "Sexe",
  __metadata_type_social_group_personal_biological_race: "Race biologique",
  __metadata_extradata_violence_inclination: "Inclinaison à la violence",
  __metadata_extradata_scientific_inclination: "Inclinaison scientifique",
  __metadata_extradata_military_inclination: "Inclinaison militaire",
  __metadata_extradata_religious_inclination: "Inclinaison religieuse",
  __metadata_extradata_political_inclination: "Inclinaison politique",
  __metadata_extradata_economic_inclination: "Inclinaison économique",
  __metadata_extradata_social_inclination: "Inclinaison sociale",
  __metadata_extradata_cultural_inclination: "Inclinaison culturelle",
  __metadata_extradata_environmental_inclination:
    "Inclinaison environnementale",
  __metadata_extradata_spiritual_inclination: "Inclinaison spirituelle",
  __metadata_extradata_educational_inclination: "Inclinaison éducative",
  __metadata_extradata_athletic_inclination: "Inclinaison athlétique",
  __metadata_extradata_artistic_inclination: "Penchant artistique",
  __metadata_extradata_musical_inclination: "Inclinaison musicale",
  __metadata_extradata_romantic_inclination: "Inclinaison romantique",
  __metadata_extradata_familial_inclination: "Inclinaison familiale",
  __metadata_extradata_friendship_inclination: "Inclinaison à l'amitié",
  __metadata_extradata_professional_inclination: "Inclinaison professionnelle",

  __metadata_objective_of_character_encounter: "Rencontrer quelqu'un",
  __metadata_objective_of_character_marry: "Épouser quelqu'un",
  __metadata_objective_of_character_reach_position: "Atteindre l'endroit",
  __metadata_objective_of_character_obtain_object: "Obtenez l'article",
  __metadata_objective_of_character_kill: "Tuer quelqu'un",
  __metadata_objective_of_character_learn:
    "Apprendre une compétence ou un concept",
  __metadata_objective_of_character_live_event:
    "Se retrouver dans un événement ou une situation",
};
