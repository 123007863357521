import { Label, PrimaryButton } from "@fluentui/react";
import "./TextToEvents.scss";
import ApiService from "../../Services/ApiService";
import { useState } from "react";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import Tr from "../../Utils/Translations/Translations";
import { IEvent } from "../../Models/IEvent";
import PrettyTable from "../PrettyTable/PrettyTable";
import SuperSpinner from "../SuperSpinner/SuperSpinner";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import {
  eventProcessingListToOptions,
  GenerateAssetEventUrl,
} from "../../Utils/EventProcessing";
import {
  dropDownEventOption,
  getEventTextByPartialKey,
  getEventTypesOptions,
} from "../../Utils/EventTypeMap";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../Redux/RootReducer";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import ImageAssetLoader from "../ImageAssetLoader/ImageAssetLoader";
import EventTypeIcon from "../EventTypeIcon/EventTypeIcon";
import { IAPIResponse } from "../../Services/AjaxService";
import { ToastMessage } from "../../Utils/UIMessages";
import Loadable from "../Loadable/Loadable";
import { IUser } from "../../Models/IUser";
import { IBook } from "../../Models/IBook";
import { IBookTimeline } from "../../Models/IBookTimeline";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import FeedbackHandler from "../FeedbackHandler/FeedbackHandler";

export interface ITextToEventsProps {
  onDismiss: () => void;
}

const GeneratedEventEditor = (props: {
  event: IEvent | null;
  onChange: (event: IEvent) => void;
}) => {
  const knownCharacters: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownCharacters
  );
  const everything: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.everything
  );

  return (
    <div>
      {props.event && (
        <SmartModal
          modalUniqueId="eneratedEventEditorModal"
          content={
            <MultiForm
              onSubmit={(data: any) => {
                data.EventType = data.EventType.replaceAll("event_type_", "");
                props.onChange(data);
                DismissModal("eneratedEventEditorModal");
              }}
              formUniqueId={"eneratedEventEditorForm"}
              inputs={[
                {
                  type: "text",
                  name: "Title",
                  currentValue: props.event?.Title,
                  label: Tr.Translate("language", "event_title"),
                  required: true,
                  extraParams: { maxLength: 50 },
                },
                {
                  type: "text",
                  name: "Description",
                  currentValue: props.event?.Description,
                  label: Tr.Translate("language", "event_description"),
                  required: true,
                  extraParams: { maxLength: 3000 },
                },
                {
                  type: "select",
                  name: "EventType",
                  currentValue: props.event?.EventType.split(", ")
                    .filter((x: string) => x)
                    .map((x: string) => "event_type_" + x),
                  extraParams: {
                    multiSelect: true,
                    options: getEventTypesOptions(),
                    onRenderOption: dropDownEventOption,
                  },
                  label: Tr.Translate("language", "event_type"),
                  required: true,
                },
                {
                  type: "picker",
                  name: "CharacterList",
                  extraParams: {
                    options: eventProcessingListToOptions(knownCharacters),
                  },
                  currentValue: props.event?.CharacterList,
                  label: Tr.Translate("language", "event_character_list"),
                },
                {
                  type: "picker",
                  name: "EventKeyword",
                  extraParams: {
                    options: eventProcessingListToOptions(everything),
                  },
                  currentValue: props.event?.EventKeyword,
                  label: Tr.Translate("language", "event_keyword"),
                },
              ]}
            />
          }
          buttons={[
            {
              text: Tr.Translate("language", "cancel"),
              onClick: () => {
                DismissModal("eneratedEventEditorModal");
              },
            },
            {
              text: Tr.Translate("language", "accept"),
              onClick: () => {
                triggerFormValidation("eneratedEventEditorForm");
              },
            },
          ]}
        />
      )}
    </div>
  );
};

const TextToEvents = (props: ITextToEventsProps) => {
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState<boolean>(false);
  const [generatedEvents, setGeneratedEvents] = useState<any[]>([]);
  const [processingDone, setProcessingDone] = useState<boolean>(false);
  const [toEditEvent, setToEditEvent] = useState<IEvent | null>(null);
  const [editingIndex, setEditingIndex] = useState<number>(-1);
  const [saveAll, setSaveAll] = useState<boolean>(false);
  const eventsDate: string = useSelector(
    (state: GlobalState) => state.generic.exploreEventsDate
  );
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.generic.user
  );
  const activeBook: IBook | undefined = useSelector(
    (state: GlobalState) => state.generic.activeBook
  );
  const activeTimeline: IBookTimeline | undefined = useSelector(
    (state: GlobalState) => state.generic.activeTimeline
  );
  const globalEvents: IEvent[] = useSelector(
    (state: GlobalState) => state.generic.globalevents
  );

  const textToEvents = (data: any) => {
    setProcessing(true);
    setProcessingDone(false);
    ApiService.EventController.TextToEvents(
      { modelSize: data.modelSyze, language: data.language, text: data.text },
      (response) => {
        if (response.error === null) {
          setGeneratedEvents(response.parsed);
          setProcessingDone(true);
        }
        setProcessing(false);
      }
    );
  };

  const saveAllGeneratedEvents = async () => {
    setSaveAll(true);
    let newList: IEvent[] = [...globalEvents];
    for (let i = 0; i < generatedEvents.length; i++) {
      let targetEvent: IEvent = generatedEvents[i];
      targetEvent.CustomDate = eventsDate;
      targetEvent.KarmaMap = "";
      targetEvent.BookId = activeBook?.Id ?? 0;
      targetEvent.OwnerId = loggedUser?.Id ?? 0;
      targetEvent.TimelineId = activeTimeline?.Id ?? 0;
      let response: IAPIResponse = await ApiService.EventController.NewEvent(
        targetEvent
      );
      if (response.error !== null) {
        ToastMessage("error", Tr.Translate("system", "generic_api_failure"));
        setSaveAll(false);
        return;
      } else {
        targetEvent.Id = response.parsed;
        targetEvent.UpdateDT = new Date().toISOString();
        newList.push(targetEvent);
      }
    }

    dispatch(GenericActions.setEvents(newList));

    ToastMessage(
      "success",
      Tr.Translate("language", "action_completed_successfully")
    );
    setSaveAll(false);
    setGeneratedEvents([]);
    setProcessingDone(false);
    props.onDismiss();
  };

  return (
    <div>
      <GeneratedEventEditor
        onChange={(event: IEvent) => {
          if (editingIndex >= 0) {
            setGeneratedEvents(
              generatedEvents.map((x: IEvent, i: number) =>
                i === editingIndex ? event : x
              )
            );
          }
        }}
        event={toEditEvent}
      />
      <Loadable
        isLoading={saveAll}
        content={
          <div>
            <FeedbackHandler section="text-to-events" />
            <MultiForm
              formUniqueId="textToEventsForm"
              onSubmit={textToEvents}
              inputs={[
                {
                  type: "select",
                  name: "modelSyze",
                  required: true,
                  label: Tr.Translate("language", "model_size"),
                  extraParams: {
                    options: [
                      {
                        key: "sm",
                        text: Tr.Translate("language", "small"),
                      },
                      {
                        key: "md",
                        text: Tr.Translate("language", "medium"),
                      },
                      {
                        key: "lg",
                        text: Tr.Translate("language", "large"),
                      },
                    ],
                    underElement: (
                      <div className="under-input-hint">
                        {Tr.Translate("language", "model_size_hint")}
                      </div>
                    ),
                  },
                },
                {
                  type: "select",
                  name: "language",
                  required: true,
                  label: Tr.Translate("language", "language"),
                  currentValue: Tr.GetLanguage(),
                  extraParams: {
                    options: [
                      { key: "it", text: "Italiano" },
                      { key: "en", text: "English" },
                      { key: "es", text: "Español" },
                      { key: "de", text: "Deutsch" },
                      { key: "fr", text: "Français" },
                    ],
                  },
                },
                {
                  type: "multiline",
                  required: true,
                  name: "text",
                  label: Tr.Translate("language", "text_to_process"),
                  extraParams: { maxLength: 1000 },
                },
              ]}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                marginBottom: "1em",
                alignItems: "end",
              }}
            >
              <PrimaryButton
                disabled={processing}
                onClick={() => {
                  triggerFormValidation("textToEventsForm");
                }}
                text={Tr.Translate("language", "generate_events")}
              />
              {processing && <SuperSpinner />}
            </div>

            {processingDone && (
              <div>
                <Label>{Tr.Translate("language", "generated_events")}</Label>
                <div>
                  <div style={{ fontSize: "0.7em" }}>
                    <PrettyTable
                      disableToolbar
                      headers={[
                        "",
                        Tr.Translate("language", "event_title"),
                        Tr.Translate("language", "event_character_list").split(
                          "("
                        )[0],
                        Tr.Translate("language", "event_keyword").split("(")[0],
                        Tr.Translate("language", "event_type"),
                      ]}
                      rows={generatedEvents.map((event: IEvent, i: number) => {
                        return [
                          <div>
                            <CustomTooltip
                              isButton
                              iconName="Edit"
                              content={Tr.Translate("language", "edit")}
                              onClick={() => {
                                setEditingIndex(i);
                                setToEditEvent({ ...event });
                                SummonModal("eneratedEventEditorModal");
                              }}
                            />
                            <CustomTooltip
                              isButton
                              iconName="Trash"
                              content={Tr.Translate("language", "delete")}
                              onClick={() => {
                                setGeneratedEvents(
                                  generatedEvents.filter((x, j) => i !== j)
                                );
                              }}
                            />
                          </div>,
                          <div>{event.Title}</div>,
                          <div>{event.CharacterList}</div>,
                          <div>{event.EventKeyword}</div>,
                          <div>
                            {event.EventType.split(",").map(
                              (y: string, j: number) => {
                                return (
                                  <div
                                    key={j}
                                    style={{ display: "inline-block" }}
                                  >
                                    <EventTypeIcon
                                      requestSizeEm="7em"
                                      iconKey={y.trim()}
                                      iconText={
                                        getEventTextByPartialKey(
                                          y.trim()
                                        ) as string
                                      }
                                    />
                                  </div>
                                );
                              }
                            )}
                          </div>,
                        ];
                      })}
                      plainRows={generatedEvents}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        marginBottom: "1em",
                        marginTop: "1em",
                        alignItems: "end",
                      }}
                    >
                      <PrimaryButton
                        disabled={saveAll}
                        onClick={() => {
                          saveAllGeneratedEvents();
                        }}
                        text={Tr.Translate(
                          "language",
                          "apply_generated_events"
                        )}
                      />
                      {saveAll && <SuperSpinner />}
                    </div>
                  </div>
                  {generatedEvents.length === 0 && (
                    <div style={{ textAlign: "center" }}>
                      <Label>
                        {Tr.Translate("language", "no_events_generated")}
                      </Label>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

export default TextToEvents;
