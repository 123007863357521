import { PrimaryButton } from "@fluentui/react";
import "./ImagePicker.scss";
import { useEffect, useState } from "react";
import Tr from "../../Utils/Translations/Translations";
import ImageAssetLoader from "../ImageAssetLoader/ImageAssetLoader";

export interface IImagePickerProps {
  onLoad: (imageBase64: string) => void;
  initialImage?: string;
  text: string;
  disabled?: boolean;
  readonlyMode?: boolean;
  requestedResolution?: { width: number; height: number; native?: boolean };
  requestedPreviewWidth?: number;
}

const ImagePicker = (props: IImagePickerProps) => {
  const [imgUrl, setImgUrl] = useState<string>("");

  useEffect(() => {
    if (props.initialImage) {
      setImgUrl(props.initialImage);
    }
  }, [props.initialImage]);

  useEffect(() => {
    props.onLoad(imgUrl);
  }, [imgUrl]);

  return (
    <div className="image-picker-wrap">
      {!props.readonlyMode && (
        <PrimaryButton
          disabled={props.disabled}
          onClick={() => {
            document.getElementById("avatar-input")?.click();
          }}
          text={props.text}
          iconProps={{ iconName: "Add" }}
        ></PrimaryButton>
      )}

      <input
        onChange={(e: any) => {
          let files = e.target.files;
          if (files.length > 0) {
            let image = files[0];

            // get image ref and preview
            let url = URL.createObjectURL(image);
            const img = new Image();
            img.src = url;
            img.onload = () => {
              // init canvas
              const canvas: any = document.createElement("canvas");
              var W = props.requestedResolution
                ? props.requestedResolution.width
                : 130;
              var H = props.requestedResolution
                ? props.requestedResolution.height
                : 130;

              let realWidth = img.naturalWidth;
              let realHeight = img.naturalHeight;

              if (props.requestedResolution?.native) {
                W = realWidth;
                H = realHeight;
              }

              canvas.width = W;
              canvas.height = H;

              // init canvas context
              const ctx = canvas.getContext("2d");
              if (ctx) {
                ctx.drawImage(img, 0, 0, W, H);

                var pngUrl = canvas.toDataURL(); // PNG is the default
                setImgUrl(pngUrl);
              }
            };
          }
        }}
        accept=".png, .jpg, .jpeg, .bmp"
        type="file"
        hidden
        id="avatar-input"
      />

      {imgUrl && (
        <ImageAssetLoader
          className="image-profile-preview"
          style={{
            width: props.requestedPreviewWidth
              ? props.requestedPreviewWidth
              : 200,
          }}
          src={imgUrl}
        />
      )}
    </div>
  );
};

export default ImagePicker;
