import "./LanguagePicker.scss";
import { useEffect, useState } from "react";
import Tr from "../../Utils/Translations/Translations";
import SettingsUtil from "../../Utils/Settings";
import ImageAssetLoader from "../ImageAssetLoader/ImageAssetLoader";

const flag_ita = "/Assets/Public/flag-ita.png";
const flag_eng = "/Assets/Public/flag-eng.png";
const flag_spa = "/Assets/Public/flag-spa.png";
const flag_ger = "/Assets/Public/flag-ger.png";
const flag_fra = "/Assets/Public/flag-fra.png";

export interface ILanguagePickerProps {}

const LanguagePicker = (props: ILanguagePickerProps) => {
  const [lang, setLang] = useState<string>(Tr.GetLanguage());
  const [targImg, setTargImg] = useState<string>("");

  useEffect(() => {
    if (lang === "it") {
      setTargImg(flag_ita);
    }
    if (lang === "en") {
      setTargImg(flag_eng);
    }
    if (lang === "es") {
      setTargImg(flag_spa);
    }
    if (lang === "de") {
      setTargImg(flag_ger);
    }
    if (lang === "fr") {
      setTargImg(flag_fra);
    }

    if (lang !== Tr.GetLanguage()) {
      SettingsUtil.SetSettings("language", lang);
      document.dispatchEvent(new Event("rerender-app"));
    }
  }, [lang]);

  return (
    <div className="language-picker-wrap">
      <ImageAssetLoader className="language-picker-image" src={targImg} />
      <select
        value={lang}
        onChange={(e: any) => {
          setLang(e.target.value);
        }}
        className="language-picker-select"
      >
        <option value={"it"}>{"Italiano"}</option>
        <option value={"en"}>{"English"}</option>
        <option value={"es"}>{"Español"}</option>
        <option value={"de"}>{"Deutsch"}</option>
        <option value={"fr"}>{"Français"}</option>
      </select>
    </div>
  );
};

export default LanguagePicker;
