export const Dictionary = {
  __registration_link_email: `
  <html>
    <head>
        <title>Bestätigen Sie Ihr Konto</title>
    </head>
    <body>
        <h2 style='background-color: #6197d6; padding: 0.5em; color: white; text-align: center;'>Kontobestätigung</h2>
        <div style='text-align: center'>
        <img src='$EMAIL_IMAGE' alt='image' title='image' style="display:block" width="450" height="450"/>
        <div style='padding: 2em;'>
          <div>Willkommen bei <strong>Plotter Studio</strong>!</div>
          <br />
          <div>Sie sind fast bereit, mit Ihren fantastischen Projekten zu beginnen.</div>
          <br />
          <div>Wir müssen nur noch Ihre Registrierung bestätigen, um Ihr Konto zu schützen.</div>
          <br />
          <div>Bitte klicken Sie auf den folgenden Link, um Ihr Konto zu aktivieren:</div>
          <br/>
          <div style='margin: 2em auto; background-color: #6197d6; padding: 0.5em; width: 15em;'>
              <a style='text-decoration: none;' href='$CURRENT_DOMAIN/confirmaccount?token=$TOKEN'>
                <div style='color: white;'>Konto bestätigen</div>
              </a>
          </div>
          <br />
          <br />
          <div style='font-size:0.8em'>
              Dies ist eine automatisierte Anfrage, bitte antworten Sie nicht direkt auf diese E-Mail. <br /> Um mehr über <strong>Plotter Studio</strong> zu erfahren, besuchen Sie unsere <a href='$CURRENT_DOMAIN/about'>Über uns</a> Seite.
          </div>
        </div>
    </body>
  </html>
`,
  __invite_user_email: `
<html>
  <head>
    <title>Einladung zur Zusammenarbeit</title>
  </head>
  <body>
    <h2 style='background-color: #6197d6; padding: 0.5em; color: white; text-align: center;'>Teamwork-Anfrage</h2>
    <div style='text-align: center'>
      <img src='$EMAIL_IMAGE' alt='image' title='image' style="display:block" width="450" height="450"/>
      <div style='padding: 2em;'>
        <div>Hallo,<br/><strong>$INVITERUSERNAME</strong> hat beschlossen, mit Ihnen an einem Schreibprojekt zu arbeiten.</div>
        <br />
        <div>Sie wurden eingeladen, einer Arbeitsgruppe bei Plotter Studio beizutreten!</div>
        <br />
        <div>Wenn Sie mehr wissen müssen, können Sie den Benutzer, der Sie eingeladen hat, per E-Mail kontaktieren: <strong>$INVITEREMAIL</strong> </div>
        <br/>
        <div style='margin: 2em auto; background-color: #6197d6; padding: 0.5em; width: 15em;'>
          <a style='text-decoration: none;' href='$CURRENT_DOMAIN/acceptinvitation?token=$TOKEN'>
            <div style='color: white;'>Einladung annehmen</div>
          </a>
        </div>
        <br />
        <br />
        <div style='font-size:0.8em'>
          Dies ist eine automatisierte Anfrage, bitte antworten Sie nicht direkt auf diese E-Mail. <br /> Um mehr über <strong>Plotter Studio</strong> zu erfahren, besuchen Sie unsere <a href='$CURRENT_DOMAIN/about'>Über uns</a> Seite.
        </div>
      </div>
    </div>
  </body>
</html>
`,
  __reset_password_link_email: `
<html>
  <head>
    <title>Passwort zurücksetzen</title>
  </head>
  <body>
    <h2 style='background-color: #6197d6; padding: 0.5em; color: white; text-align: center;'>Passwort zurücksetzen</h2>
    <div style='text-align: center'>
      <div style='padding: 2em;'>
        Grüße von <strong>Plotter Studio</strong>.<br /><br />Sie haben diese E-Mail erhalten, weil Sie Ihr Passwort zurücksetzen möchten. <br /><br />Bitte klicken Sie auf den folgenden Link, um Ihr Passwort zurückzusetzen:<br/><br/>
        <div style='margin: 2em auto; background-color: #6197d6; padding: 0.5em; width: 15em;'>
          <a style='text-decoration: none;' href='$CURRENT_DOMAIN/resetpasswordrequest?token=$TOKEN'>
            <div style='color: white;'>Passwort zurücksetzen</div>
          </a>
        </div>
        <br />
        <br />
        <div style='font-size:0.8em'>
          Dies ist eine automatisierte Anfrage, bitte antworten Sie nicht direkt auf diese E-Mail. <br /> Um mehr über <strong>Plotter Studio</strong> zu erfahren, besuchen Sie unsere <a href='$CURRENT_DOMAIN/about'>Über uns</a> Seite.
        </div>
      </div>
    </div>
  </body>
</html>
`,
};
