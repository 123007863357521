import { useEffect, useState } from "react";
import "./Pager.scss";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import Tr from "../../Utils/Translations/Translations";
import { Dropdown } from "@fluentui/react";
import { mobileCheck } from "../../App";

export interface IPagerProps {
  maxItems: number;
  onPaginate: (page: number, perPage: number) => void;
}

const PagerWideScreen = (props: IPagerProps) => {
  const [page, setPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(10);

  let maxPage = Math.ceil(props.maxItems / perPage);
  let lowerLimit = page * perPage + 1;
  let upperLimit = (page + 1) * perPage;

  if (lowerLimit > props.maxItems) {
    lowerLimit = props.maxItems;
  }
  if (upperLimit > props.maxItems) {
    upperLimit = props.maxItems;
  }

  useEffect(() => {
    let page_ = page;
    if (page_ > Math.floor(props.maxItems / perPage)) {
      page_ = Math.floor(props.maxItems / perPage);
      setPage(page_);
    }

    props.onPaginate(page_, perPage);
  }, [page, perPage]);

  return (
    <div className="pager-line">
      <CustomTooltip
        isButton
        iconName="ChevronLeft"
        disabled={page === 0}
        onClick={() => {
          setPage(page - 1);
        }}
        content={Tr.Translate("language", "table_rows_page_prev")}
      />
      <div className="pager-line-counter" style={{ whiteSpace: "pre" }}>
        {page + 1}
        {" / "}
        {maxPage}
      </div>
      <CustomTooltip
        isButton
        disabled={page === maxPage - 1}
        onClick={() => {
          setPage(page + 1);
        }}
        content={Tr.Translate("language", "table_rows_page_next")}
        iconName="ChevronRight"
      />
      <div className="pager-line-counter">
        {Tr.Translate("language", "table_rows")
          .replace("$LLIM", lowerLimit)
          .replace("$ULIM", upperLimit)
          .replace("$MLIM", props.maxItems)}
      </div>
      <div style={{ width: "0.5em" }}></div>
      {Tr.Translate("language", "table_rows_per_page")}
      <div>
        <Dropdown
          onChange={(e, o) => {
            if (o !== undefined) {
              setPerPage(+o.key);
            }
          }}
          selectedKey={perPage}
          options={[10, 20, 50, 100, 500, 1000].map((x: number) => {
            return { key: x, text: x.toString() };
          })}
        />
      </div>
    </div>
  );
};

const PagerMobile = (props: IPagerProps) => {
  const [page, setPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(10);

  let maxPage = Math.ceil(props.maxItems / perPage);
  let lowerLimit = page * perPage + 1;
  let upperLimit = (page + 1) * perPage;

  if (lowerLimit > props.maxItems) {
    lowerLimit = props.maxItems;
  }
  if (upperLimit > props.maxItems) {
    upperLimit = props.maxItems;
  }

  useEffect(() => {
    let page_ = page;
    if (page_ > Math.floor(props.maxItems / perPage)) {
      page_ = Math.floor(props.maxItems / perPage);
      setPage(page_);
    }

    props.onPaginate(page_, perPage);
  }, [page, perPage]);

  return (
    <div className="pager-line">
      <CustomTooltip
        isButton
        iconName="ChevronLeft"
        disabled={page === 0}
        onClick={() => {
          setPage(page - 1);
        }}
        content={Tr.Translate("language", "table_rows_page_prev")}
      />
      <div
        className="pager-line-counter"
        style={{ whiteSpace: "pre", fontSize: "0.7em" }}
      >
        {page + 1}
        {" / "}
        {maxPage}
      </div>
      <CustomTooltip
        isButton
        disabled={page === maxPage - 1}
        onClick={() => {
          setPage(page + 1);
        }}
        content={Tr.Translate("language", "table_rows_page_next")}
        iconName="ChevronRight"
      />
      <div className="pager-line-counter" style={{ fontSize: "0.7em" }}>
        {Tr.Translate("language", "table_rows")
          .replace("$LLIM", lowerLimit)
          .replace("$ULIM", upperLimit)
          .replace("$MLIM", props.maxItems)}
      </div>
      <div style={{ width: "0.5em" }}></div>
      <div>
        <div style={{ fontSize: "0.7em" }}>
          {Tr.Translate("language", "table_rows_per_page")}
        </div>
        <div>
          <Dropdown
            onChange={(e, o) => {
              if (o !== undefined) {
                setPerPage(+o.key);
              }
            }}
            selectedKey={perPage}
            options={[10, 20, 50, 100, 500, 1000].map((x: number) => {
              return { key: x, text: x.toString() };
            })}
          />
        </div>
      </div>
    </div>
  );
};

const Pager = (props: IPagerProps) => {
  const isMobile = mobileCheck();
  return (
    <div>
      {isMobile && <PagerMobile {...props} />}
      {!isMobile && <PagerWideScreen {...props} />}
    </div>
  );
};

export default Pager;
