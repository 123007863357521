import { useState } from "react";
import { mobileCheck } from "../../App";
import CenterPad from "../../Components/CenterPad/CenterPad";
import Loadable from "../../Components/Loadable/Loadable";
import MultiForm from "../../Components/MultiForm/MultiForm";
import Tr from "../../Utils/Translations/Translations";
import "./ResetPasswordPage.scss";
import { ToastMessage } from "../../Utils/UIMessages";
import { Icon } from "@fluentui/react";
import { AppRoutes } from "../../AppRoutes";
import { Link, useSearchParams } from "react-router-dom";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import Generic from "../../Utils/Generic";

const ResetPasswordPage = () => {
  const [params] = useSearchParams();
  const [done, setDone] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const token = params.get("token");

  const sendResetPassword = (password: string, passwordRepeat: string) => {
    if (token) {
      if (password != passwordRepeat) {
        ToastMessage("warning", Tr.Translate("language", "password_mismatch"));
        return;
      }
      setLoading(true);
      ApiService.SessionController.UpdatePassword(
        password,
        token,
        (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              "success",
              Tr.Translate("language", "password_reset_complete")
            );
            setDone(true);
          } else {
            setError(true);
          }
          setLoading(false);
        }
      );
    }
  };

  return (
    <div
      className="password-reset-page-wrap"
      style={Generic.GetRandomBackgroundUrlCss()}
    >
      <CenterPad
        content={
          <Loadable
            isLoading={loading}
            content={
              <div>
                {!done && !error && (
                  <div>
                    <MultiForm
                      autoButton={Tr.Translate("language", "demnd")}
                      formUniqueId="resetPassword"
                      inputs={[
                        {
                          label: Tr.Translate("language", "password"),
                          type: "password",
                          name: "password",
                          required: true,
                          extraParams: { maxLength: 50 },
                        },
                        {
                          label: Tr.Translate("language", "confirm_password"),
                          type: "password",
                          name: "passwordRepeat",
                          required: true,
                          extraParams: { maxLength: 50 },
                        },
                      ]}
                      onSubmit={(data: any) => {
                        sendResetPassword(data.password, data.passwordRepeat);
                      }}
                    />
                  </div>
                )}
                {done && (
                  <div>
                    <div className="password-reset-done-icon">
                      <div>{Tr.Translate("language", "done")}</div>
                      <Icon iconName="SkypeCircleCheck" />
                    </div>
                    <div className="password-reset-done-subtitle">
                      {Tr.Translate("language", "password_reset_complete")}
                    </div>
                  </div>
                )}
                {error && (
                  <div
                    className="password-reset-not-done-icon"
                    style={mobileCheck() ? { fontSize: "1.2em" } : {}}
                  >
                    <div>{Tr.Translate("system", "invalid_token")}</div>
                    <Icon iconName="StatusErrorFull" />
                  </div>
                )}
                <hr />
                <div className="flex-line">
                  <Link to={AppRoutes.LOGIN_PAGE}>
                    {Tr.Translate("language", "back")}
                  </Link>
                </div>
              </div>
            }
          />
        }
      />
    </div>
  );
};

export default ResetPasswordPage;
