import { useState } from "react";
import CenterPad from "../../Components/CenterPad/CenterPad";
import Loadable from "../../Components/Loadable/Loadable";
import MultiForm from "../../Components/MultiForm/MultiForm";
import Generic from "../../Utils/Generic";
import Tr from "../../Utils/Translations/Translations";
import "./ContactUsPage.scss";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../AppRoutes";
import { Icon } from "@fluentui/react";
import { IFeedbackInsert } from "../../Models/IFeedback";
import ApiService from "../../Services/ApiService";
import { ToastMessage } from "../../Utils/UIMessages";
import { IAPIResponse } from "../../Services/AjaxService";
import { IContactUs } from "../../Models/IContactUs";

const ContactUsPage = () => {
  const [done, setDone] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});

  const sendFeedback = (data: any) => {
    setFormData(data);
    setLoading(true);
    let feedback: IContactUs = {
      Email: data.email,
      Message: data.message,
      Language: Tr.GetLanguage(),
    };
    ApiService.SysController.AddContactUs(
      feedback,
      (response: IAPIResponse) => {
        if (response.error == null) {
          ToastMessage(
            "success",
            Tr.Translate("language", "action_completed_successfully")
          );
          setDone(true);
        } else {
          ToastMessage("error", Tr.Translate("system", "generic_api_failure"));
        }
        setLoading(false);
      }
    );
  };

  return (
    <div
      className="contact-us-page-wrap"
      style={Generic.GetRandomBackgroundUrlCss()}
    >
      <CenterPad
        content={
          <Loadable
            isLoading={loading}
            content={
              <div className="registration-inner-frame">
                {!done && (
                  <MultiForm
                    autoButton={Tr.Translate("presentation", "presentation_13")}
                    formUniqueId="loginForm"
                    onSubmit={sendFeedback}
                    inputs={[
                      {
                        type: "email",
                        name: "email",
                        currentValue: formData?.email,
                        label: Tr.Translate("language", "email"),
                        required: true,
                        extraParams: { maxLength: 50 },
                      },
                      {
                        type: "multiline",
                        name: "message",
                        currentValue: formData?.message,
                        label: Tr.Translate("language", "message"),
                        required: true,
                        extraParams: { maxLength: 1000 },
                      },
                    ]}
                  />
                )}
                {done && (
                  <div className="contact-us-done-icon">
                    <div>{Tr.Translate("language", "done")}</div>
                    <Icon iconName="SkypeCircleCheck" />
                  </div>
                )}
                <hr />
                <div className="flex-line">
                  <Link to={AppRoutes.HOME_PAGE}>
                    {Tr.Translate("language", "back")}
                  </Link>
                </div>
              </div>
            }
          />
        }
      />
    </div>
  );
};

export default ContactUsPage;
