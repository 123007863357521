import { AppRoutes } from "./AppRoutes";
import AboutPage from "./Pages/AboutPage/AboutPage";
import AcceptInvitationPage from "./Pages/AcceptInvitationPage/AcceptInvitationPage";
import ContactUsPage from "./Pages/ContactUsPage/ContactUsPage";
import EmailConfirmationPage from "./Pages/EmailConfirmationPage/EmailConfirmationPage";
import EmailDevBuilder from "./Pages/EmailDevBuilder/EmailDevBuilder";
import EventPage from "./Pages/EventPage/EventPage";
import ForgotPasswordPage from "./Pages/ForgotPasswordPage/ForgotPasswordPage";
import HomePage from "./Pages/HomePage/HomePage";
import LoginPage from "./Pages/LoginPage/LoginPage";
import MyPurchasesPage from "./Pages/MyPurchasesPage/MyPurchasesPage";
import NotFoundPage from "./Pages/NotFoundPage/NotFoundPage";
import RegistrationPage from "./Pages/RegistrationPage/RegistrationPage";
import ResendEmailPage from "./Pages/ResendEmail/ResendEmailPage";
import ResetPasswordPage from "./Pages/ResetPasswordPage/ResetPasswordPage";
import SettingsPage from "./Pages/SettingsPage/SettingsPage";
import StorePage from "./Pages/StorePage/StorePage";
import TestPage from "./Pages/TestPage/TestPage";
import WorkGroupPickerPage from "./Pages/WorkGroupPickerPage/WorkGroupPickerPage";

export interface IAppRoute {
  path: string;
  element: JSX.Element;
  requiresLogin?: boolean;
}

export const AppRoutesMap: IAppRoute[] = [
  {
    path: AppRoutes.HOME_PAGE,
    element: <HomePage />,
  },
  {
    path: AppRoutes.LOGIN_PAGE,
    element: <LoginPage />,
  },
  {
    path: AppRoutes.FORGOT_PASSWORD,
    element: <ForgotPasswordPage />,
  },
  {
    path: AppRoutes.REGISTRATION_PAGE,
    element: <RegistrationPage />,
  },
  {
    path: AppRoutes.CONTACT_US_PAGE,
    element: <ContactUsPage />,
  },
  {
    path: AppRoutes.RESEND_EMAIL_CONFIRMATION_PAGE,
    element: <ResendEmailPage />,
  },
  {
    path: AppRoutes.EMAIL_CONFIRMATION_PAGE,
    element: <EmailConfirmationPage />,
  },
  {
    path: AppRoutes.WORKGROUP_PICK_PAGE,
    element: <WorkGroupPickerPage />,
    requiresLogin: true,
  },
  {
    path: AppRoutes.STORE_PAGE,
    element: <StorePage />,
    requiresLogin: true,
  },
  {
    path: AppRoutes.MY_PURCHASES_PAGE,
    element: <MyPurchasesPage />,
    requiresLogin: true,
  },
  {
    path: AppRoutes.EVENT_MAP_PAGE,
    element: <EventPage />,
    requiresLogin: true,
  },
  {
    path: AppRoutes.SETTINGS_PAGE,
    element: <SettingsPage />,
    requiresLogin: true,
  },
  {
    path: AppRoutes.ABOUT_PAGE,
    element: <AboutPage />,
  },
  {
    path: AppRoutes.ACCEPT_INVITATION_PAGE,
    element: <AcceptInvitationPage />,
  },
  {
    path: AppRoutes.RESET_PASSWORD,
    element: <ResetPasswordPage />,
  },
  {
    path: AppRoutes.DEVEMAIL_BUILDER_PAGE,
    element: <EmailDevBuilder />,
  },
  {
    path: AppRoutes.TEST_PAGE,
    element: <TestPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];
