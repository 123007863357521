import {
  IBasePickerSuggestionsProps,
  IDropdownOption,
  ITag,
  ITagPickerProps,
  IconButton,
  Label,
  TagPicker,
} from "@fluentui/react";
import "./BetterTagPicker.scss";
import { useEffect, useId, useState } from "react";
import Tools from "../../Utils/Tools";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import Tr from "../../Utils/Translations/Translations";
const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: "Suggested colors",
  noResultsFoundText: "No color tags found",
};
export interface IBetterTagPickerProps {
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  currentValue?: string;
  onRenderLabel: (el: any) => JSX.Element;
  suggestions: IDropdownOption[];
  onChange: (dataCommaList: string | undefined) => void;
}

const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false;
  }
  return tagList.some((compareTag) => compareTag.key === tag.key);
};

const resolveSuggestions = (
  filterText: string,
  tagList: ITag[] | undefined,
  options: ITag[]
): ITag[] => {
  let list: ITag[] = options.filter(
    (tag: ITag) =>
      tag.name.toLowerCase().includes(filterText.toLowerCase()) &&
      !listContainsTagList(tag, tagList)
  );

  if (!list.map((x: ITag) => x.name).includes(filterText)) {
    list.unshift({ key: filterText, name: filterText });
  }

  return list;
};

interface ISelectionPickerItemProps {
  value: ITag;
  onDelete: () => void;
}

const SelectionPickerItem = (props: ISelectionPickerItemProps) => {
  return (
    <div className="selection-picker-slot-wrap-outer">
      <div className="selection-picker-slot-wrap">
        <div className="selection-picker-slot-name">{props.value.name}</div>
        <CustomTooltip
          onClick={props.onDelete}
          isButton
          className="selection-picker-slot-button"
          content={Tr.Translate("language", "delete")}
          iconName="Delete"
        />
      </div>
    </div>
  );
};

const BetterTagPicker = (props: IBetterTagPickerProps) => {
  const pickerId = useId();
  const [searchText, setSearchText] = useState<string>("");
  const [options, setOptions] = useState<ITag[]>([]);
  const [selectedItems, setSelectedItems] = useState<ITag[]>([]);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const closeMenu = () => {
    setShowMenu(false);
  };

  const textToTag = (text: string) => {
    if (text) {
      return text[0].toUpperCase() + text.slice(1);
    }
    return "";
  };

  useEffect(() => {
    if (props.suggestions.length > 0) {
      setOptions(
        props.suggestions.map((x: IDropdownOption) => {
          return {
            key: x.key,
            name: textToTag(x.text),
          };
        })
      );
    }

    if (props.currentValue && props.currentValue !== "") {
      let splres: string[] = props.currentValue.split(",").map((x) => x.trim());
      setSelectedItems(
        splres.map((x: string) => {
          return { key: x, name: textToTag(x) };
        })
      );
    }

    window.addEventListener("click", closeMenu);
    return () => {
      window.removeEventListener("click", closeMenu);
    };
  }, []);

  const computeValue = () => {
    let result = selectedItems
      .map((x: ITag) => x.name.replaceAll(",", ";"))
      .join(",");
    return result !== "" ? result : undefined;
  };

  useEffect(() => {
    props.onChange(computeValue());
  }, [selectedItems]);

  const searchResults = resolveSuggestions(searchText, selectedItems, options);

  return (
    <div style={{ position: "relative" }}>
      {props.label && (
        <div>
          {props.onRenderLabel(
            <Label htmlFor={pickerId} required={props.required}>
              {props.label}
            </Label>
          )}
        </div>
      )}
      <input type="hidden" value={computeValue()} required={props.required} />

      <div className="input-mimic-fluent-box">
        <div>
          {selectedItems.map((x: ITag, i: number) => {
            return (
              <SelectionPickerItem
                key={i}
                value={x}
                onDelete={() => {
                  setSelectedItems(
                    [...selectedItems].filter((t: ITag) => t.key != x.key)
                  );
                }}
              />
            );
          })}
        </div>
        <input
          disabled={props.disabled}
          className="picker-invisible-div-content"
          value={searchText}
          placeholder={props.placeholder}
          onChange={(e: any) => {
            setShowMenu(true);
            setSearchText(e.target.value);
          }}
        ></input>
        {showMenu && searchText !== "" && (
          <div className="picker-selection-menu">
            <div className="result-popup-title">{props.label ?? "Tags"}</div>
            {searchResults.length === 0 && (
              <div className="no-results-picker-slot">{"-"}</div>
            )}
            <div className="picker-selectin-menu-inner">
              {searchResults.map((x: ITag, i: number) => {
                return (
                  <div
                    className="picker-option"
                    key={i}
                    onClick={() => {
                      let items_ = [...selectedItems];
                      items_.push(x);
                      setSelectedItems(items_);
                      setSearchText("");
                    }}
                  >
                    {x.name}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BetterTagPicker;
