import { useState } from "react";
import "./EmailDevBuilder.scss";
import Tools from "../../Utils/Tools";

const EmailDevBuilder = () => {
  const [content, setContent] = useState<string>("");

  return (
    <div style={{ padding: "2em" }}>
      <textarea
        onChange={(e) => {
          setContent(e.target.value);
        }}
      ></textarea>
      <hr />
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
    </div>
  );
};

export default EmailDevBuilder;
