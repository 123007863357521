import { useEffect, useState } from "react";
import "./ChatPollingHandler.scss";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../Redux/RootReducer";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import { ChatActions } from "../../Redux/Chat/ChatAction";
import { IWorkgroupChatNotRead } from "../../Models/IWorkgroupChat";
import { Howl } from "howler";

export interface IChatPollingHandlerProps {}

export const fromActivityLevelToDelay = (activityLevel: number) => {
  /**
   * Activity Level 0 = nobody is writing recently
   * Activity Level > 0 = a person sent a message
   * Activity Level > 10 = you wrote a message
   *
   * activity level will decrease over time to 0
   * each poll
   */

  if (activityLevel > 10) {
    return 4000; // delay short
  }
  if (activityLevel > 0) {
    return 7000; // delay medium
  }
  return 20000; // default delay long
};

var activityLevelClone: number = 0;
var activeWorkgroupIdClone: number = 0;
var workgroupChatsNotReadClone: IWorkgroupChatNotRead[] = [];

const ChatPollingHandler = (props: IChatPollingHandlerProps) => {
  const dispatch = useDispatch();
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const activeWorkgroup = useSelector(
    (state: GlobalState) => state.generic.activeWorkgroup
  );
  const activityLevel = useSelector(
    (state: GlobalState) => state.chat.activityLevel
  );
  const workgroupChatsNotRead = useSelector(
    (state: GlobalState) => state.chat.workgroupChatsNotRead
  );

  const playSound = () => {
    const sound = new Howl({
      src: ["chat-blip.mp3"],
      volume: 0.5,
    });
    sound.play();
  };

  const detectSomeoneWrote = (
    newWorkgroupChatNotRead: IWorkgroupChatNotRead[]
  ) => {
    let result = false;
    newWorkgroupChatNotRead.forEach((newChat: IWorkgroupChatNotRead) => {
      let lastChat = workgroupChatsNotReadClone.find(
        (chat) => chat.BookId === newChat.BookId
      );

      if ((lastChat?.UnreadCount ?? 0) < newChat.UnreadCount) {
        result = true;
      }
    });

    if (result) {
      playSound();
    }

    return result;
  };

  const loadUnreadMessages = async () => {
    if (activeWorkgroupIdClone) {
      ApiService.WorkgroupChatController.GetWorkgroupChatsNotRead(
        activeWorkgroupIdClone ?? 0,
        (response: IAPIResponse) => {
          if (response.error === null) {
            let someoneWrote = detectSomeoneWrote(response.parsed);
            if (someoneWrote) {
              // every time you detect a new unread message,
              // increase the activity level
              dispatch(
                ChatActions.SetWorkgroupChatActivityLevel(
                  activityLevelClone + 2
                )
              );
            } else {
              dispatch(
                ChatActions.SetWorkgroupChatActivityLevel(
                  activityLevelClone - 1
                )
              );
            }

            dispatch(ChatActions.SetWorkgroupChatNotRead(response.parsed));
          }
        }
      );
    }

    setTimer(
      setTimeout(() => {
        loadUnreadMessages();
      }, fromActivityLevelToDelay(activityLevelClone))
    );
  };

  useEffect(() => {
    activityLevelClone = activityLevel;
    activeWorkgroupIdClone = activeWorkgroup?.Id ?? 0;
    workgroupChatsNotReadClone = workgroupChatsNotRead;
  }, [activityLevel, activeWorkgroup, workgroupChatsNotRead]);

  useEffect(() => {
    loadUnreadMessages();
    document.addEventListener("visibilitychange", loadUnreadMessages);
    return () => {
      document.removeEventListener("visibilitychange", loadUnreadMessages);
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return <div></div>;
};

export default ChatPollingHandler;
