import { Link, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../AppRoutes";
import Tr from "../../Utils/Translations/Translations";
import "./HomePage.scss";
import CustomTooltip from "../../Components/CustomTooltip/CustomTooltip";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { mobileCheck } from "../../App";
import StorePage from "../StorePage/StorePage";
import Footer from "../../Components/Footer/Footer";
import { useEffect } from "react";
import SettingsUtil from "../../Utils/Settings";
import ImageAssetLoader from "../../Components/ImageAssetLoader/ImageAssetLoader";

const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      SettingsUtil.GetSettings("remember_me") &&
      localStorage.getItem("do-login-automatic") !== "2"
    ) {
      localStorage.setItem("do-login-automatic", "1");
      navigate(AppRoutes.LOGIN_PAGE);
    }
  }, []);

  return (
    <div
      className="home-page-wrap"
      style={mobileCheck() ? { fontSize: "0.8em" } : {}}
    >
      <div className="home-page-banner-line">
        <div className="home-page-text-big">
          {Tr.Translate("presentation", "presentation_1")}
        </div>
        <div className="home-page-text-normal">
          {Tr.Translate("presentation", "presentation_2")}
        </div>
      </div>
      <div className="home-page-huge-title">
        {Tr.Translate("presentation", "presentation_3")}
      </div>

      <div className="home-page-tile-row">
        <div style={{ width: "fit-content" }}>
          <div
            className={
              mobileCheck()
                ? "home-page-tile-wrap-mobile"
                : "home-page-tile-wrap"
            }
          >
            <div className="home-page-tile">
              <div className="home-page-tile-background">
                <ImageAssetLoader src={"/Assets/Public/space_travel.gif"} />
              </div>
              <div style={{ padding: "1em" }}>
                <div className="home-page-tile-icon">
                  <ImageAssetLoader src={"/Assets/Public/timeline_ico_2.png"} />
                </div>
                <div className="home-page-tile-title">
                  {Tr.Translate("presentation", "presentation_4")}
                </div>
                <div className="home-page-tile-description">
                  {Tr.Translate("presentation", "presentation_5")}
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              mobileCheck()
                ? "home-page-tile-wrap-mobile"
                : "home-page-tile-wrap"
            }
          >
            <div className="home-page-tile">
              <div className="home-page-tile-background">
                <ImageAssetLoader src={"/Assets/Public/space_travel.gif"} />
              </div>
              <div style={{ padding: "1em" }}>
                <div className="home-page-tile-icon">
                  <ImageAssetLoader src={"/Assets/Public/ai_icon.png"} />
                </div>
                <div className="home-page-tile-title">
                  {Tr.Translate("presentation", "presentation_6")}
                </div>
                <div className="home-page-tile-description">
                  {Tr.Translate("presentation", "presentation_7")}
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              mobileCheck()
                ? "home-page-tile-wrap-mobile"
                : "home-page-tile-wrap"
            }
          >
            <div className="home-page-tile">
              <div className="home-page-tile-background">
                <ImageAssetLoader src={"/Assets/Public/space_travel.gif"} />
              </div>
              <div style={{ padding: "1em" }}>
                <div className="home-page-tile-icon">
                  <ImageAssetLoader src={"/Assets/Public/teamwork_2.png"} />
                </div>
                <div className="home-page-tile-title">
                  {Tr.Translate("presentation", "presentation_8")}
                </div>
                <div className="home-page-tile-description">
                  {Tr.Translate("presentation", "presentation_9")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-page-huge-title">
        {Tr.Translate("presentation", "presentation_10")}
      </div>
      <div className="home-page-min-title">
        {Tr.Translate("presentation", "presentation_11")}
      </div>
      <div className="home-page-banner-line">
        <div className="home-page-text-big">
          {Tr.Translate("presentation", "presentation_12")}
        </div>
        <div className="home-page-text-normal">
          {Tr.Translate("presentation", "presentation_12_2").trim() + ":"}

          <div className="home-page-center-big-button">
            <DefaultButton
              iconProps={{ iconName: "Message" }}
              text={Tr.Translate("presentation", "presentation_13")}
              onClick={() => {
                navigate(AppRoutes.CONTACT_US_PAGE);
              }}
            />
          </div>
        </div>
        <div className="home-page-text-normal">
          {Tr.Translate("presentation", "presentation_14").trim() + ":"}
          <br />
          <div className="home-page-center-big-button">
            <DefaultButton
              text={Tr.Translate("language", "login")}
              onClick={() => {
                navigate(AppRoutes.LOGIN_PAGE);
              }}
            />
          </div>
        </div>
      </div>
      <div className="home-page-huge-title">
        {Tr.Translate("presentation", "presentation_15")}
      </div>
      <div className="home-page-min-title">
        {Tr.Translate("presentation", "presentation_16")}
      </div>
      <StorePage onlyPreview />
      <Footer />
    </div>
  );
};

export default HomePage;

//       <div className="home-page-huge-title">Event driven plot writing</div>
