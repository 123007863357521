export const Dictionary = {
  __metadata_type_class_family: "Famiglia",
  __metadata_type_class_beliefs: "Convinzioni",
  __metadata_type_social_group_class_personal: "Sulla persona",

  __metadata_type_family_father_positive: "Famiglia - Padre (Amorevole)",
  __metadata_type_family_mother_positive: "Famiglia - Madre (Amorevole)",
  __metadata_type_family_father_negative: "Famiglia - Padre (Non amorevole)",
  __metadata_type_family_mother_negative: "Famiglia - Madre (Non amorevole)",
  __metadata_type_family_step_father_positive:
    "Famiglia - Padre Adottivo (Amorevole)",
  __metadata_type_family_step_mother_positive:
    "Famiglia - Madre Adottiva (Amorevole)",
  __metadata_type_family_step_father_negative:
    "Famiglia - Padre Adottivo (Non amorevole)",
  __metadata_type_family_step_mother_negative:
    "Famiglia - Madre Adottiva (Non amorevole)",

  __metadata_type_family_child_negative: "Figlio(a) (Non desiderato(a))",
  __metadata_type_family_child_positive: "Figlio(a) (Desiderato(a))",
  __metadata_type_family_sibling_negative: "Fratello/Sorella (Non amichevole)",
  __metadata_type_family_sibling_positive: "Frattello/Sorella amichevole)",

  __metadata_type_religion_pregiudice_negative:
    "Pregiudizio religioso negativo",
  __metadata_type_religion_pregiudice_positive:
    "Pregiudizio religioso positivo",
  __metadata_type_religion_pregiudice_negative_aggressive:
    "Pregiudizio religioso negativo - Aggressivo",

  __metadata_type_race_pregiudice_negative: "Pregiudizio raziale negativo",
  __metadata_type_race_pregiudice_positive: "Pregiudizio raziale positivo",
  __metadata_type_race_pregiudice_negative_aggressive:
    "Pregiudizio raziale negativo - Aggressivo",

  __metadata_type_ethinicity_pregiudice_positive: "Pregiuizio etnico positivo",
  __metadata_type_ethinicity_pregiudice_negative: "Pregiudizio etnico negativo",
  __metadata_type_ethinicity_pregiudice_negative_aggressive:
    "Pregiudizio etnico negativo - Aggressivo",
  __metadata_type_sex_pregiudice_positive: "Pregiudizio sessuale positivo",
  __metadata_type_sex_pregiudice_negative: "Prejudice sessuale negativo",
  __metadata_type_sex_pregiudice_negative_aggressive:
    "Prejudice sessuale negativo - Aggressivo",

  __metadata_type_social_group_religion_affiliation: "Affiliazione religiosa",
  __metadata_type_social_group_personal_ethnicity: "Etnia",
  __metadata_type_social_group_personal_sex: "Sesso",
  __metadata_type_social_group_personal_biological_race: "Specie biologica",

  __metadata_extradata_scientific_inclination: "Inclinazione scientifica",
  __metadata_extradata_military_inclination: "Inclinazione militare",
  __metadata_extradata_religious_inclination: "Inclinazione religiosa",
  __metadata_extradata_political_inclination: "Inclinazione politica",
  __metadata_extradata_economic_inclination: "Inclinazione economica",
  __metadata_extradata_social_inclination: "Inclinazione sociale",
  __metadata_extradata_cultural_inclination: "Inclinazione culturale",
  __metadata_extradata_environmental_inclination: "Inclinazione ambientale",
  __metadata_extradata_spiritual_inclination: "Inclinazione spirituale",
  __metadata_extradata_educational_inclination: "Inclinazione educativa",
  __metadata_extradata_athletic_inclination: "Inclinazione atletica",
  __metadata_extradata_artistic_inclination: "Inclinazione artistica",
  __metadata_extradata_musical_inclination: "Inclinazione musicale",
  __metadata_extradata_romantic_inclination: "Inclinazione romantica",
  __metadata_extradata_familial_inclination: "Inclinazione familiare",
  __metadata_extradata_friendship_inclination: "Inclinazione amicizia",
  __metadata_extradata_professional_inclination: "Inclinazione professionale",
  __metadata_extradata_violence_inclination: "Inclinazione alla violenza",

  __metadata_objective_of_character_encounter: "Incontrare qualcuno",
  __metadata_objective_of_character_marry: "Sposare qualcuno",
  __metadata_objective_of_character_reach_position: "Raggiungere il luogo",
  __metadata_objective_of_character_obtain_object: "Ottenere l'oggetto",
  __metadata_objective_of_character_kill: "Uccidere qualcuno",
  __metadata_objective_of_character_learn: "Apprendere abilità o nozione",
  __metadata_objective_of_character_live_event:
    "Trovarsi in un evento o situazione",
};
