export const Dictionary = {
  __metadata_type_class_family: "Family",
  __metadata_type_class_beliefs: "Beliefs",
  __metadata_type_social_group_class_personal: "Personal",

  __metadata_type_family_father_positive: "Family - Father (Loving)",
  __metadata_type_family_mother_positive: "Family - Mother (Loving)",
  __metadata_type_family_father_negative: "Family - Father (Not loving)",
  __metadata_type_family_mother_negative: "Family - Mother (Not loving)",
  __metadata_type_family_step_father_positive: "Family - Step Father (Loving)",
  __metadata_type_family_step_mother_positive: "Family - Step Mother (Loving)",
  __metadata_type_family_step_father_negative:
    "Family - Step Father (Not loving)",
  __metadata_type_family_step_mother_negative:
    "Family - Step Mother (Not loving)",

  __metadata_type_family_child_negative: "Family - Child (Unwanted)",
  __metadata_type_family_child_positive: "Family - Child (Wanted)",
  __metadata_type_family_sibling_negative: "Family - Sibling (Unfriendly)",
  __metadata_type_family_sibling_positive: "Family - Sibling (Friendly)",

  __metadata_type_religion_pregiudice_negative: "Negative religious prejudice",
  __metadata_type_religion_pregiudice_positive: "Positive religious prejudice",
  __metadata_type_religion_pregiudice_negative_aggressive:
    "Negative religious prejudice - Aggressive",

  __metadata_type_race_pregiudice_negative: "Negative racial prejudice",
  __metadata_type_race_pregiudice_positive: "Positive racial prejudice",
  __metadata_type_race_pregiudice_negative_aggressive:
    "Negative racial prejudice - Aggressive",

  __metadata_type_ethinicity_pregiudice_positive: "Positive ethnic prejudice",
  __metadata_type_ethinicity_pregiudice_negative: "Negative ethnic prejudice",
  __metadata_type_ethinicity_pregiudice_negative_aggressive:
    "Negative ethnic prejudice - Aggressive",
  __metadata_type_sex_pregiudice_positive: "Positive sexual bias",
  __metadata_type_sex_pregiudice_negative: "Negative sexual prejudice",
  __metadata_type_sex_pregiudice_negative_aggressive:
    "Negative Sexual Prejudice - Aggressive",

  __metadata_type_social_group_religion_affiliation: "Religious affiliation",
  __metadata_type_social_group_personal_ethnicity: "Ethnicity",
  __metadata_type_social_group_personal_sex: "Sex",
  __metadata_type_social_group_personal_biological_race: "Biological race",

  __metadata_extradata_violence_inclination: "Violence inclination",
  __metadata_extradata_scientific_inclination: "Scientific inclination",
  __metadata_extradata_military_inclination: "Military inclination",
  __metadata_extradata_religious_inclination: "Religious inclination",
  __metadata_extradata_political_inclination: "Political inclination",
  __metadata_extradata_economic_inclination: "Economic inclination",
  __metadata_extradata_social_inclination: "Social inclination",
  __metadata_extradata_cultural_inclination: "Cultural inclination",
  __metadata_extradata_environmental_inclination: "Environmental inclination",
  __metadata_extradata_spiritual_inclination: "Spiritual inclination",
  __metadata_extradata_educational_inclination: "Educational inclination",
  __metadata_extradata_athletic_inclination: "Athletic inclination",
  __metadata_extradata_artistic_inclination: "Artistic inclination",
  __metadata_extradata_musical_inclination: "Musical inclination",
  __metadata_extradata_romantic_inclination: "Romantic inclination",
  __metadata_extradata_familial_inclination: "Familial inclination",
  __metadata_extradata_friendship_inclination: "Friendship inclination",
  __metadata_extradata_professional_inclination: "Professional inclination",

  __metadata_objective_of_character_encounter: "Meet someone",
  __metadata_objective_of_character_marry: "Marry someone",
  __metadata_objective_of_character_reach_position: "Reach the place",
  __metadata_objective_of_character_obtain_object: "Get the item",
  __metadata_objective_of_character_kill: "Kill someone",
  __metadata_objective_of_character_learn: "Learning a skill or concept",
  __metadata_objective_of_character_live_event:
    "Finding yourself in an event or situation",
};
