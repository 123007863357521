export const Dictionary = {
  __registration_link_email: `
  <html>
    <head>
        <title>Confirme su cuenta</title>
    </head>
    <body>
        <h2 style='background-color: #6197d6; padding: 0.5em; color: white; text-align: center;'>Confirmación de cuenta</h2>
        <div style='text-align: center'>
        <img src='$EMAIL_IMAGE' alt='image' title='image' style="display:block" width="450" height="450"/>
        <div style='padding: 2em;'>
          <div>¡Bienvenido a <strong>Plotter Studio</strong>!</div>
          <br />
          <div>Estás casi listo para comenzar a trabajar en tus proyectos fantásticos.</div>
          <br />
          <div>Solo necesitamos confirmar tu registro para proteger tu cuenta.</div>
          <br />
          <div>Por favor, haz clic en el siguiente enlace para activar tu cuenta:</div>
          <br/>
          <div style='margin: 2em auto; background-color: #6197d6; padding: 0.5em; width: 15em;'>
              <a style='text-decoration: none;' href='$CURRENT_DOMAIN/confirmaccount?token=$TOKEN'>
                <div style='color: white;'>Confirmar cuenta</div>
              </a>
          </div>
          <br />
          <br />
          <div style='font-size:0.8em'>
              Esta es una solicitud automatizada, por favor no respondas directamente a este correo electrónico. <br /> Para saber más sobre <strong>Plotter Studio</strong>, visita nuestro <a href='$CURRENT_DOMAIN/about'>acerca de</a>.
          </div>
        </div>
    </body>
  </html>
`,
  __invite_user_email: `
<html>
  <head>
    <title>Invitación a trabajar en equipo</title>
  </head>
  <body>
    <h2 style='background-color: #6197d6; padding: 0.5em; color: white; text-align: center;'>Solicitud de trabajo en equipo</h2>
    <div style='text-align: center'>
      <img src='$EMAIL_IMAGE' alt='image' title='image' style="display:block" width="450" height="450"/>
      <div style='padding: 2em;'>
        <div>Hola,<br/><strong>$INVITERUSERNAME</strong> ha decidido trabajar contigo en un proyecto de escritura.</div>
        <br />
        <div>¡Has sido invitado a unirte a un grupo de trabajo en Plotter Studio!</div>
        <br />
        <div>Si necesitas saber más, puedes contactar al usuario que te invitó por correo electrónico: <strong>$INVITEREMAIL</strong> </div>
        <br/>
        <div style='margin: 2em auto; background-color: #6197d6; padding: 0.5em; width: 15em;'>
          <a style='text-decoration: none;' href='$CURRENT_DOMAIN/acceptinvitation?token=$TOKEN'>
            <div style='color: white;'>Aceptar invitación</div>
          </a>
        </div>
        <br />
        <br />
        <div style='font-size:0.8em'>
          Esta es una solicitud automatizada, por favor no respondas directamente a este correo electrónico. <br /> Para saber más sobre <strong>Plotter Studio</strong>, visita nuestro <a href='$CURRENT_DOMAIN/about'>acerca de</a>.
        </div>
      </div>
    </div>
  </body>
</html>
`,
  __reset_password_link_email: `
<html>
  <head>
    <title>Restablecimiento de contraseña</title>
  </head>
  <body>
    <h2 style='background-color: #6197d6; padding: 0.5em; color: white; text-align: center;'>Restablecimiento de contraseña</h2>
    <div style='text-align: center'>
      <div style='padding: 2em;'>
        Saludos de <strong>Plotter Studio</strong>.<br /><br />Has recibido este correo electrónico porque has solicitado restablecer tu contraseña. <br /><br />Por favor, haz clic en el siguiente enlace para restablecer tu contraseña:<br/><br/>
        <div style='margin: 2em auto; background-color: #6197d6; padding: 0.5em; width: 15em;'>
          <a style='text-decoration: none;' href='$CURRENT_DOMAIN/resetpasswordrequest?token=$TOKEN'>
            <div style='color: white;'>Restablecer contraseña</div>
          </a>
        </div>
        <br />
        <br />
        <div style='font-size:0.8em'>
          Esta es una solicitud automatizada, por favor no respondas directamente a este correo electrónico. <br /> Para saber más sobre <strong>Plotter Studio</strong>, visita nuestro <a href='$CURRENT_DOMAIN/about'>acerca de</a>.
        </div>
      </div>
    </div>
  </body>
</html>
`,
};
