import { useEffect, useState } from "react";
import "./BookTimelineEditor.scss";
import { IBookTimeline } from "../../Models/IBookTimeline";
import {
  Checkbox,
  IconButton,
  MessageBar,
  MessageBarType,
  PrimaryButton,
} from "@fluentui/react";
import Tr from "../../Utils/Translations/Translations";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../Redux/RootReducer";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import Loadable from "../Loadable/Loadable";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import { ToastMessage } from "../../Utils/UIMessages";
import { IUser } from "../../Models/IUser";
import { IBook } from "../../Models/IBook";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import PrettyTable from "../PrettyTable/PrettyTable";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import BetterCoachMarks from "../BetterCoachMark/BetterCoachMark";
import RoleDependantDisplayer from "../RoleDependantDisplayer/RoleDependantDisplayer";

export interface IBookTimelineEditorProps {}

const BookTimelineEditor = (props: IBookTimelineEditorProps) => {
  const dispatch = useDispatch();
  const [editingTimeline, setEditingTimeline] = useState<IBookTimeline>();
  const [acceptDeleting, setAcceptDeleting] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const bookTimelines: IBookTimeline[] = useSelector(
    (state: GlobalState) => state.generic.bookTimelines
  );
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.generic.user
  );
  const activeBook: IBook | undefined = useSelector(
    (state: GlobalState) => state.generic.activeBook
  );

  const loadTimelines = () => {
    setLoading(true);
    ApiService.BookTimelineController.GetBookTimelines(
      undefined,
      activeBook?.Id ?? 0,
      (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(GenericActions.SetBookTimelines(response.parsed));
        }
        setLoading(false);
      }
    );
  };

  const newBookTimelines = (data: IBookTimeline) => {
    setProcessing(true);
    data = {
      ...data,
      OwnerId: loggedUser?.Id ?? 0,
      BookId: activeBook?.Id ?? 0,
    };
    ApiService.BookTimelineController.NewBookTimeline(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(
            "success",
            Tr.Translate("language", "timeline_add_done")
          );
          DismissModal("timelineEditorActionModal");
          loadTimelines();
        }
        setProcessing(false);
      }
    );
  };

  const editBookTimelines = (data: IBookTimeline) => {
    setProcessing(true);
    data = {
      ...editingTimeline,
      ...data,
    };
    ApiService.BookTimelineController.UpdateBookTimeline(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(
            "success",
            Tr.Translate("language", "timeline_edit_done")
          );
          DismissModal("timelineEditorActionModal");
          loadTimelines();
        }
        setProcessing(false);
      }
    );
  };

  const deleteBookTimelines = () => {
    setProcessing(true);
    ApiService.BookTimelineController.DeleteBookTimeline(
      editingTimeline?.Id ?? 0,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(
            "success",
            Tr.Translate("language", "timeline_delete_done")
          );
          DismissModal("timelineDeleteActionModal");
          loadTimelines();
        }
        setProcessing(false);
      }
    );
  };

  const renderTimelineTable = (timelines: IBookTimeline[]) => {
    let timelinesEdited: any[] = [...timelines];
    for (let i = 0; i < timelinesEdited.length; i++) {
      let t: any = { ...timelinesEdited[i] };
      if (i > 0) {
        t["PrevEnd"] =
          timelinesEdited[i - 1]["PrevEnd"] + timelines[i - 1].TimelineYearSpan;
      } else {
        t["PrevEnd"] = 1;
      }

      t["FinalYear"] = t["PrevEnd"] + t.TimelineYearSpan;

      timelinesEdited[i] = t;
    }
    return timelinesEdited;
  };

  useEffect(() => {
    loadTimelines();
  }, []);

  const timelineTable = renderTimelineTable(bookTimelines);

  return (
    <div className="timeline-editor-main-wrap">
      {bookTimelines.length === 0 && (
        <MessageBar messageBarType={MessageBarType.info}>
          {Tr.Translate("language", "notimeline_yet")}
        </MessageBar>
      )}

      <Loadable
        isLoading={loading}
        content={
          <PrettyTable
            extraToolbarItems={[
              <RoleDependantDisplayer
                whiteList={["admin", "contributor"]}
                element={
                  <BetterCoachMarks
                    coachMarkId="timelineEditorCoachMark"
                    coachMarckTitle={Tr.Translate(
                      "coaching",
                      "new_timeline_button"
                    )}
                    coachMarckContent={[
                      Tr.Translate("coaching", "new_timeline_button_desc"),
                      Tr.Translate("coaching", "new_timeline_button_more"),
                    ]}
                    suggestedPosition="right"
                    element={
                      <PrimaryButton
                        iconProps={{ iconName: "Add" }}
                        onClick={() => {
                          setEditing(false);
                          setEditingTimeline(undefined);
                          SummonModal("timelineEditorActionModal");
                        }}
                      />
                    }
                  />
                }
              />,
            ]}
            headers={[
              "",
              Tr.Translate("language", "timeline_name"),
              Tr.Translate("language", "timeline_begin_year"),
              Tr.Translate("language", "timeline_duration"),
              Tr.Translate("language", "timeline_end_year"),
              Tr.Translate("language", "timeline_center_year"),
              Tr.Translate("language", "timeline_order"),
            ]}
            plainRows={timelineTable}
            rows={timelineTable.map((x: any) => {
              return [
                <RoleDependantDisplayer
                  whiteList={["admin", "contributor"]}
                  element={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5em",
                      }}
                    >
                      <CustomTooltip
                        iconName="Edit"
                        content={Tr.Translate("language", "edit")}
                        isButton
                        onClick={() => {
                          setEditingTimeline(x);
                          setEditing(true);
                          SummonModal("timelineEditorActionModal");
                        }}
                      />
                      <CustomTooltip
                        iconName="Trash"
                        content={Tr.Translate("language", "delete")}
                        isButton
                        onClick={() => {
                          setEditingTimeline(x);
                          setEditing(false);
                          setAcceptDeleting(false);
                          SummonModal("timelineDeleteActionModal");
                        }}
                      />
                    </div>
                  }
                />,
                <div>{x.Title}</div>,
                <div>{x.PrevEnd}</div>,
                <div>{x.TimelineYearSpan}</div>,
                <div>{x.FinalYear}</div>,
                <div>{x.CenterYear}</div>,
                <div>{x.TimelineOrder}</div>,
              ];
            })}
          />
        }
      />

      <SmartModal
        title={
          editing
            ? Tr.Translate("language", "timeline_edit")
            : Tr.Translate("language", "timeline_add")
        }
        content={
          <Loadable
            isLoading={processing}
            content={
              <div>
                {editing && (
                  <MessageBar messageBarType={MessageBarType.info}>
                    {Tr.Translate(
                      "language",
                      "acknowledge_edittimeline_can_screw_up_things"
                    )}
                  </MessageBar>
                )}
                <MultiForm
                  formUniqueId="timelineEditorActionForm"
                  onSubmit={(data: any) => {
                    if (editing) {
                      editBookTimelines(data);
                    } else {
                      newBookTimelines(data);
                    }
                  }}
                  inputs={[
                    {
                      type: "text",
                      name: "Title",
                      required: true,
                      currentValue: editingTimeline?.Title,
                      label: Tr.Translate("language", "timeline_name"),
                    },
                    {
                      type: "number",
                      name: "TimelineYearSpan",
                      required: true,
                      currentValue: editingTimeline?.TimelineYearSpan,
                      extraParams: {
                        min: 1,
                        max: 100000,
                        underElement: (
                          <div className="under-input-hint">
                            {Tr.Translate("language", "timeline_duration_hint")}
                          </div>
                        ),
                      },
                      label: Tr.Translate("language", "timeline_duration"),
                    },
                    {
                      type: "number",
                      name: "CenterYear",
                      required: true,
                      currentValue: editingTimeline?.CenterYear,
                      extraParams: {
                        min: 1,
                        max: 100000,
                        underElement: (
                          <div className="under-input-hint">
                            {Tr.Translate(
                              "language",
                              "timeline_central_year_hint"
                            )}
                          </div>
                        ),
                      },
                      label: Tr.Translate("language", "timeline_center_year"),
                    },
                    {
                      type: "number",
                      name: "TimelineOrder",
                      required: true,
                      currentValue: editingTimeline?.TimelineOrder,
                      extraParams: {
                        min: 0,
                        max: bookTimelines.length + 1,
                        underElement: (
                          <div className="under-input-hint">
                            {Tr.Translate("language", "timeline_order_hint")}
                          </div>
                        ),
                      },
                      label: Tr.Translate("language", "timeline_order"),
                    },
                  ]}
                />
              </div>
            }
          />
        }
        modalUniqueId="timelineEditorActionModal"
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: processing,
            onClick: () => {
              DismissModal("timelineEditorActionModal");
            },
          },
          {
            text: Tr.Translate("language", "accept"),
            disabled: processing,
            onClick: () => {
              triggerFormValidation("timelineEditorActionForm");
            },
          },
        ]}
      />
      <SmartModal
        title={Tr.Translate("language", "timeline_delete")}
        modalUniqueId="timelineDeleteActionModal"
        content={
          <Loadable
            isLoading={processing}
            content={
              <div style={{ marginBottom: "1em" }}>
                <div>{Tr.Translate("language", "action_cannot_be_undone")}</div>
                <br />
                <Checkbox
                  checked={acceptDeleting}
                  onChange={(e, c) => {
                    if (c !== undefined) {
                      setAcceptDeleting(c);
                    }
                  }}
                  label={Tr.Translate(
                    "language",
                    "acknowledge_timeline_removal"
                  )}
                />
              </div>
            }
          />
        }
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: processing,
            onClick: () => {
              DismissModal("timelineDeleteActionModal");
            },
          },
          {
            text: Tr.Translate("language", "accept"),
            disabled: processing || !acceptDeleting,
            onClick: () => {
              deleteBookTimelines();
            },
          },
        ]}
      />
    </div>
  );
};

export default BookTimelineEditor;
