import { APIUrls } from "../../ApiUrls";
import { IFeedback, IFeedbackInsert } from "../../Models/IFeedback";
import { AjaxService, IAPIRequest, IAPIResponse } from "../AjaxService";

const FeedbackController = {
  GetFeedbacks: async (
    FeedbackId?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.FEEDBACK.GET_FEEDBACKS;
    if (FeedbackId) {
      request.url += "&FeedbackId=" + FeedbackId;
    }
    request.allowCache = true;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  NewFeedback: async (
    Feedback: IFeedbackInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.FEEDBACK.INSERT_FEEDBACK;
    request.method = "POST";
    request.payload = Feedback;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  UpdateFeedback: async (
    Feedback: IFeedback,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.FEEDBACK.UPDATE_FEEDBACK;
    request.method = "PUT";
    request.payload = Feedback;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  DeleteFeedback: async (
    FeedbackId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url =
      APIUrls.FEEDBACK.DELETE_FEEDBACK + "?FeedbackId=" + FeedbackId;
    request.method = "DELETE";
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
};

export default FeedbackController;
