export const Dictionary = {
  __event_class_add_type: "Personnage ajouté à l'histoire",
  __event_class_remove_type: "Personnage retiré de l'histoire",
  __event_class_affect_others_type:
    "Les actions d'un personnage en affectent un autre",
  __event_class_change_status_type: "Evolution du personnage",
  __event_class_plot_milestone_type: "Jalon du tracé",
  __event_class_strong_effects: "Déstabilisation extrême",
  __event_type_appear: "Apparaît",
  __event_type_born: "Est né",
  __event_type_revealed: "Est révélé",
  __event_type_abbandons: "Abandons",
  __event_type_dies: "Meurt",
  __event_type_kills: "Tue",
  __event_type_marries: "Se marie",
  __event_type_allies: "Alliance",
  __event_type_engage: "Engagez la romance",
  __event_type_learns_skill: "Apprend la compétence",
  __event_type_learns_secret: "Apprend le secret",
  __event_type_book_begins: "L'histoire commence",
  __event_type_holiday: "Vacances",
  __event_type_book_closure: "Fin du livre",
  __event_type_geopolitic: "Politique",
  __event_type_natural_disaster: "Catastrophe naturelle",
  __event_type_joins_faction: "Rejoint la faction",
  __event_type_leaves_faction: "Quitte la faction",
  __event_type_obtains_item: "Obtient l'objet",
  __event_type_shares_a_secret: "Partage un secret",
  __event_type_changes_id: "Change d'identité",
  __event_type_friendship: "Amitié",
  __event_type_friendship_end: "Fin de l'amitié",
  __event_type_engage_end: "Fin d'une relation amoureuse",
  __event_type_betrays: "Trahit",
  __event_type_assumes_status: "Acquiert le statut",
  __event_type_assumes_condition: "Acquiert une condition",
  __event_type_reaches_location: "Atteint l'emplacement",
  __event_type_teams_up_with: "Des équipes",
  __event_type_disappears: "s'est perdu",
  __event_type_leaves_location: "Emplacement des feuilles",
  __event_type_meets: "Se rencontre",
  __event_type_challenge: "Défi",
  __event_type_fight: "Combat",
  __event_type_starts_mission: "Commence la mission/le travail",
  __event_type_starts_mission_end: "Fin de la mission/du travail",
  __voucher_code: "Coupon",
  __event_type_falls_in_love: "Il tombe amoureux",
  __event_type_situation: "Situation",
  __event_type_mass_kill: "Destruction massive",
  __event_type_war: "Guerre",
  __event_type_rule_changer: "Changement de règle universelle",
  __event_type_uses_object: "Utilise un outil/un objet",
  __event_type_creates_or_builds: "Crée/construit",
};
