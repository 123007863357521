export const Dictionary = {
  __answer_label_data: "Am Datum $DATE",
  __answer_label_age:
    "$CHARNAME ist am $DATE geboren, das aktuelle Alter ist $AGE",
  __answer_label_age_plus_death:
    "$CHARNAME wurde am $DATE geboren und starb am $DATE im Alter von $AGE",
  __event_type_appear: "$CHARNAME erscheint am $DATE",
  __event_type_born: "$CHARNAME wurde am $DATE geboren",
  __event_type_revealed: "$CHARNAME wird am $DATE enthüllt",
  __event_type_disappears: "$CHARNAME verschwindet am $DATE",
  __event_type_changes_id:
    "$CHARNAME ändert seine bekannte Identität und wird am $DATE zu $OBJECT",
  __event_type_abbandons: "$CHARNAME gibt seine aktuelle Rolle am $DATE auf",
  __event_type_dies: "$CHARNAME stirbt am $DATE",
  __event_type_teams_up_with:
    "$CHARNAME verbündet sich am $DATE mit $OTHERCHARACTERS",
  __event_type_challenge:
    "$CHARNAME [mit $OPS_OTHERCHARACTERS] hat die Herausforderung $OBJECT am $DATE bestanden",
  __event_type_meets: "$CHARNAME trifft am $DATE auf $OTHERCHARACTERS",
  __event_type_friendship:
    "$CHARNAME hat sich am $DATE mit $OTHERCHARACTERS angefreundet",
  __event_type_friendship_end:
    "$CHARNAME hört am $DATE auf, mit $OTHERCHARACTERS befreundet zu sein",
  __event_type_engage:
    "$CHARNAME beginnt am $DATE eine Romanze mit $OTHERCHARACTERS",
  __event_type_engage_end:
    "$CHARNAME beendet die Romanze mit $OTHERCHARACTERS am $DATE",
  __event_type_betrays: "$CHARNAME verrät $OTHERCHARACTERS am $DATE",
  __event_type_shares_a_secret:
    "$CHARNAME teilt das Geheimnis von $OBJECT mit $OTHERCHARACTERS am $DATE",
  __event_type_kills: "$CHARNAME tötet $OBJECT am $DATE",
  __event_type_marries: "$CHARNAME heiratet am $DATE mit $OTHERCHARACTERS",
  __event_type_allies: "$CHARNAME verbündet sich am $DATE mit $OTHERCHARACTERS",
  __event_type_situation:
    "$CHARNAME steht am $DATE vor der $OBJECT-Situation [mit $OPS_OTHERCHARACTERS].",
  __event_type_falls_in_love:
    "$CHARNAME verliebt sich am $DATE in $OTHERCHARACTERS",
  __event_type_starts_mission:
    "$CHARNAME markiert den Job/die Mission $OBJECT am $DATE",
  __event_type_starts_mission_end:
    "$CHARNAME schließt den Job/die Mission $OBJECT am $DATE ab",
  __event_type_assumes_status:
    "$CHARNAME erhält am $DATE den Status von $OBJECT",
  __event_type_assumes_condition:
    "$CHARNAME übernimmt die Bedingung von $OBJECT am $DATE",
  __event_type_reaches_location:
    "$CHARNAME erreicht den Speicherort von $OBJECT am $DATE",
  __event_type_leaves_location:
    "$CHARNAME verlässt den Speicherort von $OBJECT am $DATE",
  __event_type_learns_skill:
    "$CHARNAME erlernt die Fähigkeit von $OBJECT am $DATE",
  __event_type_learns_secret: "$CHARNAME erfährt am $DATE etwas über $OBJECT",
  __event_type_joins_faction:
    "$CHARNAME tritt am $DATE der Fraktion $OBJECT bei",
  __event_type_leaves_faction:
    "$CHARNAME verlässt die Fraktion $OBJECT am $DATE",
  __event_type_obtains_item: "$CHARNAME erhält das Element $OBJECT am $DATE",
  __event_type_book_closure: "$CHARNAME sieht das Ende des Buches am $DATE",
  __event_type_holiday: "$CHARNAME jubelt über $OBJECT am $DATE",
  __event_type_book_begins: "$CHARNAME sieht den Anfang des Buches am $DATE",
  __event_type_geopolitic:
    "$CHARNAME verursacht den geopolitischen Effekt von $OBJECT auf $DATE",
  __event_type_natural_disaster:
    "$CHARNAME ist an der Katastrophe von $OBJECT am $DATE beteiligt",
  __event_type_war:
    "$CHARNAME hat am $DATE am Krieg [von $OPS_OBJECT] teilgenommen",
  __event_type_mass_kill:
    "$CHARNAME verursachte am $DATE eine massive Zerstörung [während $OPS_OBJECT].",
  __event_type_creates_or_builds:
    "$CHARNAME hat Folgendes erstellt/erstellt: $OBJECT am $DATE",
  __event_type_rule_changer:
    "$CHARNAME hat am $DATE eine kosmische Regel [von $OPS_OBJECT] geändert",
  __character_leaving_position_never_reached:
    "<strong>$CHARACTER</strong> hat den Ort <strong>$OBJECT</strong>, eine Position, die noch nie zuvor erreicht wurde, verlassen.",
  __character_cannot_marry_since_there_is_no_trust:
    "<strong>$CHARACTER</strong> kann nicht mit <strong>$OBJECT</strong> befreundet sein, da zwischen ihnen zu viel Feindseligkeit besteht.",
  __character_cannot_betray_since_there_is_no_trust:
    "<strong>$CHARACTER</strong> kann nicht spontan ein Geheimnis mit <strong>$OBJECT</strong> teilen, da zwischen ihnen zu viel Feindseligkeit besteht.",
  __active_character_cannot_interact_with_unknown_character:
    "<strong>$CHARACTER</strong> sollte nicht spontan ein Geheimnis mit <strong>$OBJECT</strong> teilen, da zwischen ihnen zu viel Feindseligkeit besteht.",
  __character_changing_id_to_unknown_alterego:
    "<strong>$CHARACTER</strong> kann nicht mit <strong>$OBJECT</strong> befreundet sein, da zwischen ihnen zu viel Feindseligkeit besteht.",
  __no_errors_to_report:
    "In dieser Kategorie wurden keine Fehler festgestellt.Gute Arbeit!",
  __character_leaving_position_never_reached_quick_fix:
    "Um dies zu beheben, muss der Charakter zunächst in einem vergangenen Ereignis mithilfe des Ereignisses EVMAP an den Zielort gebracht werden",
  __character_cannot_marry_since_there_is_no_trust_quick_fix:
    "Um dies zu beheben, muss der Charakter zunächst den Umfang der positiven Interaktion mit der verheirateten Person erhöhen. Durch positive Interaktionen zwischen Charakteren wird automatisch Vertrauen geschaffen. Wenn es sich bei diesem Problem um einen Nebencharakter mit reduzierten Hintergrundinformationen handelt, können Sie dies ignorieren.",
  __character_cannot_betray_since_there_is_no_trust_quick_fix:
    "Um dies zu beheben, muss der betrogene Charakter zunächst in der Lage sein, dem Verräter zu vertrauen. Vertrauen entsteht automatisch durch positive Interaktionen zwischen den Charakteren.",
  __active_character_cannot_interact_with_unknown_character_quick_fix:
    "Um dies zu beheben, müssen Sie zunächst den unbekannten Charakter aus einem vergangenen Ereignis zur Handlung hinzufügen. Ein Charakter wird automatisch zur Handlung hinzugefügt, wenn er in der Liste der beteiligten Charaktere eines Ereignisses erscheint.",
  __character_changing_id_to_unknown_alterego_quick_fix:
    "Um dies zu beheben, müssen Sie zunächst das unbekannte Alter Ego zur Handlung hinzufügen, indem Sie dieses Alter Ego als unabhängigen Charakter aus einem vergangenen Ereignis verwenden. Ein Charakter wird automatisch zur Handlung hinzugefügt, wenn er in der Liste der beteiligten Charaktere eines Ereignisses erscheint ",

  __character_befriending_disliked_person:
    "<strong>$CHARACTER</strong> kann nicht mit <strong>$OBJECT</strong> befreundet sein, da zwischen ihnen nicht genug Vertrauen besteht.",
  __character_befriending_disliked_person_quick_fix:
    "Um dieses Problem zu lösen, muss der Charakter, der sich anfreunden möchte, zunächst seine Abneigung gegenüber der anderen Person überwinden. Vertrauen wird automatisch durch positive Interaktionen zwischen Charakteren aufgebaut.",

  __character_sharing_secret_with_untrusted_person:
    "<strong>$CHARACTER</strong> sollte nicht spontan ein Geheimnis <strong>$OBJECT</strong> teilen, da zwischen ihnen nicht genug Vertrauen besteht.",

  __character_sharing_secret_with_untrusted_person_quick_fix:
    "Um dieses Problem zu lösen, muss der Charakter, der das Geheimnis teilen möchte, zunächst in der Lage sein, der anderen Person zu vertrauen. Vertrauen wird automatisch durch positive Interaktionen zwischen Charakteren aufgebaut.",

  __character_engaging_disliked_person:
    "<strong>$CHARACTER</strong> sollte sich noch nicht mit <strong>$OBJECT</strong> verloben, da sie sich zu wenig kennen.",
  __character_engaging_disliked_person_quick_fix:
    "Damit das Vertrauen zwischen den beiden wächst, muss der Charakter zunächst die Anzahl der positiven Interaktionen mit der verlobten Person erhöhen. Vertrauen wird automatisch durch positive Interaktionen zwischen den Charakteren aufgebaut.",
  __active_character_cannot_interact_with_unknown_character_to_him:
    "<strong>$CHARACTER</strong> sollte nicht auf <strong>$OBJECT</strong> reagieren, da sie sich noch nie getroffen haben.",
  __active_character_cannot_interact_with_unknown_character_to_him_quick_fix:
    "Um dieses Problem zu lösen, müssen Sie zuerst den unbekannten Charakter zur Handlung hinzufügen, indem Sie ein vergangenes Ereignis verwenden. Ein Charakter wird automatisch zur Handlung hinzugefügt, wenn er in der Liste der beteiligten Charaktere eines Ereignisses erscheint.",
};
