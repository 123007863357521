import SettingsUtil from "../Settings";
import { Dictionary as IT_Sys_Dictionary } from "./it/system";
import { Dictionary as IT_Lang_Dictionary } from "./it/language";
import { Dictionary as IT_Email_Dictionary } from "./it/emails";
import { Dictionary as IT_Events_Dictionary } from "./it/events";
import { Dictionary as IT_Presentation_Dictionary } from "./it/presentation";
import { Dictionary as IT_Subscription_Dictionary } from "./it/subscriptions";
import { Dictionary as IT_Bot_Dictionary } from "./it/bot";
import { Dictionary as IT_Coaching_Dictionary } from "./it/coaching";
import { Dictionary as IT_MetaData_Dictionary } from "./it/metadata";

import { Dictionary as EN_Sys_Dictionary } from "./en/system";
import { Dictionary as EN_Lang_Dictionary } from "./en/language";
import { Dictionary as EN_Email_Dictionary } from "./en/emails";
import { Dictionary as EN_Events_Dictionary } from "./en/events";
import { Dictionary as EN_Presentation_Dictionary } from "./en/presentation";
import { Dictionary as EN_Subscription_Dictionary } from "./en/subscriptions";
import { Dictionary as EN_Bot_Dictionary } from "./en/bot";
import { Dictionary as EN_Coaching_Dictionary } from "./en/coaching";
import { Dictionary as EN_MetaData_Dictionary } from "./en/metadata";

import { Dictionary as SP_Sys_Dictionary } from "./es/system";
import { Dictionary as SP_Lang_Dictionary } from "./es/language";
import { Dictionary as SP_Email_Dictionary } from "./es/emails";
import { Dictionary as SP_Events_Dictionary } from "./es/events";
import { Dictionary as SP_Presentation_Dictionary } from "./es/presentation";
import { Dictionary as SP_Subscription_Dictionary } from "./es/subscriptions";
import { Dictionary as SP_Bot_Dictionary } from "./es/bot";
import { Dictionary as SP_Coaching_Dictionary } from "./es/coaching";
import { Dictionary as SP_MetaData_Dictionary } from "./es/metadata";

import { Dictionary as DE_Sys_Dictionary } from "./de/system";
import { Dictionary as DE_Lang_Dictionary } from "./de/language";
import { Dictionary as DE_Email_Dictionary } from "./de/emails";
import { Dictionary as DE_Events_Dictionary } from "./de/events";
import { Dictionary as DE_Presentation_Dictionary } from "./de/presentation";
import { Dictionary as DE_Subscription_Dictionary } from "./de/subscriptions";
import { Dictionary as DE_Bot_Dictionary } from "./de/bot";
import { Dictionary as DE_Coaching_Dictionary } from "./de/coaching";
import { Dictionary as DE_MetaData_Dictionary } from "./de/metadata";

import { Dictionary as FR_Sys_Dictionary } from "./fr/system";
import { Dictionary as FR_Lang_Dictionary } from "./fr/language";
import { Dictionary as FR_Email_Dictionary } from "./fr/emails";
import { Dictionary as FR_Events_Dictionary } from "./fr/events";
import { Dictionary as FR_Presentation_Dictionary } from "./fr/presentation";
import { Dictionary as FR_Subscription_Dictionary } from "./fr/subscriptions";
import { Dictionary as FR_Bot_Dictionary } from "./fr/bot";
import { Dictionary as FR_Coaching_Dictionary } from "./fr/coaching";
import { Dictionary as FR_MetaData_Dictionary } from "./fr/metadata";

import Tools from "../Tools";

// safe read
if (!(window as any).navigator) {
  (window as any).navigator = {};
}

const Translator: any = {
  de: {
    language: DE_Lang_Dictionary,
    system: DE_Sys_Dictionary,
    emails: DE_Email_Dictionary,
    events: DE_Events_Dictionary,
    presentation: DE_Presentation_Dictionary,
    subscription: DE_Subscription_Dictionary,
    bot: DE_Bot_Dictionary,
    coaching: DE_Coaching_Dictionary,
    metadata: DE_MetaData_Dictionary,
  },
  es: {
    language: SP_Lang_Dictionary,
    system: SP_Sys_Dictionary,
    emails: SP_Email_Dictionary,
    events: SP_Events_Dictionary,
    presentation: SP_Presentation_Dictionary,
    subscription: SP_Subscription_Dictionary,
    bot: SP_Bot_Dictionary,
    coaching: SP_Coaching_Dictionary,
    metadata: SP_MetaData_Dictionary,
  },
  fr: {
    language: FR_Lang_Dictionary,
    system: FR_Sys_Dictionary,
    emails: FR_Email_Dictionary,
    events: FR_Events_Dictionary,
    presentation: FR_Presentation_Dictionary,
    subscription: FR_Subscription_Dictionary,
    bot: FR_Bot_Dictionary,
    coaching: FR_Coaching_Dictionary,
    metadata: FR_MetaData_Dictionary,
  },
  it: {
    language: IT_Lang_Dictionary,
    system: IT_Sys_Dictionary,
    emails: IT_Email_Dictionary,
    events: IT_Events_Dictionary,
    presentation: IT_Presentation_Dictionary,
    subscription: IT_Subscription_Dictionary,
    bot: IT_Bot_Dictionary,
    coaching: IT_Coaching_Dictionary,
    metadata: IT_MetaData_Dictionary,
  },
  en: {
    language: EN_Lang_Dictionary,
    system: EN_Sys_Dictionary,
    emails: EN_Email_Dictionary,
    events: EN_Events_Dictionary,
    presentation: EN_Presentation_Dictionary,
    subscription: EN_Subscription_Dictionary,
    bot: EN_Bot_Dictionary,
    coaching: EN_Coaching_Dictionary,
    metadata: EN_MetaData_Dictionary,
  },
};

const validateDictionary = () => {
  let baseKey = "en";
  let baseDictionary = Translator[baseKey];
  let dictKeys = Object.keys(Translator).filter((x: string) => x != baseKey);
  let baseKeys = Object.keys(baseDictionary);
  let totalErrors = 0;
  for (let i = 0; i < baseKeys.length; i++) {
    let dictionaryPageKey = baseKeys[i];
    let dictLoop = baseDictionary[dictionaryPageKey];

    // keys to be confronted with all other dictionaries
    let dictionaryPageContentKeys = Object.keys(dictLoop);

    for (let j = 0; j < dictKeys.length; j++) {
      let alternateDictionary = Translator[dictKeys[j]];
      let alternatePageContent = alternateDictionary[dictionaryPageKey];
      // alternative page content for another dictionary
      let alternatePageContentKeys = Object.keys(alternatePageContent);

      for (let k = 0; k < dictionaryPageContentKeys.length; k++) {
        if (!alternatePageContentKeys.includes(dictionaryPageContentKeys[k])) {
          console.log(
            "Dictionary unmach",
            dictKeys[j],
            dictionaryPageKey,
            dictionaryPageContentKeys[k]
          );
          totalErrors++;
        }
      }
    }
  }
  return totalErrors;
};
(window as any)["validateDictionary"] = validateDictionary;

const GetLanguage = () => {
  let userLanguage = SettingsUtil.GetSettings("language");

  // remove symbols
  return userLanguage.split("-")[0];
};

const Translate = (dictionary: string, key: string) => {
  const language = GetLanguage();
  let activeDictionaryLanguage = Translator[language];
  if (activeDictionaryLanguage) {
    if (activeDictionaryLanguage.hasOwnProperty(dictionary)) {
      let activeDictionary = activeDictionaryLanguage[dictionary];
      if (activeDictionary.hasOwnProperty("__" + key)) {
        let word = activeDictionary["__" + key];
        return Tools.UpperCase(word);
      }
    }
  }
  return dictionary + ":" + key;
};

const Tr: any = {
  GetLanguage: GetLanguage,
  Translate: Translate,
};

export default Tr;
