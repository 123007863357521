import React, { useEffect, useState } from "react";
import { MessageBar, MessageBarType, ThemeProvider } from "@fluentui/react";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import AppLayout from "./AppLayout";
import { useDispatch } from "react-redux";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import { AjaxService } from "./Services/AjaxService";
import { AppRoutes } from "./AppRoutes";
import { useNavigate } from "react-router-dom";
import { GenericActions } from "./Redux/Generic/GenericAction";
import { ToastMessage, suppressToasts } from "./Utils/UIMessages";
import { DismissModal } from "./Components/SmartModal/SmartModal";
import Tr from "./Utils/Translations/Translations";

const appTheme = {
  status: {
    danger: "blue",
  },
  palette: {
    themePrimary: "#5482ba",
    themeLighterAlt: "#f7f9fc",
    themeLighter: "#dfe8f4",
    themeLight: "#c4d5ea",
    themeTertiary: "#8fafd6",
    themeSecondary: "#648fc2",
    themeDarkAlt: "#4b75a8",
    themeDark: "#40638d",
    themeDarker: "#2f4968",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#bab8b7",
    neutralSecondary: "#a3a2a0",
    neutralPrimaryAlt: "#8d8b8a",
    neutralPrimary: "#323130",
    neutralDark: "#605e5d",
    black: "#494847",
    white: "#ffffff",
  },
};

initializeIcons();

var optimizeCheck: boolean | undefined = undefined;
export const mobileCheck = (forceTrue = false) => {
  if (window.innerWidth < 768) {
    return true;
  }

  //return true;
  if (optimizeCheck !== undefined) {
    return optimizeCheck;
  }

  let check = false;

  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(
    (navigator as any).userAgent ||
      (navigator as any).vendor ||
      (window as any).opera
  );

  if (forceTrue) {
    check = forceTrue;
  }

  optimizeCheck = check;
  return check;
};

export const suppressDefaultErrorMessage = () => {
  (window as any)["lockErrorMessage"] = true;
  setTimeout(() => {
    (window as any)["lockErrorMessage"] = false;
  }, 1000);
};

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dicitonaryError, setDicitonaryError] = useState<boolean>(true);
  const [render, setRender] = useState<boolean>(true);
  const initHandlersAjaxCodes = () => {
    AjaxService.CustomResponseCodeHandlers.push({
      code: 401,
      action: (responseStatus: number) => {
        if (!window.location.pathname.includes(AppRoutes.LOGIN_PAGE)) {
          suppressToasts();
          dispatch(GenericActions.setUser(undefined));
          localStorage.removeItem("sessionToken");
          localStorage.removeItem("workgroupSessionToken");
          (window as any)["SessionExpired"] = true;
          navigate(AppRoutes.LOGIN_PAGE);
        }
      },
    });
    AjaxService.CustomResponseCodeHandlers.push({
      code: 406,
      action: (responseStatus: number) => {
        if (!window.location.pathname.includes(AppRoutes.WORKGROUP_PICK_PAGE)) {
          suppressToasts();
          dispatch(GenericActions.SetActiveWorkgroup(undefined));
          (window as any)["WorkgroupSessionExpired"] = true;
          navigate(AppRoutes.WORKGROUP_PICK_PAGE);
        }
      },
    });
    AjaxService.CustomResponseCodeHandlers.push({
      code: 403,
      action: (responseStatus: number) => {
        ToastMessage("error", Tr.Translate("system", "generic_api_403"));
      },
    });
    AjaxService.CustomResponseCodeHandlers.push({
      code: 0,
      action: (responseStatus: number) => {
        setTimeout(() => {
          if (!(window as any)["lockErrorMessage"]) {
            if (responseStatus >= 300) {
              ToastMessage(
                "error",
                Tr.Translate("system", "generic_api_failure")
              );
            }
          }
        });
      },
    });
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Escape") {
      DismissModal("*");
    }
  };

  const rerenderApp = () => {
    setRender(false);
    setTimeout(() => {
      setRender(true);
    }, 100);
  };

  useEffect(() => {
    setTimeout(() => {
      setDicitonaryError((window as any)["validateDictionary"]() > 0);
    }, 500);

    document.body.addEventListener("keyup", handleKeyPress);
    document.addEventListener("rerender-app", rerenderApp);
    initHandlersAjaxCodes();

    return () => {
      document.body.removeEventListener("keyup", handleKeyPress);
      document.removeEventListener("rerender-app", rerenderApp);
    };
  }, []);

  return (
    <ThemeProvider theme={appTheme}>
      {dicitonaryError && window.location.origin.includes("localhost") && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            zIndex: 10000,
          }}
        >
          <MessageBar messageBarType={MessageBarType.error}>
            Dictionary error
          </MessageBar>
        </div>
      )}
      <div style={{ position: "relative", zIndex: "1000000" }}>
        <ToastContainer />
      </div>
      {render && (
        <div>
          <AppLayout />
        </div>
      )}
    </ThemeProvider>
  );
}

export default App;
