import { useDispatch, useSelector } from "react-redux";
import { IBook } from "../../Models/IBook";
import "./ChatPage.scss";
import { GlobalState } from "../../Redux/RootReducer";
import Tr from "../../Utils/Translations/Translations";
import { useEffect, useRef, useState } from "react";
import {
  IWorkgroupChat,
  IWorkgroupChatFile,
  IWorkgroupChatInsert,
  IWorkgroupChatNotRead,
  IWorkgroupChatProcessedContent,
} from "../../Models/IWorkgroupChat";
import { stat } from "fs";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import { ChatActions } from "../../Redux/Chat/ChatAction";
import { mobileCheck } from "../../App";
import Loadable from "../../Components/Loadable/Loadable";
import {
  IconButton,
  Link,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import BetterTextField from "../../Components/BetterTextField/BetterTextField";
import CustomTooltip from "../../Components/CustomTooltip/CustomTooltip";
import { ToastMessage } from "../../Utils/UIMessages";
import SuperSpinner from "../../Components/SuperSpinner/SuperSpinner";

const maxKBSizeUpload = 100;

function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const convertToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve({ data: reader.result, name: file.name, size: file.size });
    reader.onerror = (error) => reject(error);
  });
};

const ChatPageDownloadLink = (props: {
  chat: IWorkgroupChat;
  file: IWorkgroupChatFile;
  bookId: number;
}) => {
  const [downloading, setDownloading] = useState<boolean>(false);
  const activeWorkgroup = useSelector((globalState: GlobalState) => {
    return globalState.generic.activeWorkgroup;
  });

  const downloadChatFile = (chat: IWorkgroupChat, fileName: string) => {
    if (downloadedFileQuickCache[chat.Id]) {
      processPostDownload(fileName, downloadedFileQuickCache[chat.Id]);
      return;
    }
    setDownloading(true);
    ApiService.WorkgroupChatController.GetWorkgroupChatsFile(
      activeWorkgroup?.Id ?? 0,
      props.bookId,
      chat.Id,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let target: IWorkgroupChat[] = response.parsed;

          if (target.length > 0) {
            let fileData = JSON.parse(target[0].MessageFile);
            downloadedFileQuickCache[chat.Id] = fileData;
            processPostDownload(fileName, fileData);
          }
        } else {
          ToastMessage("error", Tr.Translate("system", "generic_api_failure"));
        }
        setDownloading(false);
      }
    );
  };

  const processPostDownload = (
    requestedFileName: string,
    fileData: IWorkgroupChatFile[]
  ) => {
    let file = fileData.find((x) => x.FileName === requestedFileName);
    if (file && file.FileBase64) {
      let link = document.createElement("a");
      link.href = file.FileBase64;
      link.download = file.FileName;
      link.click();
    }
  };

  const ext = props.file.FileName.split(".").pop();
  const fileIcon =
    "https://emanueleuniroma2.github.io/FFASP/common/fileicons/" + ext + ".svg";

  return (
    <div>
      {downloading && <SuperSpinner />}
      {!downloading && (
        <div className="chat-page-chat-message-file-link-row">
          <img src={fileIcon} />
          <Link
            onClick={() => {
              downloadChatFile(props.chat, props.file.FileName);
            }}
            className="chat-page-chat-message-file-link"
          >
            {props.file.FileName} ({formatBytes(props.file.FileSize)})
          </Link>
        </div>
      )}
    </div>
  );
};

var downloadedFileQuickCache: any = {};

const ChatPage = (props: { onBack: () => void }) => {
  const dispatch = useDispatch();
  const books: IBook[] = useSelector(
    (state: GlobalState) => state.generic.books
  );

  const [sending, setSending] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const [filesBase64, setFilesBase64] = useState<IWorkgroupChatFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const fileSlotRef = useRef<HTMLDivElement>(null);
  const workgroupChatsNotRead = useSelector(
    (state: GlobalState) => state.chat.workgroupChatsNotRead
  );
  const [loadChatBackDays, setLoadChatBackDays] = useState<number>(2);
  const workgroupChats = useSelector(
    (state: GlobalState) => state.chat.WorkgroupChats
  );
  const activeWorkgroup = useSelector((globalState: GlobalState) => {
    return globalState.generic.activeWorkgroup;
  });
  const loggedUser = useSelector((state: GlobalState) => {
    return state.generic.user;
  });
  const [activeBookId, setActiveBookId] = useState<number>(0);
  const getRedCounterForBook = (bookId: number) => {
    let unread = workgroupChatsNotRead.find(
      (chat: IWorkgroupChatNotRead) => chat.BookId === bookId
    );

    if (unread && activeBookId !== bookId) {
      return (
        <div className="book-chat-line-counter">{unread?.UnreadCount}</div>
      );
    } else {
      return <div></div>;
    }
  };

  const markNewMessagesAsRead = async (
    bookId: number,
    messages: IWorkgroupChat[]
  ) => {
    let messagesForBookYouDidntRead = messages.filter(
      (chat: IWorkgroupChat) => {
        return (
          chat.ReadBy.split(",")
            .map((x) => +x)
            .indexOf(loggedUser?.Id ?? 0) === -1
        );
      }
    );

    let allIdsCommaSeparated = messagesForBookYouDidntRead
      .map((x: IWorkgroupChat) => x.Id)
      .join(",");

    if (allIdsCommaSeparated.length > 0) {
      await ApiService.WorkgroupChatController.UpdateWorkgroupChatReadBy(
        activeWorkgroup?.Id ?? 0,
        bookId,
        allIdsCommaSeparated
      );
    }

    // signal the polling handler that we have read the messages
    document.dispatchEvent(new Event("visibilitychange"));

    storeWorkGroupChat(messages);
  };

  const appendNewMessage = (message: IWorkgroupChatInsert) => {
    dispatch(
      ChatActions.SetWorkgroupChat([
        ...workgroupChats,
        {
          ...message,
          SentBy: loggedUser?.Id,
          DisplayUserName: loggedUser?.Username,
          CreateDT: new Date().toISOString(),
          UpdateDT: new Date().toISOString(),
          Meta: JSON.parse(message.MessageContent),
        } as any,
      ])
    );
    scrollBottom();
  };

  const storeWorkGroupChat = (messages: IWorkgroupChat[]) => {
    // add new messages to the array
    let newMessages = [...workgroupChats];

    for (let i = 0; i < messages.length; i++) {
      if (!newMessages.find((x: IWorkgroupChat) => x.Id == messages[i].Id)) {
        newMessages.push(messages[i]);
      }
    }

    // store in redux
    dispatch(
      ChatActions.SetWorkgroupChat(
        newMessages.sort((a: IWorkgroupChat, b: IWorkgroupChat) => a.Id - b.Id)
      )
    );

    scrollBottom();
  };

  const loadChatForBook = (
    bookId: number,
    lastSeenId: number,
    silent = false
  ) => {
    if (!silent) {
      setLoading(true);
    }
    ApiService.WorkgroupChatController.GetWorkgroupChats(
      activeWorkgroup?.Id ?? 0,
      bookId,
      loadChatBackDays, // up to 2 days in the past default
      lastSeenId,
      undefined,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let chatList: IWorkgroupChat[] = response.parsed;
          chatList = chatList.map((chat: IWorkgroupChat) => {
            return {
              ...chat,
              Meta: JSON.parse(chat.MessageContent),
            };
          });
          markNewMessagesAsRead(bookId, chatList);
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (activeBookId) {
      loadChatForBook(activeBookId, -1);
    }
  }, [activeBookId, loadChatBackDays]);

  const getLastSeenId = () => {
    let lastSeenId = -1;
    let lastChat = workgroupChats
      .filter((x: IWorkgroupChat) => x.BookId === activeBookId)
      .map((x: IWorkgroupChat) => x.Id)
      .sort((a, b) => b - a);

    if (lastChat.length > 0) {
      lastSeenId = lastChat[0];
    }
    return lastSeenId;
  };

  useEffect(() => {
    let targetUnread: IWorkgroupChatNotRead | undefined =
      workgroupChatsNotRead.find((x) => x.BookId === activeBookId);
    if ((targetUnread?.UnreadCount ?? 0) > 0) {
      loadChatForBook(activeBookId, getLastSeenId(), true);
    }
  }, [workgroupChatsNotRead]);

  const handleFileChange = (event: any) => {
    const files = event.target.files;
    const promises = [];

    for (let i = 0; i < files.length; i++) {
      promises.push(convertToBase64(files[i]));
    }

    Promise.all(promises).then((base64Strings: any) => {
      setFilesBase64(
        base64Strings.map((x: { data: string; name: string; size: number }) => {
          return {
            FileName: x.name,
            FileBase64: x.data,
            FileSize: x.size,
          };
        })
      );
    });
  };

  const resizeFileContainer = () => {
    if (fileSlotRef && fileSlotRef.current) {
      fileSlotRef.current.style.marginTop =
        -fileSlotRef.current.getBoundingClientRect().height - 50 + "px";
    }
  };

  useEffect(() => {
    resizeFileContainer();
  }, [filesBase64]);

  useEffect(() => {
    window.addEventListener("resize", resizeFileContainer);
    return () => {
      window.removeEventListener("resize", resizeFileContainer);
    };
  }, []);

  const sendMessage = () => {
    if (message.length > 0) {
      setSending(true);
      let msg = message;
      let files = [...filesBase64];
      let messageContent: IWorkgroupChatProcessedContent = {
        Text: msg,
        Files: filesBase64.map((x) => {
          return { FileName: x.FileName, FileSize: x.FileSize, FileBase64: "" };
        }),
      };

      let payload: IWorkgroupChatInsert = {
        BookId: activeBookId,
        WorkGroupId: activeWorkgroup?.Id ?? 0,
        MessageContent: JSON.stringify(messageContent),
        MessageFile: JSON.stringify(files),
      };
      setMessage("");
      setFilesBase64([]);
      // display immediate send to current user
      appendNewMessage(payload);

      ApiService.WorkgroupChatController.NewWorkgroupChat(
        payload,
        (response: IAPIResponse) => {
          if (response.error === null) {
            loadChatForBook(activeBookId, getLastSeenId(), true);
            setFilesBase64([]);
          } else {
            setMessage(msg);
            setFilesBase64(files);
          }
          setSending(false);
        }
      );
    }
  };

  const scrollBottom = () => {
    setTimeout(() => {
      // Get the div element
      let divElement: any = document.getElementById(
        "chat-message-main-container"
      );
      if (divElement) {
        // Scroll to the bottom of the div
        divElement.scrollTop = divElement.scrollHeight;
      }
    }, 400);
  };

  const currentSyze = filesBase64.reduce((a, b) => a + b.FileSize, 0);
  const tooManyFiles = currentSyze > maxKBSizeUpload * 1000;

  return (
    <div className="chat-page-main-wrap">
      <div className="chat-page-left-menu">
        <div
          className={
            "chat-page-left-menu-title " +
            (mobileCheck() ? "chat-page-left-menu-title-mobile" : "")
          }
        >
          <CustomTooltip
            content={Tr.Translate("language", "back")}
            isButton
            onClick={props.onBack}
            iconName="Back"
          />
          <span>{Tr.Translate("language", "workgroup_chat")}</span>
        </div>
        <div className="chat-page-left-menu-wrap">
          {books.map((book: IBook, i: number) => {
            return (
              <div
                className={
                  "book-chat-line " +
                  (activeBookId === book.Id ? "book-chat-line-active" : "")
                }
                key={i}
                onClick={() => {
                  setLoading(true);
                  setActiveBookId(book.Id ?? 0);
                }}
              >
                <div style={{ width: "fit-content", position: "relative" }}>
                  {book.Title}
                  {getRedCounterForBook(book.Id ?? 0)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className="chat-page-right-menu"
        style={
          mobileCheck()
            ? { width: "65vw" }
            : { width: "calc(100vw - 250px - 194px)" }
        }
      >
        <Loadable
          isLoading={loading}
          content={
            <div>
              {activeBookId === 0 && (
                <div>
                  <MessageBar messageBarType={MessageBarType.info}>
                    {Tr.Translate(
                      "language",
                      "pick_a_chat_room_to_start_talking"
                    )}
                  </MessageBar>
                </div>
              )}
              <div
                className="chat-messages-main-wrap"
                id="chat-message-main-container"
              >
                {activeBookId !== 0 && (
                  <div className="top-of-chat-messages">
                    <PrimaryButton
                      text={Tr.Translate(
                        "language",
                        "load_older_chat_messages"
                      )}
                      onClick={() => {
                        setLoadChatBackDays(loadChatBackDays + 2);
                      }}
                    />
                  </div>
                )}
                {workgroupChats
                  .filter(
                    (chat: IWorkgroupChat) => chat.BookId === activeBookId
                  )
                  .map((chat: IWorkgroupChat, i: number) => {
                    return (
                      <div
                        className={
                          "chat-page-chat-message " +
                          (chat.SentBy === loggedUser?.Id
                            ? "chat-page-chat-message-your"
                            : "")
                        }
                        key={i}
                      >
                        <div className="chat-page-chat-message-title">
                          <div>{chat.DisplayUserName}</div>
                          <div style={{ fontSize: "0.7em" }}>
                            {chat?.UpdateDT?.replace("T", " ").substring(0, 16)}
                          </div>
                        </div>
                        <div className="chat-page-chat-message-content">
                          {chat.Meta?.Text}
                        </div>
                        <div className="chat-page-chat-message-file-link-wrap">
                          {chat.Meta?.Files.map(
                            (file: IWorkgroupChatFile, j: number) => {
                              return (
                                <ChatPageDownloadLink
                                  key={j}
                                  chat={chat}
                                  file={file}
                                  bookId={activeBookId}
                                />
                              );
                            }
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>

              {activeBookId !== 0 && (
                <div className="chat-bar-commands">
                  {filesBase64.length > 0 && (
                    <div
                      className="command-bar-file-slot"
                      ref={fileSlotRef}
                      style={
                        mobileCheck()
                          ? { width: "60vw" }
                          : { width: "calc(100vw - 250px - 210px)" }
                      }
                    >
                      {filesBase64.map(
                        (file: IWorkgroupChatFile, i: number) => {
                          return (
                            <div className="command-bar-file-slot-item" key={i}>
                              <div className="command-bar-file-slot-item-inner">
                                {file.FileName} ({formatBytes(file.FileSize)}{" "}
                                bytes){" "}
                                <CustomTooltip
                                  isButton
                                  iconName="Cancel"
                                  content={Tr.Translate("language", "delete")}
                                  onClick={() => {
                                    let newFiles = filesBase64.filter(
                                      (x: IWorkgroupChatFile, j: number) => {
                                        return j !== i;
                                      }
                                    );
                                    setFilesBase64(newFiles);
                                  }}
                                />
                              </div>
                            </div>
                          );
                        }
                      )}
                      {tooManyFiles && (
                        <MessageBar>
                          {Tr.Translate(
                            "language",
                            "the_total_file_size_you_may_send_per_message_canno_exceed"
                          ).replace("$SIZE", maxKBSizeUpload.toString()) +
                            " (" +
                            formatBytes(currentSyze) +
                            ")"}
                        </MessageBar>
                      )}
                    </div>
                  )}
                  <div
                    style={
                      mobileCheck()
                        ? { width: "65vw  - 250px" }
                        : { width: "calc(100vw - 250px - 194px - 250px)" }
                    }
                  >
                    <BetterTextField
                      multiline
                      value={message}
                      autoHeight={300}
                      onChange={(e, t) => {
                        if (t != undefined) {
                          setMessage(t);
                        }
                      }}
                    />
                  </div>
                  <PrimaryButton
                    iconProps={{ iconName: "Attach" }}
                    onClick={() => {
                      document.getElementById("chat-input-picker")?.click();
                    }}
                  />
                  <input
                    onChange={handleFileChange}
                    hidden
                    multiple
                    type="file"
                    id="chat-input-picker"
                  ></input>
                  <PrimaryButton
                    onClick={sendMessage}
                    disabled={tooManyFiles || message === ""}
                    iconProps={{ iconName: "Send" }}
                    text={Tr.Translate("language", "send")}
                  />
                </div>
              )}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ChatPage;
