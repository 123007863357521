import { useState } from "react";
import { IEvent } from "../../Models/IEvent";
import { useDispatch, useSelector } from "react-redux";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import { GlobalState } from "../../Redux/RootReducer";
import { IAPIResponse } from "../../Services/AjaxService";
import ApiService from "../../Services/ApiService";
import { ToastMessage } from "../../Utils/UIMessages";
import Tr from "../../Utils/Translations/Translations";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import Loadable from "../Loadable/Loadable";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import {
  dropDownEventOption,
  getEventTextByPartialKey,
  getEventTypesOptions,
} from "../../Utils/EventTypeMap";
import {
  eventProcessingListToOptions,
  GenerateAssetEventUrl,
  GetEventTypeVersion,
} from "../../Utils/EventProcessing";
import Tools from "../../Utils/Tools";
import { Checkbox, IconButton, Label } from "@fluentui/react";
import "./EventsGlobalView.scss";
import BetterTextField from "../BetterTextField/BetterTextField";
import { IBookTimeline } from "../../Models/IBookTimeline";
import { betterCommaListDisplay } from "../ExploreEventsTickGrid/ExploreEventsTickGrid";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import ManageEventButtons from "../ManageEventButtons/ManageEventButtons";
import ImageAssetLoader, {
  GenerateAssetUrl,
} from "../ImageAssetLoader/ImageAssetLoader";
import SettingsUtil from "../../Utils/Settings";

const ExploreEventsRowGlobal = (props: { event: IEvent }) => {
  return (
    <div>
      <div className="global-event-exporer-row-wrap">
        <div style={{ float: "right" }}>
          <ManageEventButtons targetEvent={props.event} />
        </div>

        <div className="global-event-explorer-item">
          <Label>{Tr.Translate("language", "event_title")}</Label>
          <div className="global-event-explorer-voice">
            {props.event?.Title}
          </div>
        </div>
        <div className="global-event-explorer-item">
          <Label>{Tr.Translate("language", "custom_date")}</Label>
          <div className="global-event-explorer-voice">
            {props.event?.CustomDate}
          </div>
        </div>
        <div className="global-event-explorer-item">
          <Label>{Tr.Translate("language", "event_type")}</Label>
          <div className="global-event-explorer-voice">
            {props.event?.EventType.split(", ").map((x: string, i: number) => {
              return (
                <div key={i} className="global-event-display-item-type-wrap">
                  <ImageAssetLoader
                    className="global-event-display-icon"
                    src={GenerateAssetEventUrl(x)}
                  />

                  <div>{getEventTextByPartialKey(x)}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="global-event-explorer-item">
          <Label>{Tr.Translate("language", "event_character_list")}</Label>
          <div className="global-event-explorer-voice">
            {betterCommaListDisplay(props.event?.CharacterList, true)}
          </div>
        </div>
        <div className="global-event-explorer-item">
          <Label>{Tr.Translate("language", "event_keyword")}</Label>
          <div className="global-event-explorer-voice">
            {betterCommaListDisplay(props.event?.EventKeyword)}
          </div>
        </div>
        <div className="global-event-explorer-item">
          <Label>{Tr.Translate("language", "event_description")}</Label>
          <div className="global-event-explorer-voice">
            {props.event?.Description}
          </div>
        </div>
      </div>
    </div>
  );
};

const EventsGlobalView = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState<string>("");
  const [matchCase, setMatchCase] = useState<boolean>(false);

  const [targetEvent, setTargetEvent] = useState<IEvent>();

  const globalEvents: IEvent[] = useSelector(
    (state: GlobalState) => state.generic.globalevents
  );
  const timeline: IBookTimeline | undefined = useSelector(
    (state: GlobalState) => state.generic.activeTimeline
  );

  let serchResults: IEvent[] = [];

  if (matchCase) {
    serchResults = globalEvents.filter(
      (x: IEvent) =>
        x.TimelineId === timeline?.Id &&
        (x.CustomDate.includes(searchText) ||
          x.Title.includes(searchText) ||
          x.Description.includes(searchText) ||
          x.EventKeyword.includes(searchText) ||
          x.CharacterList.includes(searchText))
    );
  } else {
    serchResults = globalEvents.filter(
      (x: IEvent) =>
        x.TimelineId === timeline?.Id &&
        (x.CustomDate.toLocaleLowerCase().includes(
          searchText.toLocaleLowerCase()
        ) ||
          x.Title.toLocaleLowerCase().includes(
            searchText.toLocaleLowerCase()
          ) ||
          x.Description.toLocaleLowerCase().includes(
            searchText.toLocaleLowerCase()
          ) ||
          x.EventKeyword.toLocaleLowerCase().includes(
            searchText.toLocaleLowerCase()
          ) ||
          x.CharacterList.toLocaleLowerCase().includes(
            searchText.toLocaleLowerCase()
          ))
    );
  }

  return (
    <div style={{ width: "25em" }}>
      <div style={{ margin: "1em 0" }}>
        <BetterTextField
          onChange={(e, t) => {
            if (t !== undefined) {
              setSearchText(t);
            }
          }}
          label={Tr.Translate("language", "search_events")}
          placeholder={Tr.Translate("language", "search_events")}
        />
        <br />
        <Checkbox
          label={Tr.Translate("language", "case_sensitive")}
          onChange={(e, c) => {
            if (c !== undefined) {
              setMatchCase(c);
            }
          }}
        />
      </div>
      {searchText.length > 2 && (
        <h2>
          {Tr.Translate("language", "search_results") +
            ": " +
            serchResults.length}
        </h2>
      )}
      <div className="global-event-main-scroll">
        {searchText.length > 2 &&
          serchResults.map((x: IEvent, i: number) => {
            return <ExploreEventsRowGlobal key={i} event={x} />;
          })}
      </div>
    </div>
  );
};

export default EventsGlobalView;
