import { useEffect, useState } from "react";
import "./Refresher.scss";

export interface IRefresherProps {
  element: JSX.Element;
  toggle: boolean;
}

// this component force the desctruction of a component
// with a clone of the component.
// this can reset completely an instance of a component, and resets ANY pending effect
// currently running on the given component (e.g: any hover tooltip, open dialog, etc...)
const Refresher = (props: IRefresherProps) => {
  const [toggle, setToggle] = useState<boolean>(false);
  useEffect(() => {
    setToggle(props.toggle);
  }, [props.toggle]);

  return toggle ? (
    <div key={0}>{props.element}</div>
  ) : (
    <div key={1}>{props.element}</div>
  );
};

export default Refresher;
