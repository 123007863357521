import { APIUrls } from "../../ApiUrls";
import { IBook } from "../../Models/IBook";
import { AjaxService, IAPIRequest, IAPIResponse } from "../AjaxService";

const BookController = {
  GetBooks: async (
    workgroupId: number,
    bookId?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.BOOK.GET_BOOKS + "?workgroupId=" + workgroupId;
    if (bookId) {
      request.url += "&bookId=" + bookId;
    }
    request.allowCache = true;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetRecentBooks: async (
    workgroupId: number,
    bookId?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.BOOK.GET_RECENT_BOOKS + "?workgroupId=" + workgroupId;
    if (bookId) {
      request.url += "&bookId=" + bookId;
    }
    request.allowCache = true;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  NewBook: async (
    book: IBook,
    workgroupId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.BOOK.INSERT_BOOK + "?workgroupId=" + workgroupId;
    request.method = "POST";
    request.payload = book;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  UpdateBook: async (
    book: IBook,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.BOOK.UPDATE_BOOK;
    request.method = "PUT";
    request.payload = book;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  DeleteBook: async (
    bookId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.BOOK.DELETE_BOOK + "?bookId=" + bookId;
    request.method = "DELETE";
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
};

export default BookController;
