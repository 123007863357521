import "./ImageAssetLoader.scss";

export interface ImageAssetLoaderProps {
  src: string;
  title?: string;
  className?: string;
  style?: any;
  onClick?: () => void;
}

export const GenerateAssetUrl = (src: string, cssUrl = false) => {
  if (
    src.startsWith("http") ||
    src.startsWith("https") ||
    src.startsWith("data:image") ||
    src.startsWith("blob:")
  ) {
    return src;
  }

  let final = "https://emanueleuniroma2.github.io/FFASP/S_M" + src;
  if (cssUrl) {
    final = "url('" + final + "')";
  }
  return final;
};

const ImageAssetLoader = (props: ImageAssetLoaderProps) => {
  const finalUrl: string = GenerateAssetUrl(props.src);
  return (
    <>
      {props.src && (
        <img
          onClick={props.onClick}
          src={finalUrl}
          title={props.title}
          className={props.className}
          style={props.style}
        />
      )}
    </>
  );
};

export default ImageAssetLoader;
