import { DefaultButton, IconButton } from "@fluentui/react/lib/Button";
import {
  ITooltipHostStyles,
  ITooltipProps,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react/lib/Tooltip";
import { useId } from "@fluentui/react-hooks";
import "./CustomTooltip.scss";
import { Icon } from "@fluentui/react";

export interface ICustomTooltipProps {
  content?: JSX.Element;
  iconName?: string;
  isButton?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  innerComponent?: JSX.Element;
  delay?: TooltipDelay;
  closeDelay?: TooltipDelay;
  disableTooltip?: boolean;
}

export const CustomTooltip = (props: ICustomTooltipProps) => {
  const tooltipId = useId("tooltip");

  return (
    <div
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      {props.disableTooltip && props.innerComponent}
      {!props.disableTooltip && props.content && (
        <TooltipHost
          tooltipProps={{ onRenderContent: () => <div>{props.content}</div> }}
          closeDelay={props.closeDelay ?? TooltipDelay.zero}
          delay={props.delay ?? TooltipDelay.zero}
          id={tooltipId}
        >
          {props.innerComponent}
          {props.iconName && (
            <div>
              {props.isButton && (
                <div className="icon-button-clearer">
                  <IconButton
                    className={props.className}
                    disabled={props.disabled}
                    aria-describedby={tooltipId}
                    iconProps={{ iconName: props.iconName }}
                  />
                </div>
              )}
              {!props.isButton && (
                <Icon aria-describedby={tooltipId} iconName={props.iconName} />
              )}
            </div>
          )}
        </TooltipHost>
      )}
    </div>
  );
};

export default CustomTooltip;
