export const Dictionary = {
  __presentation_1:
    "El sitio para escritores de libros de fantasía, novelas y cuentos con tramas más complejas.",
  __presentation_2:
    "Plotter Studio es una plataforma creada en colaboración con escritores para escritores.",
  __presentation_3: "Escritura de trama basada en eventos",
  __presentation_4: "Orden de tiempo",
  __presentation_5:
    "Mantén todos los eventos clave de tu trama en orden colocándolos en una línea de tiempo. ",
  __presentation_6: "Control",
  __presentation_7:
    "Verifique la coherencia de los eventos insertados, para evitar lagunas o inconsistencias en la trama. ",
  __presentation_8: "Trabajo en equipo",
  __presentation_9:
    "Plotter Studio permite a los usuarios invitar a otros a colaborar en el mismo libro, permitiendo así eficacia profesional incluso en un entorno laboral.",
  __presentation_10: "Interfaz intuitiva",
  __presentation_11:
    "El uso de Plotter Studio se ha optimizado para que sea fácil de usar, de modo que puedas concentrarte en tu libro sin tener que pensar en nada más.",
  __presentation_12: "¿Tiene usted alguna pregunta?",
  __presentation_12_2:
    "El equipo de Plotter Studio está a su disposición para responder a todas sus preguntas",
  __presentation_13: "Contáctanos",
  __presentation_14: "O puedes crear una cuenta gratis ",
  __presentation_15: "Calidad profesional",
  __presentation_16:
    "Plotter Studio es desarrollado y mantenido por un grupo con décadas de experiencia en el mundo TI, y garantizamos evoluciones continuas en el tiempo.",
  __footer_about_us: "Sobre nosotros",
  __footer_our_mission: "Nuestra misión",
  __footer_were_are_we_headed: "¿Hacia dónde nos dirigimos?",
  __footer_services: "Nuestros servicios",
  __footer_serv_support: "Apoyo a los escritores",
  __footer_serv_teaching: "Enseñando",
  __footer_serv_private_solutions: "Soluciones privadas",
  __footer_contact_us: "Contáctenos",
  __footer_social_media: "Medios de comunicación social",
  __footer_social_facebook: "Facebook",
  __footer_social_twitter: "Twitter",
  __app_for_writers_logo: "La aplicación para escritores",
};
