import { useEffect, useState } from "react";
import "./MonthMapEditor.scss";
import { IconButton, Label, PrimaryButton, Toggle } from "@fluentui/react";
import Tr from "../../Utils/Translations/Translations";
import MultiForm from "../MultiForm/MultiForm";
import Tools from "../../Utils/Tools";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import BetterRadioButtons from "../BetterRadioButtons/BetterRadioButtons";

export interface IMonthMapElement {
  MonthName: string;
  MonthMaxDays: number;
  LeapYearEveryTotYears: number;
}

export interface IMonthMap {
  IsCustom: boolean;
  Months: IMonthMapElement[];
}

export interface IMonthMapEditorProps {
  onChange: (data: IMonthMap) => void;
  defaultValue?: IMonthMap;
  disabled?: boolean;
}

const standardYearhStructure: IMonthMapElement[] = [
  {
    MonthName: Tr.Translate("language", "jan"),
    MonthMaxDays: 31,
    LeapYearEveryTotYears: 0,
  },
  {
    MonthName: Tr.Translate("language", "feb"),
    MonthMaxDays: 28,
    LeapYearEveryTotYears: 4,
  },
  {
    MonthName: Tr.Translate("language", "mar"),
    MonthMaxDays: 31,
    LeapYearEveryTotYears: 0,
  },
  {
    MonthName: Tr.Translate("language", "apr"),
    MonthMaxDays: 30,
    LeapYearEveryTotYears: 0,
  },
  {
    MonthName: Tr.Translate("language", "may"),
    MonthMaxDays: 31,
    LeapYearEveryTotYears: 0,
  },
  {
    MonthName: Tr.Translate("language", "jun"),
    MonthMaxDays: 30,
    LeapYearEveryTotYears: 0,
  },
  {
    MonthName: Tr.Translate("language", "jul"),
    MonthMaxDays: 31,
    LeapYearEveryTotYears: 0,
  },
  {
    MonthName: Tr.Translate("language", "aug"),
    MonthMaxDays: 31,
    LeapYearEveryTotYears: 0,
  },
  {
    MonthName: Tr.Translate("language", "sep"),
    MonthMaxDays: 30,
    LeapYearEveryTotYears: 0,
  },
  {
    MonthName: Tr.Translate("language", "oct"),
    MonthMaxDays: 31,
    LeapYearEveryTotYears: 0,
  },
  {
    MonthName: Tr.Translate("language", "nov"),
    MonthMaxDays: 30,
    LeapYearEveryTotYears: 0,
  },
  {
    MonthName: Tr.Translate("language", "dec"),
    MonthMaxDays: 31,
    LeapYearEveryTotYears: 0,
  },
];

const MonthMapEditor = (props: IMonthMapEditorProps) => {
  const [state, setState] = useState<IMonthMap>(
    props.defaultValue ?? {
      Months: [],
      IsCustom: false,
    }
  );

  useEffect(() => {
    // in this way we don't lose the last custom date structure in case the user changes his mind
    if (state.IsCustom) {
      props.onChange({ IsCustom: true, Months: state.Months });
    } else {
      props.onChange({ IsCustom: false, Months: standardYearhStructure });
    }
  }, [state]);

  const sumTot = () => {
    let months: IMonthMapElement[] = state.IsCustom
      ? state.Months
      : standardYearhStructure;

    let tot = 0;
    months.forEach((x: IMonthMapElement) => {
      tot += +x.MonthMaxDays;
    });
    return tot.toString().replace("NaN", "");
  };

  return (
    <div>
      <BetterRadioButtons
        disabled={props.disabled}
        defaultChecked={state.IsCustom ? "1" : "0"}
        fieldName="typeOfMonthMap"
        onChange={(selected: string) => {
          setState({ ...state, IsCustom: selected === "1" });
        }}
        options={[
          {
            text: Tr.Translate("language", "allow_standard_months"),
            key: "0",
          },
          {
            text: Tr.Translate("language", "allow_custom_months"),
            key: "1",
          },
        ]}
      />
      <Label>
        {Tr.Translate("language", "total_days")}: {sumTot()}
      </Label>
      {state.IsCustom && (
        <div>
          <div>
            {state.Months.map((x: IMonthMapElement, i: number) => {
              return (
                <div key={i}>
                  <MultiForm
                    disabled={props.disabled}
                    strictBindingValue
                    onChange={(data: any) => {
                      data.MonthMaxDays = +data.MonthMaxDays;
                      data.LeapYearEveryTotYears = +data.LeapYearEveryTotYears;

                      let currState: IMonthMap = { ...state };
                      currState.Months[i] = data;
                      setState(currState);
                    }}
                    onSubmit={(data: any) => {}}
                    formUniqueId={"month-map-row-" + i}
                    inputs={[
                      {
                        type: "text",
                        name: "MonthName",
                        label:
                          Tr.Translate("language", "month_name") +
                          " " +
                          (i + 1),
                        width: 33,
                        currentValue: x.MonthName,
                        extraParams: { maxLength: 255 },
                      },
                      {
                        type: "number",
                        name: "MonthMaxDays",
                        label: Tr.Translate("language", "month_days"),
                        extraParams: { min: 1, max: 100 },
                        width: 30,
                        currentValue: x.MonthMaxDays,
                      },
                      {
                        type: "number",
                        name: "LeapYearEveryTotYears",
                        label: Tr.Translate("language", "month_leap_every"),
                        extraParams: { min: 0, max: 10 },
                        currentValue: x.LeapYearEveryTotYears,
                        inputTooltip: (
                          <div>
                            {Tr.Translate("language", "month_leap_every_hint")}
                          </div>
                        ),
                        width: 30,
                      },
                      {
                        type: "custom",
                        name: "_erease",
                        width: 5,
                        element: (
                          <div>
                            <CustomTooltip
                              disabled={props.disabled}
                              onClick={() => {
                                let currState: IMonthMap = { ...state };
                                currState.Months = currState.Months.filter(
                                  (x: IMonthMapElement, j: number) => j != i
                                );
                                setState(currState);
                              }}
                              iconName="Trash"
                              isButton
                              content={Tr.Translate("language", "delete")}
                            />
                          </div>
                        ),
                      },
                    ]}
                  />
                </div>
              );
            })}
          </div>

          <PrimaryButton
            disabled={state.Months.length >= 20 || props.disabled}
            onClick={() => {
              let currState: IMonthMap = { ...state };
              currState.Months?.push({
                MonthName: "",
                MonthMaxDays: 1,
                LeapYearEveryTotYears: 0,
              });
              setState(currState);
            }}
            iconProps={{ iconName: "Add" }}
            text={Tr.Translate("language", "add")}
          />
        </div>
      )}
    </div>
  );
};

export default MonthMapEditor;
