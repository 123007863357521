export const Dictionary = {
  __dev_sub_name: "Sviluppatore",
  __dev_sub_description:
    "Un abbonamento di test per verificare il corretto funzionamento dello store Paypal. Questo abbonamento non è visibile al pubblico e non è acquistabile.",
  __single_user_mini_name: "Utente singolo (Mini)",
  __single_user_mini_description:
    "Un abbonamento per le persone che sentono di aver bisogno di più tempo per valutare il prodotto. Questo abbonamento è quasi gratuito e offre un'anteprima di ciò che questa app può fare per i tuoi progetti.",
  __single_user_name: "Utente singolo",
  __single_user_description:
    "Un abbonamento per uso personale. Perfetto per progetti privati o appassionati.",
  __small_group_name: "Piccolo gruppo",
  __small_group_description:
    "Questa soluzione intermedia è adatta a piccoli gruppi di lavoro, studenti o familiari.",
  __office_group_name: "Ufficio",
  __office_group_description:
    "Soluzione per un livello di coinvolgimento di livello ufficio. ",
  __enterprise_group_name: "Impresa",
  __enterprise_group_description:
    "Porta il tuo team alla massima produttività con il pacchetto più avanzato di sempre.",
  __month: "Prezzo mensile",
  __subscription_includes: "Questo abbonamento include",
  __advanced_event_processing:
    "Correzione automatica degli eventi della storia.",
  __advanced_event_query: "Bot di query sugli eventi.",
  __advanced_event_advanced_processing:
    "Elaborazione avanzata degli eventi della trama.",
  __book_limit_per_workgroup: "Fino a 100 libri per ciascun gruppo di lavoro",
  __price_yearly: "Prezzo annuale scontato",

  __workgroupsNumberFeature: "Numero di gruppo di lavoro",
  __workgroupsSizeFeature: "Dimensioni del gruppo di lavoro",
  __eventErrorDetection: "Rilevamento degli errori automatico",
  __queryBotFeature: "Segretatio virtuale",
  __bookAmountFeature: "Libri per gruppo di lavoro",
  __workgroupsNumberFeatureDesc:
    "Ottieni diversi gruppi di lavoro per organizzare i tuoi lavori.",
  __workgroupsSizeFeatureDesc:
    "Ogni gruppo di lavoro può avere un gruppo diverso di collaboratori, così potrai gestire team diversi su progetti diversi.",
  __eventErrorDetectionDesc:
    "Il compilatore di eventi rileverà eventuali incoerenze per te.",
  __queryBotFeatureDesc:
    "Il tuo segretario AI. Fai domande a un bot intelligente su qualsiasi evento per tenere traccia di tutte le informazioni preziose. Niente più appunti sparsi in giro per il tuo ufficio.",
  __bookAmountFeatureDesc:
    "Ogni gruppo di lavoro creato sarà in grado di contenere questa quantità di libri.",
  __per_month_calls: "chiamate/mese",
  __amount: "Quantità",
};
