import { IBook } from "../../Models/IBook";
import { IBookTimeline } from "../../Models/IBookTimeline";
import { IDraftIdea } from "../../Models/IDraftIdea";
import { IEvent } from "../../Models/IEvent";
import { IPurchase } from "../../Models/IPurchase";
import {
  ISubscription,
  ISubscriptionFeature,
} from "../../Models/ISubscription";
import { IUser } from "../../Models/IUser";
import { IWorkgroup } from "../../Models/IWorkgroup";
import {
  IWorkgroupChat,
  IWorkgroupChatNotRead,
} from "../../Models/IWorkgroupChat";

export const SET_USER = "SET_USER";
export const SET_BOOKS = "SET_BOOKS";
export const SET_RECENT_BOOKS = "SET_RECENT_BOOKS";
export const SET_EVENTS = "SET_EVENTS";
export const SET_BOOK_TIMELINES = "SET_BOOK_TIMELINES";
export const SET_BOOK_DRAFT_IDEAS = "SET_BOOK_DRAFT_IDEAS";
export const SET_ACTIVE_WORKGROUP = "SET_ACTIVE_WORKGROUP";
export const SET_ACTIVE_BOOK = "SET_ACTIVE_BOOK";
export const SET_ACTIVE_BOOK_TIMELINE = "SET_ACTIVE_BOOK_TIMELINE";
export const SET_PURCHASES = "SET_PURCHASES";
export const SET_SUBSCRIPTIONS = "SET_SUBSCRIPTIONS";
export const SET_SUBSCRIPTION_FEATURES = "SET_SUBSCRIPTION_FEATURES";
export const SET_WORKGROUPS = "SET_WORKGROUPS";
export const SET_EXPLORE_EVENTS_DATE = "SET_EXPLORE_EVENTS_DATE";
export const SET_TIMELINE_NAVIGATOR = "SET_TIMELINE_NAVIGATOR";
export const SET_OPEN_COACHMARK = "SET_OPEN_COACHMARK";

interface SetOpenCoachMark {
  type: typeof SET_OPEN_COACHMARK;
  payload: string;
}

interface SetBookTimelines {
  type: typeof SET_BOOK_TIMELINES;
  payload: IBookTimeline[];
}

interface SetBookDraftIdeas {
  type: typeof SET_BOOK_DRAFT_IDEAS;
  payload: IDraftIdea[];
}

interface SetActiveWorkGroup {
  type: typeof SET_ACTIVE_WORKGROUP;
  payload: IWorkgroup | undefined;
}

interface SetWorkgroups {
  type: typeof SET_WORKGROUPS;
  payload: IWorkgroup[];
}

interface SetPurchases {
  type: typeof SET_PURCHASES;
  payload: IPurchase[];
}

interface SetSubscriptions {
  type: typeof SET_SUBSCRIPTIONS;
  payload: ISubscription[];
}

interface SetSubscriptionFeatures {
  type: typeof SET_SUBSCRIPTION_FEATURES;
  payload: ISubscriptionFeature[];
}

interface SetActiveBook {
  type: typeof SET_ACTIVE_BOOK;
  payload: IBook | undefined;
}

interface SetActiveBookTimeline {
  type: typeof SET_ACTIVE_BOOK_TIMELINE;
  payload: IBookTimeline | undefined;
}

interface SetTimelineNavigator {
  type: typeof SET_TIMELINE_NAVIGATOR;
  payload: string;
}

interface SetUser {
  type: typeof SET_USER;
  payload: IUser | undefined;
}
interface SetBooks {
  type: typeof SET_BOOKS;
  payload: IBook[];
}
interface SetRecentBooks {
  type: typeof SET_RECENT_BOOKS;
  payload: IBook[];
}
interface SetExploreEventsDate {
  type: typeof SET_EXPLORE_EVENTS_DATE;
  payload: string;
}
interface SetEvents {
  type: typeof SET_EVENTS;
  payload: IEvent[];
}

export type GenericAction =
  | SetTimelineNavigator
  | SetActiveBook
  | SetActiveBookTimeline
  | SetUser
  | SetBookDraftIdeas
  | SetBooks
  | SetSubscriptions
  | SetPurchases
  | SetBookTimelines
  | SetSubscriptionFeatures
  | SetActiveWorkGroup
  | SetOpenCoachMark
  | SetWorkgroups
  | SetEvents
  | SetExploreEventsDate
  | SetRecentBooks;

const SetOpenCoachMark = (coachMark: string): SetOpenCoachMark => {
  return { type: SET_OPEN_COACHMARK, payload: coachMark };
};
const SetBookTimelines = (timelines: IBookTimeline[]): SetBookTimelines => {
  return { type: SET_BOOK_TIMELINES, payload: timelines };
};

const SetActiveWorkgroup = (
  workgroup: IWorkgroup | undefined
): SetActiveWorkGroup => {
  return { type: SET_ACTIVE_WORKGROUP, payload: workgroup };
};

const SetBookDraftIdeas = (draftIdeas: IDraftIdea[]): SetBookDraftIdeas => {
  return { type: SET_BOOK_DRAFT_IDEAS, payload: draftIdeas };
};

const SetWorkgroups = (workgroup: IWorkgroup[]): SetWorkgroups => {
  return { type: SET_WORKGROUPS, payload: workgroup };
};

const setPurchases = (purchases: IPurchase[]): SetPurchases => {
  return { type: SET_PURCHASES, payload: purchases };
};

const setSubscriptions = (subscriptions: ISubscription[]): SetSubscriptions => {
  return { type: SET_SUBSCRIPTIONS, payload: subscriptions };
};

const setSubscriptionFeatures = (
  subscriptions: ISubscriptionFeature[]
): SetSubscriptionFeatures => {
  return { type: SET_SUBSCRIPTION_FEATURES, payload: subscriptions };
};

const setActiveBook = (book: IBook | undefined): SetActiveBook => {
  return { type: SET_ACTIVE_BOOK, payload: book };
};

const setActiveBookTimeline = (
  activeTimeline: IBookTimeline | undefined
): SetActiveBookTimeline => {
  return { type: SET_ACTIVE_BOOK_TIMELINE, payload: activeTimeline };
};

const setTimelineNavigator = (navigator: string): SetTimelineNavigator => {
  return { type: SET_TIMELINE_NAVIGATOR, payload: navigator };
};

const setUser = (user: IUser | undefined): SetUser => {
  return { type: SET_USER, payload: user };
};

const setEvents = (events: IEvent[]): SetEvents => {
  return { type: SET_EVENTS, payload: events };
};
const setExploreEventsDate = (targetDate: string): SetExploreEventsDate => {
  return { type: SET_EXPLORE_EVENTS_DATE, payload: targetDate };
};
const setBooks = (books: IBook[]): SetBooks => {
  return { type: SET_BOOKS, payload: books };
};
const setRecentBooks = (books: IBook[]): SetRecentBooks => {
  return { type: SET_RECENT_BOOKS, payload: books };
};

export const GenericActions = {
  setUser,
  setEvents,
  SetWorkgroups,
  SetBookTimelines,
  setBooks,
  setActiveBook,
  setActiveBookTimeline,
  setExploreEventsDate,
  SetBookDraftIdeas,
  SetOpenCoachMark,
  setSubscriptionFeatures,
  SetActiveWorkgroup,
  setTimelineNavigator,
  setPurchases,
  setSubscriptions,
  setRecentBooks,
};
