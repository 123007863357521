import { Spinner } from "@fluentui/react";
import { useEffect, useState } from "react";
import "./Loadable.scss";
import SuperSpinner from "../SuperSpinner/SuperSpinner";

export interface ILoadableProps {
  isLoading: boolean;
  content: JSX.Element;
  noPadding?: boolean;
}

const Loadable = (props: ILoadableProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(props.isLoading);
  }, [props.isLoading]);

  return (
    <div className={props.noPadding ? "" : "loadable-main-wrap"}>
      {loading && (
        <div className={props.noPadding ? "" : "loadable-spinner-wrap"}>
          <SuperSpinner />
        </div>
      )}
      {!loading && <div>{props.content}</div>}
    </div>
  );
};

export default Loadable;
