import { IMonthMap } from "../../Components/MonthMapEditor/MonthMapEditor";
import { IEvent } from "../../Models/IEvent";
import {
  CustomDateISOFormat,
  CustomISODateCompare,
} from "../../Utils/EventProcessing";
import {
  SET_USER,
  GenericAction,
  SET_BOOKS,
  SET_EVENTS,
  SET_EXPLORE_EVENTS_DATE,
  SET_TIMELINE_NAVIGATOR,
  SET_ACTIVE_BOOK,
  SET_PURCHASES,
  SET_SUBSCRIPTIONS,
  SET_WORKGROUPS,
  SET_ACTIVE_WORKGROUP,
  SET_BOOK_DRAFT_IDEAS,
  SET_BOOK_TIMELINES,
  SET_ACTIVE_BOOK_TIMELINE,
  SET_SUBSCRIPTION_FEATURES,
  SET_OPEN_COACHMARK,
  SET_RECENT_BOOKS,
} from "./GenericAction";
import { GenericState } from "./GenericState";

export const getLoggedUser = () => {
  let u = localStorage.getItem("loggedUser");
  if (u) {
    return JSON.parse(u);
  } else {
    return undefined;
  }
};

export const getActiveWorkgroup = () => {
  let u = localStorage.getItem("activeWorkgroup");
  if (u) {
    return JSON.parse(u);
  } else {
    return undefined;
  }
};

export const getStoredNavigator = () => {
  let u = localStorage.getItem("storednavigator");
  if (u) {
    return u;
  } else {
    return "day";
  }
};

export const getStoredSubscriptions = () => {
  let u = localStorage.getItem("storedSubscriptions");
  if (u) {
    return JSON.parse(u);
  } else {
    return [];
  }
};

export const getStoredSubscriptionFeatures = () => {
  let u = localStorage.getItem("storedSubscriptionFeatures");
  if (u) {
    return JSON.parse(u);
  } else {
    return [];
  }
};

const DEFAULT_STATE: GenericState = {
  user: getLoggedUser(),
  books: [],
  recentBooks: [],
  globalevents: [],
  exploreEventsDate: "",
  timelineNavigator: getStoredNavigator(),
  activeBook: undefined,
  purchases: [],
  subscriptions: getStoredSubscriptions(),
  subscriptionFeatures: getStoredSubscriptionFeatures(),
  workgroups: [],
  activeWorkgroup: getActiveWorkgroup(),
  bookDraftIdeas: [],
  bookTimelines: [],
  activeTimeline: undefined,
  openCoachMark: "",
};

export const genericReducer = (
  state: GenericState = DEFAULT_STATE,
  action: GenericAction
): GenericState => {
  let res = genericReducerInner(state, action);
  (window as any)["reducerGenericState"] = res;
  return res;
};

const genericReducerInner = (
  state: GenericState = DEFAULT_STATE,
  action: GenericAction
): GenericState => {
  switch (action.type) {
    case SET_TIMELINE_NAVIGATOR:
      localStorage.setItem("storednavigator", action.payload);
      return {
        ...state,
        timelineNavigator: action.payload,
      };
    case SET_PURCHASES:
      return {
        ...state,
        purchases: action.payload,
      };
    case SET_SUBSCRIPTIONS:
      localStorage.setItem(
        "storedSubscriptions",
        JSON.stringify(action.payload)
      );

      return {
        ...state,
        subscriptions: action.payload,
      };
    case SET_SUBSCRIPTION_FEATURES:
      localStorage.setItem(
        "storedSubscriptionFeatures",
        JSON.stringify(action.payload)
      );

      return {
        ...state,
        subscriptionFeatures: action.payload,
      };
    case SET_ACTIVE_BOOK_TIMELINE:
      return {
        ...state,
        activeTimeline: action.payload,
      };
    case SET_OPEN_COACHMARK:
      return {
        ...state,
        openCoachMark: action.payload,
      };
    case SET_BOOK_DRAFT_IDEAS:
      return {
        ...state,
        bookDraftIdeas: action.payload,
      };
    case SET_ACTIVE_WORKGROUP:
      if (action.payload) {
        localStorage.setItem("activeWorkgroup", JSON.stringify(action.payload));
      } else {
        localStorage.removeItem("activeWorkgroup");
      }
      return {
        ...state,
        activeWorkgroup: action.payload,
      };
    case SET_BOOK_TIMELINES:
      return {
        ...state,
        bookTimelines: action.payload,
      };
    case SET_WORKGROUPS:
      return {
        ...state,
        workgroups: action.payload,
      };
    case SET_ACTIVE_BOOK:
      return {
        ...state,
        activeBook: action.payload,
      };
    case SET_BOOKS:
      return {
        ...state,
        books: action.payload,
      };
    case SET_RECENT_BOOKS:
      return {
        ...state,
        recentBooks: action.payload,
      };
    case SET_EVENTS:
      // little script to sort events by custom date
      let monthMap: IMonthMap = JSON.parse(state.activeBook?.MonthMap ?? "{}");
      let sortedEvents: IEvent[] = action.payload.sort(
        (a: IEvent, b: IEvent) => {
          let aDate = CustomDateISOFormat(a.CustomDate, monthMap);
          let bDate = CustomDateISOFormat(b.CustomDate, monthMap);
          return CustomISODateCompare(aDate, bDate);
        }
      );

      return {
        ...state,
        globalevents: sortedEvents,
      };
    case SET_EXPLORE_EVENTS_DATE:
      return {
        ...state,
        exploreEventsDate: action.payload,
      };
    case SET_USER:
      if (action.payload) {
        localStorage.setItem("loggedUser", JSON.stringify(action.payload));
      } else {
        localStorage.removeItem("loggedUser");
      }
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};
