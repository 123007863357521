import { APIUrls } from "../../ApiUrls";
import { IPurchase, IPurchaseRequest } from "../../Models/IPurchase";
import { IUser, IUserRegister } from "../../Models/IUser";
import { AjaxService, IAPIRequest, IAPIResponse } from "../AjaxService";

const StoreController = {
  GetSubscriptions: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.STORE.GET_SUBSCRIPTIONS;
    request.allowCache = true;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetSubscriptionFeatures: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.STORE.GET_SUBSCRIPTION_FEATURES;
    request.allowCache = true;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetSubscriptionFeatureUsages: async (
    workgroupId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url =
      APIUrls.STORE.GET_SUBSCRIPTION_FEATURES_USAGES +
      "?workgroupId=" +
      workgroupId;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetPurchases: async (
    purchaseId?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.STORE.GET_PURCHASES;
    if (purchaseId) {
      request.url += "?purchaseId=" + purchaseId;
    }
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  InsertPurchases: async (
    purchaseRequest: IPurchaseRequest,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.STORE.INSERT_PURCHASE;
    request.method = "POST";
    request.payload = purchaseRequest;

    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  TestVoucherEffect: async (
    voucherCode: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url =
      APIUrls.STORE.GET_VOUCHER_EFFECT + `?voucherCode=${voucherCode}`;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  UpdatePaypalTransactionStatus: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.STORE.UPDATE_PAYPAL_TRANSACTION_STATUS;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
};

export default StoreController;
