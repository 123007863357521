export const Dictionary = {
  __registration_link_email: `
    <html>
      <head>
          <title>Conferma il tuo account</title>
      </head>
      <body>
          <h2 style='background-color: #6197d6; padding: 0.5em; color: white; text-align: center;'>Conferma dell'account</h2>
          <div style='text-align: center'>
          <img src='$EMAIL_IMAGE' alt='image' title='image' style="display:block" width="450" height="450"/>
          <div style='padding: 2em;'>
            <div>Benvenuti a <strong>Plotter Studio</strong>!</div>
            <br />
            <div>Siete quasi pronti per iniziare a lavorare sui votri fantastici progetti. </div>
            <br />
            <div>Dobbiamo solo confermare la vostra registrazione per proteggere il vostro account. </div>
            <br />
            <div>Per favore, clicca sul seguente link per attivare il tuo account:</div>
            <br/>
            <div style='margin: 2em auto; background-color: #6197d6; padding: 0.5em; width: 15em;'>
                <a style='text-decoration: none;' href='$CURRENT_DOMAIN/confirmaccount?token=$TOKEN'>
                  <div style='color: white;'>Conferma account</div>
                </a>
            </div>
            <br />
            <br />
            <div style='font-size:0.8em'>
                Questa è una richiesta automatizzata, ti preghiamo di non rispondere direttamente a questa email. <br /> Per sapere di più su <strong>Plotter Studio</strong>, visita il nostro <a href='$CURRENT_DOMAIN/about'>chi siamo</a>.
            </div>
          </div>
      </body>
    </html>
    `,
  __invite_user_email: `
      <html>
        <head>
            <title>Invito a lavorare in team</title>
        </head>
        <body>
            <h2 style='background-color: #6197d6; padding: 0.5em; color: white; text-align: center;'>Richiesta di lavoro di squadra</h2>
            <div style='text-align: center'>
            <img src='$EMAIL_IMAGE' alt='image' title='image' style="display:block" width="450" height="450"/>
            <div style='padding: 2em;'>
              <div>Ciao,<br/><strong>$INVITERUSERNAME</strong> ha deciso di lavorare con te su un progetto di scrittura.</div>
              <br />
              <div>Sei stato invitato a unirti a un gruppo di lavoro su Plotter Studio!</div>
              <br />
              <div>Se hai bisogno di saperne di più, puoi contattare l'utente che ti ha invitato tramite email: <strong>$INVITEREMAIL</strong> </div>
              <br/>
              <div style='margin: 2em auto; background-color: #6197d6; padding: 0.5em; width: 15em;'>
                  <a style='text-decoration: none;' href='$CURRENT_DOMAIN/acceptinvitation?token=$TOKEN'>
                    <div style='color: white;'>Accetta invito</div>
                  </a>
              </div>
              <br />
              <br />
              <div style='font-size:0.8em'>
                  Questa è una richiesta automatizzata, ti preghiamo di non rispondere direttamente a questa email. <br /> Per sapere di più su <strong>Plotter Studio</strong>, visita il nostro <a href='$CURRENT_DOMAIN/about'>chi siamo</a>.
              </div>
            </div>
        </body>
      </html>
    `,
  __reset_password_link_email: `
      <html>
        <head>
            <title>Reimpostazione Password</title>
        </head>
        <body>
            <h2 style='background-color: #6197d6; padding: 0.5em; color: white; text-align: center;'>Reimpostazione della password</h2>
            <div style='text-align: center'>
            <div style='padding: 2em;'>
              Saluti da parte di <strong>Plotter Studio</strong>.<br /><br />Hai ricevuto questa email perché hai richiesto la reimpostazione della password. <br /><br />Per favore, clicca sul seguente link per reimpostare la tua password:<br/>    <br/>
              <div style='margin: 2em auto; background-color: #6197d6; padding: 0.5em; width: 15em;'>
                  <a style='text-decoration: none;' href='$CURRENT_DOMAIN/resetpasswordrequest?token=$TOKEN'>
                    <div style='color: white;'>Reimposta password</div>
                  </a>
              </div>
              <br />
              <br />
              <div style='font-size:0.8em'>
                  Questa è una richiesta automatizzata, ti preghiamo di non rispondere direttamente a questa email. <br /> Per sapere di più su <strong>Plotter Studio</strong>, visita il nostro <a href='$CURRENT_DOMAIN/about'>chi siamo</a>.
              </div>
            </div>
        </body>
      </html>
    `,
};
