export const Dictionary = {
  __metadata_type_class_family: "Familie",
  __metadata_type_class_beliefs: "Überzeugungen",
  __metadata_type_social_group_class_personal: "persönlich",
  __metadata_type_family_father_positive: "Familie – Vater (liebend)",
  __metadata_type_family_mother_positive: "Familie - Mutter (liebend)",
  __metadata_type_family_father_negative: "Familie – Vater (liebt nicht)",
  __metadata_type_family_mother_negative: "Familie – Mutter (nicht liebend)",
  __metadata_type_family_step_father_positive: "Familie – Stiefvater (liebend)",
  __metadata_type_family_step_mother_positive:
    "Familie – Stiefmutter (liebevoll)",
  __metadata_type_family_step_father_negative:
    "Familie – Stiefvater (liebt nicht)",
  __metadata_type_family_step_mother_negative:
    "Familie – Stiefmutter (nicht liebevoll)",

  __metadata_type_family_child_negative: "Familie – Kind (unerwünscht)",
  __metadata_type_family_child_positive: "Familie – Kind (erwünscht)",
  __metadata_type_family_sibling_negative:
    "Familie – Geschwister (unfreundlich)",
  __metadata_type_family_sibling_positive: "Familie – Geschwister (freundlich)",

  __metadata_type_religion_pregiudice_negative: "Negative religiöse Vorurteile",
  __metadata_type_religion_pregiudice_positive: "Positive religiöse Vorurteile",
  __metadata_type_religion_pregiudice_negative_aggressive:
    "Negative religiöse Vorurteile – Aggressiv",
  __metadata_type_race_pregiudice_negative: "Negative Rassenvorurteile",
  __metadata_type_race_pregiudice_positive: "Positive rassistische Vorurteile",
  __metadata_type_race_pregiudice_negative_aggressive:
    "Negative Rassenvorurteile – Aggressiv",

  __metadata_type_ethinicity_pregiudice_positive:
    "Positive ethnische Vorurteile",
  __metadata_type_ethinicity_pregiudice_negative:
    "Negative ethnische Vorurteile",
  __metadata_type_ethinicity_pregiudice_negative_aggressive:
    "Negative ethnische Vorurteile – Aggressiv",
  __metadata_type_sex_pregiudice_positive:
    "Positive sexuelle Voreingenommenheit",
  __metadata_type_sex_pregiudice_negative: "Negative sexuelle Vorurteile",
  __metadata_type_sex_pregiudice_negative_aggressive:
    "Negative sexuelle Vorurteile – Aggressiv",

  __metadata_type_social_group_religion_affiliation: "Religionszugehörigkeit",
  __metadata_type_social_group_personal_ethnicity: "Ethnizität",
  __metadata_type_social_group_personal_sex: "Sex",
  __metadata_type_social_group_personal_biological_race: "Biologische Rasse",
  __metadata_extradata_violence_inclination: "Neigung zur Gewalt",
  __metadata_extradata_scientific_inclination: "Wissenschaftliche Neigung",
  __metadata_extradata_military_inclination: "Militärische Neigung",
  __metadata_extradata_religious_inclination: "Religiöse Neigung",
  __metadata_extradata_political_inclination: "Politische Neigung",
  __metadata_extradata_economic_inclination: "Wirtschaftsneigung",
  __metadata_extradata_social_inclination: "Soziale Neigung",
  __metadata_extradata_cultural_inclination: "Kulturelle Neigung",
  __metadata_extradata_environmental_inclination: "Umweltneigung",
  __metadata_extradata_spiritual_inclination: "Spirituelle Neigung",
  __metadata_extradata_educational_inclination: "Bildungsneigung",
  __metadata_extradata_athletic_inclination: "Sportliche Neigung",
  __metadata_extradata_artistic_inclination: "Künstlerische Neigung",
  __metadata_extradata_musical_inclination: "Musikalische Neigung",
  __metadata_extradata_romantic_inclination: "Romantische Neigung",
  __metadata_extradata_familial_inclination: "Familiäre Neigung",
  __metadata_extradata_friendship_inclination: "Neigung zur Freundschaft",
  __metadata_extradata_professional_inclination: "Berufliche Neigung",

  __metadata_objective_of_character_encounter: "Jemanden treffen",
  __metadata_objective_of_character_marry: "Jemanden heiraten",
  __metadata_objective_of_character_reach_position: "Erreiche den Ort",
  __metadata_objective_of_character_obtain_object: "Holen Sie sich den Artikel",
  __metadata_objective_of_character_kill: "Töte jemanden",
  __metadata_objective_of_character_learn:
    "Eine Fähigkeit oder ein Konzept erlernen",
  __metadata_objective_of_character_live_event:
    "Sich in einem Ereignis oder einer Situation wiederfinden",
};
