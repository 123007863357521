export const Dictionary = {
  __dev_sub_name: "Entwickler",
  __dev_sub_description:
    "Ein Testabonnement zur Überprüfung der ordnungsgemäßen Funktion des Paypal-Shops. Dieses Abonnement ist für die Öffentlichkeit nicht sichtbar und kann nicht gekauft werden.",
  __single_user_mini_name: "Einzelbenutzer (Mini)",
  __single_user_mini_description:
    "Ein Abonnement für Personen, die das Gefühl haben, dass sie mehr Zeit benötigen, um das Produkt zu testen. Dieses Abonnement ist nahezu kostenlos und bietet einen kleinen Einblick in die Möglichkeiten dieser App für Ihre Projekte.",
  __single_user_name: "Einzelbenutzer",
  __single_user_description:
    "Ein Abonnement für den persönlichen Gebrauch. Perfekt für private Projekte oder Bastler.",
  __small_group_name: "Kleine Gruppe",
  __small_group_description:
    "Diese Zwischenlösung eignet sich für kleine Arbeitsgruppen, Studenten oder Familienmitglieder.",
  __office_group_name: "Büro",
  __office_group_description: "Lösung für die Beteiligung auf Büroebene. ",
  __enterprise_group_name: "Unternehmen",
  __enterprise_group_description:
    "Bringen Sie Ihr Team zu maximaler Produktivität und Kompetenz beim Schreiben von Inhalten. ",
  __month: "Monatlicher Preis",
  __subscription_includes: "Dieses Abonnement beinhaltet",
  __advanced_event_processing: "Automatische Korrektur des Story-Ereignisses.",
  __advanced_event_query: "Ereignisabfrage-Bot.",
  __advanced_event_advanced_processing:
    "Erweiterte Ereignisverarbeitung für Plots.",
  __book_limit_per_workgroup: "Bis zu 100 Bücher für jede Arbeitsgruppe",
  __price_yearly: "Jährlicher Preisnachlass",
  __workgroupsNumberFeature: "Arbeitsgruppenbetrag",
  __workgroupsSizeFeature: "Arbeitsgruppengröße",
  __eventErrorDetection: "Fehlererkennung",
  __queryBotFeature: "Abfrage-Bot",
  __bookAmountFeature: "Bücher pro Arbeitsgruppe",
  __workgroupsNumberFeatureDesc:
    "Verschiedene Arbeitsgruppen zur Organisation Ihrer Arbeiten.",
  __workgroupsSizeFeatureDesc:
    "Jede Arbeitsgruppe kann eine andere Gruppe von Mitwirkenden haben, sodass Sie verschiedene Teams für verschiedene Projekte verwalten können.",
  __eventErrorDetectionDesc:
    "Der Ereigniscompiler erkennt Inkonsistenzen für Sie. ",
  __queryBotFeatureDesc:
    "Ihre KI-Sekretärin. Stellen Sie einem intelligenten Bot Fragen zu jedem Ereignis, um den Überblick über alle wertvollen Informationen zu behalten. Keine Papiernotizen mehr, die in Ihrem Büro herumliegen.",
  __bookAmountFeatureDesc:
    "Jede von Ihnen erstellte Arbeitsgruppe kann diese Menge an Büchern speichern.",
  __per_month_calls: "Anrufe/Monat",
  __amount: "Menge",
};
