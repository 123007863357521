export const Dictionary = {
  __answer_label_data: "In data $DATE",
  __answer_label_age: "$CHARNAME è nato il $DATE, l'età attuale è $AGE",
  __answer_label_age_plus_death:
    "$CHARNAME è nato il $DATE ed è morto il $DATE, all'età di $AGE",
  __event_type_appear: "$CHARNAME appare il $DATE",
  __event_type_born: "$CHARNAME è nato il $DATE",
  __event_type_revealed: "$CHARNAME viene rivelato il $DATE",
  __event_type_disappears: "$CHARNAME scompare il $DATE",
  __event_type_changes_id:
    "$CHARNAME cambia la sua identità conosciuta, diventando $OBJECT in $DATE",
  __event_type_abbandons: "$CHARNAME abbandona il suo ruolo attuale il $DATE",
  __event_type_dies: "$CHARNAME muore il $DATE",
  __event_type_teams_up_with:
    "$CHARNAME si allea con $OTHERCHARACTERS il $DATE",
  __event_type_challenge:
    "$CHARNAME [con $OPS_OTHERCHARACTERS] sostiene la sfida $OBJECT il $DATE",
  __event_type_meets: "$CHARNAME incontra $OTHERCHARACTERS il $DATE",
  __event_type_friendship:
    "$CHARNAME diventa amico di $OTHERCHARACTERS il $DATE",
  __event_type_friendship_end:
    "$CHARNAME smetterà di essere amico di $OTHERCHARACTERS il $DATE",
  __event_type_engage:
    "$CHARNAME inizia una storia d'amore con $OTHERCHARACTERS il $DATE",
  __event_type_engage_end:
    "$CHARNAME interrompe la storia d'amore con $OTHERCHARACTERS il $DATE",
  __event_type_betrays: "$CHARNAME tradisce $OTHERCHARACTERS il $DATE",
  __event_type_shares_a_secret:
    "$CHARNAME condivide il segreto di $OBJECT con $OTHERCHARACTERS il $DATE",
  __event_type_kills: "$CHARNAME uccide $OBJECT il $DATE",
  __event_type_marries: "$CHARNAME si sposa con $OTHERCHARACTERS il $DATE",
  __event_type_allies: "$CHARNAME si allea con $OTHERCHARACTERS il $DATE",
  __event_type_situation:
    "$CHARNAME affronta la situazione $OBJECT [con $OPS_OTHERCHARACTERS] il $DATE",
  __event_type_falls_in_love:
    "$CHARNAME si innamora di $OTHERCHARACTERS il $DATE",
  __event_type_starts_mission:
    "$CHARNAME avvia il lavoro/missione $OBJECT il $DATE",
  __event_type_starts_mission_end:
    "$CHARNAME conclude il lavoro/missione $OBJECT il $DATE",
  __event_type_assumes_status: "$CHARNAME ottiene lo stato di $OBJECT il $DATE",
  __event_type_assumes_condition:
    "$CHARNAME assume la condizione di $OBJECT in $DATE",
  __event_type_reaches_location:
    "$CHARNAME raggiunge la posizione di $OBJECT il $DATE",
  __event_type_leaves_location:
    "$CHARNAME lascia la posizione di $OBJECT il $DATE",
  __event_type_learns_skill:
    "$CHARNAME apprende la capacità di $OBJECT il $DATE",
  __event_type_learns_secret:
    "$CHARNAME viene a conoscenza di $OBJECT il $DATE",
  __event_type_joins_faction:
    "$CHARNAME si unisce alla fazione $OBJECT il $DATE",
  __event_type_leaves_faction: "$CHARNAME lascia la fazione $OBJECT il $DATE",
  __event_type_obtains_item: "$CHARNAME ottiene l'elemento $OBJECT il $DATE",
  __event_type_book_closure: "$CHARNAME vede la fine del libro il $DATE",
  __event_type_holiday: "$CHARNAME esulta per $OBJECT il $DATE",
  __event_type_book_begins: "$CHARNAME vede l'inizio del libro il $DATE",
  __event_type_geopolitic:
    "$CHARNAME provoca l'effetto geopolitico di $OBJECT su $DATE",
  __event_type_natural_disaster:
    "$CHARNAME è coinvolto nel disastro di $OBJECT il $DATE",
  __event_type_war:
    "$CHARNAME ha partecipato alla guerra [di $OPS_OBJECT] il $DATE",
  __event_type_mass_kill:
    "$CHARNAME ha causato una massiccia distruzione [durante $OPS_OBJECT] il $DATE",
  __event_type_creates_or_builds:
    "$CHARNAME ha creato/costruito quanto segue: $OBJECT il $DATE",
  __event_type_rule_changer:
    "$CHARNAME ha cambiato una regola cosmica [di $OPS_OBJECT] il $DATE",
  __character_leaving_position_never_reached:
    "<strong>$CHARACTER</strong> ha lasciato il luogo <strong>$OBJECT</strong>, che è una posizione mai raggiunta in precedenza.",
  __character_cannot_marry_since_there_is_no_trust:
    "<strong>$CHARACTER</strong> non dovrebbe sposarsi con <strong>$OBJECT</strong>, poichè non ha raggiunto il giusto livello di affinità e intimità.",
  __character_cannot_betray_since_there_is_no_trust:
    "<strong>$CHARACTER</strong> non può effettivamente tradire <strong>$OBJECT</strong> poichè non esiste tra loro un rapporto di fiducia.",
  __no_errors_to_report:
    "Nessun errore rilevato in questa categoria. Ottimo lavoro!",

  __active_character_cannot_interact_with_unknown_character:
    "<strong>$CHARACTER</strong> non può interagire con <strong>$OBJECT</strong> poichè tale personaggio non risulta come introdotto nella trama.",
  __character_changing_id_to_unknown_alterego:
    "<strong>$CHARACTER</strong> non può assumere l'identità di <strong>$OBJECT</strong> poichè tale identità non risulta come introdotta nella trama.",
  __character_leaving_position_never_reached_quick_fix:
    "Per risolvere questo problema, il personaggio deve prima essere introdotto nella posizione di destinazione in un evento passato utilizzando l'evento: EVMAP#event_type_reaches_location",
  __character_cannot_marry_since_there_is_no_trust_quick_fix:
    "Per risolvere questo problema, il personaggio deve prima aumentare la quantità di interazione positiva con la persona sposata. La fiducia viene automaticamente aggiunta attraverso interazioni positive tra i personaggi. Se il problema riguarda qualche personaggio secondario con informazioni di base ridotte, puoi procedere a ignorarlo.",
  __character_cannot_betray_since_there_is_no_trust_quick_fix:
    "Per risolvere questo problema, il personaggio tradito deve prima essere in grado di fidarsi del traditore. La fiducia viene automaticamente aggiunta attraverso interazioni positive tra i personaggi.",
  __active_character_cannot_interact_with_unknown_character_quick_fix:
    "Per risolvere questo problema, devi prima aggiungere il personaggio sconosciuto alla trama usando un evento passato. Un personaggio viene automaticamente aggiunto alla trama se appare nell'elenco dei personaggi coinvolti di qualsiasi evento.",
  __character_changing_id_to_unknown_alterego_quick_fix:
    "Per risolvere questo problema, devi prima aggiungere l'alter-ego sconosciuto alla trama utilizzando tale alter-ego come personaggio indipendente usando un evento passato. Un personaggio viene automaticamente aggiunto alla trama se appare nell'elenco dei personaggi coinvolti di qualsiasi evento.",

  __character_befriending_disliked_person:
    "<strong>$CHARACTER</strong> non può diventare amico con <strong>$OBJECT</strong> poichè tra i due c'è non c'è fiducia.",
  __character_befriending_disliked_person_quick_fix:
    "Per risolvere questo problema, il personaggio che diventa amico deve liberarsi del disprezzo verso l'altra persona. La fiducia viene automaticamente aggiunta attraverso interazioni positive tra i personaggi.",

  __character_sharing_secret_with_untrusted_person:
    "<strong>$CHARACTER</strong> non dovrebbe condividere spontaneamente un segreto <strong>$OBJECT</strong> poichè tra i due non c'è fiducia sufficiente.",
  __character_sharing_secret_with_untrusted_person_quick_fix:
    "Per risolvere questo problema, il personaggio che condivide il segreto deve prima essere in grado di fidarsi dell'altra persona. La fiducia viene automaticamente aggiunta attraverso interazioni positive tra i personaggi.",

  __character_engaging_disliked_person:
    "<strong>$CHARACTER</strong> non dovrebbe ancora fidanzarsi con <strong>$OBJECT</strong> poichè si conoscono troppo poco.",
  __character_engaging_disliked_person_quick_fix:
    "Perchè il grado di fiducia tra i due aumenti, il personaggio deve prima incrementare il numero di interazioni positive con la persona fidanzata. La fiducia viene automaticamente aggiunta attraverso interazioni positive tra i personaggi.",
  __active_character_cannot_interact_with_unknown_character_to_him:
    "<strong>$CHARACTER</strong> non agire su <strong>$OBJECT</strong> poichè non si sono ancora mai incontrati.",
  __active_character_cannot_interact_with_unknown_character_to_him_quick_fix:
    "Per risolvere questo problema, devi prima aggiungere il personaggio sconosciuto alla trama usando un evento passato. Un personaggio viene automaticamente aggiunto alla trama se appare nell'elenco dei personaggi coinvolti di qualsiasi evento.",
};
