const DEFAULT_SETTINGS: any = {
  store_log_user_email: "",
  store_log_user_password: "",
  remember_me: false,
  icon_set: "simple_icon_set",
  language: navigator.language ?? "en-EN",
  event_class_add_type: "#64d53b",
  event_class_remove_type: "#500c0c",
  event_class_affect_others_type: "#29e0dd",
  event_class_change_status_type: "#fbcc4b",
  event_class_plot_milestone_type: "#dc00ff",
  event_class_strong_effects: "#ff008c",
  limit_dots_on_timeline: "5",
};

const GetSettings = (key: string) => {
  let d: any = localStorage.getItem("settings");
  if (d) {
    d = JSON.parse(d);
  } else {
    d = {};
  }
  return d[key] !== undefined ? d[key] : DEFAULT_SETTINGS[key];
};

const SetSettings = (key: string, value: any) => {
  let d: any = localStorage.getItem("settings");
  if (d) {
    d = JSON.parse(d);
  } else {
    d = {};
  }
  d[key] = value;
  localStorage.setItem("settings", JSON.stringify(d));
};

const ClearSettings = () => {
  localStorage.removeItem("settings");
};

const GetDefault = (key: string) => {
  return DEFAULT_SETTINGS[key];
};

const SettingsUtil = {
  GetSettings,
  GetDefault,
  SetSettings,
  ClearSettings,
};

export default SettingsUtil;
