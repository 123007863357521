import { useSelector } from "react-redux";
import { IEvent, IEventKarmaMap } from "../../Models/IEvent";
import PrettyTable from "../PrettyTable/PrettyTable";
import "./TimelineEventTable.scss";
import { GlobalState } from "../../Redux/RootReducer";
import { IBookTimeline } from "../../Models/IBookTimeline";
import {
  getEventClassByType,
  getEventTextByPartialKey,
} from "../../Utils/EventTypeMap";
import {
  betterCommaListDisplay,
  displayEventCarmaMap,
} from "../ExploreEventsTickGrid/ExploreEventsTickGrid";
import { PrimaryButton, SearchBox } from "@fluentui/react";
import { useState } from "react";
import Tr from "../../Utils/Translations/Translations";
import { Label } from "@fluentui/react";
import { IBook } from "../../Models/IBook";
import { IMonthMap } from "../MonthMapEditor/MonthMapEditor";
import { CustomDateISOFormat } from "../../Utils/EventProcessing";
import SettingsUtil from "../../Utils/Settings";
import ManageEventButtons from "../ManageEventButtons/ManageEventButtons";
import Tools from "../../Utils/Tools";
import { getKarmaOptions } from "../KarmaPicker/KarmaPicker";
import EventTypeIcon from "../EventTypeIcon/EventTypeIcon";
import BetterCoachMarks from "../BetterCoachMark/BetterCoachMark";
import NewEventButton from "../NewEventButton/NewEventButton";
export interface ITimelineEventTableProps {
  currentDeltaYear: number;
  currentCenterYear: number;
}

const TimelineEventTable = (props: ITimelineEventTableProps) => {
  const globalEvents: IEvent[] = useSelector(
    (state: GlobalState) => state.generic.globalevents
  );
  const activeTimeline: IBookTimeline | undefined = useSelector(
    (state: GlobalState) => state.generic.activeTimeline
  );
  const activeBook: IBook | undefined = useSelector(
    (state: GlobalState) => state.generic.activeBook
  );

  let targEvents = globalEvents.filter(
    (x: IEvent) => x.TimelineId === activeTimeline?.Id
  );

  return (
    <div>
      <div className="timeline-table-container">
        <PrettyTable
          extraToolbarItems={[<NewEventButton />]}
          headers={["", Tr.Translate("language", "event_generic_info")]}
          plainRows={targEvents}
          rows={targEvents.map((x: IEvent) => {
            return [
              <div>
                <ManageEventButtons targetEvent={x} />
                <div className="date-on-table">{x.CustomDate}</div>
              </div>,

              <div>
                <strong>{x.Title}</strong>
                <div>{x.Description}</div>
                <div style={{ height: "0.5em" }}></div>

                {x?.CharacterList && (
                  <div>
                    <strong>
                      {Tr.Translate("language", "event_character_list")}
                    </strong>
                    <br />
                    {betterCommaListDisplay(x?.CharacterList)
                      .split(",")
                      .filter((x) => x)
                      .map((char: string, j: number) => {
                        return (
                          <div key={j} className="table-event-char-line">
                            {char}
                          </div>
                        );
                      })}
                  </div>
                )}
                {x.KarmaMap && !x.KarmaMap.includes("[]") && (
                  <div>
                    <strong>{Tr.Translate("language", "karma_shift")}</strong>
                    <div>{displayEventCarmaMap(x.KarmaMap)}</div>
                    <div style={{ height: "0.5em" }}></div>
                  </div>
                )}
                <div>
                  <strong>{Tr.Translate("language", "event_type")}</strong>
                  <div style={{ height: "0.5em" }}></div>
                  <div>
                    {x?.EventType.split(", ").map((x: string, i: number) => {
                      // class of event
                      let classOfType = x ? getEventClassByType(x) : "";

                      // color for class of event
                      let circleColor = classOfType
                        ? SettingsUtil.GetSettings(classOfType)
                        : "#000000";

                      return (
                        <div
                          key={i}
                          className="table-event-display-item-type-wrap-outer"
                        >
                          <div className="table-event-display-item-type-wrap">
                            <EventTypeIcon
                              hideLabel
                              //requestSizeEm="6em"
                              iconKey={x}
                              iconText={getEventTextByPartialKey(x) ?? ""}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div style={{ height: "0.5em" }}></div>

                {x?.EventKeyword && (
                  <div>
                    <strong>{Tr.Translate("language", "event_keyword")}</strong>
                    <br />
                    {betterCommaListDisplay(x?.EventKeyword)
                      .split(",")
                      .filter((x) => x)
                      .map((keyword: string, j: number) => {
                        return (
                          <div key={j} className="table-event-char-line">
                            {keyword}
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>,
            ];
          })}
        />
      </div>
    </div>
  );
};

export default TimelineEventTable;
