import { ITextFieldProps, TextField } from "@fluentui/react";
import { useState } from "react";
import "./BetterTextField.scss";
import Tr from "../../Utils/Translations/Translations";

export interface IBetterTextFieldProps extends ITextFieldProps {
  autoHeight?: number;
}

const BetterTextField = (props: IBetterTextFieldProps) => {
  const [currentValue, setCurrentValue] = useState<string>(
    props.defaultValue ?? props.value ?? ""
  );

  return (
    <div style={{ position: "relative" }}>
      <TextField
        {...props}
        onKeyDown={(e: any) => {
          setTimeout(() => {
            if (props.autoHeight && props.multiline) {
              e.target.style.height = "auto";
              let newHeight =
                e.target.scrollHeight > props.autoHeight
                  ? props.autoHeight
                  : e.target.scrollHeight;
              e.target.style.height = newHeight + "px";
            }
          }, 200);
        }}
        onChange={(e, t) => {
          if (props.onChange) {
            props.onChange(e, t);
          }

          if (t !== undefined) {
            setCurrentValue(t);
          }
        }}
      />
      {props.maxLength && (
        <div className="max-count-text">
          {Tr.Translate("language", "remaining_chars")}{" "}
          {props.maxLength - currentValue.length} / {props.maxLength}
        </div>
      )}
    </div>
  );
};

export default BetterTextField;
