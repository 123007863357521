import { useSelector } from "react-redux";
import CenterPad from "../../Components/CenterPad/CenterPad";
import { GenerateAssetUrl } from "../../Components/ImageAssetLoader/ImageAssetLoader";
import { GlobalState } from "../../Redux/RootReducer";
import Generic from "../../Utils/Generic";
import "./MyPurchasesPage.scss";
import { IPurchase } from "../../Models/IPurchase";
import PrettyTable from "../../Components/PrettyTable/PrettyTable";
import { ISubscription } from "../../Models/ISubscription";
import Tr from "../../Utils/Translations/Translations";
import { useEffect, useState } from "react";
import { PrimaryButton } from "@fluentui/react";

const MyPurchasesPage = () => {
  const [pagePurchases, setPagePurchases] = useState<IPurchase[]>([]);

  const purchases: IPurchase[] = useSelector(
    (state: GlobalState) => state.generic.purchases
  );

  const subscription: ISubscription[] = useSelector(
    (state: GlobalState) => state.generic.subscriptions
  );

  useEffect(() => {
    setPagePurchases(
      JSON.parse(JSON.stringify(purchases)).sort(
        (a: IPurchase, b: IPurchase) => {
          return (
            new Date(b.CreateDT as any).getTime() -
            new Date(a.CreateDT as any).getTime()
          );
        }
      )
    );
  }, [purchases, subscription]);

  const getPurchaseRowData = (purchase: IPurchase) => {
    const targetSubscription = subscription.find(
      (x: ISubscription) => x.Id === purchase.SubscriptionID
    );

    const cuponDiscount = purchase.Discount
      ? (purchase.Discount * purchase.Price) / 100
      : 0;

    const paydPrice = purchase.PaidOn ? purchase.Price - cuponDiscount : 0;

    return [
      <div className="table-slot">
        {purchase?.CreateDT?.replace("T", " ").substring(0, 16)}
      </div>,
      <div className="table-slot">
        {purchase?.UpdateDT?.replace("T", " ").substring(0, 16)}
      </div>,
      <div className="table-slot">
        {Tr.Translate("subscription", targetSubscription?.SubscriptionName)}
      </div>,
      <div className="table-slot">{purchase.Price.toFixed(2)}</div>,
      <div className="table-slot">
        {cuponDiscount > 0 ? cuponDiscount.toFixed(2) : "-"}
      </div>,
      <div className="table-slot">{paydPrice.toFixed(2)}</div>,
      <div className="table-slot">
        {purchase?.PaidOn
          ? purchase?.PaidOn.replace("T", " ").substring(0, 16)
          : "-"}
      </div>,

      <div className="table-slot">{purchase.TrialDays}</div>,
      <div className="table-slot">
        {purchase.RequestedTrial
          ? Tr.Translate("language", "yes")
          : Tr.Translate("language", "no")}
      </div>,
      <div className="table-slot">
        {purchase.TrialStillValid
          ? Tr.Translate("language", "yes")
          : Tr.Translate("language", "no")}
      </div>,
      <div className="table-slot">
        {purchase?.ExpiresOn?.replace("T", " ").substring(0, 16)}
      </div>,
      <div className="table-slot">{purchase.PaymentType}</div>,
      <div className="table-slot">
        {purchase.PurchaseValid
          ? Tr.Translate("language", "yes")
          : Tr.Translate("language", "no")}
      </div>,
    ];
  };

  return (
    <div
      className="my-purchase-page-wrap"
      style={Generic.GetRandomBackgroundUrlCss()}
    >
      <CenterPad
        isWidePad
        content={
          <div>
            <div className="my-purchases-table-wrap">
              <PrettyTable
                extraToolbarItems={[
                  <PrimaryButton
                    text={Tr.Translate("language", "back")}
                    onClick={() => {
                      window.history.back();
                    }}
                  />,
                ]}
                headers={[
                  Tr.Translate("language", "created_on"),
                  Tr.Translate("language", "last_upated"),
                  Tr.Translate("language", "license_type"),
                  Tr.Translate("language", "full_price") + " (€)",
                  Tr.Translate("language", "voucher_code") + " (€)",
                  Tr.Translate("language", "amount_paid") + " (€)",
                  Tr.Translate("language", "paid_on"),
                  Tr.Translate("language", "trial_days"),
                  Tr.Translate("language", "requested_trial"),
                  Tr.Translate("language", "trial_in_progress"),
                  Tr.Translate("language", "purchase_expire_on"),
                  Tr.Translate("language", "payment_type"),
                  Tr.Translate("language", "payment_is_valid"),
                ]}
                rowKeyBinder={(row: IPurchase) => {
                  let key = "";

                  key += row.PurchaseValid
                    ? Tr.Translate("language", "yes")
                    : Tr.Translate("language", "no");

                  key += row.RequestedTrial
                    ? Tr.Translate("language", "yes")
                    : Tr.Translate("language", "no");

                  key += row.TrialStillValid
                    ? Tr.Translate("language", "yes")
                    : Tr.Translate("language", "no");

                  return key;
                }}
                plainRows={pagePurchases.map((purchase: IPurchase) => {
                  (purchase as any).extraRowKeys = purchase.Id;
                  return purchase;
                })}
                rows={pagePurchases.map((purchase: IPurchase) => {
                  return getPurchaseRowData(purchase);
                })}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default MyPurchasesPage;
