import { GenerateAssetUrl } from "../Components/ImageAssetLoader/ImageAssetLoader";

let lastSelectedImage: any = null;
const GetRandomBackgroundUrlCss = () => {
  if (lastSelectedImage) {
    return lastSelectedImage;
  }
  const randomNumber = Math.floor(Math.random() * 9) + 1;
  let result = {
    backgroundImage: GenerateAssetUrl(
      "/Assets/BackgroundsBooks/bk_" + randomNumber + ".jpg",
      true
    ),
  };

  lastSelectedImage = result;
  return result;
};

const Generic = {
  GetRandomBackgroundUrlCss,
};

export default Generic;
