import {
  IWorkgroupChat,
  IWorkgroupChatNotRead,
} from "../../Models/IWorkgroupChat";

export const SET_WORKGROUP_CHATS = "SET_WORKGROUP_CHATS";
export const SET_WORKGROUP_CHATS_NOT_READ = "SET_WORKGROUP_CHATS_NOT_READ";
export const SET_WORKGROUP_CHATS_ACTIVITY_LEVEL =
  "SET_WORKGROUP_CHATS_ACTIVITY_LEVEL";

interface SetWorkgroupChatNotRead {
  type: typeof SET_WORKGROUP_CHATS_NOT_READ;
  payload: IWorkgroupChatNotRead[];
}

interface SetWorkgroupChatActivityLevel {
  type: typeof SET_WORKGROUP_CHATS_ACTIVITY_LEVEL;
  payload: number;
}

interface SetWorkgroupChat {
  type: typeof SET_WORKGROUP_CHATS;
  payload: IWorkgroupChat[];
}

export type ChatAction =
  | SetWorkgroupChat
  | SetWorkgroupChatNotRead
  | SetWorkgroupChatActivityLevel;

const SetWorkgroupChatNotRead = (
  chats: IWorkgroupChatNotRead[]
): SetWorkgroupChatNotRead => {
  return { type: SET_WORKGROUP_CHATS_NOT_READ, payload: chats };
};

const SetWorkgroupChat = (chats: IWorkgroupChat[]): SetWorkgroupChat => {
  return { type: SET_WORKGROUP_CHATS, payload: chats };
};

const SetWorkgroupChatActivityLevel = (
  level: number
): SetWorkgroupChatActivityLevel => {
  if (level < 0) {
    level = 0;
  }
  if (level > 15) {
    level = 15;
  }
  return { type: SET_WORKGROUP_CHATS_ACTIVITY_LEVEL, payload: level };
};

export const ChatActions = {
  SetWorkgroupChat,
  SetWorkgroupChatActivityLevel,
  SetWorkgroupChatNotRead,
};
