import { useEffect, useState } from "react";
import "./BetterRecaptcha.scss";

export interface IBetterRecaptchaProps {
  refresh: boolean; // This is a boolean that will be used to refresh the recaptcha
  emitToken: (token: string) => void; // This is a function that will be called when the token is emitted
}

const BetterRecaptcha = (props: IBetterRecaptchaProps) => {
  const [refresh, setRefresh] = useState(false);

  const onRecaptchaLoadCallback = () => {
    var win = window as any;
    var clientId = win.grecaptcha.render("inline-badge", {
      sitekey: "6LccWU0qAAAAABNDMUXVLusZPEW-1kEyPl_nPqbw",
      badge: "inline",
      size: "invisible",
    });

    win.grecaptcha.ready(function () {
      win.grecaptcha
        .execute(clientId, {
          action: "action_name",
        })
        .then(function (token: any) {
          props.emitToken(token);
        })
        .catch((error: any) => {
          props.emitToken("");
        });
    });
  };

  useEffect(() => {
    setRefresh(true);
    setTimeout(() => {
      setRefresh(false);
    }, 500);
  }, [props.refresh]);

  useEffect(() => {
    setTimeout(onRecaptchaLoadCallback, 500);
  }, []);

  return <div className="recaptcha-main-wrap" id="inline-badge"></div>;
};

export default BetterRecaptcha;
