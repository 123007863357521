export const Dictionary = {
  __welcome: "Bienvenido",
  __not_found_page: "¡Extraviado!",
  __not_found_page_subtitle:
    "La página que estás buscando no existe o requiere que inicies sesión.",
  __login: "Acceso",
  __goto_home: "Volver a la página de inicio",
  __password: "Contraseña",
  __username: "Nombre de usuario",
  __image_load: "Cargar imagen",
  __image_profile_load: "Cargar foto de perfil",
  __confirm_password: "Confirmar Contraseña",
  __email: "Correo electrónico",
  __email_not_received: "¿No se recibió el correo electrónico de confirmación?",
  __send_email: "Enviar nuevo correo electrónico",
  __no_such_email_registered:
    "El correo electrónico proporcionado no está registrado en nuestro sistema.",
  __no_account: "¿No tienes una cuenta?",
  __register: "¡Registro!",
  __forgot_password: "¿Has olvidado tu contraseña?",
  __reset_password: "¡Reinicialo!",
  __load_older_chat_messages: "Cargar mensajes anteriores",
  __recaptcha_test_failed: "La prueba de recaptcha falló.",
  __send_reset_email_title:
    "Inserte el correo electrónico que utilizó para registrarse. Le enviaremos un enlace para restablecer su contraseña.",
  __send_reset_email: "Envío de correo electrónico",
  __login_done: "¡Inicio de sesión completado!",
  __new_book: "Nuevo libro",
  __password_reset_complete: "Restablecimiento de contraseña completado.",
  __password_reset_email_sent:
    "Podemos enviarle un correo electrónico con un enlace para restablecer su contraseña.",
  __registration_done_sent_email:
    "¡Listo! Le hemos enviado un correo electrónico. Haga clic en el enlace del correo electrónico para confirmar su cuenta.",
  __done: "¡Todo listo!",
  __user_already_registered:
    "Este correo electrónico ya está registrado y no se puede utilizar nuevamente para registrar otra cuenta.",
  __back: "Atrás",
  __email_registered: "Activación de cuenta completa. ",
  __this_account_needs_verification:
    "Esta cuenta necesita verificación antes de poder acceder al sistema. ",
  __bad_credentials: "El correo electrónico o la contraseña no coinciden.",
  __cancel: "Cancelar",
  __accept: "Aceptar",
  __password_mismatch:
    "La contraseña y la contraseña de confirmación no son iguales.",
  __add_new_book_form: "Añade un nuevo libro para empezar.",
  __book_title: "Titulo del libro",
  __book_description: "Descripción del libro",
  __book_collection: "colección de libros",
  __create_first_timeline: "Crea la primera línea de tiempo",
  __book_timeline_center_year: "Centrar en el año",
  __book_timeline_delta_year: "Ancho del intervalo",
  __allow_custom_months: "Meses personalizados",
  __add: "Agregar",
  __month_name: "nombre del mes",
  __month_days: "Días por mes",
  __month_leap_every: "mes bisiesto",
  __month_leap_every_hint:
    "Defina cada cuantos años el mes gana un día, a partir del año cero. ",
  __session_expired: "Tu sesión ha caducado, debes iniciar sesión nuevamente.",
  __book_added: "Libro añadido a tu biblioteca.",
  __last_upated: "Actualizado en",
  __book_start: "año central",
  __table_rows_page_prev: "Pagina anterior",
  __table_rows_page_next: "Siguiente página",
  __table_rows_per_page: "Por página",
  __table_rows: "Filas $LLIM a $ULIM de $MLIM",
  __password_must_be_8_long: "La contraseña debe tener al menos 8 caracteres.",
  __add_new_event: "Agregar nuevo evento el día",
  __new_event: "Nuevo evento",
  __event_title: "Título del evento",
  __event_description: "Descripción del evento",
  __event_type: "Tipo de evento",
  __event_character_list: "Caracteres (opcional)",
  __event_keyword: "Asunto del evento (opcional)",
  __event_added_succesfully: "Evento agregado a la línea de tiempo.",
  __must_add_at_least_one_month: "Debes agregar al menos un mes.",
  __exploring_on_date: "Explorando eventos en la fecha",
  __delete_event: "¿Eliminar evento?",
  __edit_event: "Editar evento",
  __delete_book: "¿Eliminar libro?",
  __edit_book: "Editar libro",
  __action_cannot_be_undone:
    "Esta acción eliminará permanentemente este elemento. ¿Estás seguro de que deseas eliminarlo?",
  __event_deleted_succesfully: "Evento eliminado.",
  __event_edited_succesfully: "Evento editado.",
  __book_deleted_succesfully: "Libro eliminado.",
  __book_edited_succesfully: "Libro editado.",
  __book_total_days_per_year: "Días totales por año",
  __comma_separated_list: "Inserte una lista de elementos separados por coma. ",
  __no_events_on_this_day: "No hay eventos este día.",
  __expand: "Ampliar detalles",
  __collapse: "Ocultar detalles",
  __book_timeline_navigator_mode: "Modo de línea de tiempo",
  __year_view: "Vista anual",
  __month_view: "Vista mensual",
  __day_view: "Vista del día",
  __year: "Año",
  __month: "Mes",
  __day: "Día",
  __custom_date: "Fecha del evento",
  __ask_new_one: "Reenviar",
  __ask_new_many: "Reenviar",
  __ask_new_other: "Reenviar",
  __search: "Busca",
  __no_workgroup_ready:
    "Aún no tienes grupos de trabajo. ¡Crea uno para comenzar a trabajar en tu escritura!",
  __add_workgroup: "Agregar nuevo grupo de trabajo",
  __edit_workgroup: "Editar grupo de trabajo",
  __workgroup_name: "Nombre del grupo de trabajo",
  __workgroup_description: "Descripción del grupo de trabajo",
  __workgroup_logo: "Logotipo del grupo de trabajo",
  __workgroup_license: "Asignar la licencia al grupo de trabajo.",
  __license_selection_is_required:
    "Debe seleccionar una licencia para crear un nuevo grupo de trabajo.",
  __obtain_a_license: "Tienda de licencias Plotter Studio",
  __workgroup_size: "Tamaño del grupo de trabajo",
  __workgroup_amount: "Número de grupos de trabajo",
  __purchase: "Compra",
  __purchase_with_trial: "Empiza la prueba gratuita",
  __must_accept_terms: "Debes aceptar términos y condiciones para continuar.",
  __purchase_with_trial_hint_large:
    "Está disponible una prueba gratuita de días $FDAYS (no se requiere tarjeta de crédito). ¡Solo se requiere registro!",
  __purchase_with_trial_hint:
    "La prueba gratuita durará $FDAYS días, luego deberá comprar una licencia para seguir trabajando.",
  __purchase_with_trial_about_to_start:
    "Está a punto de solicitar una prueba gratuita de una suscripción. El período de prueba comienza inmediatamente. Una vez que expire el período de prueba, deberá completar la compra de la suscripción.",
  __trial_started: "El período de prueba comenzó con éxito.",
  __subscription_must_expire_first:
    "Ya compraste esta suscripción. No puedes volver a comprarla hasta que caduque.",
  __workgroup_added: "¡Grupo de trabajo creado!",
  __workgroup_edited: "¡Grupo de trabajo modificado!",
  __workgroup_open: "Seleccionar",
  __workgroup_main_list:
    "Seleccione un grupo de trabajo para cargar el espacio de trabajo.",
  __change_workgroup: "Cambiar grupo de trabajo",
  __edit: "Editar",
  __max_workgroup_limit_for_license:
    "No puede agregar más grupos de trabajo en esta licencia. Ha alcanzado el límite de grupos de trabajo admitidos para esta licencia.",
  __delete: "Borrar",
  __delete_workgroup: "¿Eliminar grupo de trabajo?",
  __delete_workgroup_accept:
    "Doy mi consentimiento para la eliminación de todos los libros, eventos, cronogramas y cualquier otro dato relacionado con este grupo de trabajo. Entiendo que esta acción es destructiva y permanente.",
  __delete_workgroup_warning:
    "Asegúrese. Si elimina un grupo de trabajo, todos los libros del grupo de trabajo y todos sus eventos relacionados se eliminarán para siempre. Esta acción no se puede deshacer.",
  __edit_workgroup_people: "Gestiona tu equipo",
  __invite_user_to_group: "Email nuevo miembro",
  __allow_standard_months: "Meses estándar",
  __more_actions_in_date: "Más acciones en la fecha",
  __move_all_events_on_a_different_date: "Mover todos los eventos a otra fecha",
  __events_in_this_date: "Eventos en esta fecha",
  __total_days: "Días totales",
  __invite_user_to_group_info:
    "Inserta el correo electrónico de otro usuario para invitarlo a unirse a tu grupo de trabajo. El usuario invitado deberá aceptar la invitación para completar este proceso.",
  __invite_user_not_found:
    "El usuario invitado no está registrado en Plotter Studio. Asegúrese de que cualquier usuario involucrado haya completado el paso de registro gratuito antes de la invitación.",
  __invitation_sent: "Invitación enviada.",
  __trial_ends_on: "El juicio termina el",
  __purchase_expire_on: "Expira el",
  __proceed_removing_user:
    "¿Continuar con la eliminación del usuario? El usuario será eliminado del grupo de trabajo.",
  __invitation_accepted: "¡Invitación aceptada!",
  __pick_book_to_start: "Elija un libro para comenzar.",
  __query_modal_title: "Motor AI",
  __query_modal_date_specific:
    "Todos los resultados dependerán de la fecha seleccionada.",
  __query_target_character: "Haz una pregunta sobre este personaje.",
  __query_target_type: "Lo que usted quiere saber",
  __age: "Edad",
  __knows_about_character: "Conocido por",
  __has_skill_or_ability: "Tiene habilidad",
  __posses_item: "Posesiones de artículo",
  __faction: "Facción",
  __status: "Estado",
  __condition: "Condición",
  __location: "Ubicación",
  __object_of_the_query: "Objeto de la pregunta",
  __character: "Personaje",
  __item: "Objeto",
  __skill: "Habilidad",
  __tell_mee_more: "Cuéntame más",
  __secret: "Secreto",
  __run_question: "Ejecutar consulta",
  __run_question_fields_missing: "Faltan algunos campos",
  __elapsed: "transcurrido",
  __character_evolution: "Evolución del personaje",
  __anyone: "Alguien",
  __any: "Cualquier",
  __jump_timeline_ticks: "Tick de salto",
  __jump_timeline_ticks_hint:
    "Introduzca el número de tick para avanzar desde la fecha actual. Para retroceder utilice números negativos. Se aceptan fórmulas simples (por ejemplo, en la vista diaria, 5 x 7 se mueve 5 semanas).",
  __bad_input: "Sólo se aceptan números o fórmulas básicas.",
  __this_is_infinite: "¡Su valor de entrada es infinito!",
  __remember_me: "Acuérdate de mí",
  __years: "Años",
  __months: "Meses",
  __days: "Días",
  __goto_last_event: "Último evento",
  __expired_purchase:
    "O su suscripción expiró o su prueba gratuita concluyó. Para seguir usando el grupo de trabajo, debe corregir el estado de la suscripción.",
  __renew_license_purchase: "Renovar licencia",
  __buy_license: "Compra licencia",
  __already_have_trial_on_this:
    "Ya solicitaste una prueba gratuita para este tipo de suscripción. Solo puedes solicitar una prueba gratuita por tipo de suscripción.",
  __payment_type_month: "Duración: 1 mes",
  __payment_type_year: "Duración: 1 año",
  __voucher_code: "Cupón",
  __subscrbe_recurrent_payment:
    "En su lugar, quiero suscribirme a un pago recurrente automático.",
  __cupon_not_allowed:
    "El código de cupón no está disponible para pagos recurrentes",
  __purchase_is_now_free:
    "¡Tu total es 0! ¡Felicidades, puedes omitir el paso de pago!",
  __purchase_completed: "¡Compra completada!",
  __requires_payment: "Requiere pago",
  __bad_cupon: "Su código de cupón no es válido o ha caducado.",
  __search_events: "Buscar eventos",
  __search_results: "Resultados de la búsqueda",
  __case_sensitive: "Distingue mayúsculas y minúsculas",
  __draft_ideas_title: "Maneja aquí tus borradores de ideas",
  __draft_idea_title: "Título del borrador de la idea",
  __draft_idea_description: "Descripción del borrador de la idea",
  __draft_idea_status: "Estado del borrador de la idea",
  __add_draft_idea: "Agregar borrador",
  __edit_draft_idea: "Editar un borrador de idea",
  __draft_ideas: "Borradores de ideas",
  __delete_draft_idea: "Eliminar idea borrador",
  __draf_idea_deleted: "Borrador de idea eliminado.",
  __draf_idea_edited: "Borrador de idea actualizado correctamente.",
  __draf_idea_added: "Idea de arrastre creada.",
  __draft_idea_status_active: "Activo",
  __draft_idea_status_archived: "Archivado",
  __draft_idea_status_completed: "Terminado",
  __draft_idea_status_to_review: "Para revisar",
  __no_draft_ideas_yet:
    "Aún no tienes borradores de ideas. Usa este formulario para agregar una nueva idea. Las ideas se utilizan para realizar un seguimiento de cómo podría evolucionar tu historia.",
  __remaining_chars: "Personajes restantes",
  __manage_timelines: "Administrar las líneas de tiempo del libro",
  __timeline_name: "Nombre",
  __timeline_duration: "Duración (Años)",
  __timeline_order: "Orden",
  __timeline_center_year: "Año central",
  __timeline_add: "Agregar línea de tiempo",
  __timeline_edit: "Editar línea de tiempo",
  __timeline_delete: "Eliminar línea de tiempo",
  __timeline_chose_workwith: "Elige una línea de tiempo",
  __timeline_add_done: "Línea de tiempo creada correctamente.",
  __timeline_edit_done: "Línea de tiempo actualizada correctamente.",
  __timeline_delete_done: "Línea de tiempo eliminada.",
  __notimeline_yet:
    "Parece que no tienes ninguna línea de tiempo configurada para este libro. Todos los libros requieren al menos una línea de tiempo para comenzar. Luego puedes agregar tantas líneas de tiempo como desees.",
  __acknowledge_editmonth_can_screw_up_things:
    "Acepto que la modificación a la estructura mensual realizada en este formulario no corregirá automáticamente las fechas de cualquier evento ya presente en la línea de tiempo. Por lo tanto, entiendo que después de modificaciones en la estructura mensual, algunos de los eventos pueden perder su lugar en el tiempo. línea.",
  __you_must_acknowledge_book_kill:
    "Debe aceptar la eliminación de los siguientes elementos antes de poder continuar.",
  __erease_current_book: "Borrar libro actual",
  __erease_all_events: "Borrar eventos de $AMOUNT",
  __erease_all_draft_ideas: "Borrar ideas de borrador de $AMOUNT",
  __erease_all_timelines: "Borrar cronogramas de $AMOUNT",
  __pay_monthly: "Deseo pagar cada mes",
  __pay_yearly: "Deseo pagar cada año",
  __read_terms_and_condition: "Lea los términos y condiciones aquí",
  __read_about_privacy_statement: "Lea nuestra política de privacidad aquí",
  __terms_and_cond:
    "Acepto los términos y condiciones de los servicios y la declaración de privacidad.",
  __event_timeline: "Cronograma del evento",
  __character_will_be_born_in: "El personaje nacerá en ",
  __character_life_at_least_this_time:
    "No hay datos claros sobre el nacimiento de este personaje. Se sabe que el personaje estuvo presente al menos en esta época:",
  __character_life_inferred:
    "Si el personaje sigue vivo, hasta este momento tendría esta edad:",
  __logout: "Cerrar sesión",
  __settings: "Ajustes",
  __user_settings: "Ajustes de usuario",
  __events_colors: "Color de eventos (para grupo de eventos)",
  __acknowledge_edittimeline_can_screw_up_things:
    "Acepto que el cambio en la estructura de la línea de tiempo realizado en este formulario no corregirá automáticamente las fechas de ningún evento que ya esté presente en la línea de tiempo. Por lo tanto, entiendo que después de modificaciones en la estructura de la línea de tiempo, algunos de los eventos pueden perder su ubicación.",
  __acknowledge_timeline_removal:
    "Acepto eliminar la línea de tiempo y todos los eventos que se encuentran actualmente en ella.",
  __timeline_begin_year: "Comienza el año",
  __timeline_end_year: "Fin de año",
  __copy: "Copiar elemento",
  __required_fields_missing: "Faltan algunos campos obligatorios.",
  __timeline_view: "Vista de línea de tiempo",
  __navigate_mobile_menu: "Alternar menú móvil",
  __please_pick_a_timeline: "Seleccione una línea de tiempo.",
  __table_view: "Vista de tabla",
  __all: "Todo",
  __all_hint: "Ingresa '$ALL' para una búsqueda sin restricciones.",
  __save: "Ahorrar",
  __color_for_event_type: "Color de viñeta para tipo de evento",
  __generic_ui_settings: "Configuración genérica de la interfaz de usuario",
  __limit_dots_on_timeline: "Viñetas máximas para marcar la línea de tiempo",
  __settings_updated: "Configuración actualizada correctamente.",
  __your_subscription_doesnt_support_this_feature:
    "La suscripción actualmente en uso no admite esta función.",
  __query_bot: "secretaria virtual",
  __error_detection: "Detección de errores",
  __workgroup_book_limit_title: "¡Libros demasiado mani!",
  __expired: "Venció",
  __workgroup_book_limit_description:
    "Parece que tienes demasiados libros en este grupo de trabajo. Los libros que sobren aún se mostrarán en la estantería, pero no podrás operar con ellos de ninguna manera. Si deseas desbloquearlos, puedes actualizar la suscripción vinculada a este grupo de trabajo. grupo de trabajo.",
  __book_limit_reached:
    "No puede agregar más libros al grupo de trabajo actual. Debe actualizar su licencia.",
  __feature_usage_limit_reached:
    "No puede utilizar esta función hasta que obtenga más tokens de uso. Puede navegar hasta el panel de suscripciones para realizar un seguimiento de todos los usos.",
  __getting_updates:
    "Obteniendo actualizaciones. Esto tomará unos segundos. Espere...",
  __sync: "sincronizar",
  __got_it: "Entiendo",
  __show_again_tutorial_coachmarkers:
    "Restaurar la visibilidad de todos los Coachmarkers del tutorial",
  __statistics: "Datos de uso del grupo de trabajo",
  __event_generic_info: "Información general",
  __no_usages_recorded:
    "No se han registrado usos de servicios de guardia en este Grupo de Trabajo. Recuerde que los datos se mostrarán en función de la suscripción vinculada al Grupo de Trabajo.",
  __error_detection_intro:
    "Este panel permitirá aprovechar el potente motor del compilador de eventos para descubrir y corregir cualquier error no deseado que se encuentre en una de las siguientes categorías. Trabajamos constantemente para mejorar esta función a medida que se agregan más y más tipos de errores al motor.",
  __error_detection_type_consistency: "Coherencia de los personajes",
  __error_detection_type_events_out: "Eventos fuera del cronograma",
  __error_detection_type_continuity: "error de continuidad",
  __error_detection_type: "Tipo de detección de errores",
  __karma_shift: "cambio de karma (opcional)",
  __karma_shift_positive: "karma positivo",
  __karma_shift_neutral: "karma neutro",
  __karma_shift_negative: "karma negativo",
  __karma_shift_weight: "Proporción de cambio",
  __karma_shift_weight_small: "Pequeño cambio de karma",
  __karma_shift_weight_normal: "Cambio de karma medio",
  __karma_shift_weight_big: "Gran cambio de karma",
  __how_to_fix: "Como arreglar",
  __severity_error: "error",
  __severity_warning: "advertencia",
  __severity_info: "información",
  __character_inclinations: "inclinaciones",
  __metadata: "Metadatos",
  __character_goals: "Objetivos",
  __metadata_type: "Tipo de metadatos",
  __metadata_value: "Valor",
  __character_generic_infos: "Información genérica",
  __character_generic_meta_description: "Descripción genérica",
  __character_potrait: "Retrato del personaje",
  __metadata_heading_hint:
    "Debes agregar un personaje desde un evento en la línea de tiempo antes de poder agregar metadatos.",
  __metadata_attitude_negative: "Detesta",
  __metadata_attitude_neutral: "Neutral",
  __metadata_attitude_positive: "Ama",
  __draft_ideas_short: "Borradores",
  __action_completed_successfully: "Acción completada exitosamente.",
  __too_many_failed_attempts:
    "Has realizado demasiados intentos fallidos. Inténtalo de nuevo en 5 minutos.",
  __simple_icon_set: "Conjunto de iconos predeterminado",
  __anime_icon_set: "Conjunto de iconos de anime",
  __used_icon_set: "Estilo de icono para eventos",
  __other_options: "Otras opciones",
  __your_recent_books: "Libros recientes",
  __work_on_book: "Trabajar en libro",
  __full_price: "Precio completo",
  __payment_is_valid: "Pago válido",
  __paid_on: "Pagado el",
  __payment_type: "Tipo de pago",
  __yes: "Sì",
  __no: "No",
  __trial_in_progress: "Prueba en progreso",
  __no_results: "No hay resultados para mostrar.",
  __book_logo: "Logo del libro",
  __amount_paid: "Cantidad pagada",
  __book_has_been_disabled: "El libro ha sido deshabilitado.",
  __created_on: "Creado en",
  __license_type: "Tipo de licencia",
  __all_my_purchases: "Todas mis compras",
  __statistic_is_subscription_based:
    "Esta información se basa en la suscripción actualmente asociada con el grupo de trabajo.",
  __workgroup_page: "Grupo de trabajo",
  __trial_days: "Días de prueba",
  __requested_trial: "Prueba solicitada",
  __timeline_duration_hint:
    "Duración del cronograma en años (por ejemplo, para el primer cronograma 2000 significa el año 1 al 2000)",
  __timeline_central_year_hint:
    "Cuando abras el editor de la línea de tiempo, esta será la fecha intermedia que se muestra de forma predeterminada.",
  __timeline_order_hint:
    "Indica la posición de esta línea de tiempo con respecto a cualquier otra. (por ejemplo, 1 para la primera línea de tiempo, 2 para la segunda, etc.)",

  __book_timeline_delta_year_hint:
    "Carga solo un intervalo de años con respecto al año central.",
  __workgroup_session_expired:
    "La sesión del grupo de trabajo ha caducado. Renueve la sesión seleccionando el grupo de trabajo.",
  __invite_user_membership_type: "Rol",
  __reader: "Lector",
  __contributor: "Colaborador",
  __invite_user_book_targets: "Permisos de libros",
  __your_role_in_this_workgroup: "Ha iniciado sesión como $ROLE.",
  __notimeline_yet_reader:
    "Parece que todavía no hay líneas de tiempo para este libro. Como eres lector, no puedes agregar nuevas líneas de tiempo",
  __jan: "Enero",
  __feb: "Febrero",
  __mar: "Marzo",
  __apr: "Abril",
  __may: "Mayo",
  __jun: "Junio",
  __jul: "Julio",
  __aug: "Agosto",
  __sep: "Septiembre",
  __oct: "Octubre",
  __nov: "Noviembre",
  __dec: "Diciembre",

  __small: "Pequeño",
  __medium: "Mediano",
  __large: "Grande",

  __model_size: "Tamaño del modelo",
  __model_size_hint:
    "Los modelos más grandes pueden tardar más en procesarse, pero son más precisos.",
  __language: "Idioma",
  __text_to_process: "Texto a procesar",
  __generated_events: "Eventos generados",
  __no_events_generated: "No se generaron eventos.",

  __text_to_events: "Texto a eventos",
  __generate_events: "Generar eventos",
  __apply_generated_events: "Aplicar eventos generados",

  __leave_a_feedback: "Deja un comentario",
  __feedback_title: "Título",
  __feedback_description: "Descripción",
  __feedback_vote: "Voto",
  __feedback_hint:
    "Deja un comentario para ayudarnos a mejorar Plotter Studio. ¡Gracias!",
  __go_to_payment: "Ir al pago",
  __message: "Mensaje",

  __chat: "Chat",
  __workgroup_chat_hint:
    "Usa este chat para comunicarte con los miembros del grupo de trabajo.",
  __workgroup_chat: "Chat del grupo de trabajo",

  __send: "Enviar",
  __attach_file: "Adjuntar archivo",
  __pick_a_chat_room_to_start_talking:
    "Selecciona una sala para comenzar a hablar.",

  __the_total_file_size_you_may_send_per_message_canno_exceed:
    "El tamaño total de los archivos que puede enviar por mensaje no puede exceder los $SIZE KB.",
  __with: "con",
};
