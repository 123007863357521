export const Dictionary = {
  // classes
  __event_class_add_type: "Character added to story",
  __event_class_remove_type: "Character removed from story",
  __event_class_affect_others_type: "Actions of a character affects another",
  __event_class_change_status_type: "Evolution of character",
  __event_class_plot_milestone_type: "Plot milestone",
  __event_class_strong_effects: "Extreme destabilization",

  // types
  __event_type_appear: "Appears",
  __event_type_born: "Is born",
  __event_type_revealed: "Is revealed",
  __event_type_abbandons: "Abbandons",
  __event_type_dies: "Dies",
  __event_type_kills: "Kills",
  __event_type_marries: "Marries",
  __event_type_allies: "Alliance",
  __event_type_engage: "Engage romance",
  __event_type_learns_skill: "Learns skill",
  __event_type_learns_secret: "Learns secret",
  __event_type_joins_faction: "Joins faction",
  __event_type_leaves_faction: "Leaves faction",
  __event_type_book_begins: "Story begins",
  __event_type_holiday: "Holiday",
  __event_type_book_closure: "End of book",
  __event_type_geopolitic: "Political",
  __event_type_natural_disaster: "Natural disaster",
  __event_type_obtains_item: "Obtains item",
  __event_type_changes_id: "Changes identity",
  __event_type_friendship: "Friendship",
  __event_type_friendship_end: "End of Friendship",
  __event_type_engage_end: "End of romantic relationship",
  __event_type_betrays: "Betrays",
  __event_type_assumes_status: "Acquires status",
  __event_type_assumes_condition: "Acquires a condition",
  __event_type_reaches_location: "Reaches location",
  __event_type_leaves_location: "Leaves location",
  __event_type_shares_a_secret: "Shares a secret",
  __event_type_teams_up_with: "Teams up",
  __event_type_disappears: "Got lost",
  __event_type_meets: "Meets",
  __event_type_challenge: "Challenge",
  __event_type_fight: "Fights",
  __event_type_starts_mission: "Starts mission/job",
  __event_type_starts_mission_end: "End mission/job",
  __event_type_falls_in_love: "Falls in love",
  __event_type_situation: "Situation",
  __event_type_mass_kill: "Mass destruction",
  __event_type_war: "War",
  __event_type_rule_changer: "Universal rule change",
  __event_type_uses_object: "Uses tool/object",
  __event_type_creates_or_builds: "Creates/builds",
};
