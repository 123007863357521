export const Dictionary = {
  __welcome: "Accueillir",
  __not_found_page: "Pas trouvé!",
  __not_found_page_subtitle:
    "La page que vous recherchez n'existe pas ou nécessite une connexion.",
  __login: "Se connecter",
  __goto_home: "Retour à la page d'accueil",
  __password: "Mot de passe",
  __username: "Nom d'utilisateur",
  __image_load: "Charger l'image",
  __image_profile_load: "Charger la photo de profil",
  __confirm_password: "Confirmez le mot de passe",
  __email: "E-mail",
  __email_not_received: "E-mail de confirmation non reçu ?",
  __send_email: "Envoyer un nouvel e-mail",
  __no_such_email_registered:
    "L'e-mail fourni n'est pas enregistré dans notre système.",
  __no_account: "Vous n'avez pas de compte ?",
  __register: "Registre!",
  __forgot_password: "Mot de passe oublié?",
  __reset_password: "Réinitialisez-le!",
  __recaptcha_test_failed: "Échec du test Recaptcha.",
  __send_reset_email_title:
    "Insérez l'e-mail que vous avez utilisé pour vous inscrire. Nous vous enverrons un lien pour réinitialiser votre mot de passe.",
  __send_reset_email: "Envoi d'e-mail",
  __login_done: "Connexion terminée!",
  __new_book: "Nouveau livre",
  __load_older_chat_messages: "Charger les messages précédents",
  __password_reset_complete: "Réinitialisation du mot de passe terminée.",
  __password_reset_email_sent:
    "Nous vous invitons à envoyer un e-mail avec un lien pour réinitialiser votre mot de passe.",
  __registration_done_sent_email:
    "Terminé !Nous vous avons envoyé un e-mail. Cliquez sur le lien contenu dans l'e-mail pour confirmer votre compte.",
  __done: "Terminé!",
  __user_already_registered:
    "Cet email est déjà enregistré et ne peut pas être réutilisé pour enregistrer un autre compte.",
  __back: "Dos",
  __email_registered: "Activation du compte terminée. ",
  __this_account_needs_verification:
    "Ce compte doit être vérifié avant de pouvoir accéder au système. ",
  __bad_credentials: "L'e-mail ou le mot de passe ne correspondent pas.",
  __cancel: "Annuler",
  __accept: "Accepter",
  __password_mismatch:
    "Le mot de passe et le mot de passe de confirmation ne sont pas égaux.",
  __add_new_book_form: "Ajoutez un nouveau livre pour commencer.",
  __book_title: "Titre de livre",
  __book_description: "Descriptif du livre",
  __navigate_mobile_menu: "Basculer le menu mobile",
  __copy: "Copier l'élément",
  __create_first_timeline: "Créer la première chronologie",
  __book_collection: "Collection de livres",
  __book_timeline_center_year: "Centrer sur l'année",
  __book_timeline_delta_year: "Largeur d'intervalle",
  __allow_custom_months: "Mois personnalisés",
  __add: "Ajouter",
  __month_name: "Nom du mois",
  __month_days: "Jours par mois",
  __month_leap_every: "Mois bissextile",
  __month_leap_every_hint:
    "Définissez tous les combien d'années le mois gagne un jour, à partir de l'année zéro. ",
  __session_expired: "Votre session a expiré, vous devez vous reconnecter.",
  __book_added: "Livre ajouté à votre bibliothèque.",
  __last_upated: "Mis à jour le",
  __book_start: "Année centrale",
  __table_rows_per_page: "Par page",
  __table_rows_page_prev: "Page précédente",
  __table_rows_page_next: "Page suivante",
  __table_rows: "Lignes $LLIM à $ULIM de $MLIM",
  __password_must_be_8_long:
    "Le mot de passe doit comporter au moins 8 caractères.",
  __add_new_event: "Ajouter un nouvel événement le jour",
  __event_title: "Titre de l'événement",
  __new_event: "Nouvel évènement",
  __event_description: "Description de l'évenement",
  __event_type: "Type d'événement",
  __event_character_list: "Personnages (facultatif)",
  __event_keyword: "Sujet de l'événement (facultatif)",
  __event_added_succesfully: "Événement ajouté à la chronologie.",
  __must_add_at_least_one_month: "Vous devez ajouter au moins un mois.",
  __exploring_on_date: "Explorer les événements à la date",
  __delete_event: "Supprimer l'événement?",
  __edit_event: "Modifier l'événement",
  __delete_book: "Supprimer le livre?",
  __edit_book: "Modifier le livre",
  __action_cannot_be_undone:
    "Cette action supprimera définitivement cet élément. Êtes-vous sûr de vouloir le supprimer ?",
  __event_deleted_succesfully: "Événement supprimé.",
  __event_edited_succesfully: "Événement modifié.",
  __book_deleted_succesfully: "Livre supprimé.",
  __book_edited_succesfully: "Livre édité.",
  __book_total_days_per_year: "Nombre total de jours par an",
  __comma_separated_list:
    "Insérez une liste d'éléments séparés par une virgule. ",
  __no_events_on_this_day: "Aucun événement ce jour-là.",
  __expand: "Développer les détails",
  __collapse: "Cacher les détails",
  __book_timeline_navigator_mode: "Mode chronologie",
  __year_view: "Vue annuelle",
  __month_view: "Vue mensuelle",
  __day_view: "Vue quotidienne",
  __year: "Année",
  __month: "Mois",
  __day: "Jour",
  __custom_date: "Date de l'événement",
  __ask_new_one: "Renvoyer",
  __ask_new_many: "Renvoyer",
  __ask_new_other: "Renvoyer",
  __search: "Près",
  __no_workgroup_ready:
    "Vous n'avez pas encore de groupes de travail. Créez-en un pour commencer à travailler sur votre écriture!",
  __add_workgroup: "Ajouter un nouveau groupe de travail",
  __edit_workgroup: "Modifier le groupe de travail",
  __workgroup_name: "Nom du groupe de travail",
  __workgroup_description: "Description du groupe de travail",
  __workgroup_logo: "Logo du groupe de travail",
  __workgroup_license: "Attribuer la licence au groupe de travail",
  __license_selection_is_required:
    "Vous devez sélectionner une licence pour créer un nouveau groupe de travail.",
  __obtain_a_license: "Magasin de licences Plotter Studio",
  __workgroup_size: "Taille du groupe de travail",
  __workgroup_amount: "Nombre de groupes de travail",
  __purchase: "Achat",
  __purchase_with_trial: "Commencer l'essai gratuit",
  __must_accept_terms:
    "Vous devez accepter les termes et conditions pour continuer.",
  __purchase_with_trial_hint_large:
    "Un essai gratuit de jours $FDAYS est disponible (aucune carte de crédit requise). Seule l'inscription est requise!",
  __purchase_with_trial_hint:
    "L'essai gratuit durera $FDAYS jours, vous devrez ensuite acheter une licence pour continuer à travailler.",
  __purchase_with_trial_about_to_start:
    "Vous êtes sur le point de demander un essai gratuit sur un abonnement. La période d’essai commence immédiatement. Une fois la période d’essai expirée, vous devrez finaliser votre achat de l’abonnement.",
  __trial_started: "La période d'essai a commencé avec succès.",
  __subscription_must_expire_first:
    "Vous avez déjà acheté cet abonnement. Vous ne pourrez pas l'acheter à nouveau jusqu'à son expiration.",
  __workgroup_added: "Groupe de travail créé!",
  __workgroup_edited: "Groupe de travail modifié!",
  __workgroup_open: "Sélectionner",
  __workgroup_main_list:
    "Sélectionnez un groupe de travail pour charger l'espace de travail.",
  __change_workgroup: "Changer de groupe de travail",
  __edit: "Modifier",
  __max_workgroup_limit_for_license:
    "Vous ne pouvez pas ajouter d'autres groupes de travail sur cette licence. Vous avez atteint la limite de groupes de travail pris en charge pour cette licence.",
  __delete: "Supprimer",
  __delete_workgroup: "Supprimer le groupe de travail?",
  __delete_workgroup_accept:
    "J'accepte la suppression de tous les livres, événements, chronologies et toute autre donnée liée à ce groupe de travail. Je comprends que cette action est destructrice et permanente.",
  __delete_workgroup_warning:
    "Soyez-en sûr. Si vous supprimez un groupe de travail, tous les livres du groupe de travail et tous les événements associés seront supprimés pour toujours. Cette action ne peut pas être annulée.",
  __edit_workgroup_people: "Gérez votre équipe",
  __invite_user_to_group: "Email nouveau membre",
  __more_actions_in_date: "Plus d'actions à la date",
  __move_all_events_on_a_different_date: "Déplacer tous les événements",
  __invite_user_to_group_info:
    "Insérez l'e-mail d'un autre utilisateur pour l'inviter à rejoindre votre groupe de travail. L'utilisateur invité devra accepter l'invitation pour terminer ce processus.",
  __invite_user_not_found:
    "L'utilisateur invité n'est pas enregistré sur Plotter Studio. Veuillez vous assurer que tout utilisateur impliqué a terminé l'étape d'inscription gratuite avant l'invitation.",
  __invitation_sent: "Invitation envoyée.",
  __trial_ends_on: "L'essai se termine le",
  __purchase_expire_on: "Expire le",
  __invitation_accepted: "Invitation acceptée!",
  __pick_book_to_start: "Choisissez un livre pour commencer.",
  __query_modal_title: "Moteur AI",
  __proceed_removing_user:
    "Procéder à la suppression de l'utilisateur? L'utilisateur sera supprimé du groupe de travail.",
  __query_modal_date_specific:
    "Tous les résultats dépendront de la date sélectionnée",
  __query_target_character: "Poser une question sur ce personnage",
  __allow_standard_months: "Mois standard",
  __query_target_type: "Ce que tu veux savoir",
  __age: "Âge",
  __knows_about_character: "Connaît ce personnage",
  __has_skill_or_ability: "Possède une compétence ou une capacité",
  __posses_item: "Possède un objet",
  __faction: "Faction",
  __status: "Statut",
  __condition: "Condition",
  __events_in_this_date: "Événements à cette date",
  __location: "Emplacement",
  __total_days: "Jours totaux",
  __object_of_the_query: "Objet de la question",
  __character: "Personnage",
  __item: "Objet",
  __skill: "Compétence",
  __secret: "Secrète",
  __run_question: "Exécuter une requête",
  __run_question_fields_missing: "Certains champs manquent",
  __elapsed: "écoulé",
  __character_evolution: "Évolution du personnage",
  __anyone: "N'importe qui",
  __any: "n'importe lequel",
  __jump_timeline_ticks: "Tick de saut",
  __jump_timeline_ticks_hint:
    "Saisissez le nombre de tick à avancer par rapport à la date actuelle. Pour revenir en arrière, utilisez des nombres négatifs. Les formules simples sont acceptées (par exemple, en vue quotidienne, 5 x 7 déplacements de 5 semaines).",
  __bad_input: "Seuls les chiffres ou les formules de base sont acceptés.",
  __this_is_infinite: "Votre valeur d'entrée est infinie!",
  __remember_me: "Souviens-toi de moi",
  __years: "Années",
  __months: "Mois",
  __days: "Jours",
  __goto_last_event: "Dernier événement",
  __expired_purchase:
    "Soit votre abonnement a expiré, soit son essai gratuit est terminé. Pour continuer à utiliser le groupe de travail, vous devez corriger le statut de l'abonnement.",
  __renew_license_purchase: "Renouveler la licence",
  __buy_license: "Acheter une licence",
  __already_have_trial_on_this:
    "Vous avez déjà demandé un essai gratuit sur ce type d'abonnement. Vous ne pouvez demander qu'un seul essai gratuit par type d'abonnement.",
  __payment_type_month: "Durée : 1 mois",
  __payment_type_year: "Durée : 1 an",
  __voucher_code: "cupón",
  __subscrbe_recurrent_payment:
    "Je souhaite plutôt souscrire à un paiement récurrent automatisé.",
  __cupon_not_allowed:
    "Les codes Cupon ne sont pas disponibles pour les paiements récurrents",
  __purchase_is_now_free:
    "Votre total est de 0 ! Félicitations, vous pouvez sauter l'étape de paiement !",
  __purchase_completed: "Achat terminé !",
  __requires_payment: "Nécessite un paiement",
  __bad_cupon: "Votre code promo est invalide ou expiré.",
  __search_events: "Rechercher",
  __search_results: "Résultats de recherche",
  __case_sensitive: "Sensible aux majuscules et minuscules",
  __draft_ideas_title: "Gérez ici vos projets d'idées",
  __draft_idea_title: "Titre de l'idée préliminaire",
  __draft_idea_description: "Projet de description de l'idée",
  __draft_idea_status: "Statut de l'ébauche d'idée",
  __add_draft_idea: "Ajouter brouillon",
  __edit_draft_idea: "Modifier un brouillon d'idée",
  __draft_ideas: "Projets d'idées",
  __delete_draft_idea: "Supprimer l'idée brouillon",
  __draf_idea_deleted: "Projet d'idée supprimé.",
  __draf_idea_edited: "L'ébauche d'idée a été correctement mise à jour.",
  __draf_idea_added: "Dragt Idée créée.",
  __draft_idea_status_active: "Actif",
  __draft_idea_status_archived: "Archivé",
  __draft_idea_status_completed: "Complété",
  __draft_idea_status_to_review: "Réviser",
  __no_draft_ideas_yet:
    "Vous n'avez pas encore d'idées de brouillon. Utilisez ce formulaire pour ajouter une nouvelle idée. Les idées sont utilisées pour suivre la manière dont votre histoire pourrait évoluer.",
  __remaining_chars: "Personnages restants",
  __manage_timelines: "Gérer les délais du livre",
  __timeline_name: "Nom",
  __timeline_duration: "Durée(années)",
  __timeline_order: "Commande",
  __timeline_add: "Ajouter une chronologie",
  __timeline_center_year: "Année centrale",
  __timeline_edit: "Modifier la chronologie",
  __timeline_chose_workwith: "Choisissez une chronologie",
  __timeline_delete: "Supprimer la chronologie",
  __timeline_add_done: "Chronologie créée correctement.",
  __timeline_edit_done: "Chronologie mise à jour correctement.",
  __timeline_delete_done: "Chronologie supprimée.",
  __notimeline_yet:
    "Il semble que vous n'ayez configuré aucune chronologie pour ce livre. Tous les livres nécessitent au moins une chronologie pour commencer. Vous pouvez ensuite ajouter autant de chronologies que vous le souhaitez.",
  __acknowledge_editmonth_can_screw_up_things:
    "J'accepte que la modification de la structure mensuelle effectuée dans ce formulaire ne corrigera pas automatiquement les dates des événements déjà présents sur la timeline. Je comprends donc que suite à des modifications de la structure mensuelle, certains événements pourront perdre leur place sur la timeline. doubler.",
  __you_must_acknowledge_book_kill:
    "Vous devez accepter la suppression des éléments suivants avant de pouvoir continuer.",
  __erease_current_book: "Effacer le livre actuel",
  __erease_all_events: "Effacer les événements $AMOUNT",
  __erease_all_draft_ideas: "Supprimer $AMOUNT brouillons d'idées",
  __erease_all_timelines: "Effacer les délais de $AMOUNT",
  __pay_monthly: "Je souhaite payer chaque mois",
  __pay_yearly: "Je souhaite payer chaque année",
  __read_terms_and_condition: "Lisez les termes et conditions ici",
  __read_about_privacy_statement:
    "Lisez notre politique de confidentialité ici",
  __terms_and_cond:
    "J'accepte les termes et conditions des services et la déclaration de confidentialité.",
  __event_timeline: "Chronologie de l'événement",
  __character_will_be_born_in: "Le personnage naîtra dans ",
  __character_life_at_least_this_time:
    "Il n'y a pas de données claires sur la naissance de ce personnage. On sait que le personnage existait au moins à cette époque:",
  __character_life_inferred:
    "Si le personnage est encore en vie, il aurait jusqu’à présent cet âge :",
  __logout: "Se déconnecter",
  __settings: "Paramètres",
  __user_settings: "Paramètres utilisateur",
  __events_colors: "Couleur des événements (pour le groupe d'événements)",
  __acknowledge_edittimeline_can_screw_up_things:
    "J'accepte que la modification de la structure de la chronologie effectuée dans ce formulaire ne corrigera pas automatiquement les dates des événements déjà présents sur la chronologie. Je comprends donc que suite à des modifications de la structure de la chronologie, certains événements peuvent perdre leur emplacement.",
  __acknowledge_timeline_removal:
    "J'accepte de supprimer la chronologie et tous les événements qui y sont actuellement placés.",
  __timeline_begin_year: "Commence l'année",
  __timeline_end_year: "Fin d'année",
  __required_fields_missing: "Certains champs obligatoires sont manquants.",
  __timeline_view: "Vue chronologique",
  __table_view: "Vue de tableau",
  __all: "Tous",
  __all_hint: "Insérez '$ALL' pour une recherche sans restrictions.",
  __save: "Sauvegarder",
  __color_for_event_type: "Couleur de puce pour le type d'événement",
  __please_pick_a_timeline: "Sélectionnez une chronologie.",
  __generic_ui_settings: "Paramètres génériques de l'interface utilisateur",
  __limit_dots_on_timeline: "Nombre maximum de puces pour la chronologie",
  __settings_updated: "Paramètres mis à jour correctement.",
  __your_subscription_doesnt_support_this_feature:
    "L'abonnement actuellement utilisé ne prend pas en charge cette fonctionnalité.",
  __query_bot: "Secrétaire virtuelle",
  __error_detection: "Détection d'erreur",
  __workgroup_book_limit_title: "Des livres trop maniables !",
  __expired: "Expiré",
  __workgroup_book_limit_description:
    "Il semble que vous ayez trop de livres dans ce groupe de travail. Les livres excédentaires s'afficheront toujours dans la bibliothèque, mais vous ne pourrez en aucun cas les utiliser. Si vous souhaitez les déverrouiller, vous pouvez mettre à niveau l'abonnement lié à ce groupe de travail. groupe de travail.",
  __book_limit_reached:
    "Vous ne pouvez pas ajouter d'autres livres dans le groupe de travail actuel. Vous devez mettre à niveau votre licence.",
  __feature_usage_limit_reached:
    "Vous ne pouvez pas utiliser cette fonctionnalité tant que vous n'avez pas obtenu plus de jetons d'utilisation. Vous pouvez accéder au tableau de bord des abonnements pour suivre toutes les utilisations.",
  __getting_updates:
    "Obtention des mises à jour. Cela prendra quelques secondes. Veuillez patienter...",
  __sync: "synchroniser",
  __got_it: "J'ai compris",
  __show_again_tutorial_coachmarkers:
    "Restaurer la visibilité de tous les Coachmarkers du tutoriel",
  __statistics: "Données d'utilisation du groupe de travail",
  __no_usages_recorded:
    "Aucune utilisation des services d'astreinte n'a été enregistrée sur ce groupe de travail. N'oubliez pas que les données seront affichées en fonction de l'abonnement lié au groupe de travail.",
  __error_detection_intro:
    "Ce panneau permettra de profiter du puissant moteur de compilation d'événements pour découvrir et corriger toutes les erreurs indésirables entrant dans l'une des catégories suivantes. Nous travaillons constamment à améliorer cette fonctionnalité à mesure que de plus en plus de types d'erreurs sont ajoutés au moteur.",
  __error_detection_type_consistency: "Coherence des personnages",
  __error_detection_type_events_out: "Événements hors chronologie",
  __error_detection_type_continuity: "Erreur de continuité",
  __error_detection_type: "Type de détection d'erreur",
  __event_generic_info: "Informations générales",
  __karma_shift: "Changement de karma (facultatif)",
  __karma_shift_positive: "Karma positif",
  __karma_shift_neutral: "Karma neutre",
  __karma_shift_negative: "Karma négatif",
  __karma_shift_weight: "Proportion de changement",
  __karma_shift_weight_small: "Petit changement de karma",
  __karma_shift_weight_normal: "Changement de karma moyen",
  __karma_shift_weight_big: "Énorme changement de karma",
  __how_to_fix: "Comment réparer",
  __severity_error: "erreur",
  __severity_warning: "avertissement",
  __severity_info: "Info",
  __character_inclinations: "Inclinaisons",
  __metadata: "Métadonnées",
  __metadata_type: "Type de métadonnées",
  __character_goals: "Objectifs",
  __metadata_value: "Valeur",
  __character_generic_meta_description: "Description générique",
  __character_potrait: "Portrait du personnage",
  __metadata_heading_hint:
    "Vous devez ajouter un personnage à partir d'un événement sur la timeline avant de pouvoir ajouter des métadonnées.",
  __character_generic_infos: "Informations générales",
  __metadata_attitude_negative: "Déteste",
  __metadata_attitude_neutral: "Neutre",
  __metadata_attitude_positive: "Aime",
  __payment_is_valid: "Le paiement est valide",
  __draft_ideas_short: "Brouillons",
  __payment_type: "Type de paiement",
  __action_completed_successfully: "Action terminée avec succès.",
  __too_many_failed_attempts:
    "Vous avez effectué trop de tentatives infructueuses. Réessayez dans quelques minutes.",
  __simple_icon_set: "Jeu d'icônes par défaut",
  __anime_icon_set: "Ensemble d'icônes d'anime",
  __used_icon_set: "Style d'icône pour les événements",
  __other_options: "Autres options",
  __your_recent_books: "Livres récents",
  __work_on_book: "Sélectionner un livre",
  __amount_paid: "Montant payé",
  __full_price: "Prix complet",
  __trial_in_progress: "Essai en cours",
  __paid_on: "Payé le",
  __trial_days: "Jours d'essai",
  __requested_trial: "Essai demandé",
  __no_results: "Aucun résultat à afficher.",
  __book_logo: "Logo du livre",
  __created_on: "Créé le",

  __yes: "Oui",
  __no: "Non",

  __book_has_been_disabled: "Le livre a été désactivé.",
  __license_type: "Type de licence",
  __all_my_purchases: "Tous mes achats",
  __statistic_is_subscription_based:
    "Ces informations sont basées sur l'abonnement actuellement associé au groupe de travail.",
  __workgroup_page: "Groupe de travail",
  __tell_mee_more: "En savoir plus",
  __timeline_duration_hint:
    "Durée du calendrier en années. (par exemple, pour le premier calendrier, 2000 signifie l'année 1 à 2000)",
  __timeline_central_year_hint:
    "Lorsque vous ouvrez l'éditeur de chronologie, ce sera la date intermédiaire affichée par défaut.",
  __timeline_order_hint:
    "Indique la position de cette timeline par rapport aux autres. (par exemple 1 pour la première timeline, 2 pour la seconde, etc.)",
  __book_timeline_delta_year_hint:
    "Charge seulement une plage d'années par rapport à l'année centrale.",
  __workgroup_session_expired:
    "La session du groupe de travail a expiré. Veuillez renouveler la session en sélectionnant le groupe de travail.",
  __invite_user_membership_type: "Rôle",
  __reader: "Lecteur",
  __contributor: "Contributeur",
  __invite_user_book_targets: "Autorisations du livre",
  __your_role_in_this_workgroup: "Vous êtes connecté en tant que $ROLE.",
  __notimeline_yet_reader:
    "Il semble qu'il n'y ait pas encore de calendrier pour ce livre. Puisque vous êtes un lecteur, vous ne pouvez pas ajouter de nouveaux calendriers.",
  __jan: "Janvier",
  __feb: "Février",
  __mar: "Mars",
  __apr: "Avril",
  __may: "Mai",
  __jun: "Juin",
  __jul: "Juillet",
  __aug: "Août",
  __sep: "Septembre",
  __oct: "Octobre",
  __nov: "Novembre",
  __dec: "Décembre",

  __small: "Petit",
  __medium: "Moyen",
  __large: "Grand",

  __model_size: "Taille du modèle",
  __model_size_hint:
    "Les modèles plus grands peuvent prendre plus de temps à traiter, mais ils sont plus précis.",
  __language: "Langue",
  __text_to_process: "Texte à traiter",
  __generated_events: "Événements générés",
  __no_events_generated: "Aucun événement généré.",
  __text_to_events: "Texte à événements",
  __generate_events: "Générer des événements",
  __apply_generated_events: "Appliquer les événements générés",

  __leave_a_feedback: "Laisser un commentaire",
  __feedback_title: "Titre",
  __feedback_description: "Description",
  __feedback_vote: "Vote",
  __feedback_hint:
    "Laissez un commentaire pour nous aider à améliorer Plotter Studio. Merci!",
  __go_to_payment: "Aller au paiement",
  __message: "Message",

  __chat: "Chat",
  __workgroup_chat: "Chat du groupe de travail",
  __workgroup_chat_hint:
    "Utilisez ce chat pour communiquer avec les membres du groupe de travail.",

  __send: "Envoyer",
  __attach_file: "Joindre un fichier",
  __pick_a_chat_room_to_start_talking:
    "Choisissez une salle de chat pour commencer à parler.",
  __the_total_file_size_you_may_send_per_message_canno_exceed:
    "Les fichiers envoyés avec un message ne peuvent pas dépasser la taille totale de $SIZE KB.",
  __with: "avec",
};
