export const Dictionary = {
  __new_book: "Aggiungi un libro alla tua libreria",
  __new_book_desc:
    "La prima cosa che vuoi fare qui è creare un libro. Puoi aggiungere un nuovo libro da qui.",
  __edit_workgroup: "Aggiungi persone al tuo team",
  __edit_workgroup_desc:
    "Qui puoi invitare altri utenti ad accedere a questo gruppo di lavoro. Gli utenti invitati potranno lavorare su tutte le risorse della cartella di lavoro.",
  __ai_box: "Strumenti di assistente virtuale",
  __ai_box_desc:
    "Quando vedi questa icona su un pulsante, significa che puoi sfruttare il nostro motore di assistente AI avanzato per portare la qualità del tuo lavoro a un livello superiore. Alcune funzionalità richiederanno l'abilitazione di abbonamenti specifici.",
  __new_event_button: "Aggiungi eventi da qui",
  __new_event_button_desc:
    "Puoi utilizzare questo pulsante per creare un nuovo evento. La data predefinita dell'evento sarà quella selezionata sulla sequenza temporale. Se preferisci, puoi sempre modificarlo in seguito.",
  __draft_ideas: "Prendi appunti delle tue idee",
  __draft_ideas_desc:
    "Le bozze delle idee non influenzano il tuo lavoro sul libro. Puoi considerarle note cartacee sui concetti che vorresti elaborare in futuro. Le bozze delle idee possono essere gestite per stato e possono essere viste da tutti i membri del tuo team.",
  __characters_meta_data: "Personaggi",
  __characters_meta_data_desc:
    "I personaggi vengono automaticamente aggiunti al tuo libro non appena li usi all'interno di un evento sulla timeline. In questo modo, puoi aggiungere quanti personaggi desideri. Una volta aggiunto un personaggio, puoi aggiungere quante metadati desideri a ciascun personaggio. Puoi anche aggiungere un'immagine a ciascun personaggio. Queste informazioni aiuteranno il compilatore degli eventi a rilevare errori di coerenza sul comportamento dei tuoi personaggi.",
  __new_timeline_button: "Crea una nuova sequenza temporale",
  __new_timeline_button_desc:
    "Puoi creare una nuova sequenza temporale per il tuo libro da qui. Una sequenza temporale è un modo per organizzare gli eventi del tuo libro in modo visivo. Puoi aggiungere eventi a una sequenza temporale e visualizzarli in un ordine cronologico. Puoi anche modificare gli eventi direttamente dalla sequenza temporale.",
  __new_timeline_button_more:
    "Ogni successiva sequenza temporale che creerai sarà considerata successiva alla precedente. Tutte le sequenze temporali, in ordine, rappresentano tutto l'arco temporale del tuo universo narrativo. \n\nSupponi che il tuo libro sia un giallo storico ambientato nel 1951, il quale prevede una conclusione di trama entro l'anno 1963. La sequenza temporale in questo caso dovrebbe partire dall'anno 1 e terminare almeno nell'anno 1963.\nPuò aver senso lasciarsi del margine, nell'esempio fatto sarebbe utile farla andare dall'anno 1 all'anno 2000.",
};
