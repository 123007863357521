import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { suppressDefaultErrorMessage } from "../App";

var disabled = false;

export const ToastMessageUnique = (
  type: "error" | "success" | "info" | "warning",
  message: string
) => {
  suppressDefaultErrorMessage();
  ToastMessage(type, message);
};

export const ToastMessage = (
  type: "error" | "success" | "info" | "warning",
  message: string
) => {
  if (disabled) {
    return;
  }

  switch (type) {
    case "error":
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    case "success":
      toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    case "info":
      toast.info(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    case "warning":
      toast.warning(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
  }
};

export const suppressToasts = () => {
  disabled = true;
  setTimeout(() => {
    disabled = false;
  }, 1000);
};
