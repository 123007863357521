import { useSelector } from "react-redux";
import { IEvent } from "../../Models/IEvent";
import "./EventLogicProcessor.scss";
import { GlobalState } from "../../Redux/RootReducer";
import {
  DropdownMenuItemType,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
} from "@fluentui/react";
import Tr from "../../Utils/Translations/Translations";
import MultiForm, {
  IMultiFormInput,
  triggerFormValidation,
} from "../MultiForm/MultiForm";
import { useEffect, useState } from "react";
import Tools from "../../Utils/Tools";
import { ToastMessage, ToastMessageUnique } from "../../Utils/UIMessages";
import Loadable from "../Loadable/Loadable";
import { mobileCheck } from "../../App";

import { IBook } from "../../Models/IBook";
import { IMonthMap } from "../MonthMapEditor/MonthMapEditor";
import ApiService from "../../Services/ApiService";
import { IBotRequest } from "../../Models/IBotRequest";
import { IAPIResponse } from "../../Services/AjaxService";
import {
  ExtraEventDataProcessed,
  GetCustomDateAbsoluteNumericDate,
} from "../../Utils/EventProcessing";
import { IBookTimeline } from "../../Models/IBookTimeline";
import { IBotAnswer } from "../../Models/IBotAnswer";
import { betterCommaListDisplay } from "../ExploreEventsTickGrid/ExploreEventsTickGrid";
import SuperSpinner from "../SuperSpinner/SuperSpinner";
import OperationProgressBar from "../OperationProgressBar/OperationProgressBar";

export interface IEventLogicProcessorProps {
  specificToDay?: boolean;
}

const QuestionSubjects: any = {
  SUBJECT_ITEM: "item",
  SUBJECT_SKILL: "skill",
  SUBJECT_SECRET: "secret",
  SUBJECT_FACTION: "faction",
  SUBJECT_STATUS: "status",
  SUBJECT_CONDITION: "condition",
  SUBJECT_LOCATION: "location",
  SUBJECT_CHARACTER: "character",
};

const cleanQuerySlot = (querySlot: string) => {
  return querySlot.trim().replaceAll("[", "").replaceAll("]", "");
};

const getGenericAnswerLabel = (
  index: string,
  activeType: string,
  cname: string,
  event: IEvent,
  timeline: IBookTimeline | undefined
) => {
  let templateTranslated = Tr.Translate(
    "bot",
    "event_type_" + activeType.trim()
  );

  let cleanOtherCharList = clearCharacterFromList(cname, event.CharacterList);
  let cleanKeywordsList = clearCharacterFromList(cname, event.EventKeyword);

  let templateSplProcessed: string[] = [];
  let tmpProcessingTxt = "";
  for (let i = 0; i < templateTranslated.length; i++) {
    let char_ = templateTranslated[i];
    if (char_ === "[" && tmpProcessingTxt !== "") {
      templateSplProcessed.push(cleanQuerySlot(tmpProcessingTxt));
      tmpProcessingTxt = "";
    }
    tmpProcessingTxt += char_;
    if (char_ === "]" && tmpProcessingTxt !== "") {
      templateSplProcessed.push(cleanQuerySlot(tmpProcessingTxt));
      tmpProcessingTxt = "";
    }
  }
  if (tmpProcessingTxt !== "") {
    templateSplProcessed.push(cleanQuerySlot(tmpProcessingTxt));
  }

  for (let i = 0; i < templateSplProcessed.length; i++) {
    let queryslot: string = templateSplProcessed[i];

    // dynamic loaded
    if (queryslot.includes("$OPS_")) {
      // dynamic other characters
      if (queryslot.includes("$OPS_OTHERCHARACTERS")) {
        if (cleanOtherCharList.length > 0) {
          queryslot =
            "<div class='is-other-characters-div'>" +
            queryslot.replace("$OPS_OTHERCHARACTERS", cleanOtherCharList) +
            "</div>";
        } else {
          // remove slot if empty
          queryslot = "";
        }
      }

      // dynamic object
      if (queryslot.includes("$OPS_OBJECT")) {
        if (cleanKeywordsList.length > 0) {
          queryslot =
            "<div class='is-object-div'>" +
            queryslot.replace("$OPS_OBJECT", cleanKeywordsList) +
            "</div>";
        } else {
          // remove slot if empty
          queryslot = "";
        }
      }
    } else {
      queryslot = queryslot.replace(
        "$DATE",
        "<div class='is-date-div'>" +
          event.CustomDate +
          (timeline
            ? " <div class='is-date-timeline-div'>[" +
              timeline.Title +
              "]</div>"
            : "") +
          "</div>"
      );
      queryslot = queryslot.replace(
        "$CHARNAME",
        "<div class='is-character-div'>" + cname + "</div>"
      );
      queryslot = queryslot.replace(
        "$OBJECT",
        "<div class='is-object-div'>" + cleanKeywordsList + "</div>"
      );
      queryslot = queryslot.replace(
        "$OTHERCHARACTERS",
        "<div class='is-other-characters-div'>" + cleanOtherCharList + "</div>"
      );
    }

    // upload processed query answer
    templateSplProcessed[i] = queryslot;
  }

  let finalResult = templateSplProcessed
    .filter((x: string) => x !== "")
    .join(" ");
  // default
  return (
    <div
      key={index}
      className="response-bot-line"
      dangerouslySetInnerHTML={{ __html: finalResult }}
    ></div>
  );
};

const SupportedQuestionTypes: { key: string; objectList: string[] }[] = [
  {
    key: "age",
    objectList: [],
  },
  {
    key: "knows_about_character",
    objectList: [QuestionSubjects.SUBJECT_CHARACTER],
  },
  {
    key: "character_evolution",
    objectList: [
      QuestionSubjects.SUBJECT_ITEM,
      QuestionSubjects.SUBJECT_SECRET,
      QuestionSubjects.SUBJECT_SKILL,
      QuestionSubjects.SUBJECT_FACTION,
      QuestionSubjects.SUBJECT_CHARACTER,
    ],
  },
  {
    key: "has_skill_or_ability",
    objectList: [QuestionSubjects.SUBJECT_SKILL],
  },
  {
    key: "posses_item",
    objectList: [QuestionSubjects.SUBJECT_ITEM],
  },
  {
    key: "faction",
    objectList: [QuestionSubjects.SUBJECT_FACTION],
  },
  {
    key: "status",
    objectList: [QuestionSubjects.SUBJECT_STATUS],
  },
  {
    key: "condition",
    objectList: [QuestionSubjects.SUBJECT_CONDITION],
  },
  {
    key: "location",
    objectList: [],
  },
];

export interface IBotLocalAnswer {
  question: string;
  answer: JSX.Element;
  elapsed: number;
}

const clearCharacterFromList = (char: string, charListString: string) => {
  let spl = charListString.split(",");
  return spl
    .filter(
      (x: string) => !x.toLocaleLowerCase().includes(char.toLocaleLowerCase())
    )
    .map((x: string) => x.trim())
    .map((x: string) => Tools.UpperCase(x))
    .join(", ");
};

var startProcessingDate = Date.now();
const EventLogicProcessor = (props: IEventLogicProcessorProps) => {
  const [state, setState] = useState<any>();
  const [activeOptions, setActiveOptions] = useState<IDropdownOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [answers, setAnswers] = useState<IBotLocalAnswer[]>([]);
  const [operationId, setOperationId] = useState<string>("");
  const activeBook: IBook | undefined = useSelector(
    (state: GlobalState) => state.generic.activeBook
  );
  const timeline: IBookTimeline[] = useSelector(
    (state: GlobalState) => state.generic.bookTimelines
  );
  const activeTimeline: IBookTimeline | undefined = useSelector(
    (state: GlobalState) => state.generic.activeTimeline
  );
  const navigator: string = useSelector(
    (state: GlobalState) => state.generic.timelineNavigator
  );
  const eventsDate: string = useSelector(
    (state: GlobalState) => state.generic.exploreEventsDate
  );

  const knownCharacters: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownCharacters
  );
  const knownItems: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownItems
  );
  const knownSkills: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownSkills
  );
  const knownSecrets: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownSecrets
  );
  const knownFactions: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownFactions
  );
  const knownStatuses: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownStatuses
  );
  const knownConditions: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownConditions
  );
  const knownLocations: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownLocations
  );

  var displayDateSpl: string[] = (eventsDate ?? "").split("/");
  var displayDateJoin: string[] = [displayDateSpl[0]];
  if (navigator === "month" || navigator === "day") {
    displayDateJoin.push(displayDateSpl[1]);
  }
  if (navigator === "day") {
    displayDateJoin.push(displayDateSpl[2]);
  }

  const appendOptionsSet = (headerText: string, options: string[]) => {
    let resultData: IDropdownOption[] = [];

    options.forEach((x: string) => {
      resultData.push({
        key: x,
        text: Tools.UpperCase(x),
      });
    });

    return resultData;
  };

  const loadObjectList = () => {
    if (state) {
      let activeSelection: any = state.question;
      if (activeSelection) {
        let objList = SupportedQuestionTypes.find(
          (x: any) => x.key === activeSelection
        );
        let list = objList?.objectList ?? [];
        let resultData: IDropdownOption[] = [];

        if (list.length > 0) {
          resultData.push({
            key: "*",
            text: Tr.Translate("language", "all"),
          });

          if (list.includes(QuestionSubjects.SUBJECT_ITEM)) {
            resultData = resultData.concat(
              appendOptionsSet(Tr.Translate("language", "item"), knownItems)
            );
          }
          if (list.includes(QuestionSubjects.SUBJECT_SKILL)) {
            resultData = resultData.concat(
              appendOptionsSet(Tr.Translate("language", "skill"), knownSkills)
            );
          }
          if (list.includes(QuestionSubjects.SUBJECT_SECRET)) {
            resultData = resultData.concat(
              appendOptionsSet(Tr.Translate("language", "secret"), knownSecrets)
            );
          }
          if (list.includes(QuestionSubjects.SUBJECT_FACTION)) {
            resultData = resultData.concat(
              appendOptionsSet(
                Tr.Translate("language", "faction"),
                knownFactions
              )
            );
          }
          if (list.includes(QuestionSubjects.SUBJECT_STATUS)) {
            resultData = resultData.concat(
              appendOptionsSet(
                Tr.Translate("language", "status"),
                knownStatuses
              )
            );
          }
          if (list.includes(QuestionSubjects.SUBJECT_CONDITION)) {
            resultData = resultData.concat(
              appendOptionsSet(
                Tr.Translate("language", "condition"),
                knownConditions
              )
            );
          }
          if (list.includes(QuestionSubjects.SUBJECT_LOCATION)) {
            resultData = resultData.concat(
              appendOptionsSet(
                Tr.Translate("language", "location"),
                knownLocations
              )
            );
          }
          if (list.includes(QuestionSubjects.SUBJECT_CHARACTER)) {
            resultData = resultData.concat(
              appendOptionsSet(
                Tr.Translate("language", "character"),
                knownCharacters
              )
            );
          }
        }

        setActiveOptions(resultData);
      }
    }
  };

  useEffect(() => {
    loadObjectList();
  }, [state]);

  const getFormInputs = () => {
    let inputs: IMultiFormInput[] = [];

    inputs.push({
      type: "combobox",
      name: "characters",
      label: Tr.Translate("language", "query_target_character"),
      extraParams: {
        options: knownCharacters.map((x: string) => {
          return {
            key: x,
            text: betterCommaListDisplay(x),
          };
        }),
      },
    });

    inputs.push({
      type: "select",
      name: "question",
      label: Tr.Translate("language", "query_target_type"),
      extraParams: {
        options: SupportedQuestionTypes.map(
          (s: { key: string; objectList: string[] }) => {
            return {
              key: s.key,
              text: Tr.Translate("language", s.key),
              objectList: s.objectList,
            };
          }
        ),
      },
    });

    if (activeOptions.length > 0) {
      inputs.push({
        type: "combobox",
        name: "object",
        label: Tr.Translate("language", "object_of_the_query"),
        extraParams: {
          underElement: (
            <div className="under-input-hint">
              {Tr.Translate("language", "all_hint").replace(
                "$ALL",
                Tr.Translate("language", "all")
              )}
            </div>
          ),
          options: activeOptions,
        },
      });
    }

    return inputs;
  };

  const concludeBotQuery = (
    questionTypeKey: string,
    botRequest: IBotRequest,
    botAnswer: IBotAnswer
  ) => {
    let character = botRequest.SubjectCharacter;
    if (character === "0") {
      character = Tr.Translate("language", "all");
    }
    let answer = <div>{"🤷?"}</div>;
    let content: JSX.Element[] = [];
    for (let i = 0; i < botAnswer.Events.length; i++) {
      let event: IEvent = botAnswer.Events[i];
      if (!event.BotMetadata?.EventOutOfTimeSpan) {
        let types: string[] = event.EventType.split(",");
        for (let j = 0; j < types.length; j++) {
          content.push(
            getGenericAnswerLabel(
              i + " " + j,
              types[j],
              character,
              event,
              timeline.find((x: IBookTimeline) => x.Id === event.TimelineId)
            )
          );
        }
      }
    }

    let extraInfos: JSX.Element[] = ExtraEventDataProcessed(
      botRequest.CustomCapDate,
      activeBook?.MonthMap ?? "{}",
      questionTypeKey,
      botAnswer
    );

    content = content.concat(extraInfos);

    if (content.length > 0) {
      answer = <div>{content}</div>;
    }

    let currentAnswers: IBotLocalAnswer[] = [...answers];

    let obj = botRequest.InvolvedObjects;
    if (obj === "*") {
      obj = "(" + Tr.Translate("language", "all") + ")";
    }

    currentAnswers.unshift({
      question:
        character +
        " " +
        Tr.Translate("language", questionTypeKey).toLocaleLowerCase() +
        " " +
        (activeOptions.length > 0 ? obj.toLocaleLowerCase() : ""),
      answer: <div>{answer}</div>,
      elapsed: Date.now() - startProcessingDate,
    });
    setAnswers(currentAnswers);
    setLoading(false);
  };

  const askBotQuery = (questionTypeKey: string, botRequest: IBotRequest) => {
    ApiService.EventController.SendBotRequest(
      botRequest,
      (response: IAPIResponse) => {
        if (response.error === null) {
          concludeBotQuery(questionTypeKey, botRequest, response.parsed);
        } else {
          if (response.raw.status === 422) {
            ToastMessageUnique(
              "error",
              Tr.Translate("language", "feature_usage_limit_reached")
            );
          }
          setLoading(false);
        }
      }
    );
  };

  const setupBotQuery = () => {
    setLoading(true);
    startProcessingDate = Date.now();
    let targetCharacter = Tools.UpperCase(state.characters);
    let query = Tr.Translate("language", state.question).toLocaleLowerCase();
    let objectOfQuery =
      activeOptions.length > 0
        ? (state.object
            ? Tools.UpperCase(state.object)
            : ""
          ).toLocaleLowerCase()
        : "*";

    // filter on all
    if (objectOfQuery === Tr.Translate("language", "all").toLocaleLowerCase()) {
      objectOfQuery = "*";
    }

    let questionTypeKey: string =
      SupportedQuestionTypes.find(
        (x: { key: string; objectList: string[] }) =>
          Tr.Translate("language", x.key).toLocaleLowerCase() ===
          query.toLocaleLowerCase()
      )?.key ?? "";

    // collector of results
    let targetOfInteractions: string[] = [];

    if (questionTypeKey === "character_evolution") {
      targetOfInteractions = ["*"];
    }

    if (questionTypeKey === "age") {
      targetOfInteractions = ["born", "dies"];
    }

    if (questionTypeKey === "knows_about_character") {
      targetOfInteractions = [
        "challenge",
        "marries",
        "allies",
        "kills",
        "meets",
        "friendship",
        "friendship_end",
        "teams_up_with",
        "engage",
        "engage_end",
        "betrays",
        "shares_a_secret",
      ];
    }
    if (questionTypeKey === "has_skill_or_ability") {
      targetOfInteractions = ["learns_skill"];
    }
    if (questionTypeKey === "posses_item") {
      targetOfInteractions = ["obtains_item"];
    }
    if (questionTypeKey === "faction") {
      targetOfInteractions = ["joins_faction", "leaves_faction"];
    }
    if (questionTypeKey === "status") {
      targetOfInteractions = ["assumes_status"];
    }
    if (questionTypeKey === "condition") {
      targetOfInteractions = ["assumes_condition"];
    }
    if (questionTypeKey === "location") {
      targetOfInteractions = ["reaches_location", "leaves_location"];
    }

    let randomId = Tools.GenerateUUID();
    setOperationId(randomId);

    let botQuery: IBotRequest = {
      InvolvedEventTypes: targetOfInteractions.join(","),
      SubjectCharacter: targetCharacter,
      InvolvedObjects: objectOfQuery,
      OperationId: randomId,
      BookId: activeBook?.Id ?? 0,
      CustomCapDate:
        eventsDate && props.specificToDay
          ? GetCustomDateAbsoluteNumericDate(
              eventsDate,
              JSON.parse(activeBook?.MonthMap ?? "{}"),
              activeTimeline?.Id ?? 0
            )
          : -1,
    };

    askBotQuery(questionTypeKey, botQuery);
  };

  return (
    <div style={mobileCheck() ? {} : { minWidth: "900px" }}>
      {props.specificToDay && (
        <MessageBar messageBarType={MessageBarType.info}>
          {Tr.Translate("language", "query_modal_date_specific") +
            ": " +
            displayDateJoin.join("/")}
        </MessageBar>
      )}
      <MultiForm
        inlineForm={!mobileCheck()}
        onSubmit={(data: any) => {
          if (
            !data.characters ||
            !data.question ||
            (!data.object && activeOptions.length > 0)
          ) {
            ToastMessage(
              "warning",
              Tr.Translate("language", "run_question_fields_missing")
            );
            return;
          }
          setupBotQuery();
        }}
        onChange={(data: any) => {
          setState(data);
        }}
        formUniqueId="eventProcessorForm"
        inputs={getFormInputs()}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          margin: "0.8em",
        }}
      >
        {!loading && (
          <PrimaryButton
            onClick={() => {
              triggerFormValidation("eventProcessorForm");
            }}
            iconProps={{ iconName: "Robot" }}
            text={Tr.Translate("language", "run_question")}
          />
        )}
        {loading && (
          <div style={{ padding: "1em" }}>
            <SuperSpinner mode="ai" />
          </div>
        )}
      </div>

      <OperationProgressBar enable={loading} operationId={operationId} />

      <div className="answer-main-box">
        {answers.map((x: IBotLocalAnswer, i: number) => {
          return (
            <div key={i} className="answer-wrap">
              <div className="answer-elapsed">
                {Tr.Translate("language", "elapsed") + " "}
                {x.elapsed < 1000 && <span>{x.elapsed} ms</span>}
                {x.elapsed >= 1000 && <span>{x.elapsed / 1000} s</span>}
              </div>
              <div className="answer-title">{x.question.trim()}?</div>
              <div className="answer-content">{x.answer}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EventLogicProcessor;
