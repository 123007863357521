export const Dictionary = {
  __presentation_1:
    "Le site destiné aux auteurs de livres fantastiques, de romans et de nouvelles aux intrigues plus complexes.",
  __presentation_2:
    "Plotter Studio est une plateforme créée en collaboration avec des écrivains pour des écrivains.",
  __presentation_3: "Écriture d'intrigues basées sur des événements",
  __presentation_4: "Ordre du temps",
  __presentation_5:
    "Gardez tous les événements clés de votre intrigue en ordre en les plaçant sur une chronologie. ",
  __presentation_6: "Contrôle",
  __presentation_7:
    "Vérifiez la cohérence des événements insérés, afin d'éviter des trous de tracé ou des incohérences. ",
  __presentation_8: "Travail en équipe",
  __presentation_9:
    "Plotter Studio permet aux utilisateurs d'inviter d'autres personnes à collaborer sur le même livre, permettant ainsi une efficacité professionnelle même dans un environnement de travail.",
  __presentation_10: "Interface intuitive",
  __presentation_11:
    "L'utilisation de Plotter Studio a été optimisée pour être facile à utiliser, afin que vous puissiez vous concentrer sur votre livre sans avoir à penser à autre chose.",
  __presentation_12: "Avez-vous des questions?",
  __presentation_12_2:
    "L'équipe de Plotter Studio est à votre disposition pour répondre à toutes vos questions",
  __presentation_13: "Contactez-nous",
  __presentation_14: "Ou vous pouvez créer un compte gratuitement ",
  __presentation_15: "Qualité professionnelle",
  __presentation_16:
    "Plotter Studio est développé et maintenu par un groupe avec des décennies d'expérience dans le monde informatique, et nous garantissons des évolutions continues dans le temps.",
  __footer_about_us: "À propos de nous",
  __footer_our_mission: "Notre mission",
  __footer_were_are_we_headed: "Où allons-nous",
  __footer_services: "Nos services",
  __footer_serv_support: "Soutien aux écrivains",
  __footer_serv_teaching: "Enseignement",
  __footer_serv_private_solutions: "Solutions privées",
  __footer_contact_us: "Contactez-nous",
  __footer_social_media: "Réseaux sociaux",
  __footer_social_facebook: "Facebook",
  __footer_social_twitter: "Twitter",
  __app_for_writers_logo: "L'application pour les écrivains",
};
