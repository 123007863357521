export const Dictionary = {
  __dev_sub_name: "Desarrollador",
  __dev_sub_description:
    "Una suscripción de prueba para verificar el correcto funcionamiento de la tienda de Paypal. Esta suscripción no es visible para el público y no se puede comprar.",
  __single_user_mini_name: "Usuario único (Mini)",
  __single_user_mini_description:
    "Una suscripción para personas que sienten que necesitan más tiempo para evaluar el producto. Esta suscripción es casi gratuita y ofrece un adelanto de lo que esta aplicación puede hacer por sus proyectos.",
  __single_user_name: "Usuario unico",
  __single_user_description:
    "Una suscripción para uso personal. Perfecta para proyectos privados o aficionados.",
  __small_group_name: "Grupo pequeño",
  __small_group_description:
    "Esta solución intermedia es adecuada para pequeños grupos de trabajo, estudiantes o familiares.",
  __office_group_name: "Oficina",
  __office_group_description:
    "Solución para un nivel de participación de oficina. ",
  __enterprise_group_name: "Empresa",
  __enterprise_group_description:
    "Lleve a su equipo a la máxima productividad y competencia en la redacción de contenido. ",
  __month: "Precio mensual",
  __subscription_includes: "Esta suscripción incluye",
  __advanced_event_processing:
    "Corrección automática del evento de la historia.",
  __advanced_event_query: "Bot de consulta de eventos.",
  __advanced_event_advanced_processing:
    "Procesamiento avanzado de eventos de la trama.",
  __book_limit_per_workgroup: "Hasta 100 libros para cada grupo de trabajo",
  __price_yearly: "Descuento de precio anual",
  __workgroupsNumberFeature: "Cantidad del grupo de trabajo",
  __workgroupsSizeFeature: "Tamaño del grupo de trabajo",
  __eventErrorDetection: "Detección de errores",
  __queryBotFeature: "Bot de consulta",
  __bookAmountFeature: "Libros por grupo de trabajo",
  __workgroupsNumberFeatureDesc:
    "Diferentes grupos de trabajo para organizar tus trabajos.",
  __workgroupsSizeFeatureDesc:
    "Cada grupo de trabajo puede tener un conjunto diferente de contribuyentes, por lo que puedes gestionar diferentes equipos en diferentes proyectos.",
  __eventErrorDetectionDesc:
    "El compilador de eventos detectará inconsistencias por usted. ",
  __queryBotFeatureDesc:
    "Su secretaria de IA. Haga preguntas a un robot inteligente sobre cualquier evento para realizar un seguimiento de cualquier información valiosa. No más notas en papel en su oficina.",
  __bookAmountFeatureDesc:
    "Cada grupo de trabajo que cree podrá almacenar esta cantidad de libros.",
  __per_month_calls: "llamadas/mes",
  __amount: "cantidad",
};
