import { useState } from "react";
import BetterPivot from "../BetterPivot/BetterPivot";
import "./ExtraComputingAI.scss";
import EventLogicProcessor from "../EventLogicProcessor/EventLogicProcessor";
import ErrorDetectionBot from "../ErrorDetectionBot/ErrorDetectionBot";
import Tr from "../../Utils/Translations/Translations";
import FeedbackHandler from "../FeedbackHandler/FeedbackHandler";

export interface IExtraComputingAIProps {
  specificToDay?: boolean;
}

const ExtraComputingAI = (props: IExtraComputingAIProps) => {
  const [key, setKey] = useState<string>("0");
  return (
    <div>
      <BetterPivot
        voices={[
          {
            key: "0",
            text: Tr.Translate("language", "query_bot"),
            iconName: "Robot",
          },
          {
            key: "1",
            text: Tr.Translate("language", "error_detection"),
            iconName: "DiamondSolid",
          },
        ]}
        onSelectedKey={setKey}
      />
      {key === "0" && (
        <EventLogicProcessor specificToDay={props.specificToDay} />
      )}
      {key === "1" && <ErrorDetectionBot specificToDay={props.specificToDay} />}
    </div>
  );
};

export default ExtraComputingAI;
