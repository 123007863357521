export const Dictionary = {
  __welcome: "Benvenuto",
  __not_found_page: "Pagina non trovata!",
  __not_found_page_subtitle:
    "La pagina che stai cercando non esiste, oppure richiede che tu esegua l'accesso.",
  __login: "Accedi",
  __goto_home: "Torna alla Home",
  __password: "Password",
  __confirm_password: "Conferma password",
  __username: "Nome utente",
  __image_load: "Carica immagine",
  __image_profile_load: "Carica immagine profilo",
  __email_not_received: "Email di conferma non ricevuta?",
  __ask_new_one: "Reinviala",
  __send_email: "Invia nuova Email",
  __no_such_email_registered:
    "L'Email inserita non è presente nel nostro sistema.",
  __email: "Email",
  __no_account: "Non hai un account?",
  __register: "Registrati",
  __forgot_password: "Hai dimenticato la password?",
  __reset_password: "Reimpostala!",
  __send_reset_email_title:
    "Inserisci l'email che hai utilizzato per registrarti. Ti invieremo un link per reimpostare la tua password.",
  __send_reset_email: "Invia link",
  __login_done: "Accesso eseguito!",
  __new_book: "Nuovo libro",
  __password_reset_complete: "Reimpostazione della password completata.",
  __password_reset_email_sent:
    "Abbiamo inviato un'email con un link per il reset della tua password.",
  __registration_done_sent_email:
    "Fatto! Ti abbiamo inviato un'email. Clicca nel link nell'email per confermare il tuo account.",
  __done: "Tutto fatto!",
  __user_already_registered:
    "Questa email risulta già registrata e non può essere usata per un nuovo account.",
  __back: "Indietro",
  __email_registered: "Attivazione account completata. Ora puoi accedere.",
  __this_account_needs_verification:
    "Questo account necessita di verifica prima di poter accedere al sistema. Controlla la tua casella di posta per un'e-mail di conferma.",
  __bad_credentials: "Email e/o password errata.",
  __cancel: "Annulla",
  __accept: "Accetta",
  __copy: "Copia elemento",
  __password_mismatch:
    "I campi password e conferma password non corrispondono.",
  __add_new_book_form: "Aggiungi un nuovo libro per iniziare.",
  __book_title: "Titolo del libro",
  __book_description: "Descrizione del libro",
  __book_collection: "Collezione di libri",
  __book_timeline_center_year: "Centra sull'anno",
  __book_timeline_delta_year: "Ampiezza intervallo",
  __allow_custom_months: "Struttura personalizzata di mesi e giorni",
  __allow_standard_months: "Struttura standard di mesi e giorni",
  __add: "Aggiungi",
  __month_name: "Nome mese",
  __navigate_mobile_menu: "Attiva/disattiva il menu mobile",
  __month_days: "Giorni nel mese",
  __month_leap_every: "Bisestile",
  __total_days: "Giorni totali",
  __month_leap_every_hint:
    "Definisci ogni quanti anni il mese guadagna un giorno, partendo a contare dall'anno zero. Usa zero per disabilitare questa funzionalità.",
  __session_expired: "Sessione scaduta. Occorre eseguire nuovamente l'accesso.",
  __book_added: "Titolo aggiunto alla tua libreria.",
  __last_upated: "Aggiornato il",
  __amount_paid: "Importo pagato",
  __paid_on: "Pagato il",
  __yes: "Sì",
  __no: "No",
  __trial_in_progress: "Prova in corso",
  __created_on: "Creato il",
  __book_start: "Anno centrale",
  __table_rows_page_prev: "Pagina precedente",
  __table_rows_page_next: "Pagina successiva",
  __password_must_be_8_long: "La password deve contenere almeno 8 caratteri.",
  __add_new_event: "Aggiungi un evento al giorno",
  __new_event: "Nuovo Evento",
  __table_rows_per_page: "Per pagina",
  __table_rows: "Righe da $LLIM a $ULIM di $MLIM",
  __event_title: "Titolo dell'evento",
  __event_description: "Descrizione dell'evento",
  __event_type: "Tipo di evento",
  __event_keyword: "Oggetto dell'evento (facoltativo)",
  __event_character_list: "Personaggi (facoltativo)",
  __event_added_succesfully: "Evento aggiunto sulla timeline",
  __must_add_at_least_one_month: "Devi aggiungere almeno un mese.",
  __exploring_on_date: "Ispezione eventi in data",
  __delete_event: "Cancellare evento?",
  __edit_event: "Modifica evento",
  __delete_book: "Cancellare libro?",
  __edit_book: "Modifica libro",
  __invite_user_membership_type: "Ruolo",
  __reader: "Lettore",
  __contributor: "Collaboratore",
  __invite_user_book_targets: "Permessi sui libri",
  __action_cannot_be_undone:
    "Questa operazione eliminerà in modo definitivo l'oggetto selezionato, siete sicuri di voler procedere?",
  __event_deleted_succesfully: "Evento eliminato.",
  __event_edited_succesfully: "Evento modificato.",
  __book_deleted_succesfully: "Libro eliminato.",
  __book_edited_succesfully: "Libro modificato.",
  __book_total_days_per_year: "Giorni totali all'anno",
  __create_first_timeline: "Crea la prima sequenza temporale",
  __comma_separated_list:
    "Inserisci una lista di valori separati da virgola. (e.g Nome1, Nome2, etc)",
  __no_events_on_this_day: "Non ci sono eventi in questo giorno.",
  __expand: "Espandi dettagli",
  __collapse: "Nascondi dettagli",
  __book_timeline_navigator_mode: "Modalità calendario",
  __year_view: "Vista annuale",
  __month_view: "Vista mensile",
  __day_view: "Vista giornaliera",
  __year: "Anno",
  __month: "Mese",
  __day: "Giorno",
  __custom_date: "Data evento",
  __search: "Cerca",
  __no_workgroup_ready:
    "Non hai ancora gruppi di lavoro. Creane uno per iniziare a lavorare sulla tua scrittura!",
  __add_workgroup: "Aggiungi nuovo gruppo di lavoro",
  __edit_workgroup: "Modifica gruppo di lavoro",
  __workgroup_name: "Nome del gruppo di lavoro",
  __workgroup_description: "Descrizione del gruppo di lavoro",
  __workgroup_logo: "Logo del gruppo di lavoro",
  __workgroup_license: "Assegna la licenza al gruppo di lavoro",
  __expired: "Scaduta",
  __license_selection_is_required:
    "È necessario selezionare una licenza per creare un nuovo gruppo di lavoro.",
  __obtain_a_license: "Negozio di licenze Plotter Studio",
  __workgroup_size: "Dimensioni del gruppo di lavoro",
  __workgroup_amount: "Numero di gruppi di lavoro",
  __purchase: "Acquistare",
  __purchase_with_trial: "Inizia la prova gratuita",
  __must_accept_terms:
    "È necessario accettare termini e condizioni per continuare.",
  __purchase_with_trial_hint_large:
    "È disponibile una prova gratuita di giorni $FDAYS (non è richiesta la carta di credito). È richiesta solo la registrazione!",
  __purchase_with_trial_hint:
    "La prova gratuita durerà $FDAYS giorni, quindi dovrai acquistare una licenza per continuare a lavorare.",
  __purchase_with_trial_about_to_start:
    "Stai per richiedere una prova gratuita di un abbonamento. Il periodo di prova inizia immediatamente. Una volta scaduto il periodo di prova, dovrai completare l'acquisto dell'abbonamento.",
  __trial_started: "Il periodo di prova è iniziato con successo.",
  __subscription_must_expire_first:
    "Hai già acquistato questo abbonamento. Non potrai acquistarlo nuovamente finché non scade.",
  __workgroup_added: "Gruppo di lavoro creato!",
  __workgroup_edited: "Gruppo di lavoro modificato!",
  __workgroup_open: "Seleziona",
  __trial_days: "Giorni di prova",
  __requested_trial: "Prova richiesta",
  __workgroup_main_list:
    "Seleziona un gruppo di lavoro per caricare l'area di lavoro.",
  __change_workgroup: "Cambia gruppo di lavoro",
  __workgroup_page: "Gruppi di lavoro",
  __edit: "Modificare",
  __max_workgroup_limit_for_license:
    "Non puoi aggiungere altri gruppi di lavoro su questa licenza. Hai raggiunto il limite di gruppi di lavoro supportati per questa licenza.",
  __delete: "Eliminare",
  __delete_workgroup: "Eliminare il gruppo di lavoro?",
  __delete_workgroup_accept:
    "Acconsento alla rimozione di tutti i libri, eventi, sequenze temporali e qualsiasi altro dato relativo a questo gruppo di lavoro. Comprendo che questa azione è distruttiva e permanente.",
  __delete_workgroup_warning:
    "Per favore, fate attenzione. Se si elimina un gruppo di lavoro, tutti i libri nel gruppo di lavoro e tutti gli eventi correlati verranno eliminati per sempre. Questa azione non può essere annullata.",
  __edit_workgroup_people: "Gestisci la tua squadra",
  __invite_user_to_group: "Email nuovo membro",
  __invite_user_to_group_info:
    "Inserisci l'e-mail di un altro utente per invitarlo a unirsi al tuo gruppo di lavoro. L'utente invitato dovrà accettare l'invito per completare questo processo.",
  __invite_user_not_found:
    "L'utente invitato non è registrato su Plotter Studio. Assicurati che tutti gli utenti coinvolti abbiano completato la fase di registrazione gratuita prima dell'invito.",
  __invitation_sent: "Invito spedito.",
  __trial_ends_on: "La prova termina il",
  __purchase_expire_on: "Scade il",
  __invitation_accepted: "Invito accettato!",
  __pick_book_to_start: "Scegli un libro per iniziare.",
  __query_modal_title: "Motore AI",
  __query_modal_date_specific:
    "Tutti i risultati dipenderanno dalla data selezionata",
  __query_target_character: "Fai una domanda su questo personaggio",
  __query_target_type: "Cosa vuoi sapere",
  __age: "Età",
  __knows_about_character: "Conosce personaggio",
  __has_skill_or_ability: "Ha abilità",
  __posses_item: "Possiede l'oggetto",
  __please_pick_a_timeline: "Seleziona una linea temporale.",
  __faction: "Fazione",
  __status: "Stato",
  __condition: "Condizione",
  __location: "Posizione",
  __object_of_the_query: "Oggetto della domanda",
  __character: "Personaggio",
  __item: "Oggetto",
  __skill: "Abilità",
  __secret: "Segreto",
  __run_question: "Esegui interrogazione",
  __run_question_fields_missing: "Mancano alcuni campi",
  __elapsed: "trascorso",
  __character_evolution: "Evoluzione del personaggio",
  __anyone: "Chiunque",
  __any: "Qualunque",
  __jump_timeline_ticks: "Salta i tick",
  __jump_timeline_ticks_hint:
    "Inserisci il numero di tick di cui avanzare rispetto alla data corrente. Per andare indietro usa i numeri negativi. Sono accettate formule semplici (es: in vista giornaliera, 5 x 7 sposta di 5 settimane).",
  __bad_input: "Sono accettati solo numeri o formule di base.",
  __this_is_infinite: "Il valore del tuo input è infinito!",
  __remember_me: "Ricordati di me",
  __years: "Anni",
  __months: "Mesi",
  __days: "Giorni",
  __goto_last_event: "Ultimo evento",
  __expired_purchase:
    "Il tuo abbonamento è scaduto o la sua prova gratuita è terminata. Per continuare a utilizzare il gruppo di lavoro, devi correggere lo stato dell'abbonamento.",
  __renew_license_purchase: "Rinnovare la licenza",
  __buy_license: "Acquista la licenza",
  __all_my_purchases: "I miei acquisti",
  __license_type: "Tipo licenza",
  __payment_type: "Tipo di pagamento",
  __payment_is_valid: "Pagamento valido",
  __already_have_trial_on_this:
    "Hai già richiesto una prova gratuita per questo tipo di abbonamento. Puoi richiedere solo una prova gratuita per tipo di abbonamento.",
  __payment_type_month: "Durata: 1 mese",
  __payment_type_year: "Durata: 1 anno",
  __voucher_code: "Cupon",
  __full_price: "Prezzo pieno",
  __subscrbe_recurrent_payment:
    "Desidero invece iscrivermi a un pagamento ricorrente automatizzato.",
  __cupon_not_allowed:
    "Il codice Cupon non è disponibile per i pagamenti ricorrenti",
  __purchase_is_now_free:
    "Il tuo totale è 0! Congratulazioni, puoi saltare la fase di pagamento!",
  __purchase_completed: "Acquisto completato!",
  __requires_payment: "Richiede il pagamento",
  __bad_cupon: "Il tuo codice coupon non è valido o è scaduto.",
  __search_events: "Cerca eventi",
  __search_results: "Risultati di ricerca",
  __case_sensitive: "Distingui maiuscole e minuscole",
  __draft_ideas_title: "Gestisci qui le tue bozze di idee",
  __draft_idea_title: "Titolo dell'idea in bozza",
  __draft_idea_description: "Descrizione dell'idea in bozza",
  __draft_idea_status: "Stato dell'idea in bozza",
  __add_draft_idea: "Aggiungi bozza",
  __edit_draft_idea: "Modifica una bozza di idea",
  __draft_ideas_short: "Bozze",
  __draft_ideas: "Bozze di idee",
  __delete_draft_idea: "Elimina bozza di idea",
  __draf_idea_deleted: "Bozza di idea eliminata.",
  __draf_idea_edited: "Bozza di idea aggiornata correttamente.",
  __draf_idea_added: "Nasce Dragt Idea.",
  __draft_idea_status_active: "Attivo",
  __draft_idea_status_archived: "Archiviato",
  __draft_idea_status_completed: "Completato",
  __draft_idea_status_to_review: "Revisionare",
  __no_draft_ideas_yet:
    "Non hai ancora nessuna bozza di idee. Utilizza questo modulo per aggiungere una nuova idea. Le idee vengono utilizzate per tenere traccia di come la tua storia potrebbe evolversi.",
  __remaining_chars: "Caratteri rimanenti",
  __manage_timelines: "Gestisci le sequenze temporali del libro",
  __timeline_name: "Nome",
  __timeline_duration: "Durata (anni)",
  __timeline_order: "Ordine",
  __timeline_center_year: "Anno centrale",
  __timeline_add: "Aggiungi sequenza temporale",
  __timeline_edit: "Modifica sequenza temporale",
  __timeline_delete: "Elimina sequenza temporale",
  __timeline_add_done: "Sequenza temporale aggiunta correttamente.",
  __timeline_edit_done: "Sequenza temporale aggiornata correttamente.",
  __timeline_delete_done: "Sequenza temporale eliminata.",
  __timeline_chose_workwith: "Scegli una sequenza temporale",
  __notimeline_yet:
    "Sembra che tu non abbia alcuna impostazione della sequenza temporale per questo libro. Tutti i libri richiedono almeno una sequenza temporale con cui iniziare. Potrai aggiungere successivamente tutte le sequenze temporali che desideri.",
  __acknowledge_edittimeline_can_screw_up_things:
    "Accetto che la modifica alla struttura delle linee temporali eseguita in questa form non correggerà automaticamente anche le date di tutti gli eventuali eventi già presenti sulla sequenza temporale. Comprendo perciò che a seguito di alterazioni della struttura della linea temporale, alcuni degli eventi potrebbero perdere la loro collocazione.",
  __acknowledge_editmonth_can_screw_up_things:
    "Accetto che la modifica alla struttura dei mesi eseguita in questa form non correggerà automaticamente anche le date di tutti gli eventuali eventi già presenti sulla sequenza temporale. Comprendo perciò che a seguito di alterazioni della struttura dei mesi, alcuni degli eventi potrebbero perdere la loro collocazione sulla linea temporale.",
  __you_must_acknowledge_book_kill:
    "Devi accettare la cancellazione dei seguenti elementi prima di poter procedere.",
  __erease_current_book: "Cancella il libro corrente",
  __erease_all_events: "Cancella $AMOUNT eventi",
  __erease_all_draft_ideas: "Cancella $AMOUNT bozze di idee ",
  __erease_all_timelines: "Cancella $AMOUNT sequenze temporali",
  __pay_monthly: "Desidero pagare ogni mese",
  __pay_yearly: "Desidero pagare ogni anno",
  __read_terms_and_condition: "Leggi i termini e le condizioni qui",
  __read_about_privacy_statement: "Leggi la nostra politica sulla privacy qui",
  __terms_and_cond:
    "Accetto i termini e le condizioni dei servizi e l'informativa sulla privacy.",
  __event_timeline: "Cronologia dell'evento",
  __character_will_be_born_in: "Il personaggio nascerà tra ",
  __character_life_at_least_this_time:
    "Non ci sono dati chiari sulla nascita di questo personaggio. Si sa che il personaggio è operativo da questo tempo:",
  __character_life_inferred:
    "Se il personaggio fosse ancora vivo, fino a questo momento avrebbe questa età:",
  __logout: "Disconnettersi",
  __settings: "Impostazioni",
  __user_settings: "Impostazioni utente",
  __events_colors: "Colore eventi (per gruppo eventi)",
  __acknowledge_timeline_removal:
    "Accetto di rimuovere la sequenza temporale e tutti gli eventi attualmente inseriti su di essa.",
  __timeline_begin_year: "Inizia l'anno",
  __timeline_end_year: "Termina in anno",
  __required_fields_missing: "Mancano alcuni campi obbligatori.",
  __timeline_view: "Vista sequenza temporale",
  __table_view: "Vista tabella",
  __all: "Tutto",
  __all_hint: "Inserisci la voce '$ALL' per una ricerca senza restrizioni.",
  __save: "Salva",
  __color_for_event_type: "Colore pallino per tipologia di evento",
  __generic_ui_settings: "Impostazioni dell'interfaccia utente generiche",
  __limit_dots_on_timeline:
    "Numero massimo di punti elenco per il tick della sequenza temporale",
  __settings_updated: "Impostazioni aggiornate correttamente.",
  __your_subscription_doesnt_support_this_feature:
    "L'abbonamento attualmente in uso non supporta questa funzionalità.",
  __query_bot: "Segretario virtuale",
  __error_detection: "Rilevamento errori",
  __workgroup_book_limit_title: "Troppi libri!",
  __event_generic_info: "Informazioni generali",
  __workgroup_book_limit_description:
    "Sembra che tu abbia troppi libri in questo gruppo di lavoro. I libri in eccesso verranno comunque visualizzati nello scaffale, ma non potrai operare su di essi in alcun modo. Se desideri sbloccarli, puoi aggiornare l'abbonamento legato a questo gruppo di lavoro.",
  __book_limit_reached:
    "Non puoi aggiungere altri libri nel gruppo di lavoro corrente. Devi aggiornare la tua licenza.",
  __feature_usage_limit_reached:
    "Non puoi utilizzare questa funzione finché non ottieni più token di utilizzo. Puoi accedere al dashboard degli abbonamenti per tenere traccia di tutti gli utilizzi.",
  __getting_updates:
    "Ricevo aggiornamenti. L'operazione richiederà alcuni secondi. Attendere...",
  __sync: "sincronizzare",
  __got_it: "Ho capito",
  __show_again_tutorial_coachmarkers:
    "Ripristina la visibilità di tutti i Coachmarker del tutorial",
  __statistics: "Dati sull'utilizzo del gruppo di lavoro",
  __no_usages_recorded:
    "Non sono stati registrati utilizzi dei servizi a chiamata su questo Gruppo di Lavoro. Si ricorda che i dati verranno visualizzati in base all'abbonamento attualmente applicato al Gruppo di Lavoro.",
  __error_detection_intro:
    "Questo pannello consentirà di sfruttare il potente motore del compilatore di eventi per scoprire e correggere eventuali errori indesiderati che rientrano in una delle seguenti categorie. Lavoriamo costantemente per migliorare questa funzionalità man mano che sempre più tipi di errori vengono aggiunti al motore.",
  __error_detection_type_consistency: "Coerenza dei personaggi",
  __error_detection_type_events_out: "Eventi fuori dalla cronologia",
  __error_detection_type_continuity: "Errore di continuità",
  __error_detection_type: "Tipo di rilevamento errori",
  __karma_shift: "Spostamento del karma (facoltativo)",
  __karma_shift_positive: "Karma positivo",
  __karma_shift_neutral: "Karma neutro",
  __karma_shift_negative: "Karma negativo",
  __karma_shift_weight: "Proporzione di spostamento",
  __karma_shift_weight_small: "Piccolo cambiamento karmico",
  __karma_shift_weight_normal: "Cambiamento karmico medio",
  __karma_shift_weight_big: "Enorme cambiamento karmico",
  __how_to_fix: "Come risolvere",
  __severity_error: "errore",
  __severity_warning: "avvertimento",
  __severity_info: "informazione",
  __metadata: "Metadati",
  __character_inclinations: "Inclinazioni",
  __metadata_type: "Tipo di metadati",
  __character_goals: "Obiettivi",
  __metadata_value: "Valore",
  __character_generic_infos: "Info generiche",
  __character_generic_meta_description: "Descrizione generale",
  __character_potrait: "Ritratto del personaggio",

  __metadata_heading_hint:
    "Devi aggiungere un personaggio in un evento sulla linea temporale prima di poter aggiungere metadati.",
  __metadata_attitude_negative: "Detesta",
  __metadata_attitude_neutral: "Neutrale",
  __metadata_attitude_positive: "Ama",
  __action_completed_successfully: "Azione completata con successo.",
  __too_many_failed_attempts:
    "Login errato troppe volte. Riprovate tra qualche minuto.",
  __proceed_removing_user:
    "Procedere con la rimozione dell'utente? L'utente verrà rimosso dal gruppo di lavoro.",
  __simple_icon_set: "Set di icone predefinito",
  __anime_icon_set: "Set di icone anime",
  __used_icon_set: "Stile delle icone per gli eventi",
  __other_options: "Altre opzioni",
  __your_recent_books: "Libri recenti",
  __work_on_book: "Seleziona libro",
  __no_results: "Non ci sono elementi da visualizzare.",
  __book_logo: "Logo del libro",
  __book_has_been_disabled: "Il libro è stato disabilitato.",
  __statistic_is_subscription_based:
    "Queste informazioni sono basate sull'abbonamento attualmente associato al gruppo di lavoro.",
  __tell_mee_more: "Dimmi di più",
  __timeline_duration_hint:
    "Durata della sequenza temporale in anni. (e.g. per la prima sequenza temporale 2000 indica dall'anno 1 al 2000)",
  __timeline_central_year_hint:
    "Quando aprirai l'editor della sequenza temporale, questa sarà la data centrale visualizzata di default.",
  __timeline_order_hint:
    "Indica la posizione di questa sequenza temporale rispetto eventuali altre. (e.g. 1 per la prima sequenza temporale, 2 per la seconda, etc)",
  __book_timeline_delta_year_hint:
    "Carica solo un'intorno di anni rispetto all'anno centrale.",
  __workgroup_session_expired:
    "La sessione del gruppo di lavoro è scaduta. Per favore, rinnova la sessione selezionando il gruppo di lavoro.",
  __your_role_in_this_workgroup: "Hai avuto accesso come $ROLE.",
  __notimeline_yet_reader:
    "Sembra che non esistano ancora linee temporali per questo libro. Poiché sei un utente con ruolo di lettore, non puoi aggiungere nuove linee temporali.",
  __more_actions_in_date: "Altre azioni in data",
  __move_all_events_on_a_different_date:
    "Sposta tutti gli eventi su un'altra data.",
  __events_in_this_date: "Eventi in questa data",
  __jan: "Gennaio",
  __feb: "Febbraio",
  __mar: "Marzo",
  __apr: "Aprile",
  __may: "Maggio",
  __jun: "Giugno",
  __jul: "Luglio",
  __aug: "Agosto",
  __sep: "Settembre",
  __oct: "Ottobre",
  __nov: "Novembre",
  __dec: "Dicembre",
  __small: "Piccolo",
  __medium: "Medio",
  __large: "Grande",
  __model_size: "Dimensione del modello",
  __model_size_hint:
    "Modelli più grandi possono richiedere più tempo per essere elaborati, ma sono più precisi.",
  __language: "Lingua",
  __text_to_process: "Testo da processare",
  __generated_events: "Eventi generati",
  __no_events_generated: "Nessun evento generato.",
  __text_to_events: "Da testo a eventi",
  __generate_events: "Genera eventi",
  __apply_generated_events: "Applica eventi generati",
  __leave_a_feedback: "Lascia un feedback",
  __feedback_title: "Titolo",
  __feedback_description: "Descrizione",
  __feedback_vote: "Voto",
  __feedback_hint:
    "Lascia un feedback per aiutarci a migliorare Plotter Studio. Grazie!",
  __go_to_payment: "Vai al pagamento",
  __message: "Messaggio",
  __chat: "Chat",
  __workgroup_chat: "Chat del gruppo di lavoro",
  __recaptcha_test_failed: "Test Recaptcha fallito.",
  __workgroup_chat_hint:
    "Usa questa chat per comunicare con i membri del gruppo di lavoro.",
  __send: "Invia",
  __attach_file: "Allega file",
  __pick_a_chat_room_to_start_talking: "Seleziona una chat per iniziare.",
  __the_total_file_size_you_may_send_per_message_canno_exceed:
    "I file inviati con un messaggio non possono superare la dimenzione complessiva di $SIZE KB.",
  __with: "con",
  __load_older_chat_messages: "Carica messaggi precedenti",
};
