import SessionController from "./Controllers/SessionController";
import BookController from "./Controllers/BookController";
import EventController from "./Controllers/EventController";
import StoreController from "./Controllers/StoreController";
import WorkGroupController from "./Controllers/WorkGroupController";
import BookDraftIdeaController from "./Controllers/BookDraftIdeaController";
import BookTimelineController from "./Controllers/BookTimelineController";
import MetaDataController from "./Controllers/MetaDataController";
import FeedbackController from "./Controllers/FeedbackController";
import SysController from "./Controllers/SysController";
import WorkgroupChatController from "./Controllers/WorkgroupChat";

export const BuildParameters = (
  baseUrl: string,
  parameters: [string, any][]
) => {
  let params: string[] = [];
  parameters.forEach((x: [string, any]) => {
    if (x[1]) {
      params.push(x[0] + "=" + x[1]);
    }
  });

  if (baseUrl.includes("?")) {
    return baseUrl + "&" + params.join("&");
  } else {
    return baseUrl + "?" + params.join("&");
  }
};

const ApiService = {
  SessionController,
  SysController,
  WorkgroupChatController,
  FeedbackController,
  BookController,
  EventController,
  MetaDataController,
  StoreController,
  WorkGroupController,
  BookDraftIdeaController,
  BookTimelineController,
};

(window as any)["ApiService"] = ApiService;

export default ApiService;
