import { createRef, useEffect, useState } from "react";
import Loadable from "../Loadable/Loadable";
import "./BookExplorer.scss";
import { IEvent } from "../../Models/IEvent";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import { ToastMessage } from "../../Utils/UIMessages";
import Tr from "../../Utils/Translations/Translations";
import { IBook } from "../../Models/IBook";
import {
  Checkbox,
  DefaultButton,
  Dropdown,
  IDropdown,
  IconButton,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Toggle,
} from "@fluentui/react";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import MonthMapEditor, {
  IMonthMap,
  IMonthMapElement,
} from "../MonthMapEditor/MonthMapEditor";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../Redux/RootReducer";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import EventLogicProcessor from "../EventLogicProcessor/EventLogicProcessor";
import DraftIdeasHandler from "../DraftIdeasHandler/DraftIdeasHandler";
import { mobileCheck } from "../../App";
import { IBookTimeline } from "../../Models/IBookTimeline";
import BookTimelineEditor from "../BookTimelineEditor/BookTimelineEditor";
import { IDraftIdea } from "../../Models/IDraftIdea";
import TimelineEventExplorer from "../TimelineEventExplorer/TimelineEventExplorer";
import ExtraComputingAI from "../ExtraComputingAI/ExtraComputingAI";
import BetterCoachMarks from "../BetterCoachMark/BetterCoachMark";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import CharacterMetaDataEditor from "../CharacterMetaDataEditor/CharacterMetaDataEditor";
import AssetThemedLoader from "../AssetThemedLoader/AssetThemedLoader";
import Tools from "../../Utils/Tools";
import ImagePicker from "../ImagePicker/ImagePicker";
import RoleDependantDisplayer from "../RoleDependantDisplayer/RoleDependantDisplayer";
import FeedbackHandler from "../FeedbackHandler/FeedbackHandler";

export interface IBookExplorerProps {}

const BookExplorer = (props: IBookExplorerProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingEvents, setLoadingEvents] = useState<boolean>(false);
  const [loadingTimelines, setLoadingTimelines] = useState<boolean>(false);
  const [expandDetails, setExpandDetails] = useState<boolean>(false);
  const [monthMap, setMonthMap] = useState<IMonthMap>();
  const [imgUrl, setImgUrl] = useState<string>("");

  const [deleteBookAccept_1, setDeleteBookAccept_1] = useState<boolean>(false);
  const [deleteBookAccept_2, setDeleteBookAccept_2] = useState<boolean>(false);
  const [deleteBookAccept_3, setDeleteBookAccept_3] = useState<boolean>(false);
  const [deleteBookAccept_4, setDeleteBookAccept_4] = useState<boolean>(false);
  const dropdownRef = createRef<IDropdown>();
  const onSetFocus = () => dropdownRef?.current?.focus(true);

  const [acceptRiskOfMonthEditing, setAcceptRiskOfMonthEditing] =
    useState<boolean>(false);
  const books: IBook[] = useSelector(
    (state: GlobalState) => state.generic.books
  );
  const activeBook: IBook | undefined = useSelector(
    (state: GlobalState) => state.generic.activeBook
  );
  const allevents: IEvent[] = useSelector(
    (state: GlobalState) => state.generic.globalevents
  );
  const draftIdeas: IDraftIdea[] = useSelector(
    (state: GlobalState) => state.generic.bookDraftIdeas
  );
  const activeTimeline: IBookTimeline | undefined = useSelector(
    (state: GlobalState) => state.generic.activeTimeline
  );
  const bookTimelines: IBookTimeline[] = useSelector(
    (state: GlobalState) => state.generic.bookTimelines
  );

  useEffect(() => {
    if (!activeTimeline && bookTimelines.length === 1) {
      dispatch(GenericActions.setActiveBookTimeline(bookTimelines[0]));
    }
  }, [bookTimelines]);

  const loadTimelines = (bookId: number) => {
    setLoadingTimelines(true);
    ApiService.BookTimelineController.GetBookTimelines(
      undefined,
      bookId,
      (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(GenericActions.SetBookTimelines(response.parsed));
        }
        setLoadingTimelines(false);
      }
    );
  };

  const loadEvents = (bookId: number) => {
    setLoadingEvents(true);
    ApiService.EventController.GetEvents(
      undefined,
      bookId,
      (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(GenericActions.setEvents(response.parsed));
        }
        setLoadingEvents(false);
      }
    );
  };

  useEffect(() => {
    if (activeBook) {
      loadTimelines(activeBook.Id ?? 0);
      loadEvents(activeBook.Id ?? 0);
      setMonthMap(JSON.parse(activeBook.MonthMap ?? "{}"));
    }
  }, [activeBook]);

  const onCancel = () => {
    SummonModal("deleteBookModal");
  };

  const onEdit = () => {
    setAcceptRiskOfMonthEditing(false);
    setImgUrl("");
    SummonModal("editBookModal");
  };

  const globalUpdateBooks = (book: IBook) => {
    let newList: IBook[] = [...books];
    for (let i = 0; i < newList.length; i++) {
      if (newList[i].Id == book.Id) {
        newList[i] = { ...book };
      }
    }
    dispatch(GenericActions.setBooks(newList));
  };

  const globalRemoveBooks = () => {
    let newList: IBook[] = [...books];
    dispatch(
      GenericActions.setBooks(
        newList.filter((x: IBook) => x.Id !== activeBook?.Id)
      )
    );
  };

  const editBook = (data: any) => {
    setLoading(true);
    let bookEdit: IBook = { ...activeBook, ...data } as IBook;
    bookEdit.MonthMap = JSON.stringify(monthMap);
    ApiService.BookController.UpdateBook(bookEdit, (response: IAPIResponse) => {
      if (response.error === null) {
        globalUpdateBooks(bookEdit);
        ToastMessage(
          "success",
          Tr.Translate("language", "book_edited_succesfully")
        );
        DismissModal("editBookModal");
        dispatch(GenericActions.setActiveBook(bookEdit));
      }
      setLoading(false);
    });
  };

  const deleteBook = () => {
    setLoading(true);
    ApiService.BookController.DeleteBook(
      activeBook?.Id ?? 0,
      (response: IAPIResponse) => {
        if (response.error === null) {
          globalRemoveBooks();
          ToastMessage(
            "success",
            Tr.Translate("language", "book_deleted_succesfully")
          );
          DismissModal("deleteBookModal");
          dispatch(GenericActions.setActiveBook(undefined));
          dispatch(GenericActions.setActiveBookTimeline(undefined));
        }
        setLoading(false);
      }
    );
  };

  const getSumDays = () => {
    let days = 0;
    monthMap?.Months.forEach((x: IMonthMapElement) => {
      days += +x.MonthMaxDays;
    });
    return days;
  };
  return (
    <div>
      <div className="book-explorer-resume">
        <div
          className={
            "book-explorer-resume-commands-wide " +
            (mobileCheck() ? "book-explorer-resume-commands-wide-mobile" : "")
          }
        >
          <div className="book-explorer-resume-commands">
            <CustomTooltip
              isButton
              iconName="Back"
              content={Tr.Translate("language", "back")}
              onClick={() => {
                dispatch(GenericActions.setActiveBook(undefined));
                dispatch(GenericActions.setActiveBookTimeline(undefined));
              }}
            />
            <Label>{activeBook?.Title}</Label>
            <RoleDependantDisplayer
              whiteList={["admin", "contributor"]}
              element={
                <CustomTooltip
                  iconName="Trash"
                  isButton
                  content={Tr.Translate("language", "delete")}
                  onClick={onCancel}
                />
              }
            />
            <RoleDependantDisplayer
              whiteList={["admin", "contributor"]}
              element={
                <CustomTooltip
                  iconName="Edit"
                  isButton
                  content={Tr.Translate("language", "edit")}
                  onClick={onEdit}
                />
              }
            />

            <Toggle
              onChange={(e, o) => {
                if (o !== undefined) {
                  setExpandDetails(o);
                }
              }}
              onText={Tr.Translate("language", "expand")}
              offText={Tr.Translate("language", "collapse")}
            />
          </div>
          <div className="timeline-dropdown-selector">
            <div className="timeline-dropdown-selector-select">
              <Dropdown
                componentRef={dropdownRef}
                selectedKey={activeTimeline?.Id}
                disabled={
                  loading ||
                  loadingTimelines ||
                  loadingEvents ||
                  bookTimelines.length === 0
                }
                placeholder={Tr.Translate(
                  "language",
                  "timeline_chose_workwith"
                )}
                onChange={(e, o) => {
                  if (o) {
                    dispatch(GenericActions.setActiveBookTimeline(undefined));
                    if (o.key !== -1) {
                      dispatch(
                        GenericActions.setActiveBookTimeline(
                          bookTimelines.find(
                            (x: IBookTimeline) => x.Id === o.key
                          )
                        )
                      );
                    }
                  }
                }}
                options={[{ key: -1, text: "" }].concat(
                  bookTimelines.map((x: IBookTimeline) => {
                    return {
                      key: x.Id ?? 0,
                      text: x.Title,
                    };
                  })
                )}
              />
            </div>
            <PrimaryButton
              disabled={loading || loadingTimelines || loadingEvents}
              onClick={() => {
                SummonModal("timelineEditorModal");
              }}
              iconProps={{ iconName: "Edit" }}
            />
          </div>
          {activeTimeline && (
            <div style={{ display: "flex", gap: "1em" }}>
              <BetterCoachMarks
                coachMarkId="characters_meta_data"
                coachMarckTitle={Tr.Translate(
                  "coaching",
                  "characters_meta_data"
                )}
                coachMarckContent={Tr.Translate(
                  "coaching",
                  "characters_meta_data_desc"
                )}
                element={
                  <PrimaryButton
                    disabled={loading || loadingTimelines}
                    onClick={() => {
                      SummonModal("charactersMetaDataModal");
                    }}
                    text={Tr.Translate("coaching", "characters_meta_data")}
                    iconProps={{ iconName: "Edit" }}
                  />
                }
              />
              <BetterCoachMarks
                coachMarkId="draft_ideas"
                coachMarckTitle={Tr.Translate("coaching", "draft_ideas")}
                coachMarckContent={Tr.Translate("coaching", "draft_ideas_desc")}
                element={
                  <PrimaryButton
                    disabled={loading || loadingTimelines}
                    onClick={() => {
                      SummonModal("bookDraftIdeasModal");
                    }}
                    text={Tr.Translate("language", "draft_ideas_short")}
                    iconProps={{ iconName: "Edit" }}
                  />
                }
              />

              <BetterCoachMarks
                coachMarkId="ai_tool_box"
                coachMarckTitle={Tr.Translate("coaching", "ai_box")}
                coachMarckContent={Tr.Translate("coaching", "ai_box_desc")}
                element={
                  <PrimaryButton
                    disabled={loading || loadingTimelines}
                    onClick={() => {
                      SummonModal("EventQueryModalGlobal");
                    }}
                    iconProps={{ iconName: "Relationship" }}
                  />
                }
              />
            </div>
          )}
        </div>
        <SmartModal
          isWideModal
          titleBarExtra={[<FeedbackHandler section="manage-draft-ideas" />]}
          title={Tr.Translate("language", "draft_ideas_title")}
          modalUniqueId="bookDraftIdeasModal"
          content={<DraftIdeasHandler />}
          buttons={[
            {
              text: Tr.Translate("language", "cancel"),
              disabled: loading,
              onClick: () => {
                DismissModal("bookDraftIdeasModal");
              },
            },
          ]}
        />
        <SmartModal
          isWideModal
          titleBarExtra={[<FeedbackHandler section="extra-computing-ai" />]}
          title={Tr.Translate("language", "query_modal_title")}
          modalUniqueId="EventQueryModalGlobal"
          content={<ExtraComputingAI />}
          buttons={[
            {
              text: Tr.Translate("language", "cancel"),
              disabled: loading,
              onClick: () => {
                DismissModal("EventQueryModalGlobal");
              },
            },
          ]}
        />
        {expandDetails && (
          <div>
            <div className="book-explorer-resume-item">
              <Label>{Tr.Translate("language", "book_title")}</Label>
              <div>{activeBook?.Title}</div>
            </div>
            <div className="book-explorer-resume-item">
              <Label>
                {Tr.Translate("language", "book_total_days_per_year")}
              </Label>
              <div>{getSumDays()}</div>
            </div>
            <div className="book-explorer-resume-item">
              <Label>{Tr.Translate("language", "last_upated")}</Label>
              {activeBook?.UpdateDT?.replace("T", " ").substring(0, 16)}
            </div>
            <div className="book-explorer-resume-item">
              <Label>{Tr.Translate("language", "book_description")}</Label>
              <div>{activeBook?.Description}</div>
            </div>
          </div>
        )}
      </div>

      <SmartModal
        title={Tr.Translate("language", "delete_book")}
        modalUniqueId="deleteBookModal"
        content={
          <Loadable
            isLoading={loading}
            content={
              <div style={{ marginBottom: "1em" }}>
                <div>{Tr.Translate("language", "action_cannot_be_undone")}</div>
                <br />
                <MessageBar messageBarType={MessageBarType.warning}>
                  <div style={{ fontSize: "1.5em" }}>
                    {Tr.Translate("language", "you_must_acknowledge_book_kill")}
                  </div>
                </MessageBar>
                <div
                  style={{
                    marginTop: "1em",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1em",
                  }}
                >
                  <Checkbox
                    onChange={(e, c) => {
                      if (c !== undefined) {
                        setDeleteBookAccept_1(c);
                      }
                    }}
                    label={Tr.Translate("language", "erease_current_book")}
                  />
                  <Checkbox
                    onChange={(e, c) => {
                      if (c !== undefined) {
                        setDeleteBookAccept_2(c);
                      }
                    }}
                    label={Tr.Translate(
                      "language",
                      "erease_all_events"
                    ).replace("$AMOUNT", allevents.length)}
                  />
                  <Checkbox
                    onChange={(e, c) => {
                      if (c !== undefined) {
                        setDeleteBookAccept_3(c);
                      }
                    }}
                    label={Tr.Translate(
                      "language",
                      "erease_all_draft_ideas"
                    ).replace("$AMOUNT", draftIdeas.length)}
                  />
                  <Checkbox
                    onChange={(e, c) => {
                      if (c !== undefined) {
                        setDeleteBookAccept_4(c);
                      }
                    }}
                    label={Tr.Translate(
                      "language",
                      "erease_all_timelines"
                    ).replace("$AMOUNT", bookTimelines.length)}
                  />
                </div>
              </div>
            }
          />
        }
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: loading,
            onClick: () => {
              DismissModal("deleteBookModal");
            },
          },
          {
            text: Tr.Translate("language", "accept"),
            disabled:
              loading ||
              !deleteBookAccept_1 ||
              !deleteBookAccept_2 ||
              !deleteBookAccept_3 ||
              !deleteBookAccept_4,
            onClick: () => {
              deleteBook();
            },
          },
        ]}
      />
      <SmartModal
        title={Tr.Translate("language", "edit_book")}
        titleBarExtra={[<FeedbackHandler section="manage-book" />]}
        modalUniqueId="editBookModal"
        content={
          <Loadable
            isLoading={loading}
            content={
              <div>
                <MultiForm
                  disableOverflowHandler
                  onSubmit={(data: any) => {
                    data.BookImage = imgUrl;
                    editBook(data);
                  }}
                  formUniqueId={"editBookForm"}
                  inputs={[
                    {
                      type: "text",
                      name: "Title",
                      label: Tr.Translate("language", "book_title"),
                      currentValue: activeBook?.Title,
                      required: true,
                      extraParams: { maxLength: 50 },
                    },
                    {
                      type: "text",
                      name: "Description",
                      label: Tr.Translate("language", "book_description"),
                      currentValue: activeBook?.Description,
                      required: true,
                      extraParams: { maxLength: 3000 },
                    },
                    {
                      type: "text",
                      name: "Collection",
                      currentValue: activeBook?.Collection,
                      label: Tr.Translate("language", "book_collection"),
                      required: true,
                      extraParams: { maxLength: 50 },
                    },
                    {
                      type: "custom",
                      name: "_image",
                      element: (
                        <ImagePicker
                          text={Tr.Translate("language", "book_logo")}
                          onLoad={setImgUrl}
                          initialImage={
                            activeBook?.BookImage
                              ? activeBook?.BookImage
                              : imgUrl
                          }
                        />
                      ),
                    },
                    {
                      type: "custom",
                      name: "_monthmap",
                      element: (
                        <div>
                          <hr />
                          <Toggle
                            inlineLabel
                            label={Tr.Translate(
                              "language",
                              "acknowledge_editmonth_can_screw_up_things"
                            )}
                            onChange={(e, t) => {
                              if (t !== undefined) {
                                setAcceptRiskOfMonthEditing(t);
                              }
                            }}
                          />
                          <div
                            style={{
                              opacity: acceptRiskOfMonthEditing ? "1" : "0.4",
                            }}
                          >
                            <MonthMapEditor
                              disabled={!acceptRiskOfMonthEditing}
                              defaultValue={monthMap}
                              onChange={(data: IMonthMap) => {
                                if (acceptRiskOfMonthEditing) {
                                  setMonthMap(data);
                                }
                              }}
                            />
                          </div>
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            }
          />
        }
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: loading,
            onClick: () => {
              DismissModal("editBookModal");
            },
          },
          {
            text: Tr.Translate("language", "accept"),
            disabled: loading,
            onClick: () => {
              triggerFormValidation("editBookForm");
            },
          },
        ]}
      />

      <Loadable
        isLoading={loading || loadingTimelines || loadingEvents}
        content={
          <div>
            {bookTimelines.length === 0 && (
              <div>
                <RoleDependantDisplayer
                  whiteList={["admin", "contributor"]}
                  element={
                    <div>
                      <MessageBar messageBarType={MessageBarType.info}>
                        {Tr.Translate("language", "notimeline_yet")}
                      </MessageBar>
                      <div className="create-timeline-button">
                        <PrimaryButton
                          onClick={() => {
                            SummonModal("timelineEditorModal");
                          }}
                          iconProps={{ iconName: "Add" }}
                          text={Tr.Translate(
                            "language",
                            "create_first_timeline"
                          )}
                        />
                      </div>
                    </div>
                  }
                />
                <RoleDependantDisplayer
                  whiteList={["reader"]}
                  element={
                    <div>
                      <MessageBar messageBarType={MessageBarType.info}>
                        {Tr.Translate("language", "notimeline_yet_reader")}
                      </MessageBar>
                    </div>
                  }
                />
              </div>
            )}
            {!activeTimeline && bookTimelines.length > 0 && (
              <div className="start-work-label">
                <AssetThemedLoader
                  style={{ height: "10em" }}
                  assetName={"timeline"}
                />
                <br />
                <DefaultButton
                  onClick={onSetFocus}
                  iconProps={{ iconName: "Edit" }}
                  text={Tr.Translate("language", "please_pick_a_timeline")}
                />
              </div>
            )}
            {activeTimeline && <TimelineEventExplorer />}
          </div>
        }
      />
      <SmartModal
        isWideModal
        titleBarExtra={[<FeedbackHandler section="manage-timelines" />]}
        title={Tr.Translate("language", "manage_timelines")}
        modalUniqueId="timelineEditorModal"
        content={<BookTimelineEditor />}
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: loading,
            onClick: () => {
              DismissModal("timelineEditorModal");
            },
          },
        ]}
      />
      <SmartModal
        isWideModal
        titleBarExtra={[
          <FeedbackHandler section="manage-characters-meta-data" />,
        ]}
        title={Tr.Translate("coaching", "characters_meta_data")}
        modalUniqueId="charactersMetaDataModal"
        content={<CharacterMetaDataEditor />}
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: loading,
            onClick: () => {
              DismissModal("charactersMetaDataModal");
            },
          },
        ]}
      />
    </div>
  );
};

export default BookExplorer;
