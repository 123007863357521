import React, { useEffect, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { debug } from "console";

// Renders errors or successfull transactions on the screen.
function Message({ content }: any) {
  return <p>{content}</p>;
}

const sandbox =
  "AZmjuV7ChA6oxS2aeyvjx22b7QFQXU96WC1cM6MRvExvyp-TIvk4w9BWCDOH5bcremkHQrlCqC55si4u";

const livePaypal =
  "AVv3c346J3x7EK3pufiyNEl2rvCJjAsJL38Bb-W_DV25ggn7_F8sbyav6CR6pYw1vt1ZeOcBt9hpMmz4";

const initialOptions = {
  clientId: livePaypal,
  currency: "EUR",
  "disable-funding": "",
  "data-sdk-integration-source": "integrationbuilder_sc",
};

export const PayPalPaymentOnce = (props: {
  price: string;
  onPaymentDone: (data: any) => void;
}) => {
  const [message, setMessage] = useState("");
  const [price, setPrice] = useState<string>("");
  const [reset, setReset] = useState<boolean>(false);

  useEffect(() => {
    setPrice(props.price ?? "");
    setReset(true);
    setTimeout(() => {
      setReset(false);
    }, 500);
  }, [props.price]);

  return (
    <div>
      {!reset && (
        <PayPalScriptProvider
          options={{
            ...initialOptions,
            intent: "capture",
            "enable-funding": "paylater,venmo,card",
          }}
        >
          <PayPalButtons
            fundingSource={undefined}
            createOrder={(data, actions) => {
              return actions.order
                .create({
                  purchase_units: [
                    {
                      description: "Plotter Studio",
                      amount: {
                        currency_code: "EUR",
                        value: price,
                      },
                    },
                  ],
                })
                .then((orderId) => {
                  // Your code here after create the order
                  console.log(orderId);
                  return orderId;
                });
            }}
            onError={(err: any) => {
              console.error("Payment paypal", err);
            }}
            onApprove={(data, actions: any) => {
              return actions.order.capture().then((order: any) => {
                // Your code here after capture the order
                props.onPaymentDone({
                  data: data,
                  actions: actions,
                  order: order,
                });
              });
            }}
          />
        </PayPalScriptProvider>
      )}
    </div>
  );
};

export const PayPalPaymentSubscription = (props: {
  subscriptionId: string | undefined;
  onPaymentDone: (data: any) => void;
}) => {
  const [message, setMessage] = useState("");
  const [subscriptionId, setsubscriptionId] = useState<string>("");
  const [reset, setReset] = useState<boolean>(false);

  useEffect(() => {
    setsubscriptionId(props.subscriptionId ?? "");
    setReset(true);
    setTimeout(() => {
      setReset(false);
    }, 500);
  }, [props.subscriptionId]);

  return (
    <div className="App">
      {!reset && (
        <div>
          <PayPalScriptProvider
            options={{
              ...initialOptions,
              intent: "subscription",
              vault: true,
              "enable-funding": "paylater,card",
            }}
          >
            <PayPalButtons
              style={{
                shape: "rect",
                layout: "vertical",
              }}
              createSubscription={(data: any, actions: any) => {
                return actions.subscription.create({
                  plan_id: subscriptionId.substring(0, 26),
                });
              }}
              onApprove={async (data: any, actions: any) => {
                props.onPaymentDone({
                  data: data,
                  actions: actions,
                  order: { id: data.orderID },
                });
              }}
            />
          </PayPalScriptProvider>
        </div>
      )}
    </div>
  );
};
