import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  IComboBoxProps,
  IDropdownOption,
  ITextFieldProps,
  Label,
} from "@fluentui/react";

import "./BetterComboBox.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import BetterTextField from "../BetterTextField/BetterTextField";
import Tools from "../../Utils/Tools";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import Tr from "../../Utils/Translations/Translations";
import { IHtmlRect } from "../../Models/ISupport";
import { betterCommaListDisplay } from "../ExploreEventsTickGrid/ExploreEventsTickGrid";

export interface IBetterComboBoxProps extends ITextFieldProps {
  options: { key: string | number; text: string }[];
  selectedKey?: string;
}

const BetterComboBox = (props: IBetterComboBoxProps) => {
  const ref = useRef<any>();

  const [inputScrollTop, setInputScrollTop] = useState<number>(0);
  const [text, setText] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [options, setOptions] = useState<IComboBoxOption[]>([]);
  const [activeOptions, setActiveOptions] = useState<IComboBoxOption[]>([]);
  const [activeOption, setActiveOption] = useState<string>("");
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const hideOptions = () => {
    setShowOptions(false);
  };

  useEffect(() => {
    if (props.defaultValue) {
      pickVoice(props.defaultValue);
    }
    window.document.addEventListener("form-is-scrolling", hideOptions);
    return () => {
      window.document.removeEventListener("form-is-scrolling", hideOptions);
    };
  }, []);

  useEffect(() => {
    setOptions(props.options);

    if (props.selectedKey) {
      pickVoice(props.selectedKey);
    }
  }, [props.options, props.selectedKey]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange({} as any, selectedOption);
    }
  }, [selectedOption]);

  useEffect(() => {
    const filteredOptions: IComboBoxOption[] = options.filter(
      (x: IComboBoxOption) =>
        x.text.toLocaleLowerCase().includes(text.toLocaleLowerCase())
    );
    setActiveOptions(filteredOptions);

    if (filteredOptions.length === 1) {
      setSelectedOption(filteredOptions[0].text);
      setText(filteredOptions[0].text);
    }

    setShowOptions(text.length > 0);
  }, [text]);

  const pickVoice = (selection: string) => {
    setSelectedOption(selection);
    setText(selection);
  };

  const reloadPosition = () => {
    if (ref && ref.current) {
      setInputScrollTop(
        ref.current.getBoundingClientRect().bottom - ref.current.scrollTop
      );
    }
  };

  const changeActiveFromArrow = (shift: number) => {
    let options = Array.from(
      document.getElementsByClassName("better-combo-option")
    );

    let optionsTexts: string[] = options.map((x: any) => x.innerHTML);
    let indexOfCurrent = optionsTexts.indexOf(activeOption);

    indexOfCurrent += shift;

    if (indexOfCurrent > options.length - 1) {
      indexOfCurrent = options.length - 1;
    }
    if (indexOfCurrent < 0) {
      indexOfCurrent = 0;
    }
    setActiveOption(optionsTexts[indexOfCurrent]);

    let activeDiv: any = options[indexOfCurrent];

    let eleTop = activeDiv.offsetTop;
    let eleBottom = eleTop + activeDiv.clientHeight;

    let containerTop = activeDiv.parentNode.scrollTop;
    let containerBottom = containerTop + activeDiv.parentNode.clientHeight;

    // The element is fully visible in the container
    let isVisible = eleTop >= containerTop && eleBottom <= containerBottom;

    if (!isVisible) {
      if (shift < 0) {
        activeDiv.parentNode.scrollTop -= activeDiv.clientHeight;
      } else {
        activeDiv.parentNode.scrollTop += activeDiv.clientHeight;
      }
    }
  };

  return (
    <div>
      {selectedOption && (
        <div>
          {props.label && (
            <Label required={props.required}>{props.label}</Label>
          )}
          <div
            className={
              "better-combo-selected-voice " +
              (props.disabled ? "disabled-combo-label" : "")
            }
          >
            <Label>{betterCommaListDisplay(selectedOption, true)}</Label>
            <div style={{ position: "relative" }}>
              <div className="better-combo-cancel-positioner">
                <CustomTooltip
                  iconName="Cancel"
                  isButton
                  disabled={props.disabled}
                  content={Tr.Translate("language", "delete")}
                  onClick={() => {
                    reloadPosition();
                    setText("");
                    setSelectedOption("");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {!selectedOption && (
        <div className="better-combo-wrap">
          <div ref={ref}>
            <BetterTextField
              disabled={props.disabled}
              {...props}
              value={text}
              onBlur={() => {
                setShowOptions(false);
              }}
              onKeyDown={(e: any) => {
                if (e.key === "ArrowDown") {
                  changeActiveFromArrow(1);
                }
                if (e.key === "ArrowUp") {
                  changeActiveFromArrow(-1);
                }
                if (e.key === "Enter") {
                  pickVoice(activeOption);
                }
              }}
              onChange={(e, t) => {
                reloadPosition();
                if (t !== undefined) {
                  setText(t);
                }
              }}
            />
          </div>

          {text !== "" &&
            selectedOption !== text &&
            showOptions &&
            activeOptions.length > 0 && (
              <div
                className="better-combo-options"
                style={{ top: inputScrollTop }}
              >
                {activeOptions.map((x: IComboBoxOption, i: number) => {
                  return (
                    <div
                      className={
                        "better-combo-option " +
                        (x.text === activeOption
                          ? "better-combo-option-active"
                          : "")
                      }
                      key={i}
                      onMouseEnter={() => {
                        setActiveOption(x.text);
                      }}
                      onMouseDown={() => {
                        pickVoice(x.text);
                      }}
                    >
                      {betterCommaListDisplay(x.text, true)}
                    </div>
                  );
                })}
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default BetterComboBox;
