import { APIUrls } from "../../ApiUrls";
import { IUser, IUserRegister } from "../../Models/IUser";
import {
  IWorkgroup,
  IWorkgroupMember,
  IWorkgroupMemberInvite,
  IWorkgroupMemberUpdate,
} from "../../Models/IWorkgroup";
import { AjaxService, IAPIRequest, IAPIResponse } from "../AjaxService";

const WorkGroupController = {
  SignIntoWokgroup: async (
    workgroupID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.WORKGROUP.LOGIN_WORKGROUP;
    if (workgroupID) {
      request.url += "?workgroupID=" + workgroupID;
    }
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetWorkgroups: async (
    workgroupID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.WORKGROUP.GET_WORKGROUPS;
    if (workgroupID) {
      request.url += "?workgroupID=" + workgroupID;
    }
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  InsertWorkgroup: async (
    workgroup: IWorkgroup,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.WORKGROUP.INSERT_WORKGROUP;
    request.method = "POST";
    request.payload = workgroup;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  UpdateWorkgroup: async (
    workgroup: IWorkgroup,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.WORKGROUP.UPDATE_WORKGROUP;
    request.method = "POST";
    request.payload = workgroup;

    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  DeleteWorkgroup: async (
    workgroupId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url =
      APIUrls.WORKGROUP.DELETE_WORKGROUP + "?workgroupId=" + workgroupId;
    request.method = "DELETE";
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetWorkgroupMembers: async (
    workgroupID?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.WORKGROUP.GET_WORKGROUP_MEMBERS;
    if (workgroupID) {
      request.url += "?workgroupID=" + workgroupID;
    }
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  AcceptInvitation: async (
    token: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.WORKGROUP.ACCEPT_INVITATION + "?token=" + token;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  InviteWorkgroupMember: async (
    workgroupMemberInvite: IWorkgroupMemberInvite,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.WORKGROUP.INVITE_MEMBER;
    request.method = "POST";
    request.payload = workgroupMemberInvite;

    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  UpdateWorkgroupMember: async (
    workgroupMember: IWorkgroupMemberUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.WORKGROUP.UPDATE_WORKGROUP_MEMBER;
    request.method = "POST";
    request.payload = workgroupMember;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  DeleteWorkgroupMember: async (
    workgroupId: number,
    workgroupMemberId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url =
      APIUrls.WORKGROUP.DELETE_WORKGROUP_MEMBER +
      "?workgroupMemberId=" +
      workgroupMemberId +
      "&workgroupId=" +
      workgroupId;
    request.method = "DELETE";

    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
};

export default WorkGroupController;
