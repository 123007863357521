export const Dictionary = {
  // specials
  __answer_label_data: "On date $DATE",
  __answer_label_age: "$CHARNAME is born on $DATE, current age is $AGE",
  __answer_label_age_plus_death:
    "$CHARNAME is born on $DATE and died on $DATE, at the age of $AGE",

  // standard labels
  __event_type_appear: "$CHARNAME appears on $DATE",
  __event_type_born: "$CHARNAME is born on $DATE",
  __event_type_revealed: "$CHARNAME is revealed on $DATE",
  __event_type_disappears: "$CHARNAME disappears on $DATE",
  __event_type_changes_id:
    "$CHARNAME changes its known identity, becoming $OBJECT on $DATE",
  __event_type_abbandons: "$CHARNAME abbandons its current role on $DATE",
  __event_type_dies: "$CHARNAME dies on $DATE",
  __event_type_teams_up_with: "$CHARNAME allies with $OTHERCHARACTERS on $DATE",
  __event_type_challenge:
    "$CHARNAME [with $OPS_OTHERCHARACTERS] sustain the challenge $OBJECT on $DATE",
  __event_type_meets: "$CHARNAME encounters $OTHERCHARACTERS on $DATE",
  __event_type_friendship:
    "$CHARNAME become friends with $OTHERCHARACTERS on $DATE",
  __event_type_friendship_end:
    "$CHARNAME stops being friends with $OTHERCHARACTERS on $DATE",
  __event_type_engage:
    "$CHARNAME starts romance with $OTHERCHARACTERS on $DATE",
  __event_type_engage_end:
    "$CHARNAME stops romance with $OTHERCHARACTERS on $DATE",
  __event_type_betrays: "$CHARNAME betrays $OTHERCHARACTERS on $DATE",
  __event_type_shares_a_secret:
    "$CHARNAME shares the secret of $OBJECT with $OTHERCHARACTERS on $DATE",
  __event_type_kills: "$CHARNAME kills $OBJECT on $DATE",
  __event_type_marries: "$CHARNAME marries with $OTHERCHARACTERS on $DATE",
  __event_type_allies: "$CHARNAME allies with $OTHERCHARACTERS on $DATE",
  __event_type_situation:
    "$CHARNAME faces the $OBJECT situation [with $OPS_OTHERCHARACTERS] on $DATE",
  __event_type_falls_in_love:
    "$CHARNAME falls in love with $OTHERCHARACTERS on $DATE",
  __event_type_starts_mission:
    "$CHARNAME stars the Job/Mission $OBJECT on $DATE",
  __event_type_starts_mission_end:
    "$CHARNAME concludes the Job/Mission $OBJECT on $DATE",
  __event_type_assumes_status:
    "$CHARNAME obtains the status of $OBJECT on $DATE",
  __event_type_assumes_condition:
    "$CHARNAME assumes the condition of $OBJECT on $DATE",
  __event_type_reaches_location:
    "$CHARNAME reaches the location of $OBJECT on $DATE",
  __event_type_leaves_location:
    "$CHARNAME leaves the location of $OBJECT on $DATE",
  __event_type_learns_skill: "$CHARNAME learns the skill of $OBJECT on $DATE",
  __event_type_learns_secret: "$CHARNAME learns about $OBJECT on $DATE",
  __event_type_joins_faction: "$CHARNAME joins the faction $OBJECT on $DATE",
  __event_type_leaves_faction: "$CHARNAME leaves the faction $OBJECT on $DATE",
  __event_type_obtains_item: "$CHARNAME obtains the item $OBJECT on $DATE",
  __event_type_book_closure: "$CHARNAME sees the end of the book on $DATE",
  __event_type_holiday: "$CHARNAME cheers about $OBJECT on $DATE",
  __event_type_book_begins: "$CHARNAME sees the start of the book on $DATE",
  __event_type_geopolitic:
    "$CHARNAME causes the geopolitical effect of $OBJECT on $DATE",
  __event_type_natural_disaster:
    "$CHARNAME is involved on the disaster of $OBJECT on $DATE",
  __event_type_war: "$CHARNAME participated in war [of $OPS_OBJECT] on $DATE",
  __event_type_mass_kill:
    "$CHARNAME caused a massive destruction [during $OPS_OBJECT] on $DATE",
  __event_type_creates_or_builds:
    "$CHARNAME created/builded the following: $OBJECT on $DATE",
  __event_type_rule_changer:
    "$CHARNAME changed a cosmic rule [of $OPS_OBJECT] on $DATE",
  __no_errors_to_report: "No errors detected in this category. Good job!",
  __character_leaving_position_never_reached:
    "<strong>$CHARACTER</strong> he left the place <strong>$OBJECT</strong>, which is a position never reached before.",
  __character_cannot_marry_since_there_is_no_trust:
    "<strong>$CHARACTER</strong> should not marry with <strong>$OBJECT</strong>, as he has not reached the right level of affinity and intimacy.",
  __character_cannot_betray_since_there_is_no_trust:
    "<strong>$CHARACTER</strong> he can't actually cheat <strong>$OBJECT</strong> as there is no relationship of trust between them.",
  __active_character_cannot_interact_with_unknown_character:
    "<strong>$CHARACTER</strong> cannot interact with <strong>$OBJECT</strong> as this character is not introduced in the plot.",
  __character_changing_id_to_unknown_alterego:
    "<strong>$CHARACTER</strong> cannot assume the identity of <strong>$OBJECT</strong> as this identity is not as introduced into the plot.",

  __character_leaving_position_never_reached_quick_fix:
    "To fix this, the character must first be introduced to the target location in a past event using the event: EVMAP#event_type_reaches_location",
  __character_cannot_marry_since_there_is_no_trust_quick_fix:
    "To fix this, the character must first increment the amount of positive interaction with the married person. Trust is automatically added trough positive interactions between characters. If this issue is about some secondary character with reduced background information, you may proceed to ignore this.",
  __character_cannot_betray_since_there_is_no_trust_quick_fix:
    "To fix this, the betrayed character must first be in the position to trust the traitor. Trust is automatically added trough positive interactions between characters.",
  __active_character_cannot_interact_with_unknown_character_quick_fix:
    "To fix this, you must first add the unknown character to the plot from a past event. A character is automatically added to the plot if it appears in the involved characters list of any event.",
  __character_changing_id_to_unknown_alterego_quick_fix:
    "To fix this, you must first add the unknown alter-ego to the plot by using such alter-ego as an independant character from a past event. A character is automatically added to the plot if it appears in the involved characters list of any event.",

  __character_befriending_disliked_person:
    "<strong>$CHARACTER</strong> cannot become friends with <strong>$OBJECT</strong> because there is not enough trust among them.",
  __character_befriending_disliked_person_quick_fix:
    "To resolve this issue, the character who wants to become friends must first overcome their dislike towards the other person. Trust is automatically built through positive interactions between characters.",

  __character_sharing_secret_with_untrusted_person:
    "<strong>$CHARACTER</strong> should not spontaneously share a secret <strong>$OBJECT</strong> because there is not enough trust among them.",
  __character_sharing_secret_with_untrusted_person_quick_fix:
    "To resolve this issue, the character who wants to share the secret must first be able to trust the other person. Trust is automatically built through positive interactions between characters.",

  __character_engaging_disliked_person:
    "<strong>$CHARACTER</strong> should not yet engage with <strong>$OBJECT</strong> because they know each other too little.",
  __character_engaging_disliked_person_quick_fix:
    "To increase the level of trust between the two, the character must first increase the number of positive interactions with the engaged person. Trust is automatically added through positive interactions between characters.",
  __active_character_cannot_interact_with_unknown_character_to_him:
    "<strong>$CHARACTER</strong> cannot act on <strong>$OBJECT</strong> because they have never met before.",
  __active_character_cannot_interact_with_unknown_character_to_him_quick_fix:
    "To resolve this issue, you must first add the unknown character to the plot using a past event. A character is automatically added to the plot if it appears in the list of involved characters of any event.",
};
