import {
  IconButton,
  Label,
  MessageBar,
  MessageBarType,
  Persona,
  PersonaPresence,
  PersonaSize,
  PrimaryButton,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react";
import "./Toolbar.scss";
import { IUser } from "../../Models/IUser";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../Redux/RootReducer";

import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../AppRoutes";
import Tr from "../../Utils/Translations/Translations";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import LanguagePicker from "../LanguagePicker/LanguagePicker";
import { mobileCheck } from "../../App";
import { useEffect, useState } from "react";
import { useId } from "@fluentui/react-hooks";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import Loadable from "../Loadable/Loadable";
import Refresher from "../Refresher/Refresher";
import BetterCoachMarks from "../BetterCoachMark/BetterCoachMark";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import ImageAssetLoader from "../ImageAssetLoader/ImageAssetLoader";
import FeedbackHandler from "../FeedbackHandler/FeedbackHandler";

export interface IToolbarProps {}

const Toolbar = (props: IToolbarProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const tooltipId = useId("tooltip");
  const [loading, setLoading] = useState<boolean>(false);
  const [hideUserMenu, setHideUserMenu] = useState<boolean>(false);
  const [hideUserMenuOnRefresh, setHideUserMenuOnRefresh] =
    useState<boolean>(false);

  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.generic.user
  );

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  useEffect(() => {
    setHideUserMenu(true);
    setTimeout(() => {
      setHideUserMenu(false);
    }, 3000);
  }, [location]);

  const displayLogoOnPage = () => {
    if (!mobileCheck()) {
      return true;
    }

    return !window.location.pathname.includes(AppRoutes.EVENT_MAP_PAGE);
  };

  const getRigthContent = () => {
    let content: JSX.Element[] = [<LanguagePicker />];
    if (
      [
        AppRoutes.EVENT_MAP_PAGE,
        AppRoutes.WORKGROUP_PICK_PAGE,
        AppRoutes.MY_PURCHASES_PAGE,
        AppRoutes.SETTINGS_PAGE,
      ].includes(window.location.pathname) &&
      loggedUser
    ) {
      content.push(<FeedbackHandler section="navigation-main-generic" />);
      content.push(
        <div>
          <Refresher
            toggle={hideUserMenuOnRefresh}
            element={
              <CustomTooltip
                disableTooltip={hideUserMenu}
                innerComponent={
                  <div className="persona-container">
                    <div>{loggedUser.Username}</div>
                    <Persona
                      presence={PersonaPresence.online}
                      size={PersonaSize.size40}
                      imageUrl={loggedUser.ImageBase64}
                    />
                  </div>
                }
                content={
                  <div>
                    <Loadable
                      isLoading={loading}
                      content={
                        <div>
                          {window.location.pathname ===
                            AppRoutes.EVENT_MAP_PAGE && (
                            <div className="user-menu-button">
                              <PrimaryButton
                                text={Tr.Translate("language", "sync")}
                                onClick={() => {
                                  (window as any)["dropApiCache"]();
                                  document.dispatchEvent(
                                    new Event("sync-data")
                                  );
                                  setHideUserMenuOnRefresh(true);
                                  setTimeout(() => {
                                    setHideUserMenuOnRefresh(false);
                                  }, 1);
                                }}
                                iconProps={{ iconName: "Sync" }}
                              />
                            </div>
                          )}
                          <div className="user-menu-button">
                            <PrimaryButton
                              text={Tr.Translate("language", "workgroup_page")}
                              onClick={() => {
                                navigate(AppRoutes.WORKGROUP_PICK_PAGE);
                              }}
                              iconProps={{ iconName: "Work" }}
                            />
                          </div>
                          <div className="user-menu-button">
                            <PrimaryButton
                              text={Tr.Translate(
                                "language",
                                "all_my_purchases"
                              )}
                              onClick={() => {
                                navigate(AppRoutes.MY_PURCHASES_PAGE);
                              }}
                              iconProps={{ iconName: "Diamond" }}
                            />
                          </div>

                          <div className="user-menu-button">
                            <PrimaryButton
                              text={Tr.Translate("language", "settings")}
                              onClick={() => {
                                navigate(AppRoutes.SETTINGS_PAGE);
                              }}
                              iconProps={{ iconName: "Settings" }}
                            />
                          </div>
                          <div className="user-menu-button">
                            <PrimaryButton
                              onClick={() => {
                                setLoading(true);
                                ApiService.SessionController.Logout(
                                  (response: IAPIResponse) => {
                                    navigate(AppRoutes.LOGIN_PAGE);
                                    setLoading(false);
                                  }
                                );
                              }}
                              text={Tr.Translate("language", "logout")}
                              iconProps={{ iconName: "ReleaseGate" }}
                            />
                          </div>
                        </div>
                      }
                    />
                  </div>
                }
                closeDelay={TooltipDelay.medium}
              />
            }
          />
        </div>
      );
    }

    if (window.location.pathname === AppRoutes.HOME_PAGE) {
      content.push(
        <div>
          <PrimaryButton
            text={Tr.Translate("language", "login")}
            onClick={() => {
              let sessionToken = localStorage.getItem("sessionToken");
              let workgroupSessionToken = localStorage.getItem(
                "workgroupSessionToken"
              );
              if (sessionToken && workgroupSessionToken) {
                navigate(AppRoutes.EVENT_MAP_PAGE);
              } else if (sessionToken) {
                navigate(AppRoutes.WORKGROUP_PICK_PAGE);
              } else {
                navigate(AppRoutes.LOGIN_PAGE);
              }
            }}
          />
        </div>
      );
    }

    return content;
  };

  const rightContent = getRigthContent();

  return (
    <div className="toolbar-main-wrap">
      {!isOnline && (
        <div className="toolbar-offline-message">
          <MessageBar messageBarType={MessageBarType.blocked}>
            <div style={{ fontSize: "2em", padding: "0.5em" }}>
              {Tr.Translate("system", "you_are_offline")}
            </div>
          </MessageBar>
        </div>
      )}
      {displayLogoOnPage() && (
        <div
          className="toolbar-logo-row"
          onClick={() => {
            navigate(AppRoutes.HOME_PAGE);
          }}
        >
          <ImageAssetLoader
            className="toolbar-logo"
            src={"/Assets/Public/IconLogo.png"}
          />
          <div className="toolbar-logo-label">
            Plotter Studio{" "}
            {window.location.origin.includes("uat") && <span>UAT</span>}
          </div>
        </div>
      )}

      {window.location.pathname.includes(AppRoutes.EVENT_MAP_PAGE) &&
        mobileCheck() && (
          <CustomTooltip
            onClick={() => {
              document.dispatchEvent(new Event("toggle-mobile-menu"));
            }}
            iconName="GlobalNavButton"
            isButton
            content={Tr.Translate("language", "navigate_mobile_menu")}
          />
        )}

      {rightContent && rightContent.length > 0 && (
        <div className="right-content-toolbar-wrap">
          {rightContent.map((x: JSX.Element, i: number) => {
            return <div key={i}>{x}</div>;
          })}
        </div>
      )}
    </div>
  );
};

export default Toolbar;
