import { useDispatch, useSelector } from "react-redux";
import {
  ISubscription,
  ISubscriptionFeature,
} from "../../Models/ISubscription";
import "./StorePage.scss";
import { GlobalState } from "../../Redux/RootReducer";
import Tr from "../../Utils/Translations/Translations";
import {
  Checkbox,
  DefaultButton,
  IconButton,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  Toggle,
} from "@fluentui/react";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../Components/SmartModal/SmartModal";
import { useState } from "react";
import ApiService from "../../Services/ApiService";
import { IPurchase } from "../../Models/IPurchase";
import { IAPIResponse } from "../../Services/AjaxService";
import { ToastMessage, ToastMessageUnique } from "../../Utils/UIMessages";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import {
  PayPalPaymentOnce,
  PayPalPaymentSubscription,
} from "../../Components/PayPalPayment/PayPalPayment";
import { IVoucher } from "../../Models/IVoucher";
import BetterTextField from "../../Components/BetterTextField/BetterTextField";
import { mobileCheck } from "../../App";
import Tools from "../../Utils/Tools";
import SuperSpinner from "../../Components/SuperSpinner/SuperSpinner";
import ImageAssetLoader from "../../Components/ImageAssetLoader/ImageAssetLoader";
import BetterRadioButtons from "../../Components/BetterRadioButtons/BetterRadioButtons";

function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c: any) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

interface ISubscriptionPurchasePreviewProps {
  subscription: ISubscription | undefined;
  onBack?: () => void;
  paymentDone: (paypalData: any) => void;
}

export const getFeatureAmountLabel = (feature: ISubscriptionFeature) => {
  let extra: JSX.Element = <div>{feature.FeatureAmount}</div>;

  if (["QUERY_BOT", "EVENT_ERROR_DETECTION"].includes(feature.FeatureCode)) {
    extra = (
      <div>
        <div>{feature.FeatureAmount}</div>
        <div
          style={mobileCheck() ? { fontSize: "0.6em" } : { fontSize: "0.8em" }}
        >
          {Tr.Translate("subscription", "per_month_calls")}
        </div>
      </div>
    );
  }

  return <div>{extra}</div>;
};

export const SubscriptionFeatureListComponent = (props: {
  subscription: ISubscription | undefined;
}) => {
  const subscriptionFeatures: ISubscriptionFeature[] = useSelector(
    (state: GlobalState) => state.generic.subscriptionFeatures
  );

  return (
    <div>
      <div className="subscription-includes">
        {Tr.Translate("subscription", "subscription_includes")}
      </div>
      {subscriptionFeatures.length === 0 && (
        <div style={{ padding: "1em" }}>
          <SuperSpinner />
        </div>
      )}
      {subscriptionFeatures.length > 0 && props.subscription && (
        <div>
          {subscriptionFeatures
            .filter(
              (x: ISubscriptionFeature) =>
                x.SubscriptionId === props.subscription?.Id &&
                x.FeatureAmount > 0
            )
            .map((x: ISubscriptionFeature, i: number) => {
              return (
                <div key={i}>
                  <div className="subscription-includes-line">
                    <div>
                      <div className="subscription-includes-title">
                        {Tr.Translate("subscription", x.FeatureLabel)}
                      </div>
                      <div className="subscription-includes-description">
                        {Tr.Translate("subscription", x.FeatureDescription)}
                      </div>
                    </div>
                    <div className={"subscription-includes-amounts"}>
                      <div>{Tr.Translate("subscription", "amount")}</div>
                      <strong className="subscription-includes-amounts-label">
                        {getFeatureAmountLabel(x)}
                      </strong>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

const SubscriptionPurchasePreview = (
  props: ISubscriptionPurchasePreviewProps
) => {
  const [payYearly, setPayYearly] = useState<boolean>(false);
  const [cupon, setCupon] = useState<string>("");
  const [voucher, setVoucher] = useState<IVoucher[]>([]);
  const [requestSubscription, setRequestSubscription] =
    useState<boolean>(false);
  const [viewPaymentMethods, setViewPaymentMethods] = useState<boolean>(false);
  const [loadingVoucher, setLoadingVoucher] = useState<boolean>(false);

  const loadVoucher = () => {
    setLoadingVoucher(true);
    ApiService.StoreController.TestVoucherEffect(
      cupon,
      (response: IAPIResponse) => {
        if (response.error === null) {
          if (response.parsed.length === 0) {
            ToastMessage("warning", Tr.Translate("language", "bad_cupon"));
          }
          setVoucher(response.parsed);
        }
        setLoadingVoucher(false);
      }
    );
  };

  var purchaseFinalCost = props.subscription?.ExposedPrice ?? 0;
  if (payYearly) {
    purchaseFinalCost = props.subscription?.ExposedPriceYearly ?? 0;
  }
  var couponCostResult = -1;
  if (voucher.length > 0 && !requestSubscription) {
    couponCostResult = (purchaseFinalCost * (100 - voucher[0].Discount)) / 100;
  }

  var purchaseAppliedCost = purchaseFinalCost;
  if (couponCostResult !== -1 && !requestSubscription) {
    purchaseAppliedCost = couponCostResult;
  }

  const computeAnnualDiscount = (monthlyPrice: number, yearlyPrice: number) => {
    return ((yearlyPrice / (monthlyPrice * 12)) * 100).toFixed(2);
  };

  return (
    <div className="subscription-recap-wrap">
      <div className="subscription-recap-title">
        <DefaultButton
          iconProps={{ iconName: "Back" }}
          text={Tr.Translate("language", "back")}
          onClick={props.onBack}
        />
        {Tr.Translate("subscription", props.subscription?.SubscriptionName)}
      </div>

      {!viewPaymentMethods && (
        <div>
          <div className="subscription-recap-description">
            {Tr.Translate(
              "subscription",
              props.subscription?.SubscriptionDescription
            )}
          </div>
          <div className="subscription-recap-includes-zone">
            <SubscriptionFeatureListComponent
              subscription={props.subscription}
            />
          </div>

          <div
            className="subscription-coupon-commands"
            style={mobileCheck() ? { flexDirection: "column" } : {}}
          >
            <Checkbox
              checked={requestSubscription}
              onChange={(e, c) => {
                setPayYearly(false);
                if (c !== undefined) {
                  setRequestSubscription(c);
                }
              }}
              label={Tr.Translate("language", "subscrbe_recurrent_payment")}
            />
            {!requestSubscription && (
              <div className="coupon-line-wrap">
                {loadingVoucher && <SuperSpinner />}
                {!loadingVoucher && (
                  <div
                    style={{ display: "flex", gap: "0.5em", alignItems: "end" }}
                  >
                    <BetterTextField
                      maxLength={50}
                      value={cupon}
                      onChange={(e, t) => {
                        if (t !== undefined) {
                          setCupon(t);
                        }
                      }}
                      placeholder={Tr.Translate("language", "voucher_code")}
                    />
                    <PrimaryButton
                      disabled={cupon.length < 5 || loadingVoucher}
                      iconProps={{ iconName: "AutoEnhanceOn" }}
                      onClick={() => {
                        loadVoucher();
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {requestSubscription && (
              <div style={{ width: "fit-content" }}>
                <MessageBar messageBarType={MessageBarType.info}>
                  {Tr.Translate("language", "cupon_not_allowed")}
                </MessageBar>
              </div>
            )}
          </div>
          {requestSubscription && (
            <div>
              <br />
              <BetterRadioButtons
                fieldName="paymentType"
                defaultChecked={payYearly ? "yearly" : "monthly"}
                options={[
                  {
                    key: "monthly",
                    text:
                      Tr.Translate("language", "pay_monthly") +
                      " " +
                      (props.subscription?.ExposedPrice ?? 0) +
                      "€",
                  },
                  {
                    key: "yearly",
                    text:
                      Tr.Translate("language", "pay_yearly") +
                      " " +
                      (props.subscription?.ExposedPriceYearly ?? 0) +
                      "€  (-" +
                      computeAnnualDiscount(
                        props.subscription?.ExposedPrice ?? 0,
                        props.subscription?.ExposedPriceYearly ?? 0
                      ) +
                      "%)",
                  },
                ]}
                onChange={(c: string) => {
                  if (c !== undefined) {
                    setPayYearly(c === "yearly");
                  }
                }}
              />
            </div>
          )}
          <div className="subscription-recap-payment-price">
            <div
              style={
                voucher.length > 0 && !requestSubscription
                  ? { textDecoration: "line-through" }
                  : {}
              }
            >
              <pre>Tot: {purchaseFinalCost} €</pre>
            </div>
            {voucher.length > 0 && !requestSubscription && (
              <div>
                <pre>
                  {cupon}: {purchaseAppliedCost} €
                </pre>
              </div>
            )}
          </div>

          <hr />
          {!requestSubscription && purchaseAppliedCost === 0 && (
            <div>
              <div className="nopay-banner">
                {Tr.Translate("language", "purchase_is_now_free")}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  margin: "1em",
                }}
              >
                <PrimaryButton
                  onClick={() => {
                    props.paymentDone({
                      order: { id: "voucher-free-" + uuidv4().toString() },
                      voucher: voucher[0],
                      requestSubscription: false,
                    });
                  }}
                  text={Tr.Translate("language", "buy_license")}
                />
              </div>
            </div>
          )}
          {purchaseAppliedCost > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                padding: "0.5em",
              }}
            >
              <PrimaryButton
                onClick={() => {
                  setViewPaymentMethods(true);
                }}
                text={Tr.Translate("language", "go_to_payment")}
              />
            </div>
          )}
        </div>
      )}

      {viewPaymentMethods && (
        <div style={{ marginTop: "2em" }}>
          <div
            className="subscription-recap-payment-price"
            style={{ textAlign: "center" }}
          >
            <div
              style={
                voucher.length > 0 ? { textDecoration: "line-through" } : {}
              }
            >
              <pre>Tot: {purchaseFinalCost} €</pre>
            </div>
            {voucher.length > 0 && (
              <div>
                <pre>
                  {cupon}: {purchaseAppliedCost} €
                </pre>
              </div>
            )}
          </div>
          {purchaseAppliedCost > 0 &&
            !requestSubscription &&
            props.subscription?.ExposedPrice && (
              <div className="paypal-payment-wrap">
                <PayPalPaymentOnce
                  onPaymentDone={(data: any) => {
                    props.paymentDone({
                      ...data,
                      voucher: voucher.length > 0 ? voucher[0] : undefined,
                      requestSubscription: requestSubscription,
                    });
                  }}
                  price={purchaseAppliedCost.toString()}
                />
              </div>
            )}
          {requestSubscription && (
            <div className="paypal-payment-wrap">
              <PayPalPaymentSubscription
                onPaymentDone={(data: any) => {
                  props.paymentDone({
                    ...data,
                    requestSubscription: requestSubscription,
                  });
                }}
                subscriptionId={
                  payYearly
                    ? props.subscription?.PaypalSubscriptionYearlyId
                    : props.subscription?.PaypalSubscriptionMonthlyId
                }
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const StorePage = (props: { onlyPreview?: boolean }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [pageType, setPageType] = useState<"store" | "purchase" | "void">(
    "store"
  );
  const [activeSubscription, setActiveSubscription] = useState<ISubscription>();
  const subscriptions: ISubscription[] = useSelector(
    (state: GlobalState) => state.generic.subscriptions
  );
  const purchase: IPurchase[] = useSelector(
    (state: GlobalState) => state.generic.purchases
  );

  const GetPurchases = () => {
    ApiService.StoreController.GetPurchases(
      undefined,
      (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(GenericActions.setPurchases(response.parsed));
        }
      }
    );
  };

  const obtainCurrentPurchaseModel = (
    paypalOrderId: string,
    voucher: IVoucher | undefined,
    requestSubscription: boolean,
    isTrial: boolean = false
  ) => {
    return {
      ExternalSystemOrderId: paypalOrderId,
      IsSubscription: requestSubscription,
      TimeSpan: "month",
      SubscriptionId: activeSubscription?.Id ?? 0,
      RequesTrial: isTrial,
      VoucherCode: voucher ? voucher.VoucherCode : undefined,
    };
  };

  const completePurchase = (
    paypalOrderId: string,
    voucher: IVoucher | undefined,
    requestSubscription: boolean
  ) => {
    if (activeSubscription) {
      setLoading(true);
      ApiService.StoreController.InsertPurchases(
        obtainCurrentPurchaseModel(paypalOrderId, voucher, requestSubscription),
        async (response: IAPIResponse) => {
          if (response.error === null) {
            ToastMessage(
              "success",
              Tr.Translate("language", "purchase_completed")
            );
            GetPurchases();
            setTimeout(() => {
              DismissModal("storeModal");
            }, 300);
          } else {
            if (response.raw.status == 422) {
              ToastMessageUnique(
                "error",
                Tr.Translate("language", "already_have_trial_on_this")
              );
            }
          }
          DismissModal("confirmTrialAction");
          setLoading(false);
        }
      );
    }
  };

  const startFreeTrial = () => {
    if (activeSubscription) {
      setLoading(true);
      ApiService.StoreController.InsertPurchases(
        obtainCurrentPurchaseModel(
          "single-payment-requested-trial",
          undefined,
          false,
          true
        ),
        (response: IAPIResponse) => {
          if (response.error === null) {
            GetPurchases();
            ToastMessage("success", Tr.Translate("language", "trial_started"));
            setTimeout(() => {
              DismissModal("storeModal");
            }, 300);
          } else {
            if (response.raw.status == 422) {
              ToastMessageUnique(
                "error",
                Tr.Translate("language", "already_have_trial_on_this")
              );
            }
          }
          DismissModal("confirmTrialAction");
          setLoading(false);
        }
      );
    }
  };

  return (
    <div className="subscription-main-wrap-outer">
      <SmartModal
        title={Tr.Translate("language", "purchase_with_trial")}
        modalUniqueId="confirmTrialAction"
        content={
          <div>
            {!loading && (
              <div style={{ maxWidth: "20em", paddingBottom: "1em" }}>
                <MessageBar messageBarType={MessageBarType.warning}>
                  {Tr.Translate(
                    "language",
                    "purchase_with_trial_about_to_start"
                  )}
                </MessageBar>
              </div>
            )}
            {loading && (
              <div className="subscription-center-spinner">
                <SuperSpinner />
              </div>
            )}
          </div>
        }
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: loading,
            onClick: () => {
              DismissModal("confirmTrialAction");
            },
          },
          {
            text: Tr.Translate("language", "accept"),
            disabled: loading,
            onClick: () => {
              startFreeTrial();
            },
          },
        ]}
      />
      {subscriptions.length === 0 && (
        <div style={{ padding: "1em" }}>
          <SuperSpinner />
        </div>
      )}
      {subscriptions.length > 0 && (
        <div>
          {pageType === "purchase" && (
            <div>
              {!loading && (
                <SubscriptionPurchasePreview
                  paymentDone={(paypalData: any) => {
                    if (paypalData?.order?.id) {
                      completePurchase(
                        paypalData.order.id,
                        paypalData.voucher,
                        paypalData.requestSubscription
                      );
                    }
                  }}
                  onBack={() => {
                    setPageType("store");
                  }}
                  subscription={activeSubscription}
                />
              )}
              {loading && (
                <div className="center-spinner-store">
                  <SuperSpinner />
                </div>
              )}
            </div>
          )}
          {pageType === "store" &&
            [...subscriptions]
              .sort((a: ISubscription, b: ISubscription) => {
                return a.ExposedPrice - b.ExposedPrice;
              })
              .filter(
                (x: ISubscription) =>
                  !x.DevelopItem || window.location.origin.includes("localhost")
              )
              .map((x: ISubscription, i: number) => {
                return (
                  <div
                    key={i}
                    className={
                      "subscription-main-wrap " +
                      (mobileCheck() ? "subscription-main-wrap-mobile" : "")
                    }
                  >
                    <div className="subscription-name-desc-wrap">
                      <div className="subscription-name">
                        {Tr.Translate("subscription", x.SubscriptionName)}
                      </div>
                      <div
                        className={
                          "subscription-description " +
                          (mobileCheck()
                            ? "subscription-description-mobile"
                            : "")
                        }
                      >
                        {Tr.Translate(
                          "subscription",
                          x.SubscriptionDescription
                        )}
                      </div>
                      <div
                        style={props.onlyPreview ? { display: "none" } : {}}
                        className={
                          "subscription-button-section " + mobileCheck()
                            ? "purchase-button-mobile"
                            : ""
                        }
                      >
                        <PrimaryButton
                          disabled={loading}
                          onClick={() => {
                            setActiveSubscription(x);
                            setPageType("void");
                            setTimeout(() => {
                              setPageType("purchase");
                            }, 50);
                          }}
                          iconProps={{ iconName: "Diamond" }}
                          text={Tr.Translate("language", "purchase")}
                        />
                        {x.TrialDays > 0 && (
                          <PrimaryButton
                            onClick={() => {
                              setActiveSubscription(x);
                              SummonModal("confirmTrialAction");
                            }}
                            disabled={loading}
                            iconProps={{ iconName: "DateTime" }}
                            text={Tr.Translate(
                              "language",
                              "purchase_with_trial"
                            )}
                          />
                        )}
                      </div>
                      {!props.onlyPreview && x.TrialDays > 0 && (
                        <div className="subscription-free-trial-hint">
                          {Tr.Translate(
                            "language",
                            "purchase_with_trial_hint"
                          ).replace("$FDAYS", x.TrialDays)}
                        </div>
                      )}
                      {props.onlyPreview && x.TrialDays > 0 && (
                        <div className="subscription-free-trial-large">
                          {Tr.Translate(
                            "language",
                            "purchase_with_trial_hint_large"
                          ).replace("$FDAYS", x.TrialDays)}
                        </div>
                      )}
                    </div>
                    <div className="subscription-image">
                      <ImageAssetLoader src={x.SubscriptionImage} />
                    </div>
                    <div className="subscription-type-price-wrap">
                      <div className="subscription-type">
                        {Tr.Translate("subscription", "month")}
                      </div>
                      <div className="subscription-price">
                        {x.ExposedPrice} €
                      </div>
                      <div className="subscription-price-yearly">
                        <div>
                          {Tr.Translate("subscription", "price_yearly") + " "}
                        </div>
                        <div style={{ fontSize: "2em" }}>
                          {x.ExposedPriceYearly} €
                        </div>
                      </div>
                    </div>
                    <div className="subscription-list-box">
                      <SubscriptionFeatureListComponent subscription={x} />
                    </div>
                  </div>
                );
              })}
        </div>
      )}
    </div>
  );
};

export default StorePage;
