import { useDispatch, useSelector } from "react-redux";
import {
  IEvent,
  IEventKarmaMap,
  IEventKarmaMapItem,
} from "../../Models/IEvent";
import "./ExploreEventsTickGrid.scss";
import { GlobalState } from "../../Redux/RootReducer";
import { useEffect, useState } from "react";
import {
  IconButton,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  SearchBox,
  Toggle,
} from "@fluentui/react";
import Tr from "../../Utils/Translations/Translations";
import {
  dropDownEventOption,
  getEventClassByType,
  getEventTextByPartialKey,
  getEventTypesOptions,
} from "../../Utils/EventTypeMap";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import Loadable from "../Loadable/Loadable";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import { ToastMessage } from "../../Utils/UIMessages";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import Tools from "../../Utils/Tools";
import EventLogicProcessor from "../EventLogicProcessor/EventLogicProcessor";
import {
  eventProcessingListToOptions,
  GenerateAssetEventUrl,
  GetEventTypeVersion,
} from "../../Utils/EventProcessing";

import { IBook } from "../../Models/IBook";
import { IBookTimeline } from "../../Models/IBookTimeline";
import { matchDateOnTarget } from "../TimelineEventMap/TimelineEventMap";
import ExtraComputingAI from "../ExtraComputingAI/ExtraComputingAI";
import BetterCoachMarks from "../BetterCoachMark/BetterCoachMark";
import SettingsUtil from "../../Utils/Settings";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import {
  getKarmaOptions,
  getKarmaShiftOptions,
} from "../KarmaPicker/KarmaPicker";
import ManageEventButtons from "../ManageEventButtons/ManageEventButtons";
import { mobileCheck } from "../../App";
import ImageAssetLoader, {
  GenerateAssetUrl,
} from "../ImageAssetLoader/ImageAssetLoader";
import NewEventButton from "../NewEventButton/NewEventButton";
import FeedbackHandler from "../FeedbackHandler/FeedbackHandler";

export interface IExploreEventsRowProps {
  event: IEvent;
}

export interface IExploreEventsTickGridProps {}

const fixCommaListToken = (token: string, allUpper: boolean) => {
  let splWords: string[] = token.split(" ");
  let inCommas: boolean = false;
  for (let i = 0; i < splWords.length; i++) {
    if (splWords[i].startsWith("(")) {
      inCommas = true;
      splWords[i] = "(" + Tools.UpperCase(splWords[i].substring(1));
    } else {
      if (allUpper && !inCommas) {
        splWords[i] = Tools.UpperCase(splWords[i]);
      }
    }
    if (splWords[i].endsWith(")")) {
      inCommas = false;
    }
  }

  return splWords.join(" ");
};

export const allCapitalWords = (
  commaList: string | undefined,
  allUpper = false
) => {
  let result = "-";
  if (commaList) {
    let spl = commaList
      .split(" ")
      .map((x: string) => x.trim())
      .map((x: string) => Tools.UpperCase(x));
    return spl.join(" ");
  }
  return result;
};

export const betterCommaListDisplay = (
  commaList: string | undefined,
  allUpper = false
) => {
  let result = "";
  if (commaList) {
    let spl = commaList
      .split(",")
      .map((x: string) => x.trim())
      .map((x: string) => Tools.UpperCase(x));
    return fixCommaListToken(spl.join(", "), allUpper);
  }
  return result;
};

export const displayEventCarmaMap = (karmaMap: string | undefined) => {
  if (karmaMap) {
    let karmaMapObk: IEventKarmaMap = JSON.parse(karmaMap);
    let karmaOptions = getKarmaOptions();

    return (
      <div>
        {karmaMapObk.Items.map((x: IEventKarmaMapItem, i: number) => {
          let amount: { icon: string; className: string }[] = [];
          for (let j = 0; j < x.Amount; j++) {
            let targetOption = karmaOptions.find(
              (y: { key: number; text: string }) => y.key === x.Karma
            );

            if (targetOption) {
              amount.push({
                icon: targetOption.icon,
                className: targetOption.karmaIconClass,
              });
            }
          }

          return (
            <div key={i} className="display-karma-row">
              <div className="display-karma-char-name">
                {allCapitalWords(x.Character)}
              </div>
              <div className="display-karma-icon">
                {amount.map(
                  (y: { icon: string; className: string }, j: number) => {
                    return (
                      <ImageAssetLoader
                        key={j}
                        src={y.icon}
                        className={y.className}
                      />
                    );
                  }
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return <div>-</div>;
};

const ExploreEventsRow = (props: IExploreEventsRowProps) => {
  const [event, setEvent] = useState<IEvent>();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  useEffect(() => {
    setEvent(props.event);
  }, [props.event]);

  return (
    <div className="event-exporer-row-wrap">
      <div
        className="event-exporer-row-wrap-image"
        style={{
          backgroundImage: GenerateAssetUrl("/Assets/Public/paper.jpg", true),
        }}
      ></div>
      <div className="event-exporer-row-wrap-inner">
        <div className="event-explorer-header-line">
          <div className="event-explorer-voice-top-title">
            <Toggle
              checked={!collapsed}
              inlineLabel
              offText={event?.Title + " (" + event?.CustomDate + ")"}
              onText={event?.Title + " (" + event?.CustomDate + ")"}
              onChange={(e, c) => {
                if (c !== undefined) {
                  setCollapsed(!c);
                }
              }}
            />
          </div>
          <div className="event-explorer-botton-box">
            {event && <ManageEventButtons targetEvent={event} />}
          </div>
        </div>

        {!collapsed && (
          <div>
            <div className="event-explorer-item">
              <Label>{Tr.Translate("language", "event_title")}</Label>
              <div className="event-explorer-voice">{event?.Title}</div>
            </div>
            <div className="event-explorer-item">
              <Label>{Tr.Translate("language", "custom_date")}</Label>
              <div className="event-explorer-voice">{event?.CustomDate}</div>
            </div>
            <div className="event-explorer-item">
              <Label>{Tr.Translate("language", "event_character_list")}</Label>
              <div className="event-explorer-voice">
                {betterCommaListDisplay(event?.CharacterList, true)}
              </div>
            </div>
            <div className="event-explorer-item">
              <Label>{Tr.Translate("language", "event_keyword")}</Label>
              <div className="event-explorer-voice">
                {betterCommaListDisplay(event?.EventKeyword)}
              </div>
            </div>
            <div className="event-explorer-item">
              <Label>{Tr.Translate("language", "karma_shift")}</Label>
              <div className="event-explorer-voice">
                {displayEventCarmaMap(event?.KarmaMap)}
              </div>
            </div>
            <div></div>
            <div className="event-explorer-item">
              <Label>{Tr.Translate("language", "event_type")}</Label>
              <div className="event-explorer-voice">
                {event?.EventType.split(", ").map((x: string, i: number) => {
                  // class of event
                  let classOfType = x ? getEventClassByType(x) : "";

                  // color for class of event
                  let circleColor = classOfType
                    ? SettingsUtil.GetSettings(classOfType)
                    : "#000000";

                  return (
                    <div key={i} className="event-display-item-type-wrap-outer">
                      <div className="event-display-item-type-wrap">
                        <ImageAssetLoader
                          className="event-display-item-type-scroll"
                          src={GenerateAssetUrl("/Assets/Public/scroll.png")}
                        />
                        <div
                          className="event-display-icon"
                          style={{
                            backgroundImage: GenerateAssetEventUrl(x, true),
                            borderColor: circleColor,
                          }}
                        >
                          <div
                            className="event-display-icon-overlay"
                            style={{ backgroundColor: circleColor }}
                          ></div>
                        </div>

                        <div className="event-explorer-voice-type-label">
                          {getEventTextByPartialKey(x)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="event-explorer-item">
              <Label>{Tr.Translate("language", "event_description")}</Label>
              <div className="event-explorer-voice">{event?.Description}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ExploreEventsTickGrid = (props: IExploreEventsTickGridProps) => {
  const globalEvents: IEvent[] = useSelector(
    (state: GlobalState) => state.generic.globalevents
  );
  const eventsDate: string = useSelector(
    (state: GlobalState) => state.generic.exploreEventsDate
  );
  const navigator: string = useSelector(
    (state: GlobalState) => state.generic.timelineNavigator
  );
  const activeTimeline: IBookTimeline | undefined = useSelector(
    (state: GlobalState) => state.generic.activeTimeline
  );
  const [search, setSearch] = useState<string>("");

  const [dayEvents, setDayEvents] = useState<IEvent[]>([]);

  useEffect(() => {
    const activeEvents = globalEvents.filter(
      (x: IEvent) => x.TimelineId === activeTimeline?.Id
    );

    setDayEvents(
      activeEvents.filter((x: IEvent) =>
        matchDateOnTarget(x.CustomDate, eventsDate, navigator)
      )
    );
  }, [eventsDate, globalEvents]);

  var displayDateSpl: string[] = (eventsDate ?? "").split("/");
  var displayDateJoin: string[] = [displayDateSpl[0]];
  if (navigator === "month" || navigator === "day") {
    displayDateJoin.push(displayDateSpl[1]);
  }
  if (navigator === "day") {
    displayDateJoin.push(displayDateSpl[2]);
  }

  const filteredEvents: IEvent[] = dayEvents.filter(
    (x: IEvent) =>
      search === "" ||
      JSON.stringify(x)
        .toLocaleLowerCase()
        .includes(search ?? "")
  );

  return (
    <div>
      {eventsDate && (
        <div>
          <div
            className="explore-event-toolbar"
            style={mobileCheck() ? { flexDirection: "column" } : {}}
          >
            <Label className="explore-event-main-title">
              {Tr.Translate("language", "exploring_on_date") + ": "}
              {displayDateJoin.join("/")}
            </Label>
            <div style={mobileCheck() ? { width: "100%" } : { width: "50%" }}>
              <SearchBox
                placeholder={Tr.Translate("language", "search")}
                onChange={(e, t) => {
                  if (t !== undefined) {
                    setSearch(t.toLocaleLowerCase());
                  }
                }}
                onClear={() => {
                  setSearch("");
                }}
              />
            </div>
            <div className="explore-event-toolbar-buttons">
              <NewEventButton eventsDate={eventsDate} />

              <PrimaryButton
                onClick={() => {
                  SummonModal("EventQueryModal");
                }}
                iconProps={{ iconName: "Relationship" }}
              />
            </div>
          </div>
          <SmartModal
            isWideModal
            titleBarExtra={[<FeedbackHandler section="extra-computing-ai" />]}
            title={Tr.Translate("language", "query_modal_title")}
            modalUniqueId="EventQueryModal"
            content={<ExtraComputingAI specificToDay />}
            buttons={[
              {
                text: Tr.Translate("language", "cancel"),
                onClick: () => {
                  DismissModal("EventQueryModal");
                },
              },
            ]}
          />

          <div className="explore-event-main-box">
            {filteredEvents.length === 0 && (
              <div>
                <MessageBar messageBarType={MessageBarType.info}>
                  {Tr.Translate("language", "no_events_on_this_day")}
                </MessageBar>
              </div>
            )}
            {filteredEvents.map((x: IEvent, i: number) => {
              return <ExploreEventsRow key={i} event={x} />;
            })}
            <div className="end-explore-padder-ios-fixer"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExploreEventsTickGrid;
