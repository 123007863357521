import { useEffect, useState } from "react";
import "./StatisticPage.scss";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import Loadable from "../../Components/Loadable/Loadable";
import Tools from "../../Utils/Tools";
import { ISubscriptionFeatureUsage } from "../../Models/ISubscription";
import { Line } from "react-chartjs-2";
import Charts, {
  IDataset,
  IDatasetPointSet,
} from "../../Components/Charts/Charts";
import { MessageBar, MessageBarType } from "@fluentui/react";
import Tr from "../../Utils/Translations/Translations";

const dateToPlainString = (dt: Date) => {
  return [
    dt.getFullYear(),
    String(dt.getMonth() + 1).padStart(2, "0"),
    String(dt.getDate()).padStart(2, "0"),
  ].join("-");
};

const getDaysInMonth = (month: number, year: number) => {
  var date = new Date(year, month, 1);
  var days: string[] = [];
  while (date.getMonth() === month) {
    days.push(dateToPlainString(new Date(date)));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

const StatisticPage = (props: { workgroupId?: number }) => {
  const [usagedData, setUsagesData] = useState<ISubscriptionFeatureUsage[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [targetWorkGroup, setTargetWorkGroup] = useState<number>(
    props.workgroupId ?? 0
  );

  const loadUsagesData = () => {
    setLoading(true);
    ApiService.StoreController.GetSubscriptionFeatureUsages(
      targetWorkGroup,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setUsagesData(response.parsed);
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (targetWorkGroup !== 0) {
      loadUsagesData();
    }
  }, [targetWorkGroup]);

  const aggregateUsagesByDays = (data: ISubscriptionFeatureUsage[]) => {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let alldays: string[] = getDaysInMonth(month, year);

    let differentCodes: string[] = [];
    for (let i = 0; i < data.length; i++) {
      if (!differentCodes.includes(data[i].FeatureCode)) {
        differentCodes.push(data[i].FeatureCode);
      }
    }

    let differentUsers: string[] = [];
    for (let i = 0; i < data.length; i++) {
      if (!differentUsers.includes(data[i].Email)) {
        differentUsers.push(data[i].Email);
      }
    }

    let datasets: IDataset[] = [];

    for (let j = 0; j < differentCodes.length; j++) {
      let targetCode: string = differentCodes[j];
      let datasetsPerCode: IDatasetPointSet[] = [];

      for (let k = 0; k < differentUsers.length; k++) {
        let targetUser: string = differentUsers[k];

        let aggregator: any = {};
        for (let i = 0; i < alldays.length; i++) {
          if (!aggregator[alldays[i]]) {
            aggregator[alldays[i]] = 0;
          }
        }

        let usagesOfCode: ISubscriptionFeatureUsage[] = data.filter(
          (x: ISubscriptionFeatureUsage) =>
            x.FeatureCode === targetCode && x.Email === targetUser
        );

        for (let i = 0; i < usagesOfCode.length; i++) {
          let baseDate = dateToPlainString(new Date(usagesOfCode[i].CreateDT));
          aggregator[baseDate] += 1;
        }

        datasetsPerCode.push({
          title: targetUser,
          dataPoints: alldays.map((x: string) => {
            return {
              pointX: x,
              pointY: aggregator[x],
            };
          }),
        });
      }

      datasets.push({
        type: "line",
        title: targetCode,
        dataSets: datasetsPerCode,
      });
    }
    return datasets;
  };

  return (
    <div>
      <Loadable
        isLoading={loading}
        content={
          <div>
            {usagedData.length === 0 && (
              <div style={{ maxWidth: "60vw", margin: "0 auto" }}>
                <MessageBar messageBarType={MessageBarType.info}>
                  {Tr.Translate("language", "no_usages_recorded")}
                </MessageBar>
              </div>
            )}
            {usagedData.length > 0 && (
              <div>
                <div>
                  <MessageBar messageBarType={MessageBarType.info}>
                    {Tr.Translate(
                      "language",
                      "statistic_is_subscription_based"
                    )}
                  </MessageBar>
                </div>
                <Charts datasets={aggregateUsagesByDays(usagedData)} />
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

export default StatisticPage;
