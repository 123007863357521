import { Link } from "react-router-dom";
import Tr from "../../Utils/Translations/Translations";
import "./NotFoundPage.scss";
import { AppRoutes } from "../../AppRoutes";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Redux/RootReducer";
import { IUser } from "../../Models/IUser";
import Tools from "../../Utils/Tools";

const NotFoundPage = () => {
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.generic.user
  );

  return (
    <div>
      <div className="not-found-title">
        {Tr.Translate("language", "not_found_page")}
      </div>
      <div className="not-found-number">404</div>
      {loggedUser === undefined && (
        <div>
          <div className="not-found-sub-title">
            {Tr.Translate("language", "not_found_page_subtitle")}
          </div>
          <div className="not-found-go-to-login">
            <Link to={AppRoutes.LOGIN_PAGE}>
              {Tr.Translate("language", "login")}
            </Link>
          </div>
        </div>
      )}
      {loggedUser !== undefined && (
        <div>
          <div className="not-found-go-to-login">
            <Link to={AppRoutes.HOME_PAGE}>
              {Tr.Translate("language", "goto_home")}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotFoundPage;
