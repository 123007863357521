export const Dictionary = {
  __answer_label_data: "Le jour $DATE",
  __answer_label_age: "$CHARNAME est né le $DATE, son âge actuel est $AGE",
  __answer_label_age_plus_death:
    "$CHARNAME est né le $DATE et est décédé le $DATE, à l'âge de $AGE",
  __event_type_appear: "$CHARNAME apparaît le $DATE",
  __event_type_born: "$CHARNAME est né le $DATE",
  __event_type_revealed: "$CHARNAME est révélé le $DATE",
  __event_type_disappears: "$CHARNAME disparaît le $DATE",
  __event_type_changes_id:
    "$CHARNAME change d'identité connue et devient $OBJECT le $DATE",
  __event_type_abbandons: "$CHARNAME abandonne son rôle actuel le $DATE",
  __event_type_dies: "$CHARNAME décède le $DATE",
  __event_type_teams_up_with: "$CHARNAME s'allie à $OTHERCHARACTERS le $DATE",
  __event_type_challenge:
    "$CHARNAME [avec $OPS_OTHERCHARACTERS] soutient le défi $OBJECT le $DATE",
  __event_type_meets: "$CHARNAME rencontre $OTHERCHARACTERS le $DATE",
  __event_type_friendship:
    "$CHARNAME devient ami avec $OTHERCHARACTERS le $DATE",
  __event_type_friendship_end:
    "$CHARNAME cesse d'être ami avec $OTHERCHARACTERS le $DATE",
  __event_type_engage:
    "$CHARNAME entame une relation amoureuse avec $OTHERCHARACTERS le $DATE",
  __event_type_engage_end:
    "$CHARNAME met fin à sa relation avec $OTHERCHARACTERS le $DATE",
  __event_type_betrays: "$CHARNAME trahit $OTHERCHARACTERS le $DATE",
  __event_type_shares_a_secret:
    "$CHARNAME partage le secret de $OBJECT avec $OTHERCHARACTERS le $DATE",
  __event_type_kills: "$CHARNAME tue $OBJECT le $DATE",
  __event_type_marries: "$CHARNAME se marie avec $OTHERCHARACTERS le $DATE",
  __event_type_allies: "$CHARNAME s'allie à $OTHERCHARACTERS le $DATE",
  __event_type_situation:
    "$CHARNAME fait face à la situation $OBJECT [avec $OPS_OTHERCHARACTERS] le $DATE",
  __event_type_falls_in_love:
    "$CHARNAME tombe amoureux de $OTHERCHARACTERS le $DATE",
  __event_type_starts_mission:
    "$CHARNAME met en vedette le travail/la mission $OBJECT le $DATE",
  __event_type_starts_mission_end:
    "$CHARNAME conclut le travail/mission $OBJECT le $DATE",
  __event_type_assumes_status:
    "$CHARNAME obtient le statut de $OBJECT le $DATE",
  __event_type_assumes_condition:
    "$CHARNAME assume la condition de $OBJECT le $DATE",
  __event_type_reaches_location:
    "$CHARNAME atteint l'emplacement de $OBJECT le $DATE",
  __event_type_leaves_location:
    "$CHARNAME quitte l'emplacement de $OBJECT le $DATE",
  __event_type_learns_skill:
    "$CHARNAME apprend la compétence de $OBJECT le $DATE",
  __event_type_learns_secret: "$CHARNAME découvre $OBJECT le $DATE",
  __event_type_joins_faction: "$CHARNAME rejoint la faction $OBJECT le $DATE",
  __event_type_leaves_faction: "$CHARNAME quitte la faction $OBJECT le $DATE",
  __event_type_obtains_item: "$CHARNAME obtient l'élément $OBJECT le $DATE",
  __event_type_book_closure: "$CHARNAME voit la fin du livre le $DATE",
  __event_type_holiday: "$CHARNAME applaudit pour $OBJECT le $DATE",
  __event_type_book_begins: "$CHARNAME voit le début du livre le $DATE",
  __event_type_geopolitic:
    "$CHARNAME provoque l'effet géopolitique de $OBJECT le $DATE",
  __event_type_natural_disaster:
    "$CHARNAME est impliqué dans le désastre de $OBJECT le $DATE",
  __event_type_war:
    "$CHARNAME a participé à la guerre [de $OPS_OBJECT] le $DATE",
  __event_type_mass_kill:
    "$CHARNAME a provoqué une destruction massive [pendant $OPS_OBJECT] le $DATE",
  __event_type_creates_or_builds:
    "$CHARNAME a créé/construit ce qui suit: $OBJECT le $DATE",
  __event_type_rule_changer:
    "$CHARNAME a modifié une règle cosmique [de $OPS_OBJECT] le $DATE",
  __character_leaving_position_never_reached:
    "<strong>$CARACTÈRE</strong> il a quitté les lieux <strong>$OBJET</strong>, ce qui est une position jamais atteinte auparavant.",
  __character_cannot_marry_since_there_is_no_trust:
    "<strong>$CARACTÈRE</strong> ne devrait pas se marier avec <strong>$OBJET</strong>, car il n’a pas atteint le bon niveau d’affinité et d’intimité.",
  __character_cannot_betray_since_there_is_no_trust:
    "<strong>$CARACTÈRE</strong> il ne peut pas vraiment tricher <strong>$OBJET</strong> car il n’y a aucune relation de confiance entre eux.",
  __active_character_cannot_interact_with_unknown_character:
    "<strong>$CARACTÈRE</strong> ne peut pas interagir avec <strong>$OBJET</strong> car ce personnage n'est pas introduit dans l'intrigue.",
  __character_changing_id_to_unknown_alterego:
    "<strong>$CARACTÈRE</strong> ne peut pas assumer l'identité de <strong>$OBJET</strong> car cette identité n'est pas aussi introduite dans l'intrigue.",

  __character_leaving_position_never_reached_quick_fix:
    "Pour résoudre ce problème, le personnage doit d'abord être présenté à l'emplacement cible lors d'un événement passé en utilisant l'événement : EVMAP#event_type_reaches_location",
  __character_cannot_marry_since_there_is_no_trust_quick_fix:
    "Pour résoudre ce problème, le personnage doit d'abord augmenter le nombre d'interactions positives avec la personne mariée. La confiance est automatiquement ajoutée via les interactions positives entre les personnages. Si ce problème concerne un personnage secondaire avec des informations de base réduites, vous pouvez l'ignorer.",
  __character_cannot_betray_since_there_is_no_trust_quick_fix:
    "Pour résoudre ce problème, le personnage trahi doit d'abord être en mesure de faire confiance au traître. La confiance est automatiquement ajoutée grâce aux interactions positives entre les personnages.",
  __active_character_cannot_interact_with_unknown_character_quick_fix:
    "Pour résoudre ce problème, vous devez d'abord ajouter le personnage inconnu à l'intrigue d'un événement passé. Un personnage est automatiquement ajouté à l'intrigue s'il apparaît dans la liste des personnages impliqués d'un événement.",
  __character_changing_id_to_unknown_alterego_quick_fix:
    "Pour résoudre ce problème, vous devez d'abord ajouter l'alter-ego inconnu à l'intrigue en utilisant cet alter-ego comme personnage indépendant d'un événement passé. Un personnage est automatiquement ajouté à l'intrigue s'il apparaît dans la liste des personnages impliqués dans un événement. ",

  __character_befriending_disliked_person:
    "<strong>$CARACTÈRE</strong> ne peut pas devenir ami avec <strong>$OBJET</strong> car il n'y a pas assez de confiance entre eux.",
  __character_befriending_disliked_person_quick_fix:
    "Pour résoudre ce problème, le personnage qui veut devenir ami doit d'abord surmonter son aversion pour l'autre personne. La confiance se construit automatiquement grâce aux interactions positives entre les personnages.",

  __character_sharing_secret_with_untrusted_person:
    "<strong>$CARACTÈRE</strong> ne devrait pas partager spontanément un secret <strong>$OBJET</strong> car il n'y a pas assez de confiance entre eux.",
  __character_sharing_secret_with_untrusted_person_quick_fix:
    "Pour résoudre ce problème, le personnage qui veut partager le secret doit d'abord pouvoir faire confiance à l'autre personne. La confiance se construit automatiquement grâce aux interactions positives entre les personnages.",
  __no_errors_to_report: "Aucune erreur détectée dans cette catégorie. Bravo!",

  __character_engaging_disliked_person:
    "<strong>$CARACTÈRE</strong> ne devrait pas encore se fiancer avec <strong>$OBJET</strong> car ils se connaissent trop peu.",
  __character_engaging_disliked_person_quick_fix:
    "Pour que le degré de confiance entre les deux augmente, le personnage doit d'abord augmenter le nombre d'interactions positives avec la personne fiancée. La confiance est automatiquement ajoutée grâce aux interactions positives entre les personnages.",
  __active_character_cannot_interact_with_unknown_character_to_him:
    "<strong>$CARACTÈRE</strong> ne peut pas agir sur <strong>$OBJET</strong> car ils ne se sont encore jamais rencontrés.",
  __active_character_cannot_interact_with_unknown_character_to_him_quick_fix:
    "Pour résoudre ce problème, vous devez d'abord ajouter le personnage inconnu à l'intrigue en utilisant un événement passé. Un personnage est automatiquement ajouté à l'intrigue s'il apparaît dans la liste des personnages impliqués dans un événement.",
};
