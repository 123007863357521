import {
  IMonthMap,
  IMonthMapElement,
} from "../Components/MonthMapEditor/MonthMapEditor";

const shadeColor = (color: string, percent: number) => {
  let R: number = parseInt(color.substring(1, 3), 16);
  let G: number = parseInt(color.substring(3, 5), 16);
  let B: number = parseInt(color.substring(5, 7), 16);

  R = Math.floor((R * (100 + percent)) / 100);
  G = Math.floor((G * (100 + percent)) / 100);
  B = Math.floor((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
};

const JSONPrint = (jObject: any) => {
  if (jObject) {
    let asStr = JSON.stringify(jObject, null, 4);
    return (
      <div
        style={{
          whiteSpace: "pre",
          border: "1px solid",
          padding: "0.5em",
          overflow: "auto",
          maxHeight: "15em",
        }}
      >
        {asStr}
      </div>
    );
  }

  return "No data";
};

const UpperCase = (str: string) => {
  if (str) {
    return str[0].toLocaleUpperCase() + str.slice(1);
  }
  return "";
};

const cleanEventTypeValueBeforeSending = (eventypes: string | string[]) => {
  let value = eventypes;
  if (Array.isArray(eventypes)) {
    value = eventypes.join(",");
  }
  return (value as string).replaceAll("event_type_", "");
};

const GenerateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const Tools = {
  GenerateUUID,
  JSONPrint,
  UpperCase,
  shadeColor,
  cleanEventTypeValueBeforeSending,
};

export default Tools;
