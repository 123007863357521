import { Dropdown } from "@fluentui/react";
import "./Gallery.scss";
import Tr from "../../Utils/Translations/Translations";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import { useEffect, useState } from "react";
import Pager from "../Pager/Pager";

export interface IGalleryProps {
  title?: string | JSX.Element;
  list: any[];
  renderItem: (item: any, iterator: number) => JSX.Element;
}

const Gallery = (props: IGalleryProps) => {
  const [page, setPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(10);

  return (
    <div className="gallery-main-wrap">
      <div className="gallery-title-bar">
        <div>{props.title && <div>{props.title}</div>}</div>
        <Pager
          maxItems={props.list.length}
          onPaginate={(page: number, perPage: number) => {
            setPage(page);
            setPerPage(perPage);
          }}
        />
      </div>
      <div>
        {props.list
          .slice(page * perPage, (page + 1) * perPage)
          .map((item: any, index: number) => {
            return (
              <div key={index} className="gallery-item">
                {props.renderItem(item, index)}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Gallery;
