import { useEffect, useState } from "react";
import { IMonthMap, IMonthMapElement } from "../MonthMapEditor/MonthMapEditor";
import "./CustomDatePicker.scss";
import { IBook } from "../../Models/IBook";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Redux/RootReducer";
import BetterDropDown from "../BetterDropDown/BetterDropDown";
import Tr from "../../Utils/Translations/Translations";
import Tools from "../../Utils/Tools";
import BetterTextField from "../BetterTextField/BetterTextField";
import { IBookTimeline } from "../../Models/IBookTimeline";
import { mobileCheck } from "../../App";

export interface ICustomDatePickerProps {
  onRenderLabel?: (el: any) => JSX.Element;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  currentValue?: string;
  onChange: (date: string) => void;
  style?: any;
}

export const MonthIndexJSON = (map: string | undefined, monthName: string) => {
  if (map) {
    return MonthIndex(JSON.parse(map), monthName);
  }
  return -1;
};

export const MonthIndex = (map: IMonthMap, monthName: string) => {
  let index = -1;
  for (let i = 0; i < map.Months.length; i++) {
    if (map.Months[i].MonthName === monthName) {
      return i;
    }
  }
  return index;
};

export const CustomDateDaysToText = (map: IMonthMap, totalDays: number) => {
  let years: number = 1;
  let months: number = 0;
  let days: number = 0;

  let monthCursorIndex: number = 0;

  while (true) {
    months = monthCursorIndex % map.Months.length;
    let monthDays = map.Months[months].MonthMaxDays;
    let monthLeap = map.Months[months].LeapYearEveryTotYears;
    if (years % monthLeap === 0 && monthLeap != 0) {
      monthDays += 1;
    }

    // you can subtract this mont
    if (totalDays >= monthDays) {
      totalDays -= monthDays;
    }
    // there are less days remaining than the current month, so add them to the
    // count and leave
    else {
      days = totalDays;
      break;
    }

    // you reached last month, increase year count
    if (months === map.Months.length - 1) {
      years++;
    }

    // step next month
    monthCursorIndex += 1;
  }

  return (
    years -
    1 +
    " " +
    Tr.Translate("language", "years") +
    ", " +
    months +
    " " +
    Tr.Translate("language", "months") +
    ", " +
    days +
    " " +
    Tr.Translate("language", "days")
  );
};

export const CustomDateDaysToTextJSON = (
  map: string | undefined,
  totalDays: number
) => {
  if (map) {
    return CustomDateDaysToText(JSON.parse(map), totalDays);
  }
  return "";
};

const CustomDatePicker = (props: ICustomDatePickerProps) => {
  const [year, setYear] = useState<number>(1);
  const [month, setMonth] = useState<string>("");
  const [day, setDay] = useState<number>(1);
  const [monthMap, setMonthMap] = useState<IMonthMap>();
  const [maxDaysForMonth, setMaxDaysForMonth] = useState<number>(1);
  const activeBook: IBook | undefined = useSelector(
    (state: GlobalState) => state.generic.activeBook
  );
  const activeTimeline: IBookTimeline | undefined = useSelector(
    (state: GlobalState) => state.generic.activeTimeline
  );

  const setMaxDays = () => {
    if (monthMap) {
      let targetBaseMonth: IMonthMapElement | undefined = monthMap.Months.find(
        (x: IMonthMapElement) => x.MonthName === month
      );
      if (targetBaseMonth) {
        setMaxDaysForMonth(targetBaseMonth.MonthMaxDays);
        if (day > targetBaseMonth.MonthMaxDays) {
          setDay(targetBaseMonth.MonthMaxDays);
        }
      }
    }
  };

  useEffect(() => {
    if (activeBook) {
      let monthMap_: IMonthMap | undefined = undefined;
      monthMap_ = JSON.parse(activeBook?.MonthMap);
      setMonthMap(monthMap_);
      if (props.currentValue) {
        let splDate: string[] = props.currentValue.split("/");
        setYear(+splDate[0]);
        setMonth(splDate[1]);
        setDay(+splDate[2]);
      } else {
        if (monthMap_ && monthMap_.Months.length > 0) {
          let first: IMonthMapElement = monthMap_.Months[0];
          setMonth(first.MonthName);
        }
      }
    }
  }, []);

  useEffect(() => {
    setMaxDays();
  }, [month]);

  useEffect(() => {
    props.onChange(year + "/" + month + "/" + day);
  }, [year, month, day]);

  const inputWidth = mobileCheck() ? "100%" : "33%";

  return (
    <div style={props.style}>
      {monthMap && (
        <div
          className="custom-date-picker-main-wrap"
          style={mobileCheck() ? { flexDirection: "column" } : {}}
        >
          <div style={{ width: inputWidth }}>
            <BetterTextField
              label={Tr.Translate("language", "year")}
              required={props.required}
              disabled={props.disabled}
              min={1}
              max={activeTimeline?.TimelineYearSpan}
              type="number"
              defaultValue={year.toString()}
              onChange={(e, o) => {
                if (o) {
                  setYear(+o);
                } else {
                  setYear(1);
                }
              }}
            />
          </div>
          <div style={{ width: inputWidth }}>
            <BetterDropDown
              label={Tr.Translate("language", "month")}
              required={props.required}
              disabled={props.disabled}
              currentValue={month}
              onChange={(e, o) => {
                if (o) {
                  setMonth(o.key.toString());
                }
              }}
              options={(monthMap?.Months ?? []).map((x: IMonthMapElement) => {
                return {
                  key: x.MonthName,
                  text: x.MonthName,
                };
              })}
            />
          </div>
          <div style={{ width: inputWidth }}>
            <BetterTextField
              label={Tr.Translate("language", "day")}
              style={{ width: inputWidth }}
              required={props.required}
              disabled={props.disabled}
              type="number"
              min={1}
              defaultValue={day.toString()}
              max={maxDaysForMonth}
              onChange={(e, o) => {
                if (o) {
                  setDay(+o);
                } else {
                  setDay(1);
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;
