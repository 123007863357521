import React, { Component, useEffect, useState } from "react";

import { MessageBar, MessageBarType } from "@fluentui/react";
import Tr from "../../Utils/Translations/Translations";
import { IBook } from "../../Models/IBook";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Redux/RootReducer";
import { betterCommaListDisplay } from "../ExploreEventsTickGrid/ExploreEventsTickGrid";
import BetterComboBox from "../BetterComboBox/BetterComboBox";
import BetterPivot from "../BetterPivot/BetterPivot";
import CharacterMetaDataEditorInnerMeta from "./Variations/CharacterMetaDataEditorInnerMeta";
import CharacterMetaDataEditorInnerSliders from "./Variations/CharacterMetaDataEditorInnerSliders";
import "./CharacterMetaDataEditor.scss";
import CharacterMetaDataEditorInnerBaseDesc from "./Variations/CharacterMetaDataEditorInnerBaseDesc";
import { mobileCheck } from "../../App";
import CharacterMetaDataEditorInnerObjectives from "./Variations/CharacterMetaDataEditorInnerObjectives";

interface ICharacterMetaDataEditorInnerProps {
  bookId: number;
  characterName: string;
}

const CharacterMetaDataEditorInner = (
  props: ICharacterMetaDataEditorInnerProps
) => {
  const [page, setPage] = useState<string>("0");

  return (
    <div
      className={
        mobileCheck() ? "meta-selector-wrap-mobile" : "meta-selector-wrap"
      }
    >
      <BetterPivot
        onSelectedKey={setPage}
        voices={[
          {
            key: "0",
            text: Tr.Translate("language", "character_generic_infos"),
            iconName: "FabricUserFolder",
          },
          {
            key: "1",
            text: Tr.Translate("language", "metadata"),
            iconName: "DataManagementSettings",
          },
          {
            key: "2",
            text: Tr.Translate("language", "character_inclinations"),
            iconName: "Slider",
          },
          {
            key: "3",
            text: Tr.Translate("language", "character_goals"),
            iconName: "BullseyeTargetEdit",
          },
        ]}
      />
      {page === "0" && <CharacterMetaDataEditorInnerBaseDesc {...props} />}
      {page === "1" && <CharacterMetaDataEditorInnerMeta {...props} />}
      {page === "2" && <CharacterMetaDataEditorInnerSliders {...props} />}
      {page === "3" && <CharacterMetaDataEditorInnerObjectives {...props} />}
    </div>
  );
};

const CharacterMetaDataEditor = () => {
  const [knownCharacter, setKnownCharacter] = useState<string | undefined>();

  const activeBook: IBook | undefined = useSelector(
    (state: GlobalState) => state.generic.activeBook
  );
  const knownCharacters: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownCharacters
  );

  return (
    <div style={mobileCheck() ? { width: "75vw", overflow: "hidden" } : {}}>
      {!knownCharacter && (
        <MessageBar messageBarType={MessageBarType.info}>
          {Tr.Translate("language", "metadata_heading_hint")}
        </MessageBar>
      )}
      <div style={{ paddingBottom: "1em" }}>
        <BetterComboBox
          label={Tr.Translate("language", "character")}
          onChange={(e, value) => {
            if (value !== undefined) {
              setKnownCharacter(value);
            }
          }}
          options={knownCharacters.map((x: string) => {
            return {
              key: x,
              text: betterCommaListDisplay(x, true),
            };
          })}
        />
      </div>
      {knownCharacter && activeBook && (
        <div>
          <hr />
          <CharacterMetaDataEditorInner
            bookId={activeBook.Id ?? 0}
            characterName={knownCharacter}
          />
        </div>
      )}
    </div>
  );
};

export default CharacterMetaDataEditor;
