import { GetEventTypeVersion } from "../../Utils/EventProcessing";
import SettingsUtil from "../../Utils/Settings";
import ImageAssetLoader from "../ImageAssetLoader/ImageAssetLoader";
import "./AssetThemedLoader.scss";

export interface IAssetThemedLoaderProps {
  assetName: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const AssetThemedLoader = (props: IAssetThemedLoaderProps) => {
  let iconSet = GetEventTypeVersion();

  switch (iconSet) {
    case "1":
      return (
        <ImageAssetLoader
          onClick={props.onClick}
          src={"/Assets/GenericV1/" + props.assetName + ".svg"}
          style={props.style}
        />
      );
    case "2":
      return (
        <ImageAssetLoader
          onClick={props.onClick}
          src={"/Assets/GenericV2/" + props.assetName + ".png"}
          style={props.style}
        />
      );
    default:
      return <div onClick={props.onClick}>{"<?>"}</div>;
  }
};

export default AssetThemedLoader;
