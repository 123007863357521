export const Dictionary = {
  __welcome: "Willkommen",
  __not_found_page: "Nicht gefunden!",
  __not_found_page_subtitle:
    "Die gesuchte Seite existiert nicht oder erfordert eine Anmeldung.",
  __login: "Anmeldung",
  __goto_home: "Zurück zur Startseite",
  __password: "Passwort",
  __username: "Nutzername",
  __image_load: "Bild laden",
  __image_profile_load: "Profilbild laden",
  __confirm_password: "Bestätige das Passwort",
  __email: "Email",
  __email_not_received: "Bestätigungs-E-Mail nicht erhalten?",
  __send_email: "Neue E-Mail senden",
  __no_such_email_registered:
    "Die angegebene E-Mail ist nicht in unserem System registriert.",
  __ask_new_one: "Erneut senden",
  __no_account: "Sie haben kein Konto?",
  __register: "Registrieren!",
  __forgot_password: "Passwort vergessen?",
  __reset_password: "Setzen Sie es zurück!",
  __load_older_chat_messages: "Ältere Nachrichten laden",
  __recaptcha_test_failed: "Recaptcha-Test fehlgeschlagen.",
  __send_reset_email_title:
    "Geben Sie die E-Mail-Adresse ein, mit der Sie sich registriert haben. Wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts.",
  __send_reset_email: "E-Mail senden",
  __login_done: "Anmeldung abgeschlossen!",
  __new_book: "Neues Buch",
  __password_reset_complete: "Passwort-Reset abgeschlossen.",
  __password_reset_email_sent:
    "Bitte schicken Sie mir eine E-Mail mit einem Link, um Ihr Passwort zurückzusetzen.",
  __registration_done_sent_email:
    "Fertig! Wir haben Ihnen eine E-Mail gesendet. Klicken Sie auf den Link in der E-Mail, um Ihr Konto zu bestätigen.",
  __done: "Alles erledigt!",
  __user_already_registered:
    "Diese E-Mail-Adresse ist bereits registriert und kann nicht erneut zur Registrierung eines anderen Kontos verwendet werden.",
  __back: "Zurück",
  __email_registered: "Kontoaktivierung abgeschlossen. ",
  __this_account_needs_verification:
    "Dieses Konto muss überprüft werden, bevor es auf das System zugreifen kann. ",
  __bad_credentials: "E-Mail oder Passwort stimmen nicht überein.",
  __cancel: "Stornieren",
  __accept: "Akzeptieren",
  __password_mismatch:
    "Das Passwort und das Bestätigungspasswort sind nicht gleich.",
  __add_new_book_form: "Fügen Sie zunächst ein neues Buch hinzu.",
  __book_title: "Buchtitel",
  __book_description: "Buchbeschreibung",
  __create_first_timeline: "Erstellen Sie die erste Zeitleiste",
  __book_collection: "Büchersammlung",
  __book_timeline_center_year: "Konzentrieren Sie sich auf das Jahr",
  __book_timeline_delta_year: "Intervallbreite",
  __allow_custom_months: "Benutzerdefinierte Monate",
  __add: "Hinzufügen",
  __month_name: "Monatsname",
  __month_days: "Tage pro Monat",
  __month_leap_every: "Schaltmonat",
  __month_leap_every_hint:
    "Legen Sie fest, wie viele Jahre der Monat beginnend mit Jahr Null einen Tag hinzugewinnt. ",
  __session_expired:
    "Ihre Sitzung ist abgelaufen, Sie müssen sich erneut anmelden.",
  __book_added: "Buch zu Ihrer Bibliothek hinzugefügt.",
  __last_upated: "Aktualisiert am",
  __book_start: "Zentrales Jahr",
  __table_rows_per_page: "Pro Seite",
  __table_rows_page_prev: "Vorherige Seite",
  __table_rows_page_next: "Nächste Seite",
  __table_rows: "Zeilen $LLIM bis $ULIM von $MLIM",
  __password_must_be_8_long:
    "Das Passwort muss mindestens 8 Zeichen lang sein.",
  __add_new_event: "Neues Ereignis am Tag hinzufügen",
  __new_event: "Neues Event",
  __event_title: "Veranstaltungstitel",
  __event_description: "Eventbeschreibung",
  __event_type: "Art der Veranstaltung",
  __event_character_list: "Figuren (optionales)",
  __event_keyword: "Veranstaltungsthema (optionales)",
  __event_added_succesfully: "Ereignis zur Zeitleiste hinzugefügt.",
  __must_add_at_least_one_month:
    "Sie müssen mindestens einen Monat hinzufügen.",
  __exploring_on_date: "Aktuelle Ereignisse erkunden",
  __delete_event: "Veranstaltung löschen?",
  __edit_event: "Veranstaltung bearbeiten",
  __delete_book: "Buch löschen?",
  __edit_book: "Buch bearbeiten",
  __action_cannot_be_undone:
    "Durch diese Aktion wird dieses Element dauerhaft entfernt. Sind Sie sicher, dass Sie es entfernen möchten?",
  __event_deleted_succesfully: "Veranstaltung gelöscht.",
  __event_edited_succesfully: "Veranstaltung bearbeitet.",
  __book_deleted_succesfully: "Buch gelöscht.",
  __book_edited_succesfully: "Buch bearbeitet.",
  __book_total_days_per_year: "Gesamtzahl der Tage pro Jahr",
  __comma_separated_list:
    "Fügen Sie eine durch Komma getrennte Liste von Elementen ein. ",
  __no_events_on_this_day: "An diesem Tag gibt es keine Veranstaltungen.",
  __expand: "Details erweitern",
  __collapse: "Details ausblenden",
  __book_timeline_navigator_mode: "Timeline-Modus",
  __year_view: "Jahresansicht",
  __month_view: "Monatsansicht",
  __day_view: "Tagesansicht",
  __year: "Jahr",
  __month: "Monat",
  __day: "Tag",
  __custom_date: "Veranstaltungsdatum",
  __search: "Nahe",
  __no_workgroup_ready:
    "Sie haben noch keine Arbeitsgruppen. Erstellen Sie eines, um mit der Arbeit an Ihrem Schreiben zu beginnen!",
  __add_workgroup: "Neue Arbeitsgruppe hinzufügen",
  __edit_workgroup: "Arbeitsgruppe bearbeiten",
  __workgroup_name: "Name der Arbeitsgruppe",
  __workgroup_description: "Beschreibung der Arbeitsgruppe",
  __workgroup_logo: "Logo der Arbeitsgruppe",
  __workgroup_license: "Weisen Sie die Lizenz der Arbeitsgruppe zu",
  __license_selection_is_required:
    "Sie müssen eine Lizenz auswählen, um eine neue Arbeitsgruppe zu erstellen.",
  __obtain_a_license: "Plotter Studio-Lizenzspeicher",
  __workgroup_size: "Arbeitsgruppengröße",
  __workgroup_amount: "Anzahl der Arbeitsgruppen",
  __purchase: "Kaufen",
  __purchase_with_trial: "Kostenlos testen",
  __must_accept_terms:
    "Sie müssen die Allgemeinen Geschäftsbedingungen akzeptieren, um fortfahren zu können.",
  __purchase_with_trial_hint_large:
    "Eine kostenlose Testversion von $FDAYS days ist verfügbar (keine Kreditkarte erforderlich). Nur Registrierung erforderlich!",
  __purchase_with_trial_hint:
    "Die kostenlose Testversion dauert $FDAYS Tage. Anschließend müssen Sie eine Lizenz erwerben, um weiterhin arbeiten zu können.",
  __purchase_with_trial_about_to_start:
    "Sie sind dabei, eine kostenlose Testversion für ein Abonnement anzufordern. Die Probezeit beginnt sofort. Sobald der Testzeitraum abgelaufen ist, müssen Sie den Kauf des Abonnements abschließen.",
  __trial_started: "Die Probezeit ist erfolgreich gestartet.",
  __subscription_must_expire_first:
    "Sie haben dieses Abonnement bereits erworben. Sie können es erst dann erneut erwerben, wenn es abgelaufen ist.",
  __workgroup_added: "Arbeitsgruppe erstellt!",
  __workgroup_edited: "Arbeitsgruppe geändert!",
  __workgroup_open: "Wählen",
  __move_all_events_on_a_different_date:
    "Alle Ereignisse auf ein anderes Datum verschieben",
  __workgroup_main_list:
    "Wählen Sie eine Arbeitsgruppe aus, um den Arbeitsbereich zu laden.",
  __change_workgroup: "Arbeitsgruppe wechseln",
  __more_actions_in_date: "Mehr Aktionen für dieses Datum",
  __allow_standard_months: "Standardmonate zulassen",
  __total_days: "Gesamtzahl der Tage",
  __events_in_this_date: "Ereignisse",
  __edit: "Bearbeiten",
  __max_workgroup_limit_for_license:
    "Sie können zu dieser Lizenz keine weiteren Arbeitsgruppen hinzufügen. Sie haben das Limit der unterstützten Arbeitsgruppen für diese Lizenz erreicht.",
  __delete: "Löschen",
  __delete_workgroup: "Arbeitsgruppe löschen?",
  __proceed_removing_user:
    "Mit dem Entfernen des Benutzers fortfahren? Der Benutzer wird aus der Arbeitsgruppe entfernt.",
  __delete_workgroup_accept:
    "Ich stimme der Entfernung aller Bücher, Ereignisse, Zeitleisten und aller anderen Daten im Zusammenhang mit dieser Arbeitsgruppe zu. Mir ist bewusst, dass diese Aktion destruktiv und dauerhaft ist.",
  __delete_workgroup_warning:
    "Bitte seien Sie sicher. Wenn Sie eine Arbeitsgruppe löschen, werden alle Bücher in der Arbeitsgruppe und alle damit verbundenen Ereignisse dauerhaft gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.",
  __edit_workgroup_people: "Verwalten Sie Ihr Team",
  __invite_user_to_group: "Email",
  __invite_user_to_group_info:
    "Geben Sie die E-Mail-Adresse eines anderen Benutzers ein, um ihn zum Beitritt zu Ihrer Arbeitsgruppe einzuladen. Der eingeladene Benutzer muss die Einladung annehmen, um diesen Vorgang abzuschließen.",
  __invite_user_not_found:
    "Der eingeladene Benutzer ist nicht bei Plotter Studio registriert. Bitte stellen Sie sicher, dass alle beteiligten Benutzer vor der Einladung den kostenlosen Registrierungsschritt abgeschlossen haben.",
  __invitation_sent: "Einladung versendet.",
  __trial_ends_on: "Der Prozess endet am",
  __purchase_expire_on: "Läuft aus am",
  __invitation_accepted: "Einladung angenommen!",
  __pick_book_to_start: "Wählen Sie ein Buch aus, um zu beginnen.",
  __query_modal_title: "AI-Engine",
  __query_modal_date_specific:
    "Alle Ergebnisse hängen vom ausgewählten Datum ab",
  __query_target_character: "Stellen Sie eine Frage zu diesem Charakter",
  __query_target_type: "Was willst du wissen",
  __age: "Alter",
  __knows_about_character: "Weiß über den Charakter",
  __has_skill_or_ability: "Hat Geschick oder Fähigkeit",
  __posses_item: "Besitzt den Artikel",
  __faction: "Fraktion",
  __status: "Status",
  __condition: "Zustand",
  __location: "Standort",
  __object_of_the_query: "Gegenstand der Frage",
  __character: "Charakter",
  __item: "Objekt",
  __skill: "Fähigkeit",
  __secret: "Geheimnis",
  __run_question: "Abfrage ausführen",
  __run_question_fields_missing: "Einige Felder fehlen",
  __elapsed: "verstrichen",
  __character_evolution: "Charakterentwicklung",
  __anyone: "Irgendjemand",
  __any: "Beliebig",
  __tell_mee_more: "Erzähl mir mehr",
  __jump_timeline_ticks: "Sprung Ticks",
  __jump_timeline_ticks_hint:
    "Geben Sie die Anzahl der Ticks ein, um vom aktuellen Datum aus voranzuschreiten. Verwenden Sie negative Zahlen, um rückwärts zu gehen. Einfache Formeln sind erlaubt (z.B. in der täglichen Ansicht bewegt sich 5 x 7 um 5 Wochen).",
  __bad_input: "Es werden nur Zahlen oder Grundformeln akzeptiert.",
  __this_is_infinite: "Ihr Eingabewert ist unendlich!",
  __remember_me: "Erinnere dich an mich",
  __years: "Jahre",
  __months: "Monate",
  __days: "Tage",
  __goto_last_event: "Letzte Veranstaltung",
  __expired_purchase:
    "Entweder ist Ihr Abonnement abgelaufen oder die kostenlose Testversion ist abgelaufen. Um die Arbeitsgruppe weiterhin nutzen zu können, müssen Sie den Abonnementstatus korrigieren.",
  __renew_license_purchase: "Lizenz erneuern",
  __buy_license: "Lizenz kaufen",
  __already_have_trial_on_this:
    "Sie haben bereits eine kostenlose Testversion für diesen Abonnementtyp angefordert. Sie können pro Abonnementtyp nur eine kostenlose Testversion anfordern.",
  __payment_type_month: "Dauer: 1 Monat",
  __payment_type_year: "Dauer: 1 Jahr",
  __voucher_code: "Cupon",
  __subscrbe_recurrent_payment:
    "Ich möchte stattdessen eine automatisierte wiederkehrende Zahlung abonnieren.",
  __cupon_not_allowed:
    "Cupon-Codes sind für wiederkehrende Zahlungen nicht verfügbar",
  __purchase_is_now_free:
    "Ihre Gesamtsumme beträgt 0! Herzlichen Glückwunsch, Sie können den Zahlungsschritt überspringen!",
  __purchase_completed: "Kauf abgeschlossen!",
  __requires_payment: "Erfordert eine Zahlung",
  __bad_cupon: "Ihr Gutscheincode ist ungültig oder abgelaufen.",
  __search_events: "Elemente suchen",
  __search_results: "Suchergebnisse",
  __case_sensitive: "Groß- und Kleinschreibung beachten",
  __draft_ideas_title: "Behandeln Sie hier Ihre Ideenentwürfe",
  __draft_idea_title: "Titel der Entwurfsidee",
  __draft_idea_description: "Entwurf einer Ideenbeschreibung",
  __draft_idea_status: "Status des Ideenentwurfs",
  __add_draft_idea: "Entwurf hinzufügen",
  __edit_draft_idea: "Bearbeiten Sie einen Ideenentwurf",
  __draft_ideas: "Ideenentwürfe",
  __delete_draft_idea: "Ideenentwurf löschen",
  __draf_idea_deleted: "Ideenentwurf gelöscht.",
  __draf_idea_edited: "Entwurfsidee korrekt aktualisiert.",
  __draf_idea_added: "Dragt Idee erstellt.",
  __draft_idea_status_active: "Aktiv",
  __draft_idea_status_archived: "Archiviert",
  __draft_idea_status_completed: "Vollendet",
  __draft_idea_status_to_review: "Zur Rezension",
  __no_draft_ideas_yet:
    "Sie haben noch keine Ideenentwürfe. Verwenden Sie dieses Formular, um eine neue Idee hinzuzufügen. Ideen werden verwendet, um den Überblick darüber zu behalten, wie sich Ihre Geschichte entwickeln könnte.",
  __remaining_chars: "Verbleibende Charaktere",
  __manage_timelines: "Verwalten Sie die Zeitleisten des Buches",
  __timeline_name: "Name der Zeitleiste",
  __timeline_duration: "Zeitleistendauer (Jahre)",
  __timeline_order: "Befehl",
  __timeline_center_year: "Zeitleiste Zentraljahr",
  __timeline_add: "Zeitleiste hinzufügen",
  __timeline_edit: "Zeitleiste bearbeiten",
  __timeline_delete: "Zeitleiste löschen",
  __timeline_chose_workwith: "Wählen Sie eine Zeitleiste",
  __timeline_add_done: "Zeitleiste korrekt erstellt.",
  __timeline_edit_done: "Zeitleiste korrekt aktualisiert.",
  __timeline_delete_done: "Zeitleiste gelöscht.",
  __notimeline_yet:
    "Anscheinend haben Sie für dieses Buch keine Zeitleiste eingerichtet. Für alle Bücher ist zunächst mindestens eine Zeitleiste erforderlich. Sie können später so viele Zeitleisten hinzufügen, wie Sie möchten.",
  __acknowledge_editmonth_can_screw_up_things:
    "Ich akzeptiere, dass die in diesem Formular vorgenommene Änderung der Monatsstruktur nicht automatisch die Daten aller bereits auf der Zeitleiste vorhandenen Ereignisse korrigiert. Ich verstehe daher, dass nach Änderungen an der Monatsstruktur einige Ereignisse möglicherweise ihren Platz auf der Zeitleiste verlieren Linie.",
  __you_must_acknowledge_book_kill:
    "Sie müssen der Löschung der folgenden Elemente zustimmen, bevor Sie fortfahren können.",
  __erease_current_book: "Aktuelles Buch löschen",
  __erease_all_events: "$AMOUNT-Ereignisse löschen",
  __erease_all_draft_ideas: "Löschen Sie $AMOUNT Entwurfsideen",
  __erease_all_timelines:
    "Eliminieren Sie Zeitpläne für den Betrag von $AMOUNT",
  __pay_monthly: "Ich möchte jeden Monat bezahlen",
  __pay_yearly: "Ich möchte jedes Jahr zahlen",
  __read_terms_and_condition:
    "Lesen Sie hier die Allgemeinen Geschäftsbedingungen",
  __read_about_privacy_statement:
    "Lesen Sie hier unsere Datenschutzbestimmungen",
  __terms_and_cond:
    "Ich akzeptiere die Allgemeinen Geschäftsbedingungen für die Dienste und die Datenschutzerklärung.",
  __event_timeline: "Zeitleiste der Veranstaltung",
  __character_will_be_born_in: "Der Charakter wird geboren ",
  __character_life_at_least_this_time:
    "Es gibt keine eindeutigen Daten zur Geburt dieses Charakters. Es ist bekannt, dass der Charakter mindestens dieses Mal in der Nähe war:",
  __character_life_inferred:
    "Wenn der Charakter noch lebt, hätte er bis zu diesem Zeitpunkt dieses Alter:",
  __logout: "Ausloggen",
  __settings: "Einstellungen",
  __user_settings: "Benutzereinstellungen",
  __events_colors: "Ereignisfarbe (für Ereignisgruppe)",
  __acknowledge_edittimeline_can_screw_up_things:
    "Ich akzeptiere, dass die in diesem Formular vorgenommene Änderung der Zeitleistenstruktur nicht automatisch die Daten bereits auf der Zeitleiste vorhandener Ereignisse korrigiert. Ich verstehe daher, dass nach Änderungen an der Zeitleistenstruktur einige Ereignisse möglicherweise ihre Platzierung verlieren.",
  __acknowledge_timeline_removal:
    "Ich bin damit einverstanden, die Zeitleiste und alle derzeit darauf platzierten Ereignisse zu entfernen.",
  __timeline_begin_year: "Beginnt im Jahr",
  __timeline_end_year: "Ende im Jahr",
  __required_fields_missing: "Einige Pflichtfelder fehlen.",
  __timeline_view: "Zeitleistenansicht",
  __table_view: "Tabellenansicht",
  __navigate_mobile_menu: "Mobilmenü umschalten",
  __all: "Alle",
  __all_hint: "Fügen Sie '$ALL' ein, um ohne Einschränkungen zu suchen.",
  __save: "Speichern",
  __color_for_event_type: "Aufzählungsfarbe für den Ereignistyp",
  __generic_ui_settings: "Allgemeine UI-Einstellungen",
  __please_pick_a_timeline: "Wählen Sie eine Zeitleiste aus.",
  __limit_dots_on_timeline:
    "Maximale Anzahl an Aufzählungszeichen für Timeline-Tick",
  __copy: "Artikel kopieren",

  __settings_updated: "Einstellungen korrekt aktualisiert.",
  __your_subscription_doesnt_support_this_feature:
    "Das derzeit verwendete Abonnement unterstützt diese Funktion nicht.",
  __query_bot: "Virtuelle Sekretärin",
  __error_detection: "Fehlererkennung",
  __workgroup_book_limit_title: "Zu Mani-Bücher!",
  __expired: "Abgelaufen",
  __workgroup_book_limit_description:
    "Es scheint, dass Sie zu viele Bücher in dieser Arbeitsgruppe haben. Überzählige Bücher werden weiterhin im Bücherregal angezeigt, aber Sie können sie in keiner Weise bearbeiten. Wenn Sie sie entsperren möchten, können Sie das daran gebundene Abonnement aktualisieren Arbeitsgruppe.",
  __book_limit_reached:
    "Sie können der aktuellen Arbeitsgruppe keine weiteren Bücher hinzufügen. Sie müssen Ihre Lizenz aktualisieren.",
  __feature_usage_limit_reached:
    "Sie können diese Funktion erst verwenden, wenn Sie mehr Nutzungstokens erhalten. Sie können zum Abonnement-Dashboard navigieren, um alle Nutzungen im Auge zu behalten.",
  __getting_updates:
    "Es werden Updates heruntergeladen. Dies kann einige Sekunden dauern. Bitte warten...",
  __sync: "synchronisieren",
  __got_it: "Ich verstehe",
  __show_again_tutorial_coachmarkers:
    "Stellen Sie die Sichtbarkeit aller Coachmarker des Tutorials wieder her",
  __statistics: "Daten zur Arbeitsgruppennutzung",
  __no_usages_recorded:
    "In dieser Arbeitsgruppe wurden keine Nutzungen von Diensten auf Abruf erfasst. Beachten Sie, dass die Daten basierend auf dem an die Arbeitsgruppe gebundenen Abonnement angezeigt werden.",
  __error_detection_intro:
    "In diesem Bereich können Sie die leistungsstarke Event-Compiler-Engine nutzen, um unerwünschte Fehler zu erkennen und zu beheben, die in eine der folgenden Kategorien fallen. Wir arbeiten ständig daran, diese Funktion zu verbessern, da der Engine immer mehr Fehlertypen hinzugefügt werden.",
  __error_detection_type_consistency: "Konsistenz der Charaktere",
  __error_detection_type_events_out: "Ereignisse außerhalb der Zeitleiste",
  __error_detection_type_continuity: "Kontinuitätsfehler",
  __error_detection_type: "Fehlererkennungstyp",
  __event_generic_info: "Allgemeine Information",
  __karma_shift: "Karma-Verschiebung (optionales)",
  __karma_shift_positive: "Positives Karma",
  __karma_shift_neutral: "Neutrales Karma",
  __karma_shift_negative: "Negatives Karma",
  __karma_shift_weight: "Anteil verschieben",
  __karma_shift_weight_small: "Kleine Karmaverschiebung",
  __karma_shift_weight_normal: "Mittlere Karmaverschiebung",
  __karma_shift_weight_big: "Riesige Karma-Verschiebung",
  __how_to_fix: "Wie repariert man",
  __severity_error: "Fehler",
  __severity_warning: "Warnung",
  __severity_info: "die Info",
  __character_inclinations: "Neigungen",
  __metadata: "Metadaten",
  __character_goals: "Ziele",
  __metadata_type: "Metadatentyp",
  __metadata_value: "Wert",
  __character_generic_infos: "Generische Informationen",
  __character_generic_meta_description: "Generische Beschreibung",
  __character_potrait: "Charakter-Porträt",
  __metadata_heading_hint:
    "Vor dem Hinzufügen von Metadaten muss ein Charakter aus einem Ereignis auf der Zeitleiste hinzugefügt werden.",
  __metadata_attitude_negative: "Verabscheut",
  __metadata_attitude_neutral: "Neutral",
  __metadata_attitude_positive: "Liebt",
  __draft_ideas_short: "Entwürfe",
  __action_completed_successfully: "Aktion erfolgreich abgeschlossen.",
  __too_many_failed_attempts:
    "Sie haben zu viele fehlgeschlagene Anmeldeversuche unternommen. Bitte warten Sie einige Minuten, bevor Sie es erneut versuchen.",
  __simple_icon_set: "Standardsymbolsatz",
  __anime_icon_set: "Reihe von Anime-Symbolen",
  __used_icon_set: "Symbolstil für Ereignisse",
  __other_options: "Andere Optionen",
  __your_recent_books: "Aktuelle Bücher",
  __work_on_book: "An Buch arbeiten",
  __amount_paid: "Bezahlter Betrag",
  __full_price: "Voller Preis",

  __paid_on: "Bezahlt am",
  __no_results: "Keine Ergebnisse gefunden.",
  __book_logo: "Buchlogo",
  __created_on: "Erstellt am",
  __payment_is_valid: "Zahlung gültig",
  __yes: "Ja",
  __no: "Nein",
  __payment_type: "Zahlungsart",
  __trial_in_progress: "Probe in Bearbeitung",
  __book_has_been_disabled: "Das Buch wurde deaktiviert.",
  __all_my_purchases: "Alle meine Einkäufe",
  __license_type: "Lizenztyp",
  __statistic_is_subscription_based:
    "Diese Informationen basieren auf dem Abonnement, das derzeit mit der Arbeitsgruppe verknüpft ist.",
  __workgroup_page: "Arbeitsgruppenseite",
  __trial_days: "Tage der Probe",
  __requested_trial: "Probe angefordert",
  __timeline_duration_hint:
    "Länge der Zeitleiste in Jahren. (z. B. bedeutet 2000 für die erste Zeitleiste von Jahr 1 bis 2000)",
  __timeline_central_year_hint:
    "Wenn Sie den Timeline-Editor öffnen, wird standardmäßig das mittlere Datum angezeigt.",
  __timeline_order_hint:
    "Gibt die Position dieser Zeitleiste im Verhältnis zu allen anderen an. (z. B. 1 für die erste Zeitleiste, 2 für die zweite usw.)",
  __book_timeline_delta_year_hint:
    "Laden Sie nur ein Intervall von Jahren um das zentrale Jahr.",
  __workgroup_session_expired:
    "Die Arbeitsgruppensitzung ist abgelaufen. Bitte erneuern Sie die Sitzung, indem Sie die Arbeitsgruppe auswählen.",
  __invite_user_membership_type: "Rolle",
  __reader: "Leser",
  __contributor: "Mitwirkender",
  __invite_user_book_targets: "Buchberechtigungen",
  __your_role_in_this_workgroup: "Sie wurden als $ROLE.",
  __notimeline_yet_reader:
    "Anscheinend gibt es noch keine Zeitpläne für dieses Buch. Da Sie ein Leser sind, können Sie keine neuen Zeitpläne hinzufügen.",
  __jan: "Januar",
  __feb: "Februar",
  __mar: "März",
  __apr: "April",
  __may: "Mai",
  __jun: "Juni",
  __jul: "Juli",
  __aug: "August",
  __sep: "September",
  __oct: "Oktober",
  __nov: "November",
  __dec: "Dezember",

  __small: "Klein",
  __medium: "Mittel",
  __large: "Groß",

  __model_size: "Modellgröße",
  __model_size_hint: "Größere Modelle können länger dauern, sind aber genauer.",
  __language: "Sprache",
  __text_to_process: "Zu verarbeitender Text",
  __generated_events: "Generierte Ereignisse",
  __no_events_generated: "Keine Ereignisse generiert.",

  __text_to_events: "Text zu Ereignissen",
  __generate_events: "Ereignisse generieren",
  __apply_generated_events: "Generierte Ereignisse anwenden",

  __leave_a_feedback: "Hinterlassen Sie ein Feedback",
  __feedback_title: "Titel",
  __feedback_description: "Beschreibung",
  __feedback_vote: "Bewertung",
  __feedback_hint:
    "Hinterlassen Sie ein Feedback, um uns zu helfen, Plotter Studio zu verbessern. Danke!",
  __go_to_payment: "Zur Zahlung gehen",
  __message: "Nachricht",
  __chat: "Chat",
  __workgroup_chat_hint:
    "Verwenden Sie diesen Chat, um mit Mitgliedern der Arbeitsgruppe zu kommunizieren.",
  __workgroup_chat: "Arbeitsgruppen-Chat",

  __send: "Senden",
  __attach_file: "Datei anhängen",
  __pick_a_chat_room_to_start_talking:
    "Wählen Sie einen Chatraum aus, um zu beginnen.",

  __the_total_file_size_you_may_send_per_message_canno_exceed:
    "Die mit einer Nachricht gesendeten Dateien dürfen die Gesamtgröße von $SIZE KB nicht überschreiten.",
  __with: "mit",
};
