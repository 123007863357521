export const Dictionary = {
  // presentation
  __presentation_1:
    "Il sito per gli scrittori di libri fantasy, romanzi e racconti con trame più articolate.",
  __presentation_2:
    "Plotter Studio è una piattaforma creata in collaborazione con scrittori per gli scrittori.",
  __presentation_3: "Stesura di trama basata su eventi",
  __presentation_4: "Ordine temporale",
  __presentation_5:
    "Tieni in ordine tutti gli eventi salienti della tua trama posizionandoli su una linea temporale. Gli eventi possono essere classificati per tipologia, personaggi coinvolti, parole chiave e altro!",
  __presentation_6: "Controllo",
  __presentation_7:
    "Verifica la consistenza degli eventi inseriti, così da evitare buchi di trama o incongruenze. Plotter Studio eseguirà per te l'analisi di tutti gli eventi e troverà potenziali errori.",
  __presentation_8: "Lavoro di squadra",
  __presentation_9:
    "Plotter Studio consente agli utenti di invitare altri a collaborare su uno stesso libro, così da permettere efficacia professionale anche in ambiente lavorativo.",
  __presentation_10: "Interfaccia intuitiva",
  __presentation_11:
    "L'uso di Plotter Studio è stato ottimizzato per essere di facile utilizzo, così che tu possa concentrarti sul tuo libro senza dover pensare ad altro.",
  __presentation_12: "Hai delle domande?",
  __presentation_12_2:
    "Il team di Plotter Studio è a tua disposizione per rispondere a tutte le tue domande",
  __presentation_13: "Contattaci",
  __presentation_14: "Oppure, puoi creare un account gratuitamente ",
  __presentation_15: "Qualità professionale",
  __presentation_16:
    "Plotter Studio è sviluppata e mantenuta da un gruppo con esperienza decennale nel mondo informatico, e garantiamo continue evoluzioni nel tempo.",
  __footer_about_us: "Chi siamo",
  __footer_our_mission: "La nostra missione",
  __footer_were_are_we_headed: "Dove siamo diretti?",
  __footer_services: "I nostri servizi",
  __footer_serv_support: "Supporto agli scrittori",
  __footer_serv_teaching: "Insegnamento",
  __footer_serv_private_solutions: "Soluzioni private",
  __footer_contact_us: "Contattaci",
  __footer_social_media: "Social media",
  __footer_social_facebook: "Facebook",
  __footer_social_twitter: "Twitter",
  __app_for_writers_logo: "L'app per gli scrittori",
};
