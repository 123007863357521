import { useEffect, useState } from "react";
import { IBookCharacterMetaData } from "../../../Models/IBookCharacterMetaData";
import SuperSpinner from "../../SuperSpinner/SuperSpinner";
import ApiService from "../../../Services/ApiService";
import { IAPIResponse } from "../../../Services/AjaxService";
import Loadable from "../../Loadable/Loadable";
import {
  getCharacterMetadataObjectives,
  getCharacterMetadataTypeOptionsForSliders,
} from "../../../Utils/CharacterMetaDataMap";
import BetterSlider from "../../BetterSlider/BetterSlider";
import MultiForm, {
  IMultiFormInput,
  triggerFormValidation,
} from "../../MultiForm/MultiForm";
import Tr from "../../../Utils/Translations/Translations";
import ImagePicker from "../../ImagePicker/ImagePicker";
import DEFAULT_NO_PERSON from "../../../Assets/default-no-person.png";
import { IDropdownOption, PrimaryButton } from "@fluentui/react";
import { mobileCheck } from "../../../App";
import { IDataImage } from "../../../Models/IDataImage";
import CustomTooltip from "../../CustomTooltip/CustomTooltip";
import { ToastMessage } from "../../../Utils/UIMessages";
import { GlobalState } from "../../../Redux/RootReducer";
import { useSelector } from "react-redux";
import { betterCommaListDisplay } from "../../ExploreEventsTickGrid/ExploreEventsTickGrid";
import { IWorkgroup } from "../../../Models/IWorkgroup";
import RoleDependantDisplayer, {
  canDisplayElement,
} from "../../RoleDependantDisplayer/RoleDependantDisplayer";
interface ICharacterMetaDataEditorInnerProps {
  bookId: number;
  characterName: string;
}
var loadingRowClone: any = {};

const CharacterMetaDataEditorInnerObjectives = (
  props: ICharacterMetaDataEditorInnerProps
) => {
  const [characterMetaData, setCharacterMetaData] = useState<
    IBookCharacterMetaData[]
  >([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [loadingRow, setLoadingRow_] = useState<any>({});
  const [formHideDeletedRows, setFormHideDeletedRows] = useState<number[]>([]);
  const [inited, setInited] = useState<boolean>(false);

  const knownCharacters: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownCharacters
  );
  const knownItems: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownItems
  );
  const knownSkills: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownSkills
  );
  const knownSecrets: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownSecrets
  );
  const knownFactions: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownFactions
  );
  const knownStatuses: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownStatuses
  );
  const knownConditions: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownConditions
  );
  const knownLocations: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownLocations
  );
  const activeWorkgroup: IWorkgroup | undefined = useSelector(
    (state: GlobalState) => state.generic.activeWorkgroup
  );
  const optionsFromStringVector = (stringVector: string[]) => {
    return stringVector.map((x: string) => {
      return { key: x, text: betterCommaListDisplay(x, false) };
    });
  };

  const setLoadingRow = (loading: boolean, index: number) => {
    loadingRowClone[index] = loading;
    setLoadingRow_({ ...loadingRowClone });
  };

  const addHiddenRow = (hideRow: number) => {
    let rows: number[] = [...formHideDeletedRows];
    rows.push(hideRow);
    setFormHideDeletedRows(rows);
  };

  const loadCharacterMetaData = () => {
    setLoading(true);
    ApiService.MetaDataController.GetCharacterMetaData(
      props.bookId,
      props.characterName,
      undefined,
      undefined,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setCharacterMetaData(
            response.parsed.filter((x: IBookCharacterMetaData) =>
              (x.MetaDataType ?? "").includes("metadata_objective_of_character")
            )
          );

          setTimeout(() => {
            setInited(true);
          }, 300);
        }
        setLoading(false);
      }
    );
  };

  const addCharacterMetaData = (
    metaData: IBookCharacterMetaData,
    index: number
  ) => {
    setLoadingRow(true, index);
    ApiService.MetaDataController.InsertCharacterMetaData(
      metaData,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let meta: IBookCharacterMetaData[] = [...characterMetaData];
          metaData.Id = response.parsed;
          meta[index] = metaData;
          setCharacterMetaData(meta);
          ToastMessage(
            "success",
            Tr.Translate("language", "action_completed_successfully")
          );
        }
        setLoadingRow(false, index);
      }
    );
  };

  const editCharacterMetaData = (
    metaData: IBookCharacterMetaData,
    index: number
  ) => {
    setLoadingRow(true, index);
    ApiService.MetaDataController.UpdateCharacterMetaData(
      metaData,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let meta: IBookCharacterMetaData[] = [...characterMetaData];
          meta[index] = metaData;
          setCharacterMetaData(meta);
          ToastMessage(
            "success",
            Tr.Translate("language", "action_completed_successfully")
          );
        }
        setLoadingRow(false, index);
      }
    );
  };

  const deleteCharacterMetaData = (
    metaData: IBookCharacterMetaData,
    index: number
  ) => {
    setLoadingRow(true, index);
    ApiService.MetaDataController.DeleteCharacterMetaData(
      metaData.Id ?? 0,
      (response: IAPIResponse) => {
        if (response.error === null) {
          addHiddenRow(index);
          ToastMessage(
            "success",
            Tr.Translate("language", "action_completed_successfully")
          );
        }
        setLoadingRow(false, index);
      }
    );
  };

  const getInputsForForm = (metaData: IBookCharacterMetaData) => {
    let optionsForCombo: IDropdownOption[] = [];

    if (
      [
        "metadata_objective_of_character_encounter",
        "metadata_objective_of_character_marry",
        "metadata_objective_of_character_kill",
      ].includes(metaData.MetaDataType)
    ) {
      optionsForCombo = optionsFromStringVector(knownCharacters);
    }
    if (
      ["metadata_objective_of_character_learn"].includes(metaData.MetaDataType)
    ) {
      optionsForCombo = optionsFromStringVector(knownSecrets).concat(
        optionsFromStringVector(knownSkills)
      );
    }
    if (
      ["metadata_objective_of_character_obtain_object"].includes(
        metaData.MetaDataType
      )
    ) {
      optionsForCombo = optionsFromStringVector(knownItems);
    }

    if (
      ["metadata_objective_of_character_reach_position"].includes(
        metaData.MetaDataType
      )
    ) {
      optionsForCombo = optionsFromStringVector(knownLocations);
    }

    let inputs: IMultiFormInput[] = [
      {
        type: "hidden",
        currentValue: metaData.Id,
        name: "Id",
      },
      {
        type: "hidden",
        currentValue: metaData.BookId,
        name: "BookId",
      },
      {
        type: "hidden",
        currentValue: metaData.CharacterName,
        name: "CharacterName",
      },
      {
        type: "select",
        width: mobileCheck() ? 100 : 50,
        currentValue: metaData.MetaDataType,
        label: Tr.Translate("language", "metadata_type"),
        required: true,
        onChangeResetsOtherInputs: ["MetaDataValue"],
        name: "MetaDataType",
        extraParams: {
          options: getCharacterMetadataObjectives(),
        },
      },
      {
        type: "combobox",
        width: mobileCheck() ? 100 : 50,
        currentValue: metaData.MetaDataValue,
        label: Tr.Translate("language", "metadata_value"),
        required: true,
        name: "MetaDataValue",
        extraParams: { options: optionsForCombo },
      },
    ];

    return inputs;
  };

  useEffect(() => {
    loadCharacterMetaData();
  }, [props.characterName]);

  return (
    <div>
      <Loadable
        isLoading={loading}
        content={
          <div style={mobileCheck() ? { width: "75vw" } : {}}>
            {characterMetaData.map(
              (metaData: IBookCharacterMetaData, i: number) => {
                return (
                  <div key={i}>
                    {formHideDeletedRows.indexOf(i) === -1 && (
                      <div
                        key={i}
                        className={
                          mobileCheck()
                            ? "char-meta-form-row-mobile"
                            : "char-meta-form-row"
                        }
                      >
                        <div style={{ width: "100%" }}>
                          <MultiForm
                            readOnlyMode={
                              !canDisplayElement(activeWorkgroup, [
                                "admin",
                                "contributor",
                              ])
                            }
                            strictBindingValue
                            disabled={loadingRow[i]}
                            formUniqueId={"edit_character_meta_" + i}
                            inputs={getInputsForForm(metaData)}
                            onChange={(data: any) => {
                              if (!inited) {
                                return;
                              }
                              let meta: IBookCharacterMetaData[] = [
                                ...characterMetaData,
                              ];
                              meta[i] = data;
                              setCharacterMetaData(meta);
                            }}
                            onSubmit={(data: any) => {
                              if (data.Id) {
                                editCharacterMetaData(data, i);
                              } else {
                                addCharacterMetaData(data, i);
                              }
                            }}
                          />
                        </div>
                        {loadingRow[i] && (
                          <div
                            style={{
                              display: "flex",
                              paddingBottom: "1.3em",
                              width: "6em",
                            }}
                          >
                            <SuperSpinner />
                          </div>
                        )}
                        {!loadingRow[i] && (
                          <RoleDependantDisplayer
                            whiteList={["admin", "contributor"]}
                            element={
                              <div
                                style={{
                                  display: "flex",
                                  paddingBottom: "1em",
                                  width: "6em",
                                }}
                              >
                                <CustomTooltip
                                  isButton
                                  iconName="Save"
                                  disabled={!metaData.MetaDataValue}
                                  onClick={() => {
                                    triggerFormValidation(
                                      "edit_character_meta_" + i
                                    );
                                  }}
                                  content={
                                    <div>
                                      {Tr.Translate("language", "save")}
                                    </div>
                                  }
                                />
                                <CustomTooltip
                                  isButton
                                  iconName="Trash"
                                  onClick={() => {
                                    if (metaData.Id) {
                                      deleteCharacterMetaData(metaData, i);
                                    } else {
                                      addHiddenRow(i);
                                    }
                                  }}
                                  content={
                                    <div>
                                      {Tr.Translate("language", "delete")}
                                    </div>
                                  }
                                />
                              </div>
                            }
                          />
                        )}
                      </div>
                    )}
                  </div>
                );
              }
            )}
            <RoleDependantDisplayer
              whiteList={["admin", "contributor"]}
              element={
                <div className="char-meta-button-on-right">
                  <PrimaryButton
                    iconProps={{ iconName: "Add" }}
                    text={Tr.Translate("language", "add")}
                    onClick={() => {
                      let meta: IBookCharacterMetaData[] = [
                        ...characterMetaData,
                      ];
                      meta.push({
                        BookId: props.bookId,
                        CharacterName: props.characterName,
                        MetaDataType: "",
                        MetaDataValue: "",
                        Deleted: false,
                      });
                      setCharacterMetaData(meta);
                    }}
                  />
                </div>
              }
            />
          </div>
        }
      />
    </div>
  );
};

export default CharacterMetaDataEditorInnerObjectives;
