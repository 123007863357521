import { Link, useNavigate } from "react-router-dom";
import CenterPad from "../../Components/CenterPad/CenterPad";
import MultiForm from "../../Components/MultiForm/MultiForm";
import SettingsUtil from "../../Utils/Settings";
import Tr from "../../Utils/Translations/Translations";
import "./SettingsPage.scss";
import { AppRoutes } from "../../AppRoutes";
import {
  DefaultButton,
  ISelectableOption,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
} from "@fluentui/react";
import { useState } from "react";
import Loadable from "../../Components/Loadable/Loadable";
import { ToastMessage } from "../../Utils/UIMessages";
import { resetAllCoachMarkers } from "../../Components/BetterCoachMark/BetterCoachMark";
import Generic from "../../Utils/Generic";
import ImageAssetLoader from "../../Components/ImageAssetLoader/ImageAssetLoader";

export interface ISettingVoice {
  key: string;
  label: string;
  type: "text" | "custom" | "color" | "number" | "select";
  options?: string[];
  onRenderOption?: (item: ISelectableOption<any> | undefined) => JSX.Element;
  width?: number;
}

const SETTINGS_FORM_1: ISettingVoice[] = [
  {
    key: "",
    label: Tr.Translate("language", "generic_ui_settings"),
    type: "custom",
  },
  {
    key: "limit_dots_on_timeline",
    label: Tr.Translate("language", "limit_dots_on_timeline"),
    type: "number",
    width: 45,
  },
  {
    key: "icon_set",
    label: Tr.Translate("language", "used_icon_set"),
    type: "select",
    options: ["simple_icon_set", "anime_icon_set"],
    onRenderOption: (item: ISelectableOption<any> | undefined): JSX.Element => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
          <ImageAssetLoader
            style={{
              height: "80px",
              width: "80px",
            }}
            src={"/Assets/Public/" + item?.key + ".png"}
          />
          {item?.text}
        </div>
      );
    },
    width: 45,
  },
];

const SETTINGS_FORM_2: ISettingVoice[] = [
  {
    key: "",
    label: Tr.Translate("language", "color_for_event_type"),
    type: "custom",
  },
  {
    key: "event_class_add_type",
    label: Tr.Translate("events", "event_class_add_type"),
    type: "color",
    width: 33,
  },
  {
    key: "event_class_remove_type",
    label: Tr.Translate("events", "event_class_remove_type"),
    type: "color",
    width: 33,
  },
  {
    key: "event_class_affect_others_type",
    label: Tr.Translate("events", "event_class_affect_others_type"),
    type: "color",
    width: 33,
  },
  {
    key: "event_class_change_status_type",
    label: Tr.Translate("events", "event_class_change_status_type"),
    type: "color",
    width: 33,
  },
  {
    key: "event_class_plot_milestone_type",
    label: Tr.Translate("events", "event_class_plot_milestone_type"),
    type: "color",
    width: 33,
  },
  {
    key: "event_class_strong_effects",
    label: Tr.Translate("events", "event_class_strong_effects"),
    type: "color",
    width: 33,
  },
];

const FORM_SECTIONS: ISettingVoice[][] = [SETTINGS_FORM_1, SETTINGS_FORM_2];

const SettingsPage = () => {
  const navigate = useNavigate();
  const [reload, setReload] = useState<boolean>(false);

  const saveSettings = (data: any) => {
    ToastMessage("success", Tr.Translate("language", "settings_updated"));
    let keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      SettingsUtil.SetSettings(keys[i], data[keys[i]]);
    }
  };

  return (
    <div
      className="settings-page-main-wrap"
      style={Generic.GetRandomBackgroundUrlCss()}
    >
      <CenterPad
        isWidePad
        content={
          <div>
            <div className="settings-page-top-buttons">
              <PrimaryButton
                text={Tr.Translate("language", "back")}
                onClick={() => {
                  window.history.back();
                }}
              />
              <DefaultButton
                iconProps={{ iconName: "Refresh" }}
                text="Reset"
                onClick={() => {
                  ToastMessage(
                    "success",
                    Tr.Translate("language", "settings_updated")
                  );
                  setReload(true);
                  SettingsUtil.ClearSettings();
                  setTimeout(() => {
                    setReload(false);
                  }, 1000);
                }}
              />
            </div>
            <Loadable
              isLoading={reload}
              content={
                <div>
                  {FORM_SECTIONS.map((form: ISettingVoice[], index: number) => {
                    return (
                      <div key={index}>
                        <hr style={{ marginTop: "2em" }} />
                        <MultiForm
                          tinySubmitButton
                          autoButton={Tr.Translate("language", "save")}
                          disableOverflowHandler
                          onSubmit={(data: any) => {
                            saveSettings(data);
                          }}
                          formUniqueId="settingsForm"
                          inputs={form.map((x: ISettingVoice) => {
                            return {
                              type: x.type,
                              name: x.key,
                              label: x.label,
                              width: x.width,
                              element: (
                                <div>
                                  <Label style={{ fontSize: "1.2em" }}>
                                    {x.label}
                                  </Label>
                                </div>
                              ),
                              currentValue: SettingsUtil.GetSettings(x.key),
                              extraParams: {
                                onRenderOption: x.onRenderOption,
                                options: (x.options ?? []).map((x: string) => {
                                  return {
                                    key: x,
                                    text: Tr.Translate("language", x),
                                  };
                                }),
                              },
                            };
                          })}
                        />
                      </div>
                    );
                  })}

                  <hr />
                  <div style={{ marginTop: "2em" }}>
                    <Label style={{ fontSize: "1.2em" }}>
                      {Tr.Translate("language", "other_options")}
                    </Label>
                  </div>
                  <DefaultButton
                    onClick={() => {
                      ToastMessage(
                        "success",
                        Tr.Translate("language", "settings_updated")
                      );
                      resetAllCoachMarkers();
                    }}
                    text={Tr.Translate(
                      "language",
                      "show_again_tutorial_coachmarkers"
                    )}
                  />
                </div>
              }
            />
          </div>
        }
      />
    </div>
  );
};

export default SettingsPage;
