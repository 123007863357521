import {
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  SearchBox,
} from "@fluentui/react";
import { IBook } from "../../Models/IBook";
import Tr from "../../Utils/Translations/Translations";
import PrettyTable from "../PrettyTable/PrettyTable";
import "./BookListPicker.scss";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import { mobileCheck } from "../../App";
import { useState } from "react";
import ImageAssetLoader from "../ImageAssetLoader/ImageAssetLoader";

export interface IBookListPickerProps {
  books: IBook[];
  onBookSelected: (book: IBook) => void;
}

const BookListPicker = (props: IBookListPickerProps) => {
  const [search, setSearch] = useState<string>("");

  const targetBooks: IBook[] = props.books.filter((book: IBook) =>
    JSON.stringify(book).includes(search)
  );

  return (
    <div style={{ padding: "0.5em" }}>
      <SearchBox
        placeholder={Tr.Translate("language", "search")}
        onChange={(e, t) => {
          if (t !== undefined) {
            setSearch(t.toLocaleLowerCase());
          }
        }}
        onClear={() => {
          setSearch("");
        }}
      />
      <div
        className="book-list-result-wrap"
        style={{
          fontSize: mobileCheck() ? "0.7em" : "",
        }}
      >
        {targetBooks.length === 0 && (
          <MessageBar messageBarType={MessageBarType.info}>
            {Tr.Translate("language", "no_results")}
          </MessageBar>
        )}
        {targetBooks.map((book: IBook, i: number) => {
          return (
            <div key={i}>
              {book.Locked && (
                <MessageBar messageBarType={MessageBarType.warning}>
                  {Tr.Translate("language", "book_has_been_disabled")}
                </MessageBar>
              )}
              <div className="book-list-picker-row-outer">
                <div className="book-list-picker-row">
                  <div className="book-list-picker-slot">
                    <ImageAssetLoader
                      src={
                        book.BookImage && book.BookImage !== ""
                          ? book.BookImage
                          : "/Assets/Public/generic_book.png"
                      }
                      title={book.Title}
                    />
                  </div>
                  <div className="book-list-picker-slot">
                    <PrimaryButton
                      iconProps={{ iconName: "Edit" }}
                      onClick={() => {
                        props.onBookSelected(book);
                      }}
                      text={Tr.Translate("language", "work_on_book")}
                    />
                  </div>
                </div>
                <div className="book-list-picker-row">
                  <div className="book-list-picker-slot">
                    <Label>{Tr.Translate("language", "book_title")}</Label>
                    <div>{book.Title}</div>
                  </div>
                  <div className="book-list-picker-slot">
                    <Label>{Tr.Translate("language", "book_collection")}</Label>
                    <div>{book.Collection}</div>
                  </div>
                  <div className="book-list-picker-slot">
                    <Label>{Tr.Translate("language", "last_upated")}</Label>
                    <div>
                      {book?.UpdateDT?.replace("T", " ").substring(0, 16)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BookListPicker;
