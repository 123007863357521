import { ISliderProps, Label, Slider } from "@fluentui/react";
import "./BetterSlider.scss";
import Tr from "../../Utils/Translations/Translations";
import { useEffect, useState } from "react";

export interface IBetterSliderProps extends ISliderProps {}

const BetterSlider = (props: IBetterSliderProps) => {
  const [targetWidth, setTargetWidth] = useState<number>(0);

  const handleWidth = () => {
    let divs = document.getElementsByClassName("ms-Slider-line");
    let width = divs.length > 0 ? divs[0].getBoundingClientRect().width : 0;
    setTargetWidth(width);
  };
  useEffect(() => {
    handleWidth();
    window.addEventListener("resize", handleWidth);
    return () => {
      window.removeEventListener("resize", handleWidth);
    };
  }, []);

  return (
    <div className="better-slider-wrap">
      <Label>{props.label}</Label>
      <div
        className="better-slider-label-wrap"
        style={
          props.disabled
            ? { opacity: "0.5", width: targetWidth + "px" }
            : { width: targetWidth + "px" }
        }
      >
        <div
          className="better-slider-label better-slider-label-negative"
          style={{ width: targetWidth / 3 }}
        >
          {Tr.Translate("language", "metadata_attitude_negative")}
        </div>
        <div
          className="better-slider-label better-slider-label-neutral"
          style={{ width: targetWidth / 3 }}
        >
          {Tr.Translate("language", "metadata_attitude_neutral")}
        </div>
        <div
          className="better-slider-label better-slider-label-positive"
          style={{ width: targetWidth / 3 }}
        >
          {Tr.Translate("language", "metadata_attitude_positive")}
        </div>
      </div>
      <div className="better-slider-input-wrap">
        <Slider {...props} label="" />
      </div>
    </div>
  );
};

export default BetterSlider;
