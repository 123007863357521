import { GetEventTypeVersion } from "../../Utils/EventProcessing";
import { getEventClassByType } from "../../Utils/EventTypeMap";
import SettingsUtil from "../../Utils/Settings";
import Tools from "../../Utils/Tools";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import ImageAssetLoader from "../ImageAssetLoader/ImageAssetLoader";
import "./EventTypeIcon.scss";

export interface IEventTypeIconProps {
  iconText: string;
  iconKey: string;
  requestSizeEm?: string;
  hideLabel?: boolean;
}

const EventTypeIconV1 = (props: IEventTypeIconProps) => {
  let eventType: string = props.iconKey.replace("event_type_", "") ?? "";

  // class of event
  let classOfType = eventType ? getEventClassByType(eventType) : "";

  // color for class of event
  let circleColor = classOfType
    ? SettingsUtil.GetSettings(classOfType)
    : "#000000";

  let size: string = props.requestSizeEm ? props.requestSizeEm : "12em";

  return (
    <div>
      <CustomTooltip
        content={<div>{props.iconText}</div>}
        innerComponent={
          <div
            style={{
              marginLeft: "1em",
              display: "flex",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <div style={{ position: "relative" }}>
              <div
                style={{
                  background: circleColor + "66",
                  width: size,
                  height: size,
                  position: "absolute",
                  opacity: "0.2",
                }}
              ></div>
              <div className="image-wrapper-v1">
                <ImageAssetLoader
                  style={{
                    height: size,
                    width: size,
                  }}
                  src={"/Assets/EventsV1/" + eventType + ".svg"}
                />
                {!props.hideLabel && (
                  <span
                    className="image-event-label"
                    style={{ background: circleColor + "AA" }}
                  >
                    {props.iconText}
                  </span>
                )}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

const EventTypeIconV2 = (props: IEventTypeIconProps) => {
  let eventType: string = props.iconKey.replace("event_type_", "") ?? "";

  // class of event
  let classOfType = eventType ? getEventClassByType(eventType) : "";

  // color for class of event
  let circleColor = classOfType
    ? SettingsUtil.GetSettings(classOfType)
    : "#000000";

  let size: string = props.requestSizeEm ? props.requestSizeEm : "12em";

  return (
    <div>
      <CustomTooltip
        content={<div>{props.iconText}</div>}
        innerComponent={
          <div
            style={{
              marginLeft: "1em",
              display: "flex",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <div className="image-wrapper-v2">
              <ImageAssetLoader
                style={{
                  height: size,
                  width: size,
                }}
                src={"/Assets/EventsV2/" + eventType + ".png"}
              />
              {!props.hideLabel && (
                <span
                  className="image-event-label"
                  style={{ background: circleColor + "AA" }}
                >
                  {props.iconText}
                </span>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
};

const EventTypeIcon = (props: IEventTypeIconProps) => {
  let iconSet = GetEventTypeVersion();
  return (
    <>
      {iconSet === "1" && <EventTypeIconV1 {...props} />}
      {iconSet === "2" && <EventTypeIconV2 {...props} />}
    </>
  );
};

export default EventTypeIcon;
