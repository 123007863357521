import { useSelector } from "react-redux";
import "./ErrorDetectionBot.scss";
import { GlobalState } from "../../Redux/RootReducer";
import { IWorkgroup } from "../../Models/IWorkgroup";
import { IPurchase } from "../../Models/IPurchase";
import {
  ISubscription,
  ISubscriptionFeature,
} from "../../Models/ISubscription";
import Tools from "../../Utils/Tools";
import {
  IDropdownOption,
  Label,
  Link,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
} from "@fluentui/react";
import Tr from "../../Utils/Translations/Translations";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import { ToastMessageUnique } from "../../Utils/UIMessages";
import { useEffect, useState } from "react";
import { IBook } from "../../Models/IBook";
import {
  IErrorDetectionBotData,
  IErrorDetectionBotDataItem,
} from "../../Models/IErrorDetectionBotData";
import { betterCommaListDisplay } from "../ExploreEventsTickGrid/ExploreEventsTickGrid";
import { IEvent } from "../../Models/IEvent";
import { IBookTimeline } from "../../Models/IBookTimeline";
import ManageEventButtons from "../ManageEventButtons/ManageEventButtons";
import { mobileCheck } from "../../App";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import { getEventTextByPartialKey } from "../../Utils/EventTypeMap";
import SuperSpinner from "../SuperSpinner/SuperSpinner";
import { IErrorCompilerRequest } from "../../Models/IErrorCompilerRequest";
import { GetCustomDateAbsoluteNumericDate } from "../../Utils/EventProcessing";
import ImageAssetLoader from "../ImageAssetLoader/ImageAssetLoader";
import OperationProgressBar from "../OperationProgressBar/OperationProgressBar";
import Gallery from "../Gallery/Gallery";

export interface IErrorDetectionBotProps {
  specificToDay?: boolean;
}

const ErrorDetectionItemLineDisplay = (props: {
  item: IErrorDetectionBotDataItem;
  timeline: IBookTimeline | undefined;
  showControls: boolean;
  onRequestFixHint?: () => void;
}) => {
  const [metadata, setMetadata] = useState<{
    character: string;
    object: string;
    eventType: string;
  }>({ character: "", object: "", eventType: "" });

  useEffect(() => {
    setMetadata(JSON.parse(props.item.ErrorMetadata ?? "{}"));
  }, [props.item]);

  const getMessageBarType = (errorLevel: string) => {
    if (errorLevel === "error") {
      return MessageBarType.error;
    }
    if (errorLevel === "warning") {
      return MessageBarType.warning;
    }
    return MessageBarType.info;
  };

  const getEventDate = () => {
    let event: IEvent = props.item.InvolvedEvents[0];

    return (
      "<div class='error-row-date'>[" +
      props.timeline?.Title +
      "] " +
      event.CustomDate +
      "</div>"
    );
  };

  return (
    <div className="inline-error-line-message">
      <div className="inline-error-line-message-buttons-wrap">
        <div className="inline-error-line-message-buttons">
          {props.showControls && (
            <ManageEventButtons targetEvent={props.item.InvolvedEvents[0]} />
          )}
        </div>
      </div>
      <MessageBar
        className="inline-error-line-message-bar"
        isMultiline
        messageBarType={getMessageBarType(props.item.ErrorLevel)}
      >
        <div
          style={{ paddingRight: "10em" }}
          dangerouslySetInnerHTML={{
            __html:
              "<div class='error-label-" +
              props.item.ErrorLevel +
              "'>" +
              Tr.Translate("language", "severity_" + props.item.ErrorLevel) +
              "</div>" +
              " " +
              getEventDate() +
              Tr.Translate("bot", props.item.ErrorDescriptor)
                .replaceAll(
                  "$CHARACTER",
                  betterCommaListDisplay(metadata?.character, true)
                )
                .replaceAll(
                  "$OBJECT",
                  betterCommaListDisplay(metadata?.object, false)
                )
                .replaceAll(
                  "::to::",
                  " " +
                    Tr.Translate("language", "with").toLocaleLowerCase() +
                    " "
                ),
          }}
        ></div>
        <div>
          {props.onRequestFixHint && (
            <Link onClick={props.onRequestFixHint}>
              {Tr.Translate("language", "how_to_fix")}
            </Link>
          )}
        </div>
      </MessageBar>
    </div>
  );
};

const ErrorDetectionBot = (props: IErrorDetectionBotProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<any>({});
  const [operationId, setOperationId] = useState<string>("");
  const [requestedHelp, setRequestedHelp] = useState<
    IErrorDetectionBotDataItem | undefined
  >();
  const [answers, setAnswers] = useState<IErrorDetectionBotData[]>([]);
  const timelines: IBookTimeline[] = useSelector(
    (state: GlobalState) => state.generic.bookTimelines
  );
  const eventsDate: string = useSelector(
    (state: GlobalState) => state.generic.exploreEventsDate
  );
  const activeWorkgroup: IWorkgroup | undefined = useSelector(
    (state: GlobalState) => state.generic.activeWorkgroup
  );
  const activeBook: IBook | undefined = useSelector(
    (state: GlobalState) => state.generic.activeBook
  );
  const activeTimeline: IBookTimeline | undefined = useSelector(
    (state: GlobalState) => state.generic.activeTimeline
  );
  const purchases: IPurchase[] = useSelector(
    (state: GlobalState) => state.generic.purchases
  );
  const subsriptions: ISubscription[] = useSelector(
    (state: GlobalState) => state.generic.subscriptions
  );
  const subsriptionFeatures: ISubscriptionFeature[] = useSelector(
    (state: GlobalState) => state.generic.subscriptionFeatures
  );
  const activePurchase: IPurchase | undefined = purchases.find(
    (x: IPurchase) => x.Id === activeWorkgroup?.PurchaseID
  );
  const activeSubscription: ISubscription | undefined = subsriptions.find(
    (x: ISubscription) => x.Id === activePurchase?.SubscriptionID
  );
  const activeFeatures: ISubscriptionFeature[] = subsriptionFeatures.filter(
    (x: ISubscriptionFeature) => x.SubscriptionId === activeSubscription?.Id
  );

  const dropDownOptions: IDropdownOption[] = [
    {
      key: 1,
      text: Tr.Translate("language", "error_detection_type_events_out"),
    },

    {
      key: 2,
      text: Tr.Translate("language", "error_detection_type_continuity"),
    },
  ];

  const activeSusbcriptionAllowsErrorDetection =
    activeFeatures.find(
      (x: ISubscriptionFeature) =>
        x.FeatureCode === "EVENT_ERROR_DETECTION" && x.FeatureAmount > 0
    ) !== undefined;

  const sendErrorCompilerRequest = (data: any) => {
    let startProcessingDate = Date.now();
    setLoading(true);

    let randomId = Tools.GenerateUUID();
    setOperationId(randomId);

    let errorRequest: IErrorCompilerRequest = {
      BookId: activeBook?.Id ?? 0,
      Type: data.type,
      OperationId: randomId,
      CustomCapDate:
        eventsDate && props.specificToDay
          ? GetCustomDateAbsoluteNumericDate(
              eventsDate,
              JSON.parse(activeBook?.MonthMap ?? "{}"),
              activeTimeline?.Id ?? 0
            )
          : -1,
    };

    ApiService.EventController.SendErrorCompilerRequest(
      errorRequest,
      (response: IAPIResponse) => {
        if (response.error === null) {
          let newdata: IErrorDetectionBotData = response.parsed;
          newdata.Elapsed = Date.now() - startProcessingDate;
          newdata.SourceQuestion = +data.type;
          let ans = [...answers];
          ans.unshift(newdata);
          setAnswers(ans);
          console.log((window as any).reducerGenericState.bookTimelines[0]);
        } else {
          if (response.raw.status === 422) {
            ToastMessageUnique(
              "error",
              Tr.Translate("language", "feature_usage_limit_reached")
            );
          }
        }
        setLoading(false);
      }
    );
  };

  const joinDataOnSameEvent = (rawDataList: IErrorDetectionBotDataItem[]) => {
    let totalEventIdList: number[] = rawDataList.map(
      (x: IErrorDetectionBotDataItem) => x.InvolvedEvents[0].Id ?? 0
    );
    let uniques: number[] = Array.from(new Set(totalEventIdList));
    let joinedDataList: any = {};

    for (let i = 0; i < uniques.length; i++) {
      joinedDataList["ev_id_" + i] = rawDataList.filter(
        (x: IErrorDetectionBotDataItem) => x.InvolvedEvents[0].Id === uniques[i]
      );
    }

    return Object.values(joinedDataList) as IErrorDetectionBotDataItem[][];
  };

  const printQuickFix = () => {
    let fixMessage: string = Tr.Translate(
      "bot",
      requestedHelp?.ErrorDescriptor + "_quick_fix"
    );
    let splOnToken: string[] = fixMessage.split("EVMAP#");
    let evMapReplacer: string = "";
    if (splOnToken.length > 1) {
      splOnToken[1] =
        "<strong>" + Tr.Translate("events", splOnToken[1]) + "</strong>";
    }

    return splOnToken.join(" ");
  };

  return (
    <div>
      {!activeSusbcriptionAllowsErrorDetection && (
        <div className="broken-bot-error-dect">
          <MessageBar messageBarType={MessageBarType.warning}>
            {"OPS! " +
              Tr.Translate(
                "language",
                "your_subscription_doesnt_support_this_feature"
              )}
          </MessageBar>
          <ImageAssetLoader src={"/Assets/Public/broken_bot.png"} />
        </div>
      )}
      {activeSusbcriptionAllowsErrorDetection && (
        <div style={{ padding: "1em" }}>
          <div>
            {Tr.Translate("language", "error_detection_intro").split(".")[0] +
              ".*"}
          </div>
          <small>
            *
            {Tr.Translate("language", "error_detection_intro").split(".")[1] +
              "."}
          </small>
          <MultiForm
            formUniqueId="errorFixForm"
            onChange={setState}
            disabled={loading}
            inputs={[
              {
                type: "select",
                name: "type",
                label: Tr.Translate("language", "error_detection_type"),
                required: true,
                extraParams: {
                  options: dropDownOptions,
                },
              },
            ]}
            onSubmit={sendErrorCompilerRequest}
          />
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            {loading && (
              <div style={{ padding: "1em" }}>
                <SuperSpinner mode="ai" />
              </div>
            )}
            {!loading && (
              <PrimaryButton
                disabled={!state.type}
                iconProps={{ iconName: "Send" }}
                onClick={() => {
                  triggerFormValidation("errorFixForm");
                }}
              >
                {Tr.Translate("language", "error_detection")}
              </PrimaryButton>
            )}
          </div>
          <OperationProgressBar enable={loading} operationId={operationId} />

          <div>
            {answers.map((x: IErrorDetectionBotData, i: number) => {
              return (
                <div key={i} className="error-detection-answer-slot">
                  <div
                    className="answer-slot-banner"
                    style={mobileCheck() ? { flexDirection: "column" } : {}}
                  >
                    <div className="table-bot-error-data-title">
                      {
                        dropDownOptions.find(
                          (opt: IDropdownOption) => opt.key === x.SourceQuestion
                        )?.text
                      }
                    </div>
                    <div className="answer-elapsed">
                      {Tr.Translate("language", "elapsed") + " "}
                      {x.Elapsed < 1000 && <span>{x.Elapsed} ms</span>}
                      {x.Elapsed >= 1000 && <span>{x.Elapsed / 1000} s</span>}
                    </div>
                  </div>

                  <div className="table-bot-error-data-wrap">
                    <div
                      className={
                        "table-bot-error-data " +
                        (mobileCheck() ? "table-bot-error-data-mobile" : "")
                      }
                    >
                      {x.Data.length === 0 && (
                        <div>
                          <MessageBar messageBarType={MessageBarType.success}>
                            {Tr.Translate("bot", "no_errors_to_report")}
                          </MessageBar>
                        </div>
                      )}
                      <Gallery
                        list={joinDataOnSameEvent(x.Data)}
                        renderItem={(
                          item: IErrorDetectionBotDataItem[],
                          iterator: number
                        ) => {
                          return (
                            <div key={iterator}>
                              {item.map(
                                (y: IErrorDetectionBotDataItem, j: number) => {
                                  return (
                                    <ErrorDetectionItemLineDisplay
                                      onRequestFixHint={() => {
                                        setRequestedHelp(y);
                                        SummonModal("howToFixHint");
                                      }}
                                      item={y}
                                      key={j}
                                      timeline={timelines.find(
                                        (p: IBookTimeline) =>
                                          p.Id ===
                                          y.InvolvedEvents[0].TimelineId
                                      )}
                                      showControls={j === 0}
                                    />
                                  );
                                }
                              )}
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <SmartModal
            title={
              Tr.Translate("language", "how_to_fix") +
              " " +
              getEventTextByPartialKey(
                requestedHelp?.InvolvedEvents[0].EventType ?? ""
              )
            }
            content={
              <div style={{ maxWidth: "600px" }}>
                {requestedHelp && (
                  <ErrorDetectionItemLineDisplay
                    item={requestedHelp}
                    timeline={timelines.find(
                      (p: IBookTimeline) =>
                        p.Id === requestedHelp.InvolvedEvents[0].TimelineId
                    )}
                    showControls={false}
                  />
                )}
                <hr />
                <div
                  style={{ padding: "1em" }}
                  dangerouslySetInnerHTML={{ __html: printQuickFix() }}
                ></div>
              </div>
            }
            modalUniqueId="howToFixHint"
            buttons={[
              {
                text: Tr.Translate("language", "cancel"),
                disabled: loading,
                onClick: () => {
                  DismissModal("howToFixHint");
                },
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default ErrorDetectionBot;
