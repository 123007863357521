import { useState } from "react";
import CenterPad from "../../Components/CenterPad/CenterPad";
import Loadable from "../../Components/Loadable/Loadable";
import "./ResendEmailPage.scss";
import { Icon, PrimaryButton } from "@fluentui/react";
import Tr from "../../Utils/Translations/Translations";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../AppRoutes";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import { ToastMessage, ToastMessageUnique } from "../../Utils/UIMessages";
import MultiForm, {
  triggerFormValidation,
} from "../../Components/MultiForm/MultiForm";
import Generic from "../../Utils/Generic";

const ResendEmailPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);

  const resendRegistrationEmail = (data: any) => {
    setLoading(true);
    ApiService.SessionController.ResendInviteEmail(
      data.email,
      Tr.Translate("emails", "registration_link_email").replaceAll(
        "$CURRENT_DOMAIN",
        window.location.origin
      ),
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(
            "success",
            Tr.Translate("language", "registration_done_sent_email")
          );
          setDone(true);
        } else {
          if (response.raw.status === 404) {
            ToastMessageUnique(
              "error",
              Tr.Translate("language", "no_such_email_registered")
            );
          }
        }
        setLoading(false);
      }
    );
  };

  return (
    <div
      className="re-confirm-email-page-wrap"
      style={Generic.GetRandomBackgroundUrlCss()}
    >
      <CenterPad
        content={
          <Loadable
            isLoading={loading}
            content={
              <div className="re-confirm-email-inner-frame">
                {!done && (
                  <div>
                    <MultiForm
                      autoButton={Tr.Translate("language", "send_email")}
                      formUniqueId="resendEmailForm"
                      onSubmit={(data: any) => {
                        resendRegistrationEmail(data);
                      }}
                      inputs={[
                        {
                          type: "email",
                          name: "email",
                          label: Tr.Translate("language", "email"),
                          required: true,
                        },
                      ]}
                    />
                  </div>
                )}

                {done && (
                  <div className="registration-done-icon">
                    <div>{Tr.Translate("language", "done")}</div>
                    <Icon iconName="SkypeCircleCheck" />
                  </div>
                )}
                <hr />
                <div className="flex-line">
                  <Link to={AppRoutes.LOGIN_PAGE}>
                    {Tr.Translate("language", "back")}
                  </Link>
                </div>
              </div>
            }
          />
        }
      />
    </div>
  );
};

export default ResendEmailPage;
