import { APIUrls } from "../../ApiUrls";
import { IBook } from "../../Models/IBook";
import { IContactUs } from "../../Models/IContactUs";
import { AjaxService, IAPIRequest, IAPIResponse } from "../AjaxService";

const SysController = {
  AddContactUs: async (
    contactUs: IContactUs,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.SYSTEM.INSERT_CONTACT_US;
    request.method = "POST";
    request.payload = contactUs;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetOperationProgress: async (
    operationId: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url =
      APIUrls.SYSTEM.GET_OPERATION_PROGRESS + "?operationId=" + operationId;
    request.method = "GET";
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
  },
};

export default SysController;
