import { useSelector } from "react-redux";
import { IWorkgroup } from "../../Models/IWorkgroup";
import "./RoleDependantDisplayer.scss";
import { GlobalState } from "../../Redux/RootReducer";

export interface IRoleDependantDisplayerProps {
  element: JSX.Element;
  whiteList: string[];
}

export function canDisplayElement(
  workgroup: IWorkgroup | undefined,
  whiteList: string[]
) {
  if (!workgroup) return false;
  return whiteList.includes(workgroup.Role ?? "");
}

const RoleDependantDisplayer = (props: IRoleDependantDisplayerProps) => {
  const activeWorkgroup: IWorkgroup | undefined = useSelector(
    (state: GlobalState) => state.generic.activeWorkgroup
  );

  const canDisplay: boolean = props.whiteList.includes(
    activeWorkgroup?.Role ?? ""
  );

  return <div>{canDisplay && props.element}</div>;
};

export default RoleDependantDisplayer;
