export const Dictionary = {
  __new_book: "Add a book to your bookshelf",
  __new_book_desc:
    "The first thing you want to do here is create a book. You can add a new book from here.",
  __edit_workgroup: "Add people to your team",
  __edit_workgroup_desc:
    "Here you can invite other users to access this workgroup. Invited users will be able to work on all the assets from the workbook.",
  __ai_box: "Virtual assistant tools",
  __ai_box_desc:
    "When you see this icon on a button, it means you can take advantage of our advanced AI assistant engine to bring the quality of your work to the next level. Some feature will require specific subscriptions to be enabled.",
  __new_event_button: "Add events from here",
  __new_event_button_desc:
    "You can use this button to create a new event. The event date default will be the one you selected on the timeline, but you can always change it.",
  __draft_ideas: "Take memos of your ideas",
  __draft_ideas_desc:
    "Draft ideas do not influence your work on the book. You can consider them papernotes about concepts you would like to elaborate in the future. Draft Ideas can be managed by status, and can be seen by all your Team Members.",
  __characters_meta_data: "Characters",
  __characters_meta_data_desc:
    "Characters are automatically added to your book as soon as you use them inside an event on the Timeline. In this way, you can add as many characters as you want. Once a character is added, you can add as many metadata as you want to each character. You can also add a picture to each character. This informations will help the event compiler detect consistency errors about your characters behavior.",
  __new_timeline_button: "Create a new timeline",
  __new_timeline_button_desc:
    "You can create a new timeline for your book from here. A timeline is a way to organize the events of your book visually. You can add events to a timeline and view them in chronological order. You can also modify the events directly from the timeline.",
  __new_timeline_button_more:
    "Each new timeline you create will be considered following the previous one. All timelines, in order, represent the entire time period of your narrative universe. \n\nLet's say your book is a historical detective novel set in 1951, with a plot ending in 1963. The timeline in this case should start from year 1 and end at least in 1963.\nIt may make sense to leave a margin, in the example given the timeline would be useful from year 1 to year 2000.",
};
