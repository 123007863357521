import { useState } from "react";
import { ISysAdminPublicMessage } from "../../Models/ISysAdminPublicMessage";
import "./SysAdminBanner.scss";
import { IconButton, MessageBar, MessageBarType } from "@fluentui/react";

export interface ISysAdminBannerProps {
  sysAdminPublicMessage: ISysAdminPublicMessage;
}

const SysAdminBannerIsVisible = (
  sysAdminPublicMessage: ISysAdminPublicMessage
) => {
  return localStorage.getItem(
    "sysAdminBannerIsVisible-" + sysAdminPublicMessage.Id
  ) === "false"
    ? false
    : true;
};

const SysAdminBanner = (props: ISysAdminBannerProps) => {
  const [visible, setVisible] = useState<boolean>(
    SysAdminBannerIsVisible(props.sysAdminPublicMessage)
  );

  return (
    <div className="message-admin-wrap">
      {visible && (
        <MessageBar
          messageBarType={MessageBarType.warning}
          onDismiss={() => {
            setVisible(false);
            localStorage.setItem(
              "sysAdminBannerIsVisible-" + props.sysAdminPublicMessage.Id,
              "false"
            );
          }}
        >
          <div className="sysadmin-banner-content">
            <div>{props.sysAdminPublicMessage.Content}</div>
          </div>
        </MessageBar>
      )}
    </div>
  );
};

export default SysAdminBanner;
