import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../Redux/RootReducer";
import "./EventMover.scss";
import { useEffect, useState } from "react";
import { IBookTimeline } from "../../Models/IBookTimeline";
import { IEvent } from "../../Models/IEvent";
import { matchDateOnTarget } from "../TimelineEventMap/TimelineEventMap";
import { IAPIResponse } from "../../Services/AjaxService";
import { ToastMessage } from "../../Utils/UIMessages";
import Tr from "../../Utils/Translations/Translations";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import Loadable from "../Loadable/Loadable";
import MultiForm from "../MultiForm/MultiForm";
import ApiService from "../../Services/ApiService";
import { Label } from "@fluentui/react";

export interface IEventMoverProps {
  onDismiss: () => void;
}
var manipulatedEventsCache: IEvent[] = [];

const EventMover = (props: IEventMoverProps) => {
  const dispatch = useDispatch();
  const [targetDate, setTargetDate] = useState<string>("");
  const [processing, setProcessing] = useState<boolean>(false);
  const eventsDate: string = useSelector(
    (state: GlobalState) => state.generic.exploreEventsDate
  );
  const navigator: string = useSelector(
    (state: GlobalState) => state.generic.timelineNavigator
  );
  const globalEvents: IEvent[] = useSelector(
    (state: GlobalState) => state.generic.globalevents
  );
  const activeTimeline: IBookTimeline | undefined = useSelector(
    (state: GlobalState) => state.generic.activeTimeline
  );

  const globalUpdateEvents = (event: IEvent) => {
    let newList: IEvent[] = [...manipulatedEventsCache];
    for (let i = 0; i < newList.length; i++) {
      if (newList[i].Id == event.Id) {
        newList[i] = { ...event };
      }
    }
    manipulatedEventsCache = [...newList];
  };

  useEffect(() => {
    const activeEvents = globalEvents.filter(
      (x: IEvent) => x.TimelineId === activeTimeline?.Id
    );

    manipulatedEventsCache = [
      ...activeEvents.filter((x: IEvent) =>
        matchDateOnTarget(x.CustomDate, eventsDate, navigator)
      ),
    ];

    setTargetDate(eventsDate);
  }, [eventsDate, globalEvents]);

  const updateAllEventsDate = async (targetDate_: string) => {
    setProcessing(true);
    let error = false;
    for (let i = 0; i < manipulatedEventsCache.length; i++) {
      let newEvent: IEvent = {
        ...manipulatedEventsCache[i],
        CustomDate: targetDate_,
      };
      let response: IAPIResponse = await ApiService.EventController.UpdateEvent(
        newEvent
      );
      if (response.error !== null) {
        error = true;
      } else {
        globalUpdateEvents(newEvent);
      }
    }

    if (error) {
      ToastMessage("error", Tr.Translate("system", "generic_api_failure"));
    } else {
      ToastMessage(
        "success",
        Tr.Translate("language", "action_completed_successfully")
      );
      dispatch(GenericActions.setEvents(manipulatedEventsCache));

      props.onDismiss();
    }

    setProcessing(false);
  };

  return (
    <div>
      <Loadable
        isLoading={processing}
        content={
          <div>
            <Label>
              {Tr.Translate("language", "events_in_this_date") +
                ": " +
                manipulatedEventsCache.length}
            </Label>
            <MultiForm
              autoButton={Tr.Translate("language", "save")}
              autoButtonIcon="Save"
              formUniqueId="moveEventsToOtherDateForm"
              onSubmit={(data: any) => {
                updateAllEventsDate(data.targetDate);
              }}
              inputs={[
                {
                  type: "customDatePicker",
                  name: "targetDate",
                  currentValue: targetDate,
                  required: true,
                  label: Tr.Translate(
                    "language",
                    "move_all_events_on_a_different_date"
                  ),
                },
              ]}
            />
          </div>
        }
      />
    </div>
  );
};

export default EventMover;
