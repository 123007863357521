import { IAPIRequest, IAPIResponse } from "./AjaxService";

const CacheName = "API_CACHE";

const getApiCacheContent = () => {
  let cache: any = localStorage.getItem(CacheName);
  if (cache === null) {
    return {};
  } else {
    return JSON.parse(cache);
  }
};

(window as any)["dropApiCache"] = () => {
  localStorage.removeItem(CacheName);
};

const writeApiCacheContent = (cache: any) => {
  localStorage.setItem(CacheName, JSON.stringify(cache));
};

const wipeControllerCache = (controllerName: string) => {
  let cache = getApiCacheContent();
  let entries: string[] = Object.keys(cache);
  for (let i = 0; i < entries.length; i++) {
    if (entries[i].includes(controllerName)) {
      delete cache[entries[i]];
    }
  }
  writeApiCacheContent(cache);
};

const getUniqueApiSignature = (request: IAPIRequest) => {
  return (request.method + "__" + request.url).toLocaleLowerCase();
};

const getControllerFromApiSignature = (signature: string) => {
  let spl: string[] = signature.split("__");
  let apiurl = spl[1];
  let splurl = apiurl.split("/");
  return splurl[1];
};

const getCachedResponse = (request: IAPIRequest) => {
  let response: IAPIResponse | null = null;

  let cache = getApiCacheContent();
  let apiSignature = getUniqueApiSignature(request);

  if (cache[apiSignature]) {
    response = cache[apiSignature];
  }

  return response;
};

const storeCacheResult = (request: IAPIRequest, response: IAPIResponse) => {
  let cache = getApiCacheContent();
  let apiSignature = getUniqueApiSignature(request);

  // only cache get requests
  if (!response.skipped) {
    if (
      request.method.toLocaleLowerCase() === "get" &&
      response.error === null &&
      request.allowCache
    ) {
      cache[apiSignature] = response;
      writeApiCacheContent(cache);
    } else {
      // you must clear the controller cache when for a given controller some
      // data has been edited on DB.
      // aka, any method other than GET is expected to change the remote status
      // so we cant rely on current cache any more
      let controller = getControllerFromApiSignature(apiSignature);
      console.log("Dropping cache on controller", controller);
      wipeControllerCache(controller);
    }
  }
};

const AjaxCache = {
  getCachedResponse,
  storeCacheResult,
};

export default AjaxCache;
