import {
  EventProcessingActions,
  SET_KNOWN_CHARACTERS,
  SET_KNOWN_CONDITIONS,
  SET_KNOWN_FACTIONS,
  SET_KNOWN_ITEMS,
  SET_KNOWN_LOCATIONS,
  SET_KNOWN_SECRETS,
  SET_KNOWN_SKILLS,
  SET_KNOWN_STATUSES,
} from "./EventProcessingActions";
import { EventProcessingState } from "./EventProcessingState";

const DEFAULT_STATE: EventProcessingState = {
  knownCharacters: [],
  knownItems: [],
  knownSkills: [],
  knownSecrets: [],
  knownFactions: [],
  knownStatuses: [],
  knownConditions: [],
  knownLocations: [],
  everything: [],
};

export const EventProcessingReducer = (
  state: EventProcessingState = DEFAULT_STATE,
  action: EventProcessingActions
): EventProcessingState => {
  let res = EventProcessingReducerInner(state, action);
  (window as any)["reducerEventProcessingState"] = res;
  return res;
};

const concatEveryThing = (state: any) => {
  let maxConcat: string[] = [];
  let keys = Object.keys(state);
  for (let i = 0; i < keys.length; i++) {
    let key: string = keys[i];
    let list = state[key];
    maxConcat = maxConcat.concat(list);
  }
  return Array.from(new Set(maxConcat));
};

var state_: EventProcessingState = {} as EventProcessingState;
const EventProcessingReducerInner = (
  state: EventProcessingState = DEFAULT_STATE,
  action: EventProcessingActions
): EventProcessingState => {
  switch (action.type) {
    case SET_KNOWN_CHARACTERS:
      state_ = {
        ...state,
        knownCharacters: action.payload,
      };
      state_.everything = concatEveryThing(state_);
      return state_;
    case SET_KNOWN_ITEMS:
      state_ = {
        ...state,
        knownItems: action.payload,
      };
      state_.everything = concatEveryThing(state_);
      return state_;
    case SET_KNOWN_SKILLS:
      state_ = {
        ...state,
        knownSkills: action.payload,
      };
      state_.everything = concatEveryThing(state_);
      return state_;
    case SET_KNOWN_SECRETS:
      state_ = {
        ...state,
        knownSecrets: action.payload,
      };
      state_.everything = concatEveryThing(state_);
      return state_;
    case SET_KNOWN_FACTIONS:
      state_ = {
        ...state,
        knownFactions: action.payload,
      };
      state_.everything = concatEveryThing(state_);
      return state_;
    case SET_KNOWN_STATUSES:
      state_ = {
        ...state,
        knownStatuses: action.payload,
      };
      state_.everything = concatEveryThing(state_);
      return state_;
    case SET_KNOWN_CONDITIONS:
      state_ = {
        ...state,
        knownConditions: action.payload,
      };
      state_.everything = concatEveryThing(state_);
      return state_;
    case SET_KNOWN_LOCATIONS:
      state_ = {
        ...state,
        knownLocations: action.payload,
      };
      state_.everything = concatEveryThing(state_);
      return state_;
    default:
      return state;
  }
};
