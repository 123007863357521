export const Dictionary = {
  __registration_link_email: `
    <html>
      <head>
          <title>Confirmez votre compte</title>
      </head>
      <body>
          <h2 style='background-color: #6197d6; padding: 0.5em; color: white; text-align: center;'>Confirmation du compte</h2>
          <div style='text-align: center'>
          <img src='$EMAIL_IMAGE' alt='image' title='image' style="display:block" width="450" height="450"/>
          <div style='padding: 2em;'>
            <div>Bienvenue à <strong>Plotter Studio</strong>!</div>
            <br />
            <div>Vous êtes presque prêt à commencer à travailler sur vos projets fantastiques.</div>
            <br />
            <div>Nous devons simplement confirmer votre inscription pour protéger votre compte.</div>
            <br />
            <div>Veuillez cliquer sur le lien suivant pour activer votre compte :</div>
            <br/>
            <div style='margin: 2em auto; background-color: #6197d6; padding: 0.5em; width: 15em;'>
                <a style='text-decoration: none;' href='$CURRENT_DOMAIN/confirmaccount?token=$TOKEN'>
                  <div style='color: white;'>Confirmer le compte</div>
                </a>
            </div>
            <br />
            <br />
            <div style='font-size:0.8em'>
                Ceci est une demande automatisée, veuillez ne pas répondre directement à cet email. <br /> Pour en savoir plus sur <strong>Plotter Studio</strong>, visitez notre <a href='$CURRENT_DOMAIN/about'>à propos</a>.
            </div>
          </div>
      </body>
    </html>
  `,
  __invite_user_email: `
  <html>
    <head>
      <title>Invitation à travailler en équipe</title>
    </head>
    <body>
      <h2 style='background-color: #6197d6; padding: 0.5em; color: white; text-align: center;'>Demande de travail en équipe</h2>
      <div style='text-align: center'>
        <img src='$EMAIL_IMAGE' alt='image' title='image' style="display:block" width="450" height="450"/>
        <div style='padding: 2em;'>
          <div>Bonjour,<br/><strong>$INVITERUSERNAME</strong> a décidé de travailler avec vous sur un projet d'écriture.</div>
          <br />
          <div>Vous avez été invité à rejoindre un groupe de travail sur Plotter Studio !</div>
          <br />
          <div>Si vous avez besoin d'en savoir plus, vous pouvez contacter l'utilisateur qui vous a invité par email : <strong>$INVITEREMAIL</strong> </div>
          <br/>
          <div style='margin: 2em auto; background-color: #6197d6; padding: 0.5em; width: 15em;'>
            <a style='text-decoration: none;' href='$CURRENT_DOMAIN/acceptinvitation?token=$TOKEN'>
              <div style='color: white;'>Accepter l'invitation</div>
            </a>
          </div>
          <br />
          <br />
          <div style='font-size:0.8em'>
            Ceci est une demande automatisée, veuillez ne pas répondre directement à cet email. <br /> Pour en savoir plus sur <strong>Plotter Studio</strong>, visitez notre <a href='$CURRENT_DOMAIN/about'>à propos</a>.
          </div>
        </div>
      </div>
    </body>
  </html>
  `,
  __reset_password_link_email: `
  <html>
    <head>
      <title>Réinitialisation du mot de passe</title>
    </head>
    <body>
      <h2 style='background-color: #6197d6; padding: 0.5em; color: white; text-align: center;'>Réinitialisation du mot de passe</h2>
      <div style='text-align: center'>
        <div style='padding: 2em;'>
          Salutations de <strong>Plotter Studio</strong>.<br /><br />Vous avez reçu cet email parce que vous avez demandé la réinitialisation de votre mot de passe. <br /><br />Veuillez cliquer sur le lien suivant pour réinitialiser votre mot de passe:<br/><br/>
          <div style='margin: 2em auto; background-color: #6197d6; padding: 0.5em; width: 15em;'>
            <a style='text-decoration: none;' href='$CURRENT_DOMAIN/resetpasswordrequest?token=$TOKEN'>
              <div style='color: white;'>Réinitialiser le mot de passe</div>
            </a>
          </div>
          <br />
          <br />
          <div style='font-size:0.8em'>
            Ceci est une demande automatisée, veuillez ne pas répondre directement à cet email. <br /> Pour en savoir plus sur <strong>Plotter Studio</strong>, visitez notre <a href='$CURRENT_DOMAIN/about'>à propos</a>.
          </div>
        </div>
      </div>
    </body>
  </html>
  `,
};
