import {
  DropdownMenuItemType,
  IDropdownOption,
  ISelectableOption,
} from "@fluentui/react";
import Tr from "./Translations/Translations";
import Tools from "./Tools";
import SettingsUtil from "./Settings";
import { useEffect, useRef } from "react";
import EventTypeIcon from "../Components/EventTypeIcon/EventTypeIcon";

export const getEventTypesOptions = () => {
  return [
    {
      key: "event_class_add_type",
      text: Tr.Translate("events", "event_class_add_type"),
      itemType: DropdownMenuItemType.Header,
    },
    {
      key: "event_type_appear",
      text: Tr.Translate("events", "event_type_appear"),
    },
    {
      key: "event_type_born",
      text: Tr.Translate("events", "event_type_born"),
    },
    {
      key: "event_type_revealed",
      text: Tr.Translate("events", "event_type_revealed"),
    },
    {
      key: "div_1",
      text: "-",
      itemType: DropdownMenuItemType.Divider,
    },
    {
      key: "event_class_remove_type",
      text: Tr.Translate("events", "event_class_remove_type"),
      itemType: DropdownMenuItemType.Header,
    },
    {
      key: "event_type_disappears",
      text: Tr.Translate("events", "event_type_disappears"),
    },
    {
      key: "event_type_changes_id",
      text: Tr.Translate("events", "event_type_changes_id"),
    },
    {
      key: "event_type_abbandons",
      text: Tr.Translate("events", "event_type_abbandons"),
    },
    {
      key: "event_type_dies",
      text: Tr.Translate("events", "event_type_dies"),
    },
    {
      key: "div_2",
      text: "-",
      itemType: DropdownMenuItemType.Divider,
    },
    {
      key: "event_class_affect_others_type",
      text: Tr.Translate("events", "event_class_affect_others_type"),
      itemType: DropdownMenuItemType.Header,
    },
    {
      key: "event_type_teams_up_with",
      text: Tr.Translate("events", "event_type_teams_up_with"),
    },
    {
      key: "event_type_challenge",
      text: Tr.Translate("events", "event_type_challenge"),
    },
    {
      key: "event_type_fight",
      text: Tr.Translate("events", "event_type_fight"),
    },
    {
      key: "event_type_meets",
      text: Tr.Translate("events", "event_type_meets"),
    },
    {
      key: "event_type_friendship",
      text: Tr.Translate("events", "event_type_friendship"),
    },
    {
      key: "event_type_friendship_end",
      text: Tr.Translate("events", "event_type_friendship_end"),
    },
    {
      key: "event_type_geopolitic",
      text: Tr.Translate("events", "event_type_geopolitic"),
    },
    {
      key: "event_type_engage",
      text: Tr.Translate("events", "event_type_engage"),
    },
    {
      key: "event_type_engage_end",
      text: Tr.Translate("events", "event_type_engage_end"),
    },
    {
      key: "event_type_betrays",
      text: Tr.Translate("events", "event_type_betrays"),
    },
    {
      key: "event_type_shares_a_secret",
      text: Tr.Translate("events", "event_type_shares_a_secret"),
    },
    {
      key: "event_type_kills",
      text: Tr.Translate("events", "event_type_kills"),
    },
    {
      key: "event_type_marries",
      text: Tr.Translate("events", "event_type_marries"),
    },

    {
      key: "event_type_allies",
      text: Tr.Translate("events", "event_type_allies"),
    },
    {
      key: "div_3",
      text: "-",
      itemType: DropdownMenuItemType.Divider,
    },
    {
      key: "event_class_change_status_type",
      text: Tr.Translate("events", "event_class_change_status_type"),
      itemType: DropdownMenuItemType.Header,
    },
    {
      key: "event_type_situation",
      text: Tr.Translate("events", "event_type_situation"),
    },
    {
      key: "event_type_falls_in_love",
      text: Tr.Translate("events", "event_type_falls_in_love"),
    },
    {
      key: "event_type_holiday",
      text: Tr.Translate("events", "event_type_holiday"),
    },
    {
      key: "event_type_starts_mission",
      text: Tr.Translate("events", "event_type_starts_mission"),
    },
    {
      key: "event_type_starts_mission_end",
      text: Tr.Translate("events", "event_type_starts_mission_end"),
    },
    {
      key: "event_type_assumes_status",
      text: Tr.Translate("events", "event_type_assumes_status"),
    },
    {
      key: "event_type_assumes_condition",
      text: Tr.Translate("events", "event_type_assumes_condition"),
    },
    {
      key: "event_type_reaches_location",
      text: Tr.Translate("events", "event_type_reaches_location"),
    },
    {
      key: "event_type_leaves_location",
      text: Tr.Translate("events", "event_type_leaves_location"),
    },
    {
      key: "event_type_learns_skill",
      text: Tr.Translate("events", "event_type_learns_skill"),
    },
    {
      key: "event_type_learns_secret",
      text: Tr.Translate("events", "event_type_learns_secret"),
    },
    {
      key: "event_type_joins_faction",
      text: Tr.Translate("events", "event_type_joins_faction"),
    },
    {
      key: "event_type_leaves_faction",
      text: Tr.Translate("events", "event_type_leaves_faction"),
    },
    {
      key: "event_type_obtains_item",
      text: Tr.Translate("events", "event_type_obtains_item"),
    },
    {
      key: "event_type_creates_or_builds",
      text: Tr.Translate("events", "event_type_creates_or_builds"),
    },
    {
      key: "event_type_uses_object",
      text: Tr.Translate("events", "event_type_uses_object"),
    },
    {
      key: "div_4",
      text: "-",
      itemType: DropdownMenuItemType.Divider,
    },
    {
      key: "event_class_plot_milestone_type",
      text: Tr.Translate("events", "event_class_plot_milestone_type"),
      itemType: DropdownMenuItemType.Header,
    },
    {
      key: "event_type_book_closure",
      text: Tr.Translate("events", "event_type_book_closure"),
    },
    {
      key: "event_type_book_begins",
      text: Tr.Translate("events", "event_type_book_begins"),
    },
    {
      key: "event_class_strong_effects",
      text: Tr.Translate("events", "event_class_strong_effects"),
      itemType: DropdownMenuItemType.Header,
    },
    {
      key: "event_type_war",
      text: Tr.Translate("events", "event_type_war"),
    },
    {
      key: "event_type_mass_kill",
      text: Tr.Translate("events", "event_type_mass_kill"),
    },
    {
      key: "event_type_natural_disaster",
      text: Tr.Translate("events", "event_type_natural_disaster"),
    },
    {
      key: "event_type_rule_changer",
      text: Tr.Translate("events", "event_type_rule_changer"),
    },
  ] as IDropdownOption[];
};

export const getEventClassByType = (type: string) => {
  let options: IDropdownOption[] = getEventTypesOptions();

  for (let i = options.length - 1; i >= 0; i--) {
    let currentKey = options[i].key.toString();
    if (currentKey === "event_type_" + type) {
      for (let j = i; j >= 0; j--) {
        if (options[j].itemType === DropdownMenuItemType.Header) {
          return options[j].key.toString();
        }
      }
    }
  }
};

export const getEventTextByPartialKey = (partialKey: string) => {
  let options: IDropdownOption[] = getEventTypesOptions();

  for (let i = 0; i < options.length; i++) {
    let currentKey = options[i].key.toString();
    if (currentKey === "event_type_" + partialKey) {
      return options[i].text;
    }
  }
};

const DropDownOptionDisplay = (props: {
  item: ISelectableOption<any> | undefined;
}) => {
  const ref = useRef<any>();
  let notSpecial = props.item?.itemType === undefined;

  useEffect(() => {
    if (ref && ref.current) {
      let parent = ref.current.parentNode.parentNode;
      parent.className += " " + "is-event-dropdown";
    }
  }, [ref]);

  return (
    <div ref={ref}>
      {!notSpecial && <div>{props.item?.text}</div>}
      {notSpecial && (
        <EventTypeIcon
          iconKey={props.item?.key.toString() ?? ""}
          iconText={props.item?.text ?? ""}
        />
      )}
    </div>
  );
};

export const dropDownEventOption = (
  item: ISelectableOption<any> | undefined
): JSX.Element => {
  return <DropDownOptionDisplay item={item} />;
};
