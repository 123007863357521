export const Dictionary = {
  __dev_sub_name: "Développeur",
  __dev_sub_description:
    "Un abonnement de test pour vérifier le bon fonctionnement de la boutique Paypal. Cet abonnement n'est pas visible par le public et ne peut pas être acheté.",

  __single_user_mini_name: "Utilisateur unique (mini)",
  __single_user_mini_description:
    "Un abonnement destiné aux personnes qui estiment avoir besoin de plus de temps pour évaluer le produit. Cet abonnement est presque gratuit et offre un aperçu de ce que cette application peut faire pour vos projets.",
  __single_user_name: "Utilisateur unique",
  __single_user_description:
    "Un abonnement pour un usage personnel. Parfait pour les projets privés ou les amateurs.",
  __small_group_name: "Petit groupe",
  __small_group_description:
    "Cette solution intermédiaire convient aux petits groupes de travail, aux étudiants ou aux membres de la famille.",
  __office_group_name: "Bureau",
  __office_group_description:
    "Solution pour un niveau d’implication de niveau bureau. ",
  __enterprise_group_name: "Entreprise",
  __enterprise_group_description:
    "Amenez votre équipe à une productivité et une maîtrise maximales dans la rédaction de contenu. ",
  __month: "Prix ​​mensuel",
  __subscription_includes: "Cet abonnement comprend",
  __advanced_event_processing:
    "Correction automatique des événements de l'histoire.",
  __advanced_event_query: "Bot de requête d’événement.",
  __advanced_event_advanced_processing:
    "Traitement avancé des événements du tracé.",
  __book_limit_per_workgroup: "Up to 100 books for each workgroup",
  __price_yearly: "Remise de prix annuelle",
  __workgroupsNumberFeature: "Montant du groupe de travail",
  __workgroupsSizeFeature: "Taille du groupe de travail",
  __eventErrorDetection: "Détection d'erreur",
  __queryBotFeature: "Bot de requête",
  __bookAmountFeature: "Livres par groupe de travail",
  __workgroupsNumberFeatureDesc:
    "Différents groupes de travail pour organiser vos travaux.",
  __workgroupsSizeFeatureDesc:
    "Chaque groupe de travail peut avoir un ensemble différent de contributeurs, vous pouvez donc gérer différentes équipes sur différents projets.",
  __eventErrorDetectionDesc:
    "Le compilateur d'événements détectera les incohérences pour vous. ",
  __queryBotFeatureDesc:
    "Votre secrétaire IA. Posez des questions à un robot intelligent sur n'importe quel événement pour garder une trace de toute information précieuse. Fini les notes papier qui traînent dans votre bureau.",
  __bookAmountFeatureDesc:
    "Chaque groupe de travail que vous créez pourra stocker cette quantité de livres.",
  __per_month_calls: "appels/mois",
  __amount: "Quantité",
};
