import { useEffect, useState } from "react";
import "./KarmaPicker.scss";
import { IEventKarmaMap, IEventKarmaMapItem } from "../../Models/IEvent";
import {
  Dropdown,
  ISelectableOption,
  Label,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { mobileCheck } from "../../App";
import Tr from "../../Utils/Translations/Translations";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import Tools from "../../Utils/Tools";
import BetterComboBox from "../BetterComboBox/BetterComboBox";
import { GenerateAssetUrl } from "../ImageAssetLoader/ImageAssetLoader";

export interface IKarmaPickerProps {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  currentValue?: string;
  suggestions: any[];
  onRenderLabel?: (el: any) => JSX.Element;
  onChange?: (karmaMap: string) => void;
}

export const getKarmaOptions = () => {
  return [
    {
      karmaIconClass: "good-karma-icon",
      key: 1,
      text: Tr.Translate("language", "karma_shift_positive"),
      icon: GenerateAssetUrl("/Assets/Public/good_karma.svg"),
    },
    {
      karmaIconClass: "neutral-karma-icon",
      key: 2,
      text: Tr.Translate("language", "karma_shift_neutral"),
      icon: GenerateAssetUrl("/Assets/Public/neutral_karma.svg"),
    },
    {
      karmaIconClass: "bad-karma-icon",
      key: 3,
      text: Tr.Translate("language", "karma_shift_negative"),
      icon: GenerateAssetUrl("/Assets/Public/bad_karma.svg"),
    },
  ];
};

export const getKarmaShiftOptions = () => {
  return [
    {
      key: 1,
      text: Tr.Translate("language", "karma_shift_weight_small"),
    },
    {
      key: 2,
      text: Tr.Translate("language", "karma_shift_weight_small"),
    },
    {
      key: 5,
      text: Tr.Translate("language", "karma_shift_weight_normal"),
    },
    {
      key: 8,
      text: Tr.Translate("language", "karma_shift_weight_normal"),
    },
    {
      key: 15,
      text: Tr.Translate("language", "karma_shift_weight_big"),
    },
    {
      key: 20,
      text: Tr.Translate("language", "karma_shift_weight_big"),
    },
  ].map((x: { key: number; text: string }) => {
    x.text = `(+${x.key}) ${x.text}`;
    return x;
  });
};

const KarmaPicker = (props: IKarmaPickerProps) => {
  const [value, setValue] = useState<IEventKarmaMap | undefined>(undefined);

  useEffect(() => {
    let initialValue: IEventKarmaMap = props.currentValue
      ? JSON.parse(props.currentValue)
      : { Items: [] };

    setTimeout(() => {
      setValue(initialValue);
    }, 200);
  }, []);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(JSON.stringify(value));
    }
  }, [value]);

  const onChangeSelection = (
    key: "Character" | "Karma" | "Amount",
    optionValue: string | number,
    index: number
  ) => {
    if (value) {
      let value_: IEventKarmaMap = { ...value };
      (value_.Items[index] as any)[key] = optionValue;
      setValue(value_);
    }
  };

  return (
    <div>
      {props.label && (
        <div>
          {props.onRenderLabel && (
            <div>
              {props.onRenderLabel(
                <Label required={props.required}>{props.label}</Label>
              )}
            </div>
          )}
          {!props.onRenderLabel && (
            <Label required={props.required}>{props.label}</Label>
          )}
        </div>
      )}
      {value &&
        value.Items.map((x: IEventKarmaMapItem, i: number) => {
          return (
            <div
              key={i}
              className="karma-picker-input-row"
              style={mobileCheck() ? { flexDirection: "column" } : {}}
            >
              <div
                className="karma-picker-input"
                style={mobileCheck() ? { width: "100%" } : {}}
              >
                <BetterComboBox
                  selectedKey={value.Items[i].Character}
                  onChange={(e, o) => {
                    if (o !== undefined) {
                      onChangeSelection("Character", o.toString(), i);
                    }
                  }}
                  options={props.suggestions}
                  required={props.required}
                  placeholder={Tr.Translate("language", "character")}
                />
              </div>
              <div
                className="karma-picker-input"
                style={mobileCheck() ? { width: "100%" } : {}}
              >
                <Dropdown
                  selectedKey={value.Items[i].Karma}
                  onChange={(e, o) => {
                    if (o !== undefined) {
                      onChangeSelection("Karma", o.key, i);
                    }
                  }}
                  placeholder={Tr.Translate("language", "karma_shift")}
                  options={getKarmaOptions()}
                  required={props.required}
                />
              </div>
              <div
                className="karma-picker-input"
                style={mobileCheck() ? { width: "100%" } : {}}
              >
                <Dropdown
                  onRenderOption={(
                    item: ISelectableOption<any> | undefined
                  ) => {
                    return (
                      <div className="custom-karma-option">
                        <strong>
                          {item?.text.split(")")[0]}
                          {") "}
                        </strong>
                        <span>{item?.text.split(")")[1]}</span>
                      </div>
                    );
                  }}
                  selectedKey={value.Items[i].Amount}
                  onChange={(e, o) => {
                    if (o !== undefined) {
                      onChangeSelection("Amount", o.key, i);
                    }
                  }}
                  placeholder={Tr.Translate("language", "karma_shift_weight")}
                  options={getKarmaShiftOptions()}
                  required={props.required}
                />
              </div>
              <div style={{ width: "fit-content" }}>
                <CustomTooltip
                  iconName="Trash"
                  isButton
                  content={Tr.Translate("language", "delete")}
                  onClick={() => {
                    let value_: IEventKarmaMap = { ...value };
                    value_.Items = value_.Items.filter(
                      (x: IEventKarmaMapItem, j: number) => i !== j
                    );
                    setValue(value_);
                  }}
                />
              </div>
            </div>
          );
        })}
      <PrimaryButton
        onClick={() => {
          if (value) {
            let value_: IEventKarmaMap = { ...value };
            value_.Items.push({
              Character: "",
              Karma: 0,
              Amount: 0,
            });
            setValue(value_);
          }
        }}
        iconProps={{ iconName: "Add" }}
      ></PrimaryButton>
    </div>
  );
};

export default KarmaPicker;
