import "./Charts.scss";
import { Bar, Line, Pie } from "react-chartjs-2";
import { Chart, CategoryScale } from "chart.js/auto";
import Tools from "../../Utils/Tools";
Chart.register(CategoryScale);

const chartDefaults: any = {
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
};

export interface IChartsProps {
  datasets: IDataset[];
}

export interface IDatasetPoint {
  pointX: string | number;
  pointY: number;
}

export interface IDatasetPointSet {
  title: string;
  dataPoints: IDatasetPoint[];
}

export interface IDataset {
  type: "bar" | "line" | "pie";
  title: string;
  dataSets: IDatasetPointSet[];
}

const dataPointsToBarData = (points: IDatasetPointSet[]) => {
  return {
    labels: [],
    datasets: [],
  };
};
const dataPointsToLineData = (dataSets: IDatasetPointSet[]) => {
  if (dataSets.length === 0) {
    return {
      labels: [],
      datasets: [],
    };
  }

  return {
    labels: dataSets[0].dataPoints.map((x: IDatasetPoint) =>
      x.pointX.toString()
    ),
    datasets: dataSets.map((x: IDatasetPointSet) => {
      return {
        label: x.title,
        data: x.dataPoints.map((x: IDatasetPoint) => x.pointY),
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      };
    }),
  };
};

const dataPointsToPieData = (points: IDatasetPointSet[]) => {
  return {
    labels: [],
    datasets: [],
  };
};

const getChartOptions = (title: string) => {
  return {
    ...chartDefaults,
    plugins: {
      title: {
        display: true,
        text: title,
      },
    },
  };
};

const Charts = (props: IChartsProps) => {
  return (
    <div className="chart-main-wrapper">
      {props.datasets.map((x: IDataset, i: number) => {
        return (
          <div key={i} className="chart-single-item">
            {x.type === "bar" && (
              <Bar
                options={getChartOptions(x.title)}
                data={dataPointsToBarData(x.dataSets)}
              />
            )}
            {x.type === "line" && (
              <Line
                options={getChartOptions(x.title)}
                data={dataPointsToLineData(x.dataSets)}
              />
            )}
            {x.type === "pie" && (
              <Pie
                options={getChartOptions(x.title)}
                data={dataPointsToPieData(x.dataSets)}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Charts;
