import { APIUrls } from "../../ApiUrls";
import {
  IWorkgroupChatInsert,
  IWorkgroupChatUpdate,
} from "../../Models/IWorkgroupChat";
import { AjaxService, IAPIRequest, IAPIResponse } from "../AjaxService";
import { BuildParameters } from "../ApiService";

const WorkgroupChatController = {
  GetWorkgroupChatsNotRead: async (
    workgroupId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(
      APIUrls.WORKGROUP_CHAT.GET_WORKGROUP_CHAT_NOT_READ,
      [["workgroupId", workgroupId]]
    );
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetWorkgroupChats: async (
    workgroupId: number,
    bookId: number,
    pastCapDays: number,
    lastSeenId: number,
    id?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(APIUrls.WORKGROUP_CHAT.GET_WORKGROUP_CHAT, [
      ["workgroupId", workgroupId],
      ["bookId", bookId],
      ["pastCapDays", pastCapDays],
      ["lastSeenId", lastSeenId],
      ["id", id],
    ]);
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetWorkgroupChatsFile: async (
    workgroupId: number,
    bookId: number,
    id: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(
      APIUrls.WORKGROUP_CHAT.GET_WORKGROUP_CHAT_FILE,
      [
        ["workgroupId", workgroupId],
        ["bookId", bookId],
        ["id", id],
      ]
    );
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  NewWorkgroupChat: async (
    WorkgroupChat: IWorkgroupChatInsert,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.WORKGROUP_CHAT.INSERT_WORKGROUP_CHAT;
    request.method = "POST";
    request.payload = WorkgroupChat;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  UpdateWorkgroupChat: async (
    WorkgroupChat: IWorkgroupChatUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.WORKGROUP_CHAT.UPDATE_WORKGROUP_CHAT;
    request.method = "PUT";
    request.payload = WorkgroupChat;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  UpdateWorkgroupChatReadBy: async (
    workgroupId: number,
    bookId: number,
    ids: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(
      APIUrls.WORKGROUP_CHAT.UPDATE_WORKGROUP_CHAT_READ_BY,
      [
        ["workgroupId", workgroupId],
        ["bookId", bookId],
        ["ids", ids],
      ]
    );
    request.method = "POST";
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  DeleteWorkgroupChat: async (
    WorkgroupChatId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url =
      APIUrls.WORKGROUP_CHAT.DELETE_WORKGROUP_CHAT +
      "?WorkgroupChatId=" +
      WorkgroupChatId;
    request.method = "DELETE";
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
};

export default WorkgroupChatController;
