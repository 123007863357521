import { useEffect, useState } from "react";
import { eventProcessingListToOptions } from "../../Utils/EventProcessing";
import Tr from "../../Utils/Translations/Translations";
import Loadable from "../Loadable/Loadable";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import "./ManageEventButtons.scss";
import { IEvent } from "../../Models/IEvent";
import { ToastMessage } from "../../Utils/UIMessages";
import Tools from "../../Utils/Tools";
import { IAPIResponse } from "../../Services/AjaxService";
import ApiService from "../../Services/ApiService";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../Redux/RootReducer";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import { IBookTimeline } from "../../Models/IBookTimeline";
import {
  dropDownEventOption,
  getEventTypesOptions,
} from "../../Utils/EventTypeMap";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import { IBook } from "../../Models/IBook";
import { IUser } from "../../Models/IUser";
import RoleDependantDisplayer from "../RoleDependantDisplayer/RoleDependantDisplayer";
import FeedbackHandler from "../FeedbackHandler/FeedbackHandler";

export interface IManageEventButtonsProps {
  targetEvent: IEvent;
}

const getUniqueId = () => {
  return Math.floor(Math.random() * 500000).toString();
};

const ManageEventButtonsInner = (props: IManageEventButtonsProps) => {
  const dispatch = useDispatch();
  const [uniqueHandlerId] = useState<string>(getUniqueId());
  const [targetEvent, setTargetEvent] = useState<IEvent>(props.targetEvent);
  const [loading, setLoading] = useState<boolean>(false);
  const [duplicateEvent, setDuplicateEvent] = useState<boolean>(false);
  const globalEvents = useSelector(
    (state: GlobalState) => state.generic.globalevents
  );
  const timelines: IBookTimeline[] = useSelector(
    (state: GlobalState) => state.generic.bookTimelines
  );

  const everything: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.everything
  );
  const knownCharacters: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownCharacters
  );
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.generic.user
  );
  const activeBook: IBook | undefined = useSelector(
    (state: GlobalState) => state.generic.activeBook
  );
  const activeTimeline: IBookTimeline | undefined = useSelector(
    (state: GlobalState) => state.generic.activeTimeline
  );

  const globalUpdateEvents = (event: IEvent) => {
    let newList: IEvent[] = [...globalEvents];
    for (let i = 0; i < newList.length; i++) {
      if (newList[i].Id == event.Id) {
        newList[i] = { ...event };
      }
    }
    dispatch(GenericActions.setEvents(newList));
  };

  const globalRemoveEvents = () => {
    let newList: IEvent[] = [...globalEvents];
    dispatch(
      GenericActions.setEvents(
        newList.filter((x: IEvent) => x.Id !== targetEvent?.Id)
      )
    );
  };

  const addNewEvent = (data: any) => {
    let event: IEvent = { ...targetEvent, ...data } as IEvent;
    if (!event.EventType) {
      ToastMessage(
        "warning",
        Tr.Translate("language", "required_fields_missing")
      );
      return;
    }
    delete event.Id;
    event.BookId = activeBook?.Id ?? 0;
    event.OwnerId = loggedUser?.Id ?? 0;
    event.TimelineId = activeTimeline?.Id ?? 0;
    event.EventType = Tools.cleanEventTypeValueBeforeSending(event.EventType);
    setLoading(true);
    ApiService.EventController.NewEvent(event, (response: IAPIResponse) => {
      if (response.error === null) {
        event.Id = response.parsed;
        event.UpdateDT = new Date().toISOString();
        let newList: IEvent[] = [...globalEvents];
        newList.push(event);
        dispatch(GenericActions.setEvents(newList));
        ToastMessage(
          "success",
          Tr.Translate("language", "event_added_succesfully")
        );
        DismissModal("eventEditModal");
      }
      setLoading(false);
    });
  };

  const editEvent = (data: any) => {
    let event: IEvent = { ...targetEvent, ...data } as IEvent;
    event.EventType = Tools.cleanEventTypeValueBeforeSending(event.EventType);

    setTargetEvent(event);
    setLoading(true);
    ApiService.EventController.UpdateEvent(event, (response: IAPIResponse) => {
      if (response.error === null) {
        globalUpdateEvents(event);
        ToastMessage(
          "success",
          Tr.Translate("language", "event_edited_succesfully")
        );
        DismissModal("*");
      }
      setLoading(false);
    });
  };

  const deleteEvent = () => {
    setLoading(true);
    ApiService.EventController.DeleteEvent(
      targetEvent?.Id ?? 0,
      (response: IAPIResponse) => {
        if (response.error === null) {
          globalRemoveEvents();
          ToastMessage(
            "success",
            Tr.Translate("language", "event_deleted_succesfully")
          );
          DismissModal("eventDeleteModalGlobal");
        }
        setLoading(false);
      }
    );
  };

  return (
    <div>
      <div className="event-manage-buttons">
        <CustomTooltip
          iconName="Trash"
          isButton
          content={Tr.Translate("language", "delete")}
          onClick={() => {
            SummonModal(
              "eventDeleteModalGlobal-" +
                props.targetEvent.Id +
                "-" +
                uniqueHandlerId
            );
          }}
        />
        <CustomTooltip
          iconName="Edit"
          isButton
          content={Tr.Translate("language", "edit")}
          onClick={() => {
            setDuplicateEvent(false);
            SummonModal(
              "eventEditModalGlobal-" +
                props.targetEvent.Id +
                "-" +
                uniqueHandlerId
            );
          }}
        />
        <CustomTooltip
          iconName="Copy"
          isButton
          content={Tr.Translate("language", "copy")}
          onClick={() => {
            setDuplicateEvent(true);
            SummonModal(
              "eventEditModalGlobal-" +
                props.targetEvent.Id +
                "-" +
                uniqueHandlerId
            );
          }}
        />
      </div>
      <SmartModal
        title={Tr.Translate("language", "delete_event")}
        modalUniqueId={
          "eventDeleteModalGlobal-" +
          props.targetEvent.Id +
          "-" +
          uniqueHandlerId
        }
        content={
          <Loadable
            isLoading={loading}
            content={
              <div style={{ marginBottom: "1em" }}>
                {Tr.Translate("language", "action_cannot_be_undone")}
              </div>
            }
          />
        }
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: loading,
            onClick: () => {
              DismissModal(
                "eventDeleteModalGlobal-" +
                  props.targetEvent.Id +
                  "-" +
                  uniqueHandlerId
              );
            },
          },
          {
            text: Tr.Translate("language", "accept"),
            disabled: loading,
            onClick: () => {
              deleteEvent();
            },
          },
        ]}
      />
      <SmartModal
        titleBarExtra={[<FeedbackHandler section="manage-event" />]}
        title={
          duplicateEvent
            ? Tr.Translate("language", "add_new_event")
            : Tr.Translate("language", "edit_event")
        }
        modalUniqueId={
          "eventEditModalGlobal-" + props.targetEvent.Id + "-" + uniqueHandlerId
        }
        content={
          <Loadable
            isLoading={loading}
            content={
              <div>
                <MultiForm
                  disableOverflowHandler
                  onSubmit={(data: any) => {
                    if (duplicateEvent) {
                      addNewEvent(data);
                    } else {
                      editEvent(data);
                    }
                  }}
                  formUniqueId={
                    "editEventFormGlobal-" +
                    props.targetEvent.Id +
                    "-" +
                    uniqueHandlerId
                  }
                  inputs={[
                    {
                      type: "select",
                      name: "TimelineId",
                      label: Tr.Translate("language", "event_timeline"),
                      currentValue: targetEvent?.TimelineId,
                      extraParams: {
                        options: timelines.map((x: IBookTimeline) => {
                          return {
                            key: x.Id ?? 0,
                            text: x.Title,
                          };
                        }),
                      },
                    },
                    {
                      type: "customDatePicker",
                      name: "CustomDate",
                      currentValue: targetEvent?.CustomDate,
                      required: true,
                    },
                    {
                      type: "text",
                      name: "Title",
                      currentValue: targetEvent?.Title,
                      label: Tr.Translate("language", "event_title"),
                      required: true,
                      extraParams: { maxLength: 50 },
                    },

                    {
                      type: "text",
                      name: "Description",
                      currentValue: targetEvent?.Description,
                      label: Tr.Translate("language", "event_description"),
                      required: true,
                      extraParams: { maxLength: 3000 },
                    },
                    {
                      type: "select",
                      name: "EventType",
                      currentValue: targetEvent?.EventType.split(", ")
                        .filter((x: string) => x)
                        .map((x: string) => "event_type_" + x),
                      extraParams: {
                        multiSelect: true,
                        options: getEventTypesOptions(),
                        onRenderOption: dropDownEventOption,
                      },
                      label: Tr.Translate("language", "event_type"),
                      required: true,
                    },
                    {
                      type: "picker",
                      name: "CharacterList",
                      extraParams: {
                        options: eventProcessingListToOptions(knownCharacters),
                      },
                      currentValue: targetEvent?.CharacterList,
                      label: Tr.Translate("language", "event_character_list"),
                    },
                    {
                      type: "picker",
                      name: "EventKeyword",
                      extraParams: {
                        options: eventProcessingListToOptions(everything),
                      },
                      currentValue: targetEvent?.EventKeyword,
                      label: Tr.Translate("language", "event_keyword"),
                    },
                    {
                      type: "karmaPicker",
                      name: "KarmaMap",
                      extraParams: {
                        options: eventProcessingListToOptions(knownCharacters),
                      },
                      currentValue: targetEvent?.KarmaMap,
                      label: "Karma",
                    },
                  ]}
                />
              </div>
            }
          />
        }
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: loading,
            onClick: () => {
              DismissModal(
                "eventEditModalGlobalGlobal-" +
                  props.targetEvent.Id +
                  "-" +
                  uniqueHandlerId
              );
            },
          },
          {
            text: Tr.Translate("language", "accept"),
            disabled: loading,
            onClick: () => {
              triggerFormValidation(
                "editEventFormGlobal-" +
                  props.targetEvent.Id +
                  "-" +
                  uniqueHandlerId
              );
            },
          },
        ]}
      />
    </div>
  );
};

const ManageEventButtons = (props: IManageEventButtonsProps) => {
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    setReload(true);
    setTimeout(() => {
      setReload(false);
    }, 100);
  }, [props.targetEvent]);

  return (
    <RoleDependantDisplayer
      element={
        <div>
          {reload && (
            <ManageEventButtonsInner targetEvent={props.targetEvent} />
          )}
          {!reload && (
            <ManageEventButtonsInner targetEvent={props.targetEvent} />
          )}
        </div>
      }
      whiteList={["admin", "contributor"]}
    />
  );
};

export default ManageEventButtons;
