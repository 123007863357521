import exp from "constants";
import "./WorkgroupInfoCard.scss";
import { IWorkgroup } from "../../Models/IWorkgroup";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Redux/RootReducer";
import {
  ISubscription,
  ISubscriptionFeature,
} from "../../Models/ISubscription";
import Tr from "../../Utils/Translations/Translations";
import {
  getFeatureAmountLabel,
  SubscriptionFeatureListComponent,
} from "../../Pages/StorePage/StorePage";
import { useState } from "react";
import { Label, Link, Toggle } from "@fluentui/react";
import ImageAssetLoader, {
  GenerateAssetUrl,
} from "../ImageAssetLoader/ImageAssetLoader";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import StatisticPage from "../../Pages/StatisticPage/StatisticPage";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import { IUser } from "../../Models/IUser";
import RoleDependantDisplayer from "../RoleDependantDisplayer/RoleDependantDisplayer";
import FeedbackHandler from "../FeedbackHandler/FeedbackHandler";

export interface IWorkgroupInfoCardProps {}

const WorkgroupInfoCard = (props: IWorkgroupInfoCardProps) => {
  const activeWorkgroup: IWorkgroup | undefined = useSelector(
    (state: GlobalState) => state.generic.activeWorkgroup
  );
  const subscriptions: ISubscription[] = useSelector(
    (state: GlobalState) => state.generic.subscriptions
  );

  const activeSubscription: ISubscription | undefined = subscriptions.find(
    (x: ISubscription) => x.Id === activeWorkgroup?.SubscriptionID
  );

  return (
    <div className="work-group-info-card-main-wrap">
      <div className="work-group-info-main-row">
        <ImageAssetLoader
          src={
            activeWorkgroup?.WorkgroupImage
              ? activeWorkgroup?.WorkgroupImage
              : GenerateAssetUrl("/Assets/Public/default-wg-image.png")
          }
        />
        <div>
          <Label>{activeWorkgroup?.WorkgroupName}</Label>
          <div style={{ fontSize: "0.7em" }}>
            {Tr.Translate("subscription", activeSubscription?.SubscriptionName)}
            <Link onClick={() => SummonModal("workgroupInfoCardModal")}>
              {Tr.Translate("language", "expand")}
            </Link>
            <RoleDependantDisplayer
              whiteList={["admin"]}
              element={
                <Link onClick={() => SummonModal("statisticsModal")}>
                  {Tr.Translate("language", "statistics")}
                </Link>
              }
            />
          </div>
        </div>
      </div>

      <div
        className="role-label"
        dangerouslySetInnerHTML={{
          __html: Tr.Translate(
            "language",
            "your_role_in_this_workgroup"
          ).replaceAll("$ROLE", "<span>" + activeWorkgroup?.Role + "</span>"),
        }}
      ></div>
      <SmartModal
        isWideModal
        modalUniqueId="statisticsModal"
        title={Tr.Translate("language", "statistics")}
        content={<StatisticPage workgroupId={activeWorkgroup?.Id} />}
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            onClick: () => {
              DismissModal("statisticsModal");
            },
          },
        ]}
      />
      <SmartModal
        modalUniqueId="workgroupInfoCardModal"
        content={
          <SubscriptionFeatureListComponent subscription={activeSubscription} />
        }
        title={Tr.Translate(
          "subscription",
          activeSubscription?.SubscriptionName
        )}
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            onClick: () => {
              DismissModal("workgroupInfoCardModal");
            },
          },
        ]}
      />
    </div>
  );
};

export default WorkgroupInfoCard;
