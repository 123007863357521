export const Dictionary = {
  __new_book: "Añade un libro a tu estantería",
  __new_book_desc:
    "Lo primero que desea hacer aquí es crear un libro. Puede agregar un nuevo libro desde aquí.",
  __edit_workgroup: "Añade personas a tu equipo",
  __edit_workgroup_desc:
    "Aquí puede invitar a otros usuarios a acceder a este grupo de trabajo. Los usuarios invitados podrán trabajar en todos los recursos del libro.",
  __ai_box: "Herramientas de asistente virtual",
  __ai_box_desc:
    "Cuando ve este ícono en un botón, significa que puede aprovechar nuestro avanzado motor de asistente de IA para llevar la calidad de su trabajo al siguiente nivel. Algunas funciones requerirán la habilitación de suscripciones específicas.",
  __new_event_button: "Agregar eventos desde aquí",
  __new_event_button_desc:
    "Puede usar este botón para crear un nuevo evento. La fecha predeterminada del evento será la que seleccionó en la línea de tiempo. Si lo prefieres, siempre puedes cambiarlo más tarde.",
  __draft_ideas: "Toma notas de tus ideas.",
  __draft_ideas_desc:
    "Los borradores de ideas no influyen en su trabajo en el libro. Puede considerarlos notas en papel sobre conceptos que le gustaría elaborar en el futuro. Los borradores de ideas se pueden administrar por estado y pueden ser vistos por todos los miembros de su equipo.",
  __characters_meta_data: "Personajes",
  __characters_meta_data_desc:
    "Los personajes se agregan automáticamente a tu libro tan pronto como los uses dentro de un evento en la línea de tiempo. De esta manera, puedes agregar tantos personajes como desees. Una vez que se agrega un personaje, puedes agregar tantos metadatos como desees a cada personaje. También puedes agregar una imagen a cada personaje. Esta información ayudará al compilador de eventos a detectar errores de consistencia en el comportamiento de tus personajes.",

  __new_timeline_button: "Crea una nueva línea de tiempo",
  __new_timeline_button_desc:
    "Puede crear una nueva línea de tiempo para su libro desde aquí. Una línea de tiempo es una forma de organizar los eventos de su libro de manera visual. Puede agregar eventos a una línea de tiempo y verlos en orden cronológico. También puede modificar los eventos directamente desde la línea de tiempo.",
  __new_timeline_button_more:
    "Cada línea de tiempo siguiente que crees se considerará siguiente a la anterior. Todas las líneas de tiempo, en orden, representan todo el período de tiempo de tu universo narrativo. \n\nSupongamos que tu libro es una novela policiaca histórica ambientada en 1951, con una trama que termina en 1963. La línea de tiempo en este caso debería comenzar en el año 1 y terminar al menos en 1963.\nPuede tener sentido dejar un margen, en el ejemplo dado la línea de tiempo sería útil desde el año 1 hasta el año 2000.",
};
