import { APIUrls } from "../../ApiUrls";
import { IBook } from "../../Models/IBook";
import { IBookCharacterMetaData } from "../../Models/IBookCharacterMetaData";
import { IDataImage } from "../../Models/IDataImage";
import { AjaxService, IAPIRequest, IAPIResponse } from "../AjaxService";
import { BuildParameters } from "../ApiService";

const MetaDataController = {
  GetCharacterMetaData: async (
    bookId: number,
    characterName?: string,
    metadataType?: string,
    itemId?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(
      APIUrls.METADATA.GET_METADATA + "?bookId=" + bookId,
      [
        ["characterName", characterName],
        ["itemId", itemId],
        ["metadataType", metadataType],
      ]
    );
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  InsertCharacterMetaData: async (
    metaData: IBookCharacterMetaData,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.METADATA.INSERT_METADATA;
    request.method = "POST";
    request.payload = metaData;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  UpdateCharacterMetaData: async (
    metaData: IBookCharacterMetaData,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.METADATA.UPDATE_METADATA;
    request.method = "PUT";
    request.payload = metaData;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  DeleteCharacterMetaData: async (
    metaDataId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();

    request.url = BuildParameters(APIUrls.METADATA.DELETE_METADATA, [
      ["metadataId", metaDataId],
    ]);

    request.method = "DELETE";
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetDataImage: async (
    bookId: number,
    imageTarget?: string,
    itemId?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(
      APIUrls.METADATA.GET_METADATA_IMAGE + "?bookId=" + bookId,
      [
        ["imageTarget", imageTarget],
        ["itemId", itemId],
      ]
    );
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  InsertDataImage: async (
    dataImage: IDataImage,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.METADATA.INSERT_METADATA_IMAGE;
    request.method = "POST";
    request.payload = dataImage;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  UpdateDataImage: async (
    dataImage: IDataImage,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = APIUrls.METADATA.UPDATE_METADATA_IMAGE;
    request.method = "PUT";
    request.payload = dataImage;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  DeleteDataImage: async (
    dataId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(APIUrls.METADATA.DELETE_METADATA_IMAGE, [
      ["dataId", dataId],
    ]);

    request.method = "DELETE";
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
};

export default MetaDataController;
