import { Icon, Label } from "@fluentui/react";
import "./StarPicker.scss";
import { useState } from "react";

export interface IStarPickerProps {
  currentValue: number;
  onChange: (value: number) => void;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  onRenderLabel: (el: any) => JSX.Element;
}

const StarPicker = (props: IStarPickerProps) => {
  const [value, setValue] = useState<number>(props.currentValue ?? 1);

  return (
    <div>
      {props.label && (
        <div>
          {props.onRenderLabel(
            <Label required={props.required}>{props.label}</Label>
          )}
        </div>
      )}
      <div className="star-picker">
        {[1, 2, 3, 4, 5].map((i: number) => {
          return (
            <Icon
              style={{ cursor: "pointer" }}
              key={i}
              iconName={i <= value ? "FavoriteStarFill" : "FavoriteStar"}
              onClick={() => {
                setValue(i);
                props.onChange(i);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default StarPicker;
