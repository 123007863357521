import { Checkbox, Icon, PrimaryButton } from "@fluentui/react";
import "./RegistrationPage.scss";
import Tr from "../../Utils/Translations/Translations";
import { useRef, useState } from "react";
import CenterPad from "../../Components/CenterPad/CenterPad";
import Loadable from "../../Components/Loadable/Loadable";
import ImagePicker from "../../Components/ImagePicker/ImagePicker";
import ApiService from "../../Services/ApiService";
import { IUser, IUserRegister } from "../../Models/IUser";
import { IAPIResponse } from "../../Services/AjaxService";
import { ToastMessage, ToastMessageUnique } from "../../Utils/UIMessages";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../AppRoutes";
import MultiForm from "../../Components/MultiForm/MultiForm";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../Components/SmartModal/SmartModal";
import { GenerateAssetUrl } from "../../Components/ImageAssetLoader/ImageAssetLoader";
import Generic from "../../Utils/Generic";
import BetterRecaptcha from "../../Components/BetterRecaptcha/BetterRecaptcha";

const RegistrationPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [imgUrl, setImgUrl] = useState<string>("");
  const [done, setDone] = useState<boolean>(false);
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});
  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const insertRegistration = (data: any) => {
    setFormData(data);

    if (!acceptTerms) {
      ToastMessage("warning", Tr.Translate("language", "must_accept_terms"));
      return;
    }

    if (data.password != data.passwordConfirm) {
      ToastMessage("warning", Tr.Translate("language", "password_mismatch"));
      return;
    }

    if (data.password.length < 8) {
      ToastMessage(
        "warning",
        Tr.Translate("language", "password_must_be_8_long")
      );
      return;
    }

    setLoading(true);
    let userRegister: IUserRegister = {
      Email: data.email,
      Username: data.username,
      ImageBase64: imgUrl,
      Password: data.password,
      ReCaptchaToken: token,
      EmailContent: Tr.Translate("emails", "registration_link_email")
        .replaceAll("$CURRENT_DOMAIN", window.location.origin)
        .replaceAll(
          "$EMAIL_IMAGE",
          "https://emanueleuniroma2.github.io/FFASP/common/assets/smiling_woman_at_pc.png"
        ),
    };
    ApiService.SessionController.InsertRegistration(
      userRegister,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage(
            "success",
            Tr.Translate("language", "registration_done_sent_email")
          );
          setDone(true);
        } else {
          if (response.raw.status === 407) {
            ToastMessageUnique(
              "error",
              Tr.Translate("language", "recaptcha_test_failed")
            );
          }
          if (response.raw.status === 422) {
            ToastMessageUnique(
              "error",
              Tr.Translate("language", "user_already_registered")
            );
          }
        }
        setRefreshReCaptcha(!refreshReCaptcha);
        setLoading(false);
      }
    );
  };

  return (
    <div
      className="registration-page-wrap"
      style={Generic.GetRandomBackgroundUrlCss()}
    >
      <CenterPad
        content={
          <Loadable
            isLoading={loading}
            content={
              <div className="registration-inner-frame">
                {!done && (
                  <MultiForm
                    autoButtonDisable={!acceptTerms}
                    autoButton={Tr.Translate("language", "register")}
                    formUniqueId="loginForm"
                    onSubmit={insertRegistration}
                    inputs={[
                      {
                        type: "email",
                        name: "email",
                        currentValue: formData?.email,
                        label: Tr.Translate("language", "email"),
                        required: true,
                        extraParams: { maxLength: 50 },
                      },
                      {
                        type: "text",
                        name: "username",
                        currentValue: formData?.username,
                        label: Tr.Translate("language", "username"),
                        required: true,
                        extraParams: { maxLength: 50 },
                      },
                      {
                        type: "password",
                        name: "password",
                        currentValue: formData?.password,
                        label: Tr.Translate("language", "password"),
                        required: true,
                        extraParams: { maxLength: 50 },
                      },
                      {
                        type: "password",
                        name: "passwordConfirm",
                        currentValue: formData?.passwordConfirm,
                        label: Tr.Translate("language", "confirm_password"),
                        required: true,
                        extraParams: { maxLength: 50 },
                      },
                      {
                        type: "custom",
                        name: "_image",
                        element: (
                          <ImagePicker
                            text={Tr.Translate(
                              "language",
                              "image_profile_load"
                            )}
                            onLoad={setImgUrl}
                            initialImage={imgUrl}
                          />
                        ),
                      },
                      {
                        type: "custom",
                        name: "_accept_terms_and_cond",
                        element: (
                          <div>
                            <div>
                              <Link
                                to=""
                                onClick={() => {
                                  SummonModal("TermsAndConditionsModal");
                                }}
                              >
                                {Tr.Translate(
                                  "language",
                                  "read_terms_and_condition"
                                )}
                              </Link>
                            </div>
                            <br />
                            <div>
                              <Link
                                to=""
                                onClick={() => {
                                  SummonModal("PrivacyPolicyModal");
                                }}
                              >
                                {Tr.Translate(
                                  "language",
                                  "read_about_privacy_statement"
                                )}
                              </Link>
                            </div>
                            <br />
                            <Checkbox
                              onChange={(e, c) => {
                                if (c !== undefined) {
                                  setAcceptTerms(c);
                                }
                              }}
                              label={Tr.Translate("language", "terms_and_cond")}
                            />
                          </div>
                        ),
                      },
                      {
                        type: "custom",
                        name: "recaptcha",
                        element: (
                          <BetterRecaptcha
                            refresh={refreshReCaptcha}
                            emitToken={setToken}
                          />
                        ),
                      },
                    ]}
                  />
                )}
                {done && (
                  <div className="registration-done-icon">
                    <div>{Tr.Translate("language", "done")}</div>
                    <Icon iconName="SkypeCircleCheck" />
                  </div>
                )}
                <hr />
                <div className="flex-line">
                  <Link to={AppRoutes.LOGIN_PAGE}>
                    {Tr.Translate("language", "back")}
                  </Link>
                </div>
              </div>
            }
          />
        }
      />
      <SmartModal
        title={Tr.Translate("language", "read_terms_and_condition")}
        content={
          <div className="iframe-container">
            <iframe
              width={"100%"}
              height={"100%"}
              src={GenerateAssetUrl(
                "/Assets/IframeSections/terms_and_conditions.html"
              )}
            />
          </div>
        }
        modalUniqueId="TermsAndConditionsModal"
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            onClick: () => {
              DismissModal("TermsAndConditionsModal");
            },
          },
        ]}
      />
      <SmartModal
        title={Tr.Translate("language", "read_about_privacy_statement")}
        content={
          <div className="iframe-container">
            <iframe
              width={"100%"}
              height={"100%"}
              src={GenerateAssetUrl("/Assets/IframeSections/privacy.html")}
            />
          </div>
        }
        modalUniqueId="PrivacyPolicyModal"
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            onClick: () => {
              DismissModal("PrivacyPolicyModal");
            },
          },
        ]}
      />
    </div>
  );
};

export default RegistrationPage;
