import { Link } from "react-router-dom";
import { AppRoutes } from "../../AppRoutes";
import CenterPad from "../../Components/CenterPad/CenterPad";
import MultiForm from "../../Components/MultiForm/MultiForm";
import Tr from "../../Utils/Translations/Translations";
import "./ForgotPasswordPage.scss";
import { Icon, MessageBar, MessageBarType } from "@fluentui/react";
import { useState } from "react";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import { ToastMessage } from "../../Utils/UIMessages";
import { mobileCheck } from "../../App";
import Loadable from "../../Components/Loadable/Loadable";
import Generic from "../../Utils/Generic";

const ForgotPasswordPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);

  const sendResetPasswordRequest = (email: string) => {
    setLoading(true);
    ApiService.SessionController.PasswordResetEmail(
      email,
      Tr.Translate("emails", "reset_password_link_email").replaceAll(
        "$CURRENT_DOMAIN",
        window.location.origin
      ),
      (response: IAPIResponse) => {
        if (response.error === null) {
          setDone(true);
          ToastMessage(
            "success",
            Tr.Translate("language", "password_reset_email_sent")
          );
        }
        setLoading(false);
      }
    );
  };

  return (
    <div
      className="forgot-password-page-wrap"
      style={Generic.GetRandomBackgroundUrlCss()}
    >
      <CenterPad
        content={
          <Loadable
            isLoading={loading}
            content={
              <div>
                {!done && (
                  <MultiForm
                    autoButton={Tr.Translate(
                      "language",
                      "send_reset_email"
                    ).replaceAll("$CURRENT_DOMAIN", window.location.origin)}
                    formUniqueId="resetPassword"
                    inputs={[
                      {
                        label: Tr.Translate("language", "email"),
                        type: "email",
                        name: "email",
                        required: true,
                      },
                    ]}
                    onSubmit={(data: any) => {
                      sendResetPasswordRequest(data.email);
                    }}
                  />
                )}
                {done && (
                  <div className="registration-done-icon">
                    <div>{Tr.Translate("language", "done")}</div>
                    <Icon iconName="SkypeCircleCheck" />
                  </div>
                )}
                <hr />
                <div className="flex-line">
                  <Link to={AppRoutes.LOGIN_PAGE}>
                    {Tr.Translate("language", "back")}
                  </Link>
                </div>
              </div>
            }
          />
        }
      />
    </div>
  );
};

export default ForgotPasswordPage;
