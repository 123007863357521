export const Dictionary = {
  __answer_label_data: "En la fecha $DATE",
  __answer_label_age: "$CHARNAME nace el $DATE, la edad actual es $AGE",
  __answer_label_age_plus_death:
    "$CHARNAME nació el $DATE y murió el $DATE, a la edad de $AGE",
  __event_type_appear: "$CHARNAME aparece el $DATE",
  __event_type_born: "$CHARNAME nace el $DATE",
  __event_type_revealed: "$CHARNAME se revela el $DATE",
  __event_type_disappears: "$CHARNAME desaparece el $DATE",
  __event_type_changes_id:
    "$CHARNAME cambia su identidad conocida, convirtiéndose en $OBJECT el $DATE",
  __event_type_abbandons: "$CHARNAME abandona su función actual el $DATE",
  __event_type_dies: "$CHARNAME muere el $DATE",
  __event_type_teams_up_with: "$CHARNAME se alía con $OTHERCHARACTERS el $DATE",
  __event_type_challenge:
    "$CHARNAME [con $OPS_OTHERCHARACTERS] sostiene el desafío $OBJECT el $DATE",
  __event_type_meets: "$CHARNAME se encuentra con $OTHERCHARACTERS el $DATE",
  __event_type_friendship:
    "$CHARNAME se hace amigo de $OTHERCHARACTERS el $DATE",
  __event_type_friendship_end:
    "$CHARNAME deja de ser amigo de $OTHERCHARACTERS el $DATE",
  __event_type_engage:
    "$CHARNAME comienza romance con $OTHERCHARACTERS el $DATE",
  __event_type_engage_end:
    "$CHARNAME detiene el romance con $OTHERCHARACTERS el $DATE",
  __event_type_betrays: "$CHARNAME traiciona a $OTHERCHARACTERS el $DATE",
  __event_type_shares_a_secret:
    "$CHARNAME comparte el secreto de $OBJECT con $OTHERCHARACTERS el $DATE",
  __event_type_kills: "$CHARNAME mata a $OBJECT el $DATE",
  __event_type_marries: "$CHARNAME se casa con $OTHERCHARACTERS el $DATE",
  __event_type_allies: "$CHARNAME se alía con $OTHERCHARACTERS el $DATE",
  __event_type_situation:
    "$CHARNAME enfrenta la situación de $OBJECT [con $OPS_OTHERCHARACTERS] el $DATE",
  __event_type_falls_in_love:
    "$CHARNAME se enamora de $OTHERCHARACTERS el $DATE",
  __event_type_starts_mission:
    "$CHARNAME protagoniza el trabajo/misión $OBJECT el $DATE",
  __event_type_starts_mission_end:
    "$CHARNAME concluye el Trabajo/Misión $OBJECT el $DATE",
  __event_type_assumes_status:
    "$CHARNAME obtiene el estado de $OBJECT el $DATE",
  __event_type_assumes_condition:
    "$CHARNAME asume la condición de $OBJECT el $DATE",
  __event_type_reaches_location:
    "$CHARNAME llega a la ubicación de $OBJECT el $DATE",
  __event_type_leaves_location:
    "$CHARNAME deja la ubicación de $OBJECT el $DATE",
  __event_type_learns_skill:
    "$CHARNAME aprende la habilidad de $OBJECT el $DATE",
  __event_type_learns_secret: "$CHARNAME aprende sobre $OBJECT el $DATE",
  __event_type_joins_faction: "$CHARNAME se une a la facción $OBJECT el $DATE",
  __event_type_leaves_faction: "$CHARNAME deja la facción $OBJECT el $DATE",
  __event_type_obtains_item: "$CHARNAME obtiene el artículo $OBJECT el $DATE",
  __event_type_book_closure: "$CHARNAME ve el final del libro el $DATE",
  __event_type_holiday: "$CHARNAME aplaude a $OBJECT en $DATE",
  __event_type_book_begins: "$CHARNAME ve el inicio del libro el $DATE",
  __event_type_geopolitic:
    "$CHARNAME causa el efecto geopolítico de $OBJECT en $DATE",
  __event_type_natural_disaster:
    "$CHARNAME está involucrado en el desastre de $OBJECT el $DATE",
  __event_type_war:
    "$CHARNAME participó en la guerra [de $OPS_OBJECT] el $DATE",
  __event_type_mass_kill:
    "$CHARNAME causó una destrucción masiva [durante $OPS_OBJECT] el $DATE",
  __event_type_creates_or_builds:
    "$CHARNAME creó/construyó lo siguiente: $OBJECT el $DATE",
  __event_type_rule_changer:
    "$CHARNAME cambió una regla cósmica [de $OPS_OBJECT] el $DATE",
  __character_leaving_position_never_reached:
    "<strong>$CARACTER</strong> abandonó el lugar <strong>$OBJETO</strong>, una posición nunca antes alcanzada.",
  __character_cannot_marry_since_there_is_no_trust:
    "<strong>$CARACTER</strong> no debería casarse con <strong>$OBJETO</strong>, ya que no ha alcanzado el nivel adecuado de afinidad e intimidad.",
  __character_cannot_betray_since_there_is_no_trust:
    "<strong>$CARACTER</strong> en realidad no puede hacer trampa <strong>$OBJETO</strong> ya que no existe una relación de confianza entre ellos.",
  __active_character_cannot_interact_with_unknown_character:
    "<strong>$CARACTER</strong> no puede interactuar con <strong>$OBJETO</strong> ya que este personaje no se introduce en la trama.",
  __character_changing_id_to_unknown_alterego:
    "<strong>$CARACTER</strong> no puede asumir la identidad de <strong>$OBJETO</strong> ya que esta identidad no está tan introducida en la trama.",
  __no_errors_to_report:
    "No se detectaron errores en esta categoría. ¡Bien hecho!",

  __character_leaving_position_never_reached_quick_fix:
    "Para solucionar este problema, primero se debe presentar al personaje en la ubicación de destino en un evento pasado usando el evento: EVMAP#event_type_reaches_location",
  __character_cannot_marry_since_there_is_no_trust_quick_fix:
    "Para solucionar este problema, el personaje primero debe incrementar la cantidad de interacción positiva con la persona casada. La confianza se agrega automáticamente a través de interacciones positivas entre personajes. Si este problema se trata de algún personaje secundario con información de fondo reducida, puedes proceder a ignorarlo.",
  __character_cannot_betray_since_there_is_no_trust_quick_fix:
    "Para solucionar este problema, el personaje traicionado primero debe estar en condiciones de confiar en el traidor. La confianza se agrega automáticamente a través de interacciones positivas entre personajes.",
  __active_character_cannot_interact_with_unknown_character_quick_fix:
    "Para solucionar este problema, primero debes agregar el personaje desconocido a la trama de un evento pasado. Un personaje se agrega automáticamente a la trama si aparece en la lista de personajes involucrados de cualquier evento.",
  __character_changing_id_to_unknown_alterego_quick_fix:
    "Para solucionar este problema, primero debes agregar el alter ego desconocido a la trama usando dicho alter ego como un personaje independiente de un evento pasado. Un personaje se agrega automáticamente a la trama si aparece en la lista de personajes involucrados de cualquier evento. ",

  __character_befriending_disliked_person:
    "<strong>$CARACTER</strong> no puede hacerse amigo de <strong>$OBJETO</strong> porque no hay suficiente confianza entre ellos.",
  __character_befriending_disliked_person_quick_fix:
    "Para resolver este problema, el personaje que quiere hacerse amigo primero debe superar su aversión hacia la otra persona. La confianza se construye automáticamente a través de interacciones positivas entre personajes.",

  __character_sharing_secret_with_untrusted_person:
    "<strong>$CARACTER</strong> no debería compartir un secreto espontáneamente <strong>$OBJETO</strong> porque no hay suficiente confianza entre ellos.",

  __character_sharing_secret_with_untrusted_person_quick_fix:
    "Para resolver este problema, el personaje que quiere compartir el secreto primero debe poder confiar en la otra persona. La confianza se construye automáticamente a través de interacciones positivas entre personajes.",

  __character_engaging_disliked_person:
    "<strong>$CARACTER</strong> no debería comprometerse con <strong>$OBJETO</strong> porque se conocen muy poco.",
  __character_engaging_disliked_person_quick_fix:
    "Para que el grado de confianza entre los dos aumente, el personaje debe primero incrementar el número de interacciones positivas con la persona comprometida. La confianza se agrega automáticamente a través de interacciones positivas entre personajes.",
  __active_character_cannot_interact_with_unknown_character_to_him:
    "<strong>$CARACTER</strong> no puede interactuar con <strong>$OBJETO</strong> porque aún no se han conocido.",
  __active_character_cannot_interact_with_unknown_character_to_him_quick_fix:
    "Para resolver este problema, primero debes agregar el personaje desconocido a la trama usando un evento pasado. Un personaje se agrega automáticamente a la trama si aparece en la lista de personajes involucrados de cualquier evento.",
};
