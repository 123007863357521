import {
  FontSizes,
  INavLink,
  INavLinkGroup,
  MessageBar,
  MessageBarType,
  Nav,
} from "@fluentui/react";
import { IBook } from "../../Models/IBook";
import "./BooksMainGallery.scss";
import { useEffect, useState } from "react";
import { mobileCheck } from "../../App";
import ImageAssetLoader, {
  GenerateAssetUrl,
} from "../ImageAssetLoader/ImageAssetLoader";
import Tr from "../../Utils/Translations/Translations";

export interface IBooksMainGalleryProps {
  books: IBook[];
  obBookSelected: (book: IBook | undefined) => void;
}

const BooksMainGallery = (props: IBooksMainGalleryProps) => {
  return (
    <div>
      <div>
        {props.books.map((book: IBook, i: number) => (
          <div
            key={i}
            onClick={() => props.obBookSelected(book)}
            className="book-box-outer"
            style={mobileCheck() ? { fontSize: "0.8em" } : {}}
          >
            <div className="book-box">
              <ImageAssetLoader
                src={
                  book.BookImage && book.BookImage !== ""
                    ? book.BookImage
                    : "/Assets/Public/generic_book.png"
                }
                title={book.Title}
                className="book-image"
              />
              <div className="book-info">
                {book.Locked && (
                  <MessageBar messageBarType={MessageBarType.warning}>
                    {Tr.Translate("language", "book_has_been_disabled")}
                  </MessageBar>
                )}
                <div className="book-title">{book.Title}</div>
                <div className="book-description">{book.Description}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BooksMainGallery;
