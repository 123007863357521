import { PrimaryButton } from "@fluentui/react";
import BetterCoachMarks from "../BetterCoachMark/BetterCoachMark";
import "./NewEventButton.scss";
import Tr from "../../Utils/Translations/Translations";
import { useState } from "react";
import { IUser } from "../../Models/IUser";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../Redux/RootReducer";
import { IEvent } from "../../Models/IEvent";
import { IBook } from "../../Models/IBook";
import Tools from "../../Utils/Tools";
import { IBookTimeline } from "../../Models/IBookTimeline";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import { ToastMessage } from "../../Utils/UIMessages";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../SmartModal/SmartModal";
import Loadable from "../Loadable/Loadable";
import MultiForm, { triggerFormValidation } from "../MultiForm/MultiForm";
import {
  dropDownEventOption,
  getEventTypesOptions,
} from "../../Utils/EventTypeMap";
import { eventProcessingListToOptions } from "../../Utils/EventProcessing";
import RoleDependantDisplayer from "../RoleDependantDisplayer/RoleDependantDisplayer";
import FeedbackHandler from "../FeedbackHandler/FeedbackHandler";

const NewEventButton = (props: { eventsDate?: string }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [activeEvent, setActiveEvent] = useState<IEvent>();

  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.generic.user
  );
  const activeBook: IBook | undefined = useSelector(
    (state: GlobalState) => state.generic.activeBook
  );
  const activeTimeline: IBookTimeline | undefined = useSelector(
    (state: GlobalState) => state.generic.activeTimeline
  );
  const globalEvents: IEvent[] = useSelector(
    (state: GlobalState) => state.generic.globalevents
  );

  const knownCharacters: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.knownCharacters
  );
  const everything: string[] = useSelector(
    (state: GlobalState) => state.eventProcessing.everything
  );

  const addNewEvent = (data: any) => {
    let event: IEvent = { ...data } as IEvent;
    event.BookId = activeBook?.Id ?? 0;
    event.OwnerId = loggedUser?.Id ?? 0;
    event.EventType = Tools.cleanEventTypeValueBeforeSending(event.EventType);
    event.TimelineId = event.TimelineId
      ? event.TimelineId
      : activeTimeline?.Id ?? 0;
    setActiveEvent(event);
    setLoading(true);
    ApiService.EventController.NewEvent(event, (response: IAPIResponse) => {
      if (response.error === null) {
        event.Id = response.parsed;
        event.UpdateDT = new Date().toISOString();
        let newList: IEvent[] = [...globalEvents];
        newList.push(event);
        dispatch(GenericActions.setEvents(newList));
        ToastMessage(
          "success",
          Tr.Translate("language", "event_added_succesfully")
        );
        DismissModal("*");
        setActiveEvent(undefined);
      }
      setLoading(false);
    });
  };

  return (
    <div>
      <RoleDependantDisplayer
        whiteList={["admin", "contributor"]}
        element={
          <BetterCoachMarks
            coachMarkId="new_event_button"
            coachMarckTitle={Tr.Translate("coaching", "new_event_button")}
            coachMarckContent={Tr.Translate(
              "coaching",
              "new_event_button_desc"
            )}
            element={
              <PrimaryButton
                onClick={() => {
                  SummonModal("addNewEventModal");
                }}
                text={Tr.Translate("language", "new_event")}
                iconProps={{ iconName: "Add" }}
              />
            }
          />
        }
      />
      <SmartModal
        titleBarExtra={[<FeedbackHandler section="manage-event" />]}
        title={Tr.Translate("language", "add_new_event")}
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: loading,
            onClick: () => {
              DismissModal("addNewEventModal");
            },
          },
          {
            text: Tr.Translate("language", "accept"),
            disabled: loading,
            onClick: () => {
              triggerFormValidation("addEventForm");
            },
          },
        ]}
        content={
          <div>
            <Loadable
              isLoading={loading}
              content={
                <div>
                  <MultiForm
                    disableOverflowHandler
                    onSubmit={addNewEvent}
                    formUniqueId={"addEventForm"}
                    inputs={[
                      {
                        type: "customDatePicker",
                        name: "CustomDate",
                        currentValue:
                          activeEvent?.CustomDate ?? props.eventsDate,
                        required: true,
                      },
                      {
                        type: "text",
                        name: "Title",
                        currentValue: activeEvent?.Title,
                        label: Tr.Translate("language", "event_title"),
                        required: true,
                        extraParams: { maxLength: 50 },
                      },
                      {
                        type: "text",
                        name: "Description",
                        currentValue: activeEvent?.Description,
                        label: Tr.Translate("language", "event_description"),
                        required: true,
                        extraParams: { maxLength: 3000 },
                      },
                      {
                        type: "select",
                        name: "EventType",
                        currentValue: activeEvent?.EventType,
                        extraParams: {
                          multiSelect: true,
                          options: getEventTypesOptions(),
                          onRenderOption: dropDownEventOption,
                        },
                        label: Tr.Translate("language", "event_type"),
                        required: true,
                      },
                      {
                        type: "picker",
                        name: "CharacterList",
                        extraParams: {
                          options:
                            eventProcessingListToOptions(knownCharacters),
                        },
                        currentValue: activeEvent?.CharacterList,
                        label: Tr.Translate("language", "event_character_list"),
                      },
                      {
                        type: "picker",
                        name: "EventKeyword",
                        extraParams: {
                          options: eventProcessingListToOptions(everything),
                        },
                        currentValue: activeEvent?.EventKeyword,
                        label: Tr.Translate("language", "event_keyword"),
                      },
                      {
                        type: "karmaPicker",
                        name: "KarmaMap",
                        extraParams: {
                          options:
                            eventProcessingListToOptions(knownCharacters),
                        },
                        currentValue: activeEvent?.KarmaMap,
                        label: "Karma",
                      },
                    ]}
                  />
                </div>
              }
            />
          </div>
        }
        modalUniqueId={"addNewEventModal"}
      />
    </div>
  );
};

export default NewEventButton;
